import { JobsCategoriesEffect } from './categories.effect';
import { JobCandidatesEffect } from './jobCandidates.effect';
import { JobsEffect } from './jobs.effect';
import { StagesDataEffect } from './stagesData.effect';

export const effects: any[] = [JobsEffect, JobCandidatesEffect, JobsCategoriesEffect, StagesDataEffect];

export * from './jobs.effect';
export * from './stagesData.effect';
export * from './jobCandidates.effect';
export * from './categories.effect';
