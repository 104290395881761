<div class="summary-block" [ngStyle]="{ height: 30 * employmentSummary.length + 34 + 'px' }">
    <div
        class="summary-year"
        [ngStyle]="{ width: 100 / workExperience.length + '%' }"
        *ngFor="let item of workExperience; let isLast = last"
        [ngClass]="{ bg: isLast && hasWork }"
    >
        <div class="grid">
            <div
                class="inner"
                *ngFor="let work of item.work"
                [ngStyle]="{
                    width: (work?.duration / 12) * 100 + '%',
                    left: ((work?.month_started - 1) / 12) * 100 + '%'
                }"
            >
                <div
                    class="work"
                    [ngStyle]="{
                        top: 32 * work?.index + 'px',
                        backgroundColor: work?.color
                    }"
                >
                    <span>{{ work?.company }}</span>
                    <div class="arrow" *ngIf="work?.current" [ngStyle]="{ borderLeftColor: work?.color }"></div>
                </div>
            </div>
        </div>
        <div class="year">
            {{ item?.year }}
        </div>
    </div>
</div>
