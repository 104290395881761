import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class EmsiService {
    apiURL: string = environment.apiUrl;
    totkenUrl: string = 'https://auth.emsicloud.com/connect/token';
    url: string = 'https://api.emsidata.com/apis/titles';
    scope: string = 'emsi_open';
    token: string;
    version: string = '4.24';

    constructor(private http: HttpClient) {}

    getInitialTitles() {
        return [
            '.NET Application Architect',
            '.NET Application Developer',
            '.NET Architect',
            '.NET Azure Developer',
            '.NET Back End Developer',
            '.NET Consultant',
            '.NET Developer',
            '.NET Developer Consultant',
            '.NET Developer/Analyst',
            '.NET Developer/Architect',
            '.NET Developer/Programmer',
            '.NET Developer/Software Engineer',
            '.NET Developer/Team Lead',
            '.NET Front End Developer',
            '.NET Full Stack Architect',
            '.NET Full Stack Developer',
            '.NET Full Stack Engineer',
            '.NET Instructor',
            '.NET MVC Developer',
            '.NET Programmer',
            '.NET Programmer Analyst',
            '.NET SQL Developer',
            '.NET Sitecore Developer',
            '.NET Software Architect',
            '.NET Software Developer',
            '.NET Software Engineer',
            '.NET Team Lead',
            '.NET Technical Architect',
            '.NET Technical Lead',
            '.NET UI Developer',
            '.NET Web Application Developer',
            '.NET Web Developer',
            '.NET/Angular Developer',
            '.NET/Angular Engineer',
            '.NET/SQL Server Developer',
            '.NET/Sharepoint Developer'
        ];
    }

    getPainPointes() {
        return [
            { name: 'Long commute', selected: false },
            { name: 'No flexibility', selected: false },
            { name: 'Lack of career progression', selected: false },
            { name: 'Lack of appreciation', selected: false },
            { name: 'Micro management', selected: false },
            { name: 'No training budget', selected: false },
            { name: 'Lack of culture', selected: false },
            { name: 'No work / life balance', selected: false },
            { name: 'Working extra hours and weekends', selected: false }
        ];
    }

    getTitles() {
        return this.http.get(`${this.apiURL}/emsi-titles`);
    }

    skills(title: string) {
        return this.http.post<string[]>(`${this.apiURL}/jobdescription/skills`, { title });
    }

    responsibilities(title: string, skills: string = '', additionalSkills: string = '') {
        return this.http.post<string[]>(`${this.apiURL}/jobdescription/responsibilities`, {
            title,
            skills,
            additionalSkills
        });
    }

    qualifications(title: string) {
        return this.http.post<string[]>(`${this.apiURL}/jobdescription/qualifications`, { title });
    }

    roleSummary(title: string, skills: string = '', additionalSkills: string = '') {
        return this.http.post<string>(`${this.apiURL}/jobdescription/roleSummary`, { title, skills, additionalSkills });
    }

    sellTheJob(title: string, pain: string) {
        return this.http.post<string>(`${this.apiURL}/jobdescription/sellTheJob`, { title, pain });
    }
}
