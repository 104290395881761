import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lettersuppercase'
})
export class FirstThreeLetterUpperPipe implements PipeTransform {
    transform(value: any): any {
        let initials;
        if (value) {
            let splitStr = value.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                if (splitStr[i] && splitStr[i].length < 4) {
                    splitStr[i] =
                        splitStr[i].charAt(0).toUpperCase() +
                        splitStr[i].charAt(1).toUpperCase() +
                        splitStr[i].charAt(2).toUpperCase();
                } else {
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                }
            }
            initials = splitStr.join(' ');
        }
        return initials;
    }
}
