<div class="datepicker-holder" (click)="clickedOutside($event)">
    <div class="current-data">
        <div class="form-input" (click)="onShowCalendar()">
            <span class="value" *ngIf="value && value.length">{{ value }}</span>
            <span class="placeholder" *ngIf="!value || !value.length">{{ placeholder }}</span>
        </div>
    </div>
    <div class="calendar-holder" *ngIf="calendarVisible" [ngClass]="{ disabled: disabledCalendar }">
        <div class="datepicker-header">
            <button
                class="previous-years"
                [disabled]="disabledPreviousBtn()"
                [ngClass]="{ disabledbtn: disabledPreviousBtn() }"
                (click)="onPreviousYears()"
            >
                <img src="assets/images/portal/left.svg" alt="Left Icon" />
            </button>
            <div class="datepicker-years">
                <span *ngIf="activeView === 'years'">
                    <span> {{ displayYearsList[0].title }} </span> <span *ngIf="displayYearsList.length > 1"> - </span>
                    <span *ngIf="displayYearsList.length > 1">
                        {{ displayYearsList[displayYearsList.length - 1].title }}
                    </span>
                </span>
                <span *ngIf="activeView === 'months'">{{ yearsList[selectedYearIndex].title }}</span>
            </div>
            <button
                class="next-years"
                [disabled]="disabledNextBtn()"
                [ngClass]="{ disabledbtn: disabledNextBtn() }"
                (click)="onNextYears()"
            >
                <img src="assets/images/portal/right.svg" alt="Right Icon" />
            </button>
        </div>
        <div class="datepicker-years-block" *ngIf="activeView === 'years'">
            <div class="list-years">
                <div class="item" *ngFor="let year of displayYearsList">
                    <button [disabled]="year.disabled" (click)="onSelectYear($event, year, year.position)">
                        {{ year.title }}
                    </button>
                </div>
            </div>
        </div>
        <div class="datepicker-months-block" *ngIf="activeView === 'months'">
            <div class="item" *ngFor="let month of monthsList" (click)="onSelectMonth(month.value)">
                <button [disabled]="month?.disabled">{{ month.title }}</button>
            </div>
        </div>
        <div class="datepicker-footer" *ngIf="hasCheckbox" [formGroup]="form">
            <div class="chb-block">
                <p-checkbox
                    binary="true"
                    formControlName="checkbox"
                    [label]="checkboxText"
                    (onChange)="onCheckboxChange()"
                ></p-checkbox>
            </div>
        </div>
    </div>
</div>
