import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as formCountries from './../reducers/countries.reducer';

export const getCountriesState = createFeatureSelector<formCountries.CountriesState>('countries');

export const getCountriesLoading = createSelector(
    getCountriesState,
    (state: formCountries.CountriesState) => state.loading
);

export const getCountriesLoaded = createSelector(
    getCountriesState,
    (state: formCountries.CountriesState) => state.loaded
);

export const getCountriesEntities = createSelector(
    getCountriesState,
    (state: formCountries.CountriesState) => state.entities
);

export const getCountriesError = createSelector(
    getCountriesState,
    (state: formCountries.CountriesState) => state.error
);
