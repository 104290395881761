import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { documentTypeOptions, uploadedTypesOptions } from '@app/core/constants/options.constants';
import { Candidate, User } from '@app/core/models';
import { CandidateService, JobService, UtilitiesService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { SurveyService } from '@app/core/services/surveys.service';
import { WorkflowService } from '@app/core/services/workflow.service';
import { select, Store } from '@ngrx/store';
import { groupBy, omit } from 'lodash';
import moment from 'moment';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from './../../../../../core/services/user.service';
import * as fromSelectors from './../../../../../store/selectors';

@Component({
    selector: 'app-employee-item',
    templateUrl: './employee-item.component.html',
    styleUrls: ['./employee-item.component.scss']
})
export class EmployeeItemComponent implements OnInit, OnChanges, OnDestroy {
    unsubscribe: Subject<void> = new Subject<void>();
    contentLoading = false;
    candidateLoaded = false;
    editMode = false;
    showQuickActionsDropdown = false;
    @Input() employeeId;
    @Input() employeeData;
    employee: any = {};
    sections: string[] = ['job', 'personal', 'employment & education', 'answers', 'history'];
    activeSection = 'job';
    documents = {};
    users: User[];
    user: User;
    usersSubscription: Subscription;
    userSubscription: Subscription;
    showUploaderOptions = {
        // 'Employee Documents': {
        //     show: false,
        //     error: null
        // },
        // 'Personal Files': {
        //     show: false,
        //     error: null
        // },
        'Onboarding Documents': {
            show: false,
            error: null
        }
    };
    selectedDocument: any = {};
    uploadedTypesOptions: any[] = uploadedTypesOptions;
    documentTypeOptions: any[] = documentTypeOptions;
    uploadQueue: any[] = [];
    supportedFileTypes: string[];
    documentReviewModal = {
        show: false,
        data: null,
        employeeId: null
    };
    workflowModal = {
        visible: false,
        options: []
    };
    workflowControl: FormControl;
    requestLoading = false;
    constructor(
        private userService: UserService,
        private employeeService: EmployeesService,
        private candidateService: CandidateService,
        private jobService: JobService,
        public utilitiesService: UtilitiesService,
        private store: Store,
        private surveyService: SurveyService,
        private fb: FormBuilder,
        private workflowService: WorkflowService
    ) {
        this.supportedFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.oasis.opendocument.text',
            'text/rtf'
        ];
    }
    @ViewChild('uploadOptionsDropUp') uploadOptionsDropUp: ElementRef;
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.uploadOptionsDropUp && !this.uploadOptionsDropUp.nativeElement.children[0].contains(event.target)) {
            this.showUploaderOptions['Onboarding Documents'].show = false;
        }
    }

    ngOnInit(): void {
        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.users = [...users];
        });
        this.userSubscription = this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
            this.user = user;
        });
    }

    ngOnChanges(changes) {
        // console.log('changes', changes, this.employeeId);
        if (changes.employeeId) {
            this.contentLoading = true;
            this.loadCandidate();
            this.employee.id = this.employeeId;
        }
        if (changes.employeeData) {
            this.employee = this.employeeData;
        }
    }

    editProfile() {
        this.editMode = true;
    }

    onCloseEditMode() {
        this.editMode = false;
        for (let item in this.showUploaderOptions) {
            this.showUploaderOptions[item].show = false;
        }
    }

    processTabs() {
        this.sections = ['job', 'personal', 'employment & education', 'answers', 'history'];
        if (this.user && this.employee) {
            // console.log('processTabs', this.user.role, this.employee.role);
            const securedSections = ['compensation', 'time off', 'files'];
            const allowedRoles = ['account_owner', 'admin', 'hr_admin'];
            const protectedRoles = ['account_owner', 'admin', 'hr_business_partner'];
            if (
                (this.user.role === 'hr_business_partner' && !protectedRoles.includes(this.employee.role)) ||
                this.user.id === this.employee.id
            ) {
                allowedRoles.push('hr_business_partner');
                // console.log('hr_business_partner can view complensaton/files');
            }
            if (this.user.role && allowedRoles.includes(this.user.role)) {
                this.sections.splice(3, 0, ...securedSections);
            }
        }
    }

    async loadCandidate() {
        this.candidateLoaded = false;
        // const candidate: Candidate = await this.candidateService.getDbCandidate(this.employeeId);
        const candidate: Candidate = await this.candidateService.getDbCandidateFromAPI(this.employeeId).toPromise();
        if (candidate) {
            const data = omit(candidate, ['id']);
            this.employee = {
                ...this.employee,
                ...data
            };
        }
        // const candidateData$ = this.jobService.getCandidateDetails(candidate);
        const jobDetails$ = this.employeeService.getJobDetails(this.employee.id);
        // const employeeData$ = this.employeeService.getEmployee(this.employee.id);
        const documents$ = this.employeeService.getEmployeeDocuments(this.employee.id);
        const audit$ = this.employeeService.getEmployeeAudit(this.employee.id);
        console.time('fullDetailsLoad');
        forkJoin([jobDetails$, documents$, audit$])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                ([jobDetails, documents, audit]) => {
                    console.timeEnd('fullDetailsLoad');
                    this.candidateLoaded = true;
                    this.contentLoading = false;
                    // const empData: any = employeeData;
                    // if (!empData.tags) {
                    //     empData.tags = [];
                    // }
                    // Object.assign(this.employee, empData);

                    if (!this.employee.job_details) {
                        this.employee.job_details = {};
                    }
                    Object.assign(this.employee.job_details, jobDetails);
                    this.employee.documents = documents || [];
                    this.employee.audit = audit || [];

                    this.transformCandidateDataToForm(this.employee);

                    this.employeeService.getEmployeeAnswers(this.employee.id).subscribe((answers: any) => {
                        this.employee.answers = [];
                        this.employee.answers = answers;
                    });

                    this.processTabs();

                    this.groupDocuments(documents);

                    if (this.employee.job_details && this.employee.job_details.reporting) {
                        const manager = this.employee.job_details.reporting.find(
                            (u) => u.relationship === 'Reporting Manager'
                        );
                        if (manager) {
                            this.employee.reported_chain = manager;
                        }
                    }
                    console.log('🕵🏼 Employee:', this.employee);
                },
                (errorResponse) => {
                    console.error('ERROR', errorResponse);
                }
            );
    }

    private transformCandidateDataToForm(employee): any {
        this.employee.education = (employee.education && employee.education.education_history) || [];
        this.employee.employment_history =
            (employee['work-experience'] && employee['work-experience'].employment_history) || [];
        this.employee.summary = (employee['work-experience'] && employee['work-experience'].employment_summary) || '';
    }

    onChangeSection(section: string) {
        this.activeSection = section;
    }

    groupDocuments(doc) {
        this.documents = groupBy(doc, 'category');
        // if (!this.documents['Employee Documents']) {
        //     this.documents['Employee Documents'] = [];
        // }
        // if (!this.documents['Personal Files']) {
        //     this.documents['Personal Files'] = [];
        // }
        if (!this.documents['Onboarding Documents']) {
            this.documents['Onboarding Documents'] = [];
        }
        // console.log(this.documents);
    }

    documentUpdated(documents) {
        this.groupDocuments(documents);
    }

    employmentSectionUpdated() {
        this.employeeService.getEmploymentDetails(this.employee).then((r: any) => {
            // console.log(r);
            if (r) {
                if (r.education && r.education.education_history) {
                    Object.assign(this.employee.education, r.education.education_history);
                }
                if (this.employee.employment_history) {
                    Object.assign(this.employee.employment_history, r['work-experience'].employment_history);
                }
                this.employee.summary = (r['work-experience'] && r['work-experience'].employment_summary) || '';
                if (this.employee.certificates) {
                    Object.assign(this.employee.certificates, r.certificates);
                }
            }
            // console.log(this.employee);
        });
    }

    onQuickActionsClick(e) {
        e.stopPropagation();
        this.showQuickActionsDropdown = !this.showQuickActionsDropdown;
    }

    onShowWorkflowModal() {
        this.workflowControl = this.fb.control('');
        this.workflowService.getWorkflows().subscribe((workflows: any[]) => {
            // console.log('WORKFLOWS', workflows);
            this.showQuickActionsDropdown = !this.showQuickActionsDropdown;
            this.workflowModal.options = workflows
                .filter((w: any) => w?.status === 'live' && w?.rules?.type === 'manually')
                .map((w: any) => ({ label: w.title, value: w.id }));

            this.workflowModal.visible = true;
        });
    }

    onHideModal() {
        this.workflowModal = {
            visible: false,
            options: []
        };
    }

    onAddToWorkflow() {
        console.log('onAddToWorkflow', this.workflowControl.value);
        this.requestLoading = true;
        this.employeeService.addToWorkflow(this.employee.id, this.workflowControl.value).subscribe(
            () => {
                this.requestLoading = false;
                this.onHideModal();
            },
            (errorResponse) => {
                console.error(errorResponse);
                alert('Error');
                this.requestLoading = false;
                this.onHideModal();
            }
        );
    }

    async onAddEmployeeClick(event) {
        event.stopPropagation();

        this.employee.pending = false;
        this.employee.status = 'active';
        this.showQuickActionsDropdown = false;
        const user: any = await this.userService.getDbUser(this.employee.email);
        if (user) {
            await this.userService.updateDbUser(user.id, { pendingEmployee: false, role: 'employee', activated: true });
        }
        this.employeeService.updateEmployeeObj(this.employee.id, { pending: false, status: 'active' });
        this.employeeService.updateEmployee(this.employee.id, { pending: false, status: 'active' });

        this.employee.pending = false;
        this.employee.status = 'active';
        await this.employeeService.updateEmployeeData(this.employee.id, this.employee);
    }

    onAddDocumentBtnClick(key) {
        // console.log('onAddDocumentBtnClick', key, this.showUploaderOptions, this.uploadedTypesOptions);
        for (let item in this.showUploaderOptions) {
            this.showUploaderOptions[item].show = false;
        }
        this.showUploaderOptions[key].show = true;
    }

    onUploadItemClick(type, key) {
        this.selectedDocument = {
            type,
            key
        };
        this.showUploaderOptions[key].show = false;
    }

    isFileSizeValid(size: number) {
        const maxSize = 5 * 1024 * 1024;
        // console.log(size, maxSize);
        return size <= maxSize ? true : false;
    }

    private validateFileType(file: File, types: string[]) {
        return types.indexOf(file.type) !== -1;
    }

    onFileUpload(fileEvent: any) {
        const file = fileEvent.target.files[0];
        const key = this.selectedDocument.key;
        if (this.isFileSizeValid(file.size)) {
            this.showUploaderOptions[key].error = null;
            console.log('File size is valid');
        } else {
            this.showUploaderOptions[key].error = 'File size is too big';
            setTimeout(() => (this.showUploaderOptions[key].error = null), 10000);
        }
        this.contentLoading = true;
        if (this.validateFileType(file, this.supportedFileTypes)) {
            this.uploadQueue.push({
                file,
                uploadStarted: false,
                uploadFinished: false,
                progress: 0,
                success: false,
                text: file.name,
                type: this.selectedDocument.type,
                category: this.selectedDocument.key,
                extension: this.utilitiesService.getExtension(file.name)
            });
            this.processQueue();
        } else {
            this.showUploaderOptions[key].error = 'Only supported formats are: pdf, doc, docx, rtf, odt';
            this.contentLoading = false;
            setTimeout(() => (this.showUploaderOptions[key].error = null), 10000);
        }
    }

    processQueue() {
        this.uploadQueue.forEach((item) => {
            if (!item.uploadStarted && !item.uploadFinished) {
                this.uploadFile(item);
                const itemInterval = setInterval(() => {
                    if (item.uploadFinished) {
                        const itemIndex = this.uploadQueue.findIndex((ui) => ui.id === item.id);
                        if (itemIndex !== -1) {
                            this.uploadQueue.splice(itemIndex, 1);
                        }
                        clearInterval(itemInterval);
                    }
                });
            }
        });
    }

    uploadFile(item) {
        this.utilitiesService
            .readFile(item.file)
            .then((fileValue) => {
                item.uploadStarted = true;
                const uploadProgressInterval = setInterval(() => {
                    item.progress = item.progress + 1 < 100 ? item.progress + 1 : item.progress;
                }, 400);
                this.employeeService
                    .addDocumentsToEmployee(this.employee.id, {
                        document: fileValue,
                        documentType: item.type,
                        documentCategory: item.category,
                        name: item.text,
                        status: 'approved'
                    })
                    .subscribe(
                        (response: HttpResponse<any>) => {
                            item.progress = 100;
                            item.uploadFinished = true;
                            item.success = true;
                            item.fadeout = true;
                            clearInterval(uploadProgressInterval);
                            const resp: any = response;
                            this.contentLoading = false;
                            const document = this.employee.documents.find(
                                (d) => d.document_type === resp.employee.document.document_type
                            );
                            if (document) {
                                if (!document.versions) {
                                    document.versions = [];
                                }
                                document.versions.push({
                                    link: document.link,
                                    mime_type: document.mime_type,
                                    original_name: document.original_name,
                                    name: document.name,
                                    uploaded_at: document.uploaded_at
                                });
                                document.link = resp.employee.document.link;
                                document.name = resp.employee.document.name;
                                document.original_name = resp.employee.document.original_name;
                                document.size = resp.employee.document.size;
                                document.uploaded_at = resp.employee.document.uploaded_at;
                            } else {
                                this.employee.documents.push(resp.employee.document);
                            }

                            this.documentUpdated(this.employee.documents);
                            console.log(resp);
                            const audit = {
                                created_at: Math.floor(Date.now() / 1000),
                                user_id: this.user.id,
                                type: 'updated',
                                section: 'document',
                                updated: [this.selectedDocument.key]
                            };
                            this.employeeService.saveEmployeeAudit(this.employee.id, audit);
                        },
                        (error) => {
                            console.error(error);
                            item.text =
                                error && error.error && error.error.message ? error.error.error.message : 'Error';
                            item.progress = 100;
                            item.uploadFinished = true;
                            clearInterval(uploadProgressInterval);
                            this.contentLoading = false;
                        }
                    );
            })
            .catch((error) => {
                console.error(error);
                console.error('Error reading uploaded file');
            });
    }

    onShowDocument(document) {
        console.log('onShowDocument', document);
        this.documentReviewModal = {
            show: true,
            data: document,
            employeeId: this.employeeId
        };
    }

    onClosePreviewModal() {
        this.documentReviewModal = {
            show: false,
            data: null,
            employeeId: null
        };
    }

    onDocumentUpdated() {
        console.log('onDocumentUpdated');
    }

    onDownloadDocumnt(event, file) {
        event.stopPropagation();
        let name = '';
        if (this.employee.employee_id) {
            name = `${this.employee.employee_id}_`;
        }
        let documentType = this.documentTypeOptions.find((d) => d.value === file.document_type);
        const url = file.link;
        name += `${this.employee.first_name} ${this.employee.last_name}_${
            documentType ? documentType.name : file.original_name
        }_${moment().format('YYYYMMDD')}`;
        debugger;
        this.employeeService.download(url, name).subscribe();
    }

    ngOnDestroy(): void {
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }
}
