import { AbstractControl } from '@angular/forms';
export class CompareTwoFieldsValidator {
    static validate(ac: AbstractControl) {
        const salary_from = +ac.get('salary.from').value;
        const salary_to = +ac.get('salary.to').value;
        if (salary_from > salary_to) {
            ac.get('salary.to').setErrors({ WrongSalary: true });
        } else {
            return null;
        }
    }
}
