import { DragDropModule } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BarChartComponent } from '@app/core/components/job-analytics/bar-chart/bar-chart.component';
import { PieChartComponent } from '@app/core/components/job-analytics/gender-chat/pie-chart.component';
import { GraphActivityOverviewComponent } from '@app/core/components/job-analytics/graph-activity-overview/graph-activity-overview.component';
import { LineChartComponent } from '@app/core/components/job-analytics/line-chart/line-chart.component';
import { CountIterablePipe } from '@app/core/pipes/count-iterable.pipe';
import { CurrencyPromotePipe } from '@app/core/pipes/currency-promote.pipe';
import { FromNumberToTextPipe } from '@app/core/pipes/from-number-to-text.pipe';
import { FirstThreeLetterUpperPipe } from '@app/core/pipes/mytitlecase.pipe';
import { ReplacePipe } from '@app/core/pipes/replace.pipe';
import { TimeAgoPipe } from '@app/core/pipes/time-ago.pipe';
import { SmallLoaderComponent } from '@app/modules/shared/small-loader/small-loader.component';
import { DocumentPreviewComponent } from '@app/products/hire/modules/employees/document-preview/document-preview.component';
import { EmployeeAnswersComponent } from '@app/products/hire/modules/employees/employee-answers/employee-answers.component';
import { EmployeeItemEditComponent } from '@app/products/hire/modules/employees/employee-item-edit/employee-item-edit.component';
import { EmployeeItemTimelineComponent } from '@app/products/hire/modules/employees/employee-item-timeline/employee-item-timeline.component';
import { EmployeeItemComponent } from '@app/products/hire/modules/employees/employee-item/employee-item.component';
import { EmployeeModalComponent } from '@app/products/hire/modules/employees/employee-modal/employee-modal.component';
import { EmployeeTagsComponent } from '@app/products/hire/modules/employees/employee-tags/employee-tags.component';
import { CandidateBlockComponent } from '@app/products/hire/modules/jobs/candidate-block/candidate-block.component';
import { CandidateInterviewScorecardComponent } from '@app/products/hire/modules/jobs/candidate-interview-scorecard/candidate-interview-scorecard.component';
import { InterviewScorecardComponent } from '@app/products/hire/modules/jobs/candidate-interview-scorecard/interview-scorecard/interview-scorecard.component';
import { OverallRatingComponent } from '@app/products/hire/modules/jobs/candidate-interview-scorecard/overall-rating/overall-rating.component';
import { CandidateInterviewsComponent } from '@app/products/hire/modules/jobs/candidate-interviews/candidate-interviews.component';
import { InterviewCreateComponent } from '@app/products/hire/modules/jobs/candidate-interviews/interview-create/interview-create.component';
import { InterviewSelectServiceComponent } from '@app/products/hire/modules/jobs/candidate-interviews/interview-select-service/interview-select-service.component';
import { CandidateItemFeedbackComponent } from '@app/products/hire/modules/jobs/candidate-item-feedback/candidate-item-feedback.component';
import { CandidateItemNoJobComponent } from '@app/products/hire/modules/jobs/candidate-item-no-job/candidate-item-no-job.component';
import { CandidateItemTimelineComponent } from '@app/products/hire/modules/jobs/candidate-item-timeline/candidate-item-timeline.component';
import { CandidateItemComponent } from '@app/products/hire/modules/jobs/candidate-item/candidate-item.component';
// import { CandidatePreviewDocumentComponent } from '@app/products/hire/modules/jobs/candidate-preview-document/candidate-preview-document.component';
import { CandidateCertificatesComponent } from '@app/products/hire/modules/jobs/candidate-resume/candidate-certificates/candidate-certificates.component';
import { CandidateEducationComponent } from '@app/products/hire/modules/jobs/candidate-resume/candidate-education/candidate-education.component';
import { CandidateLanguagesComponent } from '@app/products/hire/modules/jobs/candidate-resume/candidate-languages/candidate-languages.component';
import { CandidateResumeComponent } from '@app/products/hire/modules/jobs/candidate-resume/candidate-resume.component';
import { WorkExperienceComponent } from '@app/products/hire/modules/jobs/candidate-resume/work-experience/work-experience.component';
import { CandidateDetailsComponent } from '@app/products/hire/modules/jobs/components/candidate-details/candidate-details.component';
import { CandidateItemProfileEditModalComponent } from '@app/products/hire/modules/jobs/components/candidate-item-profile-edit-modal/candidate-item-profile-edit-modal.component';
import { CandidateModalPeopleComponent } from '@app/products/hire/modules/jobs/components/candidate-modal-people/candidate-modal-people.component';
import { CandidateModalComponent } from '@app/products/hire/modules/jobs/components/candidate-modal/candidate-modal.component';
import { CandidateOfferBlockComponent } from '@app/products/hire/modules/jobs/components/candidate-offer-block/candidate-offer-block.component';
import { CandidateOverviewComponent } from '@app/products/hire/modules/jobs/components/candidate-overview/candidate-overview.component';
import { CandidateQuestionsComponent } from '@app/products/hire/modules/jobs/components/candidate-questions/candidate-questions.component';
import { SetRatingComponent } from '@app/products/hire/modules/jobs/components/candidate-questions/set-rating/set-rating.component';
import { CandidateTagsComponent } from '@app/products/hire/modules/jobs/components/candidate-tags/candidate-tags.component';
import { OfferLetterComponent } from '@app/products/hire/modules/jobs/components/offer-letter/offer-letter.component';
import { JobItemEditComponent } from '@app/products/hire/modules/jobs/job-item-edit/job-item-edit.component';
import { JobQualificationsModalComponent } from '@app/products/hire/modules/jobs/job-qualifications-modal/job-qualifications-modal.component';
import { ThemeComponent } from '@app/products/hire/modules/settings/preferences/workspace/theme/theme.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MomentModule } from 'angular2-moment';
import { NgDragDropModule } from 'ng-drag-drop';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ColorPickerModule } from 'ngx-color-picker';
import { CookieService } from 'ngx-cookie-service';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { TooltipModule } from 'primeng/tooltip';
import { GetFirstLetterPipe } from '../../core/pipes/get-first-letter.pipe';
import { HireEditorModule } from '../../libs/editor/editor.module';
import { DisableControlDirective } from './../../core/directives/disable-control.directive';
import { DragEnterDirective } from './../../core/directives/drag-enter.directive';
import { DragEnter1Directive } from './../../core/directives/drag-enter1.directive';
import { ToggleDetailsDirective } from './../../core/directives/toggle-details.directive';
import { httpInterceptorProviders } from './../../core/interceptors';
import { AccountTypePipe } from './../../core/pipes/account-type.pipe';
import { InitialsPipe } from './../../core/pipes/initials.pipe';
import { SortByDatePipe } from './../../core/pipes/sort-by-date.pipe';
import { SortByOrderPipe } from './../../core/pipes/sort-by-order.pipe';
import { UploadFileNamePipe } from './../../core/pipes/upload-file-name.pipe';
import { CriminalCheckModalComponent } from './../../products/hire/modules/jobs/criminal-check-modal/criminal-check-modal.component';
import { JobRequirementsModalComponent } from './../../products/hire/modules/jobs/job-requirements-modal/job-requirements-modal.component';
import { NewLocationComponent } from './../../products/hire/modules/settings/preferences/locations/new-location/new-location.component';
import { QuestionnaireTypePipe, QuestionTypePipe } from './../../products/hire/modules/settings/questionnaires/pipes';
import { AuthService } from './../auth/auth.service';
import { AcChipMaterialComponent } from './ac-chip-material/ac-chip-material.component';
import { AcChipsMaterialComponent } from './ac-chips-material/ac-chips-material.component';
import { AcChipsComponent } from './ac-chips/ac-chips.component';
import { AutoDropdownComponent } from './auto-dropdown/auto-dropdown.component';
import { CandidateFitCircleComponent } from './candidate-fit-circle/candidate-fit-circle.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ExperienceComponent } from './experience/experience.component';
import { FileTypeComponent } from './file-type/file-type.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { ListFilterComponent } from './list-filter/list-filter.component';
import { LoaderComponent } from './loader/loader.component';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { ProgressCircleComponent } from './progress-circle/progress-circle.component';
import { ReadMoreHeightComponent } from './read-more-height/read-more-height.component';
import { RequestPositionComponent } from './request-position/request-position.component';
import { ResumeFileTypeComponent } from './resume-file-type/resume-file-type.component';
import { StageColumnHeightDirective } from './stage-column-height.directive';
// import { BarChartComponent } from '@app/core/components/job-analytics/bar-chart/bar-chart.component';
// import { OfferLetterComponent } from '@app/products/hire/modules/jobs/components/offer-letter/offer-letter.component';
// import { CandidateOfferBlockComponent } from '@app/products/hire/modules/jobs/components/candidate-offer-block/candidate-offer-block.component';
// import { CandidatePreviewDocumentComponent } from '@app/products/hire/modules/jobs/candidate-preview-document/candidate-preview-document.component';
// import { FirstThreeLetterUpperPipe } from '@app/core/pipes/mytitlecase.pipe';
import { AddRoleModalComponent } from '@app/products/hire/modules/jobs/components/add-role-modal/add-role-modal.component';

@NgModule({
    declarations: [
        LoaderComponent,
        CandidateFitCircleComponent,
        FileTypeComponent,
        MultiSelectComponent,
        ProgressCircleComponent,
        ResumeFileTypeComponent,
        GetFirstLetterPipe,
        InitialsPipe,
        FirstThreeLetterUpperPipe,
        AccountTypePipe,
        QuestionTypePipe,
        QuestionnaireTypePipe,
        SortByDatePipe,
        SortByOrderPipe,
        UploadFileNamePipe,
        DisableControlDirective,
        DragEnterDirective,
        DragEnter1Directive,
        DatepickerComponent,
        ToggleDetailsDirective,
        ListFilterComponent,
        InputPhoneComponent,
        StageColumnHeightDirective,
        DropdownComponent,
        AutoDropdownComponent,
        ThemeComponent,
        AcChipsComponent,
        CountIterablePipe,
        CurrencyPromotePipe,
        FromNumberToTextPipe,
        ReplacePipe,
        SmallLoaderComponent,
        NewLocationComponent,
        JobItemEditComponent,
        CandidateItemComponent,
        CandidateItemNoJobComponent,
        CandidateItemFeedbackComponent,
        CandidateItemTimelineComponent,
        CandidateBlockComponent,
        CandidateResumeComponent,
        WorkExperienceComponent,
        CandidateLanguagesComponent,
        CandidateEducationComponent,
        CandidateCertificatesComponent,
        CandidateModalComponent,
        CandidateOverviewComponent,
        CandidateDetailsComponent,
        CandidateQuestionsComponent,
        CandidateInterviewsComponent,
        CandidateTagsComponent,
        InterviewSelectServiceComponent,
        InterviewCreateComponent,
        CandidateInterviewScorecardComponent,
        SetRatingComponent,
        InterviewScorecardComponent,
        OverallRatingComponent,
        CandidateModalPeopleComponent,
        CandidateItemProfileEditModalComponent,
        CriminalCheckModalComponent,
        TimeAgoPipe,
        ReadMoreHeightComponent,
        AcChipsMaterialComponent,
        AcChipMaterialComponent,
        RequestPositionComponent,
        MaintenancePageComponent,
        ExperienceComponent,
        PieChartComponent,
        LineChartComponent,
        BarChartComponent,
        GraphActivityOverviewComponent,
        EmployeeModalComponent,
        EmployeeItemComponent,
        EmployeeItemEditComponent,
        EmployeeItemTimelineComponent,
        EmployeeAnswersComponent,
        DocumentPreviewComponent,
        EmployeeTagsComponent,
        OfferLetterComponent,
        CandidateOfferBlockComponent,
        AddRoleModalComponent,
        JobRequirementsModalComponent,
        JobQualificationsModalComponent
    ],
    providers: [
        AuthService,
        httpInterceptorProviders,
        CookieService,
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [ENTER, COMMA]
            }
        }
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        CreditCardDirectivesModule,
        NgDragDropModule.forRoot(),
        DragDropModule,
        GooglePlaceModule,
        InfiniteScrollModule,
        AutoCompleteModule,
        CheckboxModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        HireEditorModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        PanelModule,
        PanelMenuModule,
        SelectButtonModule,
        SliderModule,
        TooltipModule,
        ChipsModule,
        AngularSvgIconModule.forRoot(),
        Ng2TelInputModule,
        AutoCompleteModule,
        RadioButtonModule,
        InputSwitchModule,
        CalendarModule,
        ChartModule,
        ColorPickerModule,
        AccordionModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatCheckboxModule,
        MatIconModule,
        MomentModule,
        NgxMaterialTimepickerModule,
        MomentModule,
        PdfViewerModule,
        NgxCurrencyModule,
        NgxDocViewerModule
    ],
    exports: [
        AccordionModule,
        AcChipsComponent,
        AcChipsMaterialComponent,
        AcChipMaterialComponent,
        LoaderComponent,
        DropdownComponent,
        AutoDropdownComponent,
        DatepickerComponent,
        ThemeComponent,
        CandidateFitCircleComponent,
        FileTypeComponent,
        MultiSelectComponent,
        ProgressCircleComponent,
        ResumeFileTypeComponent,
        ListFilterComponent,
        InputPhoneComponent,
        GetFirstLetterPipe,
        CurrencyPromotePipe,
        FromNumberToTextPipe,
        ReplacePipe,
        InitialsPipe,
        FirstThreeLetterUpperPipe,
        AccountTypePipe,
        QuestionTypePipe,
        QuestionnaireTypePipe,
        SortByDatePipe,
        SortByOrderPipe,
        UploadFileNamePipe,
        DisableControlDirective,
        DragEnterDirective,
        DragEnter1Directive,
        ToggleDetailsDirective,
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        CreditCardDirectivesModule,
        NgDragDropModule,
        DragDropModule,
        GooglePlaceModule,
        InfiniteScrollModule,
        CheckboxModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        HireEditorModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        PanelModule,
        PanelMenuModule,
        SelectButtonModule,
        SliderModule,
        TooltipModule,
        ChipsModule,
        AngularSvgIconModule,
        Ng2TelInputModule,
        AutoCompleteModule,
        RadioButtonModule,
        InputSwitchModule,
        CalendarModule,
        ChartModule,
        StageColumnHeightDirective,
        CountIterablePipe,
        PdfViewerModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatChipsModule,
        MatInputModule,
        MatIconModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatCheckboxModule,
        MomentModule,
        NgxMaterialTimepickerModule,
        NgxDocViewerModule,
        NgxCurrencyModule,
        SmallLoaderComponent,
        NewLocationComponent,
        JobItemEditComponent,
        CandidateItemComponent,
        CandidateItemFeedbackComponent,
        CandidateItemTimelineComponent,
        CandidateBlockComponent,
        CandidateResumeComponent,
        WorkExperienceComponent,
        CandidateLanguagesComponent,
        CandidateEducationComponent,
        CandidateCertificatesComponent,
        CandidateModalComponent,
        CandidateOverviewComponent,
        CandidateDetailsComponent,
        CandidateQuestionsComponent,
        CandidateInterviewsComponent,
        CandidateTagsComponent,
        InterviewSelectServiceComponent,
        InterviewCreateComponent,
        CandidateInterviewScorecardComponent,
        SetRatingComponent,
        InterviewScorecardComponent,
        OverallRatingComponent,
        CandidateModalPeopleComponent,
        CandidateItemNoJobComponent,
        CandidateItemProfileEditModalComponent,
        CriminalCheckModalComponent,
        ReadMoreHeightComponent,
        TimeAgoPipe,
        RequestPositionComponent,
        ExperienceComponent,
        PieChartComponent,
        LineChartComponent,
        BarChartComponent,
        GraphActivityOverviewComponent,
        EmployeeModalComponent,
        EmployeeItemComponent,
        EmployeeItemEditComponent,
        EmployeeItemTimelineComponent,
        EmployeeAnswersComponent,
        DocumentPreviewComponent,
        EmployeeTagsComponent,
        OfferLetterComponent,
        CandidateOfferBlockComponent,
        AddRoleModalComponent,
        JobRequirementsModalComponent,
        JobQualificationsModalComponent
    ]
})
export class SharedModule {}
