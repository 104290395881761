import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Employee } from '@app/core/models/employee';
import { UserService } from '@app/core/services/user.service';
import { download, Download } from '@app/libs/download';
import { SAVER, Saver } from '@app/libs/saver.provider';
import { environment } from '@env/environment';
import { pick, uniq } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ITag } from './../models/job';
import { UtilitiesService } from './utilities.service';

@Injectable({
    providedIn: 'root'
})
export class EmployeesService {
    apiURL: string = environment.apiUrl;
    tenantId: string;
    private employees = new BehaviorSubject<any[]>([]);
    private subject: Subject<any> = new Subject<any>();

    constructor(
        private http: HttpClient,
        private firestore: AngularFirestore,
        private utilities: UtilitiesService,
        private userService: UserService,
        @Inject(SAVER) private save: Saver
    ) {
        this.tenantId = this.utilities.getTenant();
    }

    setEmployees(employees) {
        this.employees.next(employees);
    }

    getAllEmployees(): Observable<Employee[]> {
        return this.employees.asObservable();
    }

    getUsers() {
        return this.firestore
            .collection(`tenants/${this.tenantId}/employees_data`, (ref) =>
                ref.where('role', 'in', [
                    'recruiter',
                    'admin',
                    'hr_business_partner',
                    'onboarding_business_partner',
                    'hr_admin',
                    'payroll_business_partner'
                ])
            )
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getEmployees() {
        return this.firestore
            .collection(`tenants/${this.tenantId}/employees_data`)
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((employees: any) => {
                    return employees.filter((i) => !i.offer_pending);
                })
            );
    }

    getChunkEmployees(orderBy) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/employees_data`, (ref) => ref.orderBy(orderBy))
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((employees: any) => {
                    return employees.filter((i) => !i.offer_pending);
                })
            );
    }

    // getChunkEmployees(limit, orderBy, last) {
    //     return this.firestore
    //         .collection(`tenants/${this.tenantId}/employees_data`, (ref) =>
    //         ref.orderBy(orderBy).startAfter(last).limit(limit)
    //     )
    //         .valueChanges({ idField: 'id' })
    //         .pipe(
    //             take(1),
    //             map((employees: any) => {
    //                 return employees.filter((i) => !i.offer_pending);
    //             })
    //         );
    // }

    getHrBusinessPartners() {
        return this.firestore
            .collection(`tenants/${this.tenantId}/employees_data`, (ref) =>
                ref.where('role', '==', 'hr_business_partner')
            )
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getTasks(employeeId) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/candidates/${employeeId}/assignments`)
            .valueChanges({ idField: 'id' });
    }

    getEmployeesTags(): Promise<ITag[]> {
        return new Promise(async (resolve, reject) => {
            const tags = [];
            this.firestore
                .collection(`tenants/${this.tenantId}/candidates`, (ref) => ref.where('employee', '==', true))
                .get()
                .pipe(take(1))
                .subscribe((querySnapshot) => {
                    const empArr = [];
                    querySnapshot.forEach((doc) => {
                        empArr.push(doc.data());
                    });
                    console.log('🎎 Employees amount:', empArr.length);
                    empArr.forEach((e: any) => {
                        if (e.tags && e.tags.length) {
                            e.tags.forEach((tag) => {
                                if (!tag.title && tag.hash) {
                                    tag.title = tag.hash;
                                }
                                if (tag.title) {
                                    if (!tags.find((t) => t.title === tag.title)) {
                                        tags.push(tag);
                                    }
                                }
                            });
                        }
                    });
                    return resolve(tags);
                });
        });
    }

    getEmployee(id) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/candidates`)
            .doc(id)
            .valueChanges()
            .pipe(take(1));
    }

    inviteNewEmployee(data) {
        return this.http.post(`${this.apiURL}/employees`, data);
    }

    cronTrigger(data) {
        return this.http.post(`${this.apiURL}/employees/cron-trigger`, data);
    }

    saveEmployee(id: string, data: any) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/candidates`)
            .doc(id)
            .set(data);
    }

    resendNotification(employeeId: string, workflowId: string, data) {
        return this.http.put(
            `${
                this.apiURL
            }/employees/tenants/${this.utilities.getTenant()}/${employeeId}/workflow/${workflowId}/resend-email`,
            data
        );
    }

    addToWorkflow(employeeId: string, workflowId: string) {
        return this.http.post(
            `${
                this.apiURL
            }/employees/tenants/${this.utilities.getTenant()}/${employeeId}/workflow/${workflowId}/assign`,
            {}
        );
    }

    resetWorkflow(employeeId: string, workflowId: string) {
        return this.http.post(
            `${this.apiURL}/employees/tenants/${this.utilities.getTenant()}/${employeeId}/workflow/${workflowId}/reset`,
            {}
        );
    }

    deleteWorkflow(employeeId: string, workflowId: string) {
        return this.http.delete(
            `${this.apiURL}/employees/tenants/${this.utilities.getTenant()}/${employeeId}/workflow/${workflowId}/delete`
        );
    }

    updateEmployee(id: string, data: any) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates`)
            .doc(id)
            .update(data);
    }

    updateEmployeeData(id: string, candidate: any) {
        const employeeData = pick(candidate, [
            'first_name',
            'last_name',
            'email',
            'personal',
            'job_details',
            'type',
            'unverified',
            'role',
            'job_location',
            'job_location_short',
            'employee_id',
            'start_date',
            'pending',
            'status',
            'offer_pending'
        ]);
        if (employeeData.personal) {
            employeeData.personal = {
                profile_image: employeeData.personal.profile_image || null
            };
        }
        if (employeeData.job_details) {
            employeeData.job_details = {
                designation: employeeData.job_details.designation || null,
                business_unit: employeeData.job_details.business_unit || null
            };
        }

        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/employees_data`)
            .doc(id)
            .update(employeeData);
    }

    updateEmployeeUser(email: string, data) {
        const updData: any = {};
        if (data.first_name) {
            updData.first_name = data.first_name;
            updData.last_name = data.last_name;
        }
        if (data.phone && data.phone.length) {
            updData.phone = data.phone;
        }
        return this.updateUser(email, updData);
    }

    async updateUser(email: string, data: any) {
        const user = await this.firestore
            .collection(`users`, (ref) => ref.where('email', '==', email.toLowerCase()).limit(1))
            .valueChanges({ idField: 'id' })
            .pipe(take(1))
            .toPromise();
        if (user && user[0]) {
            return this.firestore
                .collection(`users`)
                .doc(user[0].id)
                .update(data);
        }
    }

    saveJobDetails(id: string, data: any) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/candidates/${id}/details`)
            .doc('job-details')
            .set(data);
    }
    getJobDetails(id: string) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/candidates/${id}/details`)
            .doc('job-details')
            .valueChanges()
            .pipe(take(1))
            .toPromise();
    }

    getEmployeeDocuments(employeeId) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/candidates/${employeeId}/documents`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    addDocumentsToEmployee(employeeId: string, formData: object) {
        return this.http.post(
            `${this.apiURL}/employees/tenants/${this.utilities.getTenant()}/${employeeId}/documents`,
            formData
        );
    }

    uploadImportFile(formData: object) {
        return this.http.post(`${this.apiURL}/employees/import-csv`, formData);
    }

    importEmployees(data: any) {
        data.tenantId = this.utilities.getTenant();
        return this.http.post(`${this.apiURL}/employees/import`, data);
    }

    // saveEmployeeResume(employeeId: string, data: object) {
    //     return this.http.post(
    //         `${this.apiURL}/employees/tenants/${this.utilities.getTenant()}/${employeeId}/resume`,
    //         data
    //     );
    // }

    offerAccepted(candidateId: string, data: object) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/${candidateId}/offer-accepted`,
            data
        );
    }

    getEmploymentDetails(employee) {
        return new Promise(async (resolve, reject) => {
            try {
                const user: any = await this.userService.getDbUser(employee.email);
                if (user) {
                    let userId = user.id;
                    const resume = await this.userService.getUserResume(userId);
                    if (!resume) {
                        return resolve(null);
                    } else {
                        // console.log('RESUME: ', resume);
                    }

                    return resolve(resume);
                } else {
                    // candidate.audit = (await this.getCandidateAudit(candidate.id)) || {};
                    return resolve(employee);
                }
            } catch (error) {
                console.error(error);
                return reject(error);
            }
        });
    }

    removeDocument(employeeId: string, documentId: string) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates/${employeeId}/documents`)
            .doc(documentId)
            .delete();
    }

    employeeUpdated() {
        return this.subject.asObservable();
    }

    updateEmployeeObj(employeeId: string, data: any) {
        return this.subject.next({ employeeId, data });
    }

    updateEmployeeEmail(employeeId, data: any) {
        return this.http.put(`${this.apiURL}/employees/${employeeId}/update-email`, data);
    }

    filteredEmployees(data) {
        return this.http.post(`${this.apiURL}/employees/filtered`, data);
    }

    saveEmployeeAudit(employeeId, data) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates/${employeeId}/audit`)
            .add(data);
    }

    getEmployeeAudit(employeeId) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates/${employeeId}/audit`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getEmployeeAnswers(employeeId) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/candidates/${employeeId}/surveys`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getDepartmentsList() {
        return this.firestore
            .collection(`tenants/${this.tenantId}/tenant_data`)
            .doc('departments_list')
            .valueChanges()
            .pipe(
                map((doc: { data: string[] }) => (doc && doc.data ? doc.data : [])),
                take(1)
            );
    }

    getDepartments() {
        return new Promise(async (resolve, reject) => {
            try {
                const departmentsList = await this.getDepartmentsList().toPromise();
                if (departmentsList && departmentsList.length) {
                    return resolve(departmentsList);
                } else {
                    const employeesDepartments: string[] = (await this.getEmployees().toPromise())
                        .filter(
                            (employee: any) =>
                                employee.job_details &&
                                employee.job_details.department &&
                                employee.job_details.department.length
                        )
                        .map((employee: any) => employee.job_details.department);
                    if (employeesDepartments && employeesDepartments.length) {
                        const departments = uniq(employeesDepartments);
                        return resolve(departments);
                    } else {
                        return resolve([]);
                    }
                }
            } catch (error) {
                return reject(error);
            }
        });
    }

    approveDocument(employeeId: string, documentId: string) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/candidates/${employeeId}/documents`)
            .doc(documentId)
            .update({ status: 'approved' });
    }

    updateEngagment(employeeId: string, workflowId: string, seriesId, data) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/workflows/${workflowId}/series/${seriesId}/engagement`)
            .doc(employeeId)
            .update(data);
    }

    setEngagment(employeeId: string, workflowId: string, seriesId, data) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/workflows/${workflowId}/series/${seriesId}/engagement`)
            .doc(employeeId)
            .set(data);
    }

    rejectDocument(employeeId: string, data) {
        return this.http.post(
            `${this.apiURL}/employees/tenants/${this.utilities.getTenant()}/${employeeId}/reject-document`,
            data
        );
    }

    download(url: string, filename?: string): Observable<Download> {
        return this.http
            .get(url, {
                reportProgress: true,
                observe: 'events',
                responseType: 'blob'
            })
            .pipe(download((blob) => this.save(blob, filename)));
    }

    updateAlgoliaVisitsCount(id) {
        const tenantId = `${this.utilities.getTenant()}`;
        return new Promise(async (resolve, reject) => {
            const algolia: any = await this.firestore
                .collection(`tenants/${tenantId}/algolia`)
                .doc(id)
                .valueChanges()
                .pipe(take(1))
                .toPromise();
            const today = moment().format('DD-MM-YYYY');
            // console.log('updateAlgoliaVisitsCount', id, algolia[today]);
            if (algolia) {
                let sum = algolia.sum + 1 || 0;
                const data = {
                    [today]: algolia[today] + 1 || 1,
                    sum
                };
                await this.firestore
                    .collection(`tenants/${tenantId}/algolia`)
                    .doc(id)
                    .update(data);
            } else {
                const data = {
                    [today]: 1,
                    sum: 1
                };
                await this.firestore
                    .collection(`tenants/${tenantId}/algolia`)
                    .doc(id)
                    .set(data);
            }
            return resolve(null);
        });
    }
}
