<div class="overlay" (click)="onHideModal()"></div>
<div class="general-modal">
    <div class="general-modal__header">
        <div class="title">
            <span>Contact</span>
        </div>
        <button type="button" class="btn btn-close-icon" (click)="onHideModal()"></button>
    </div>
    <div class="general-modal__content">
        <form [formGroup]="form">
            <div class="form-content-section">
                <div class="form-content-holder">
                    <div class="form-block">
                        <div class="input-block">
                            <mat-form-field appearance="outline">
                                <mat-label>Full Name</mat-label>
                                <input type="text" formControlName="fullName" matInput />
                                <mat-error
                                    class="error-message"
                                    *ngIf="!form.get('fullName').valid && !form.get('fullName').touched"
                                >
                                    Full Name is required
                                </mat-error>
                                <mat-error class="error-message" *ngIf="form.get('fullName').errors?.LessThenTwoWords">
                                    Full Name must have two words minimum
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-block form-block-columns">
                        <div class="form-block-col input-block">
                            <mat-form-field appearance="outline">
                                <mat-label>Email Address</mat-label>
                                <input type="text" formControlName="email" email matInput />
                                <mat-error class="error-message" *ngIf="form.get('email').errors?.EmailNotUnique">
                                    Email Address already used
                                </mat-error>
                                <mat-error
                                    class="error-message"
                                    *ngIf="
                                        !form.controls.email.valid &&
                                        form.controls.email.touched &&
                                        !form.get('email').errors?.EmailNotUnique
                                    "
                                >
                                    Email Address is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col form-block-relative">
                            <label class="form-block-label pse-mat-label">Mobile Number</label>
                            <app-input-phone [country]="initialCountry" formControlName="phone"> </app-input-phone>
                            <mat-error class="error-message" *ngIf="form.get('phone').errors?.incorrect_number">
                                Mobile Number is invalid
                            </mat-error>
                        </div>
                    </div>

                    <div class="form-block form-block-relative">
                        <label class="form-block-label pse-mat-label" [ngClass]="{ empty: isEmpty }"
                            >Physical Address</label
                        >
                        <div class="input-block">
                            <input type="hidden" formControlName="location" id="location-input" />
                            <input
                                type="text"
                                class="form-input"
                                formControlName="location"
                                placeholder=""
                                ngx-google-places-autocomplete
                                #placesRef="ngx-places"
                                #locationInputRef
                                [options]="locationOptions"
                                (onAddressChange)="onLocationChange($event)"
                                [(value)]="inputAddress"
                                (change)="handleAddressTextChange($event)"
                                (focus)="focused()"
                                (blur)="blured()"
                            />
                            <div
                                class="error-message"
                                *ngIf="!form.controls.location.valid && form.controls.location.touched"
                            >
                                Physical Address is required
                            </div>
                        </div>
                    </div>

                    <div class="form-block">
                        <div class="form-block-columns" *ngIf="appliedInfos.length">
                            <div
                                class="form-block-col"
                                *ngFor="let mediaInfo of appliedInfos; index as i"
                                [ngClass]="{ 'with-separator': i % 2 == 0 }"
                            >
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{ mediaInfo.label }}</mat-label>
                                        <input type="text" [formControlName]="mediaInfo.value" matInput />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p-dropdown
                        *ngIf="notAppliedInfos && notAppliedInfos.length > 1"
                        [options]="notAppliedInfos"
                        (onChange)="selectAppliedInfo($event)"
                    >
                        <ng-template let-item pTemplate="selectedItem">
                            <div class="btn-white">
                                <div>
                                    <img src="assets/images/plus-icon.svg" width="16" height="16" />
                                </div>
                                <span>Add Contact Info</span>
                            </div>
                        </ng-template>
                        <ng-template let-new pTemplate="item">
                            <div *ngIf="!new.isStandart" class="social-media" style="display: flex;">
                                <div class="social-media-icon">
                                    <img src="assets/images/{{ new?.icon }}" width="13" height="13" />
                                </div>
                                <div class="social-media-title">
                                    {{ new?.label }}
                                </div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
        </form>
    </div>
    <div class="general-modal__footer">
        <button class="btn btn-primary w140 mla" (click)="submitForm()">Update User</button>
    </div>
</div>
