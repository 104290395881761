import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { iconTypeOptions } from '@app/core/constants/settings.constants';
import { GeoService } from '@app/core/services/geo.service';
import { select, Store } from '@ngrx/store';
import { pick, values } from 'lodash';
import * as moment from 'moment';
import { Message, SelectItem } from 'primeng/api';
import { Slider } from 'primeng/slider';
import { forkJoin, from, Subscription } from 'rxjs';
import { debounceTime, delay, distinct, filter, first, take } from 'rxjs/operators';
import { JobCatalogue } from '../../../../../core/models/job_catalogue';
import { User } from '../../../../../core/models/user';
import { FormHelperService } from '../../../../../core/services/form-helper.service';
import { JobService } from '../../../../../core/services/job.service';
import { EditorValidator } from '../../../../../core/validators/description.validator';
import * as fromJobsStore from '../store';
import * as fromJobsStoreActions from '../store/actions';
import { JobCategory } from './../../../../../core/models/cateogory';
import { SdkJob } from './../../../../../core/models/job';
import { EmsiService } from './../../../../../core/services/emsi.service';
import { TenantService } from './../../../../../core/services/tenant.service';
import { UtilitiesService } from './../../../../../core/services/utilities.service';
import { XLSXService } from './../../../../../core/services/xlsx.service';
import { CompareTwoFieldsValidator } from './../../../../../core/validators/compareTwoFields.validator';
import { ConditionalValidator } from './../../../../../core/validators/conditional.validator';
import * as fromStore from './../../../../../store';
import * as fromSelectors from './../../../../../store/selectors';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'DD MMMM YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};
@Component({
    selector: 'app-job-item-edit',
    templateUrl: './job-item-edit.component.html',
    styleUrls: ['./job-item-edit.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class JobItemEditComponent implements OnInit, OnDestroy {
    @Input()
    @HostBinding('class.editMode')
    editMode = false;
    @Input() action;
    jobDetailsForm: FormGroup;
    applicationsForm: FormGroup;

    pipelineForm: FormGroup;

    @Input() job: SdkJob;
    @Input() title: string;
    titleMaxLength = 250;

    @Output() setEditMode = new EventEmitter<boolean>();
    @Output() onJobUpdate = new EventEmitter<any>();
    @Output() jobPublished = new EventEmitter<any>();
    @Output() jobIdChanged = new EventEmitter<string>();
    @Output() closeComponent = new EventEmitter<any>();

    user: User;
    users: User[];
    accountOwners: SelectItem[] = [];
    recruitersDefaults: SelectItem[] = [];
    recruiters: User[] = [];
    descriptions: JobCatalogue[] = [];

    categories: JobCategory[] = [];
    updatedCategories: JobCategory[] = [];
    jobTypeOptions: SelectItem[];
    contractDuration: SelectItem[];
    educationOptions: SelectItem[];
    hiresOptions: SelectItem[];
    experienceOptions: SelectItem[];
    salaryOptions: SelectItem[];
    joblistingOptions: SelectItem[];
    questionnaireOptions: SelectItem[];
    applicationFieldsOptions: SelectItem[];
    hiringManagersOptions: SelectItem[];
    JobDescriptionOptions: SelectItem[];
    pipelineOptions: SelectItem[];
    tenantLocationOptions: any[] = [];
    companyOptions: SelectItem[] = [];
    currencyOptions: SelectItem[] = [];
    businessUnitOptions: SelectItem[] = [];
    targetJobCategoryList = [];
    targetIndustryList = [];
    targetSeniorityList = [];
    yearsOfExperienceOptions = [];
    jobStatusOptions = [];
    activeSection = 'job-details';
    // activeSection = 'hiring-team';
    sections = ['job-details', 'applications'];
    contentLoading = false;
    disableDropdown = false;
    disableEdit = false;
    jobOwner = '';
    disableContractDuration = true;

    selectedJob: string;
    locationOptions: any;
    isJobOwner = false;
    allowJobChange = false;
    msgs: Message[] = [];
    str_array = [];
    req_str_array = [];
    selectedOwner: any;
    baseUrl: string;

    matchingMap = {
        education: 'Education',
        job_titles: 'Job Titles',
        skills: 'Skills',
        industries: 'Industries',
        languages: 'Languages',
        certifications: 'Certifications',
        executive_type: 'Executive Type',
        management_level: 'Management Level'
    };
    stageWeighting: any = {};
    @ViewChild('hcSlider') hcSlider: Slider;
    // stageSettingsForm: FormGroup;
    preferences;
    default_pipeline;
    createNewLocation = false;
    createNewPipeline = false;
    dropdownValueUpdated = false;
    iconTypeOptions = iconTypeOptions;
    isFormLoading = true;
    isShowAdvertisingFields: boolean;
    timeZones;
    setSuggestions = false;

    isShowTakeOwnershipModal = false;
    takeOwnershipConfirm: FormGroup;
    takeOwnershipRecruiters: any[] = [];

    config: any = {};
    locations: any[] = [];
    pipelines: any[] = [];
    autotags: any = {};
    integrations: any = {};

    mode: string;
    dataLoaded: boolean = false;
    jdEnabled: boolean;
    emsiTitles: string[] = [];
    filteredTitlesOptions: string[] = [];
    jobDescription = {
        skills: [],
        additionalSkills: [],
        requirements: '',
        qualifications: '',
        roleSummary: '',
        pain: [],
        sellTheJob: '',
        result: ''
    };
    skillsList: any[] = [];
    painPoints: any[] = [];
    additionalSkills: any[] = [];
    showRequirementsModal = false;
    showQualificationsModal = false;
    skillsLoading = false;

    // chips config
    selectableAdditionalSkill = true;
    removableAdditionalSkill = true;
    addOnBlurAdditionalSkill = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    subscribtions: Subscription = new Subscription();
    hiringType = 'new';

    navSections: Array<{ title: string; value: string }> = [
        {
            title: 'Settings',
            value: 'applications'
        }
    ];

    constructor(
        private route: ActivatedRoute,
        private jobService: JobService,
        private store: Store<fromStore.State>,
        private jobsStore: Store<fromJobsStore.JobsState>,
        private fb: FormBuilder,
        private router: Router,
        private formHelper: FormHelperService,
        private xlsxService: XLSXService,
        private utilities: UtilitiesService,
        private geoService: GeoService,
        private tenantService: TenantService,
        private emsiService: EmsiService
    ) {
        this.tenantService.init();
        this.baseUrl = this.utilities.getHireBaseUrl();
        this.route.paramMap.subscribe((params: ParamMap) => {
            const section = this.route.snapshot.queryParamMap.get('section');
            // console.log('ROUTE CHANGE:', section);
            if (section && this.sections.indexOf(section) !== -1) {
                this.activeSection = section;
                this.router.navigate([], {
                    queryParams: {
                        section: null
                    },
                    queryParamsHandling: 'merge'
                });
            }
        });

        this.initForms();
        this.pipelineFormInit();

        // Initial values
        this.emsiTitles = this.emsiService.getInitialTitles();
        this.painPoints = this.emsiService.getPainPointes();

        // Options
        this.jobTypeOptions = [
            { label: 'Full Time', value: 'permanent' },
            { label: 'Temporary', value: 'temporary' },
            { label: 'Fixed Term', value: 'fixed_term' },
            { label: 'Freelance', value: 'freelance' },
            { label: 'Internship', value: 'internship' }
        ];

        this.contractDuration = [
            { label: 'More than 6 Months', value: 'more_6_month' },
            { label: '3 to 6 Months', value: '3_to_6_month' },
            { label: '1 to 3 Months', value: '1_to_3_month' },
            { label: 'Less than 1 Month', value: 'less_1_month' }
        ];

        this.currencyOptions = [
            { label: 'ZAR - South African Rand', value: 'ZAR' },
            { label: 'USD - US Dollar', value: 'USD' },
            { label: 'KES - Kenyan Shilling', value: 'KES' },
            { label: 'TZS - Tanzanian Shilling', value: 'TZS' },
            { label: 'UGX - Ugandan Shilling', value: 'UGX' },
            { label: 'NGN - Nigerian Naira', value: 'NGN' }
        ];

        for (let i = 1; i <= 100; i++) {
            this.yearsOfExperienceOptions.push({ value: i, label: i + ' Year' + (i !== 1 ? 's' : '') });
        }

        this.jobStatusOptions = [
            {
                label: 'Published',
                value: 'published',
                text: 'Visible on your career and employee portal',
                img: 'published'
            },
            { label: 'Internal', value: 'internal', text: 'Visible only on your employee portal', img: 'internal' },
            { label: 'Private', value: 'private', text: 'Visible only to recruiters and hiring team ', img: 'private' }
        ];

        this.hiresOptions = [];
        for (let i = 1; i <= 100; i++) {
            this.hiresOptions.push({ label: `${i} ${i === 1 ? 'hire' : 'hires'}`, value: i });
        }
        this.hiresOptions.push({ label: 'Ongoing', value: 'ongoing' });

        this.educationOptions = [
            { label: 'Unspecified', value: 'unspecified' },
            { label: 'High School or Equivalent', value: 'school' },
            { label: 'Certification', value: 'certification' },
            { label: 'Vocational', value: 'vocational' },
            { label: 'Associate Degree', value: 'associate' },
            { label: 'Bachelors Degree', value: 'bachelors' },
            { label: 'Masters Degree', value: 'masters' },
            { label: 'Professional', value: 'professional' }
        ];

        this.educationOptions = [
            { label: 'Master / Post-Graduate / PhD', value: 'masters' },
            { label: 'Bachelor / Graduate', value: 'bachelors' },
            { label: 'Vocational / Diploma / Associates degree', value: 'vocational' },
            { label: 'GCSE / A-Level / Highschool / GED', value: 'school' }
        ];

        this.experienceOptions = [
            { label: 'Executive/Director', value: 'executive' },
            { label: 'Manager', value: 'senior' },
            { label: 'Mid-Senior level', value: 'mid' },
            { label: 'Entry level/Graduate', value: 'entry' },
            { label: 'Student/Trainee', value: 'student' }
        ];

        this.salaryOptions = [
            { label: 'per hour', value: 'hourly' },
            { label: 'per day', value: 'dayly' },
            { label: 'per week', value: 'weekly' },
            { label: 'per month', value: 'monthly' },
            { label: 'per year', value: 'yearly' }
        ];

        this.joblistingOptions = [
            { label: 'Default - Visible', value: 'default' },
            { label: 'Auto-matching off', value: 'auto-matching-off' },
            { label: 'Hidden', value: 'hidden' }
        ];

        this.businessUnitOptions = [
            { value: 'Intelligent Workplace', label: 'Intelligent Workplace' },
            { value: 'Intelligent Business Applications', label: 'Intelligent Business Applications' },
            { value: 'Intelligent Security', label: 'Intelligent Security' },
            { value: 'Intelligent Infrastructure', label: 'Intelligent Infrastructure' },
            { value: 'Intelligent Customer Experience', label: 'Intelligent Customer Experience' },
            { value: 'Geography', label: 'Geography' },
            { value: 'Cross Go-To-Market', label: 'Cross Go-To-Market' },
            { value: 'MEA Business Services', label: 'MEA Business Services' },
            { value: 'Services', label: 'Services' },
            { value: 'Human Resources', label: 'Human Resources' },
            { value: 'Finance', label: 'Finance' },
            { value: 'I&T', label: 'I&T' },
            { value: 'Marketing', label: 'Marketing' },
            { value: 'Risk', label: 'Risk' },
            { value: 'Compliance & Legal', label: 'Compliance & Legal' },
            { value: 'Other', label: 'Other' }
        ];

        this.JobDescriptionOptions = [];
        this.xlsxService
            .getJobCatalogues()
            .pipe(first())
            .subscribe((descriptions: JobCatalogue[]) => {
                this.descriptions = descriptions;
                descriptions.forEach((q) => this.JobDescriptionOptions.push({ label: q.Role, value: q.Role }));
            });

        this.applicationFieldsOptions = [
            { label: 'Required', value: 'required' },
            { label: 'Optional', value: 'optional' },
            { label: 'Disabled', value: 'disabled' }
        ];

        this.jobService
            .getTaxonomyEndpoints(null)
            .pipe(take(1))
            .subscribe(
                (data: any) => {
                    const [industry, jobcategory, seniority] = data;
                    this.targetIndustryList = industry;
                    this.targetJobCategoryList = jobcategory;
                    this.targetSeniorityList = seniority;
                },
                (err) => {
                    console.log(err);
                }
            );

        this.hiringManagersOptions = [];
        this.locationOptions = {
            bounds: null,
            componentRestrictions: {
                country: []
            }
        };
    }

    ngOnDestroy(): void {
        this.subscribtions.unsubscribe();
    }

    ngOnInit(): void {
        console.log('📓 JOB', this.job);
        if (this.job.description && this.job.requirements && this.job.role) {
            this.disableDropdown = true;
            this.disableEdit = true;
            this.selectedJob = this.job.role;
        }

        this.contentLoading = true;
        this.subscribtions.add(
            forkJoin([
                this.geoService.getAllTimeZones(),
                this.tenantService.config(),
                this.tenantService.locations(),
                this.tenantService.pipelines(),
                this.tenantService.autotags(),
                this.tenantService.integrations(),
                this.emsiService.getTitles()
            ]).subscribe(([timeZones, config, locations, pipelines, autotags, integrations, emsiTitles]) => {
                this.dataLoaded = true;

                this.config = config;
                this.timeZones = timeZones;
                this.locations = locations.filter((l) => l.name !== 'Any of the Dimension Data Offices');
                this.pipelines = pipelines;
                this.autotags = autotags;
                this.integrations = integrations;
                this.emsiTitles = emsiTitles;

                if (this.integrations && this.integrations.jobdescription) {
                    this.jdEnabled = true;
                }
                this.contentLoading = false;

                this.pipelineOptions = [];
                if (this.pipelines) {
                    this.pipelines.forEach((p) => {
                        let pipe = `${p.name}: `;
                        p.stages.forEach((s, i) => {
                            if (i === p.stages.length - 1) {
                                pipe += s.stage_name;
                            } else {
                                pipe += s.stage_name + ' > ';
                            }
                        });
                        this.pipelineOptions.push({ label: pipe, value: p.id, title: pipe.split(':')[0] });
                    });
                    this.pipelineOptions.push({ label: 'Add a new pipeline', value: '#pipeline' });
                    this.default_pipeline = this.pipelines.find((p) => p.default_pipeline);
                    if (this.default_pipeline) {
                        this.applicationsForm.get('pipeline').patchValue(this.job.pipeline || this.default_pipeline.id);
                    }
                }

                if (this.locations) {
                    this.locations.forEach((l) => {
                        this.tenantLocationOptions.push({
                            name: l.name,
                            location: l.location_short,
                            id: l.location,
                            currency: l.currency
                        });
                    });
                    this.tenantLocationOptions.push({
                        name: 'Add a new location',
                        id: '#location',
                        location: 'Add a new location'
                    });
                }
                if (this.autotags) {
                    this.autotags.ex_internal.forEach((n) => {
                        this.companyOptions.push({ label: n, value: n });
                    });
                }
                this.populateForms();
                if (this.job && (!this.job.location || !this.job.location.length)) {
                    this.jobDetailsForm.get('company').patchValue(this.config.title);
                    // let location = this.locations.find((l) => l.primary_location);
                    // if (location && !this.job.is_remote) {
                    //     this.jobDetailsForm.get('location').setValue([location.location]);
                    // }
                } else {
                    this.jobDetailsForm.get('company').patchValue(this.job.company);
                    this.jobDetailsForm.get('location').setValue(this.job.location);
                }
                this.dropdownValueUpdated = true;
                this.editorAutofocusFix();

                this.mode = this.job && this.job.id && this.job.created_at ? 'edit' : 'new';
                console.log('🐝 MODE:', this.mode);

                if (this.jdEnabled && (this.mode === 'new' || (this.mode === 'edit' && !this.job.jdAiCompleted))) {
                    this.navSections.unshift(
                        ...[
                            {
                                title: 'Details',
                                value: 'details'
                            },
                            {
                                title: 'Job Description',
                                value: 'description'
                            }
                        ]
                    );
                    this.sections.splice(1, 0, 'details', 'description');
                }
            })
        );
        // Get current user
        this.subscribtions.add(
            this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
                this.user = { ...user };
                if (!this.job.title.length && !this.job.ref.length && this.job.status === 'BUILD') {
                    this.job.owner = this.user.id;
                }
                if (
                    this.job.owner === user.id ||
                    user.role === 'admin' ||
                    user.role === 'account_owner' ||
                    user.role === 'recruiter'
                ) {
                    this.isJobOwner = true;
                    this.jobOwner = `${user.first_name} ${user.last_name}`;
                }
                if (this.job.owner === user.id) {
                    this.allowJobChange = true;
                }
            })
        );

        this.subscribtions.add(
            this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
                this.takeOwnershipRecruiters = users
                    .filter((user) => ['admin', 'recruiter'].indexOf(user.role) !== -1)
                    .map((user) => Object({ label: user.full_name, value: user.id }));
                this.takeOwnershipRecruiters = this.takeOwnershipRecruiters.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
            })
        );

        //default selected job owner
        this.selectedOwner = this.job.default_email_name == this.jobOwner ? this.user.id : this.job.default_email_name;

        this.recruitersDefaults.push({
            label: `${this.user.first_name} ${this.user.last_name}`,
            value: `${this.user.id}`
        });

        // Get list of users
        this.subscribtions.add(
            this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
                this.users = users.map((u) => ({ ...u }));

                this.accountOwners = [];
                this.users.forEach((user) => {
                    if (user.role && ['recruiter', 'admin', 'account_owner'].indexOf(user.role) !== -1) {
                        const name = user.first_name ? `${user.first_name} ${user.last_name}` : user.email;
                        this.accountOwners.push({
                            label: name,
                            value: user.id
                        });
                    }
                    if (user.role && user.role === 'recruiter') {
                        this.recruiters.push(user);
                        this.recruitersDefaults.push({
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id
                        });
                    }
                });
                if (this.user) {
                    // sort this list
                    const currentUserIndex = this.accountOwners.findIndex((ao) => ao.value === this.user.id);
                    if (currentUserIndex !== -1) {
                        this.accountOwners = this.utilities.arrayMove(this.accountOwners, currentUserIndex, 0);
                    }
                }
            })
        );

        this.populateForms();
        this.subscribtions.add(
            this.jobsStore.pipe(select(fromJobsStore.getJobsCategoriesState)).subscribe((categories: JobCategory[]) => {
                this.categories = categories;
            })
        );

        this.subscribtions.add(
            this.jobsStore.pipe(select(fromJobsStore.getJobsCategoriesState)).subscribe((categories: JobCategory[]) => {
                this.categories = categories;
            })
        );

        if (this.job.id) {
            this.jobService
                .getJobDescriptionDetails(this.job.id)
                .pipe(take(1))
                .subscribe((response: any) => {
                    if (response) {
                        const details = response.data();
                        console.log('🏏 Description details loaded:', details);
                        if (details.skills) {
                            this.jobDescription.skills = details.skills;
                            this.skillsList =
                                details.skills && details.skills.length
                                    ? details.skills.map((item) => ({ name: item, selected: true }))
                                    : [];
                        }
                        if (details.additionalSkills) {
                            this.jobDescription.additionalSkills = details.additionalSkills;
                            this.additionalSkills = details.additionalSkills.map((item) => ({ name: item }));
                        }
                        if (details.result) {
                            this.jobDescription.result = details.result;
                        }
                        if (!this.job.jdAiCompleted) {
                            if (details.requirements) {
                                this.jobDescription.requirements = details.requirements;
                                this.jobDetailsForm.get('details').patchValue({ requirements: details.requirements });
                            }
                            if (details.qualifications) {
                                this.jobDescription.qualifications = details.qualifications;
                                this.jobDetailsForm
                                    .get('details')
                                    .patchValue({ qualifications: details.qualifications });
                            }
                            if (details.roleSummary) {
                                this.jobDescription.roleSummary = details.roleSummary;
                                this.jobDetailsForm.get('details').patchValue({ roleSummary: details.roleSummary });
                            }
                            if (details.pain) {
                                this.jobDescription.pain = details.pain;
                                this.jobDetailsForm.get('details').patchValue({ pain: details.pain });
                            }
                            if (details.sellTheJob) {
                                this.jobDescription.sellTheJob = details.sellTheJob;
                                this.jobDetailsForm.get('details').patchValue({ sellTheJob: details.sellTheJob });
                            }
                            console.log(
                                '🪂 Form populated with details:',
                                this.jobDetailsForm.get('details').value,
                                this.jobDetailsForm.get('details')
                            );
                        }

                        const title = this.jobDetailsForm.get('title').value;
                        this.loadSkillsForTitle(title);
                    } else {
                        console.log('🛼 No details loaded');
                    }
                });
        }

        this.tenantService.config().subscribe((theme) => {
            document.documentElement.style.setProperty('--primary-color', theme.color);
        });
    }

    onChangeUser(event) {
        const user = this.users.filter((x) => x.id === event.value)[0];
        this.jobOwner = `${user.first_name} ${user.last_name}`;
    }

    defineBullets(...args: string[]) {
        const str_array = [];
        const req_str_array = [];
        const removeOld = new RegExp('<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|</p>', 'g');
        const res = (args[0] || '').replace(removeOld, '').split('● ');
        const req = (args[1] || '').replace(removeOld, '').split('● ');
        const prefix = res
            .shift()
            .split('  ')
            .join('<br>');
        const old = req.shift().trim();
        for (let i of res) {
            str_array.push('<li>' + i + '</li>');
        }
        for (let i of req) {
            req_str_array.push('<li>' + i + '</li>');
        }
        this.jobDetailsForm.patchValue({ description: `${prefix}<ul>${str_array.join('')}</ul>` });
        this.jobDetailsForm.patchValue({ requirements: `${old}<ul>${req_str_array.join('')}</ul>` });
    }

    onChangeJob(event) {
        this.selectedJob = event.value;
        const job_des = this.descriptions.filter((x) => x.Role === event.value);
        const description = `${job_des[0].Overview}  ${job_des[0].Description}  ${job_des[0].Responsibilities}`;
        this.defineBullets(description, job_des[0].Requirements);
    }

    // TEMPORARY (till Quill fixes it)
    private editorAutofocusFix() {
        if (this.activeSection === 'applications') {
            document.querySelector('.container.fixed-container').scrollTo(0, 0);
        }
        setTimeout(() => {
            this.isFormLoading = false;
            const el = document.querySelector('[formControlName]') as HTMLElement;
            if (el) {
                el.focus();
            }
        }, 0);
    }

    private initForms() {
        this.jobDetailsForm = this.fb.group({
            title: [''],
            company: [''],
            ref: [''],
            category: [''],
            location: [''],
            hiring_role: ['new'],
            business_unit: [''],
            cost_centre: [''],
            is_remote: [false],
            type: [null],
            start_date: [''],
            contract_duration: [''],
            number_of_hires: [null],
            salary: this.fb.group({
                from: [''],
                to: [''],
                period: ['yearly'],
                currency: [''],
                hide: [''],
                single: [false]
            }),
            description: [''],
            requirements: [''],
            role: [''],
            details: this.fb.group({
                requirements: [''],
                qualifications: [''],
                roleSummary: [''],
                pain: [''],
                sellTheJob: ['']
            })
        });
        this.applicationsForm = this.fb.group({
            status: ['', Validators.required],
            industry: ['', Validators.required],
            vonq_category: ['', Validators.required],
            seniority: ['', Validators.required],
            education: [''],
            years_experience: [null, Validators.required],
            pipeline: ['', Validators.required],
            resume_matching_threshold: [60],
            do_not_match: [false],
            upload_resume_required: [true]
        });
        this.takeOwnershipConfirm = this.fb.group({
            assign_to_another: [false],
            recruiter: ['']
        });
        this.subscribtions.add(
            this.takeOwnershipConfirm.get('assign_to_another').valueChanges.subscribe((value) => {
                if (value) {
                    this.takeOwnershipConfirm.get('recruiter').setValidators([Validators.required]);
                    this.takeOwnershipConfirm.get('recruiter').updateValueAndValidity();
                } else {
                    this.takeOwnershipConfirm.get('recruiter').patchValue('');
                    this.takeOwnershipConfirm.get('recruiter').clearValidators();
                    this.takeOwnershipConfirm.get('recruiter').updateValueAndValidity();
                }
                this.takeOwnershipConfirm.markAsPristine();
            })
        );
    }

    private _filter(title: string) {
        const filterValue = title.toLowerCase();
        return this.emsiTitles.filter((option) => option.toLowerCase().includes(filterValue)).slice(0, 10);
    }

    pipelineFormInit() {
        this.pipelineForm = this.fb.group({
            name: ['', Validators.required],
            stages: this.fb.array([
                this.fb.group({
                    stage_name: 'Applied',
                    icon: '⚡'
                }),
                this.fb.group({
                    stage_name: 'Offer / Hired',
                    icon: '🎉'
                })
            ]),
            default_pipeline: [false]
        });
    }

    private populateForms() {
        // Forms
        this.jobDetailsForm = this.fb.group(
            {
                title: [this.job.title, Validators.required],
                company: [this.job.company, Validators.required],
                ref: [this.job.ref],
                category: [this.job.category || []],
                location: [
                    { value: this.job.location, disabled: false },
                    ConditionalValidator.validate(() => !this.job.is_remote, Validators.required)
                ],
                hiring_role: [this.job.hiring_role || 'new'],
                business_unit: [this.job.business_unit || '', Validators.required],
                cost_centre: [this.job.cost_centre],
                is_remote: [this.job.is_remote || false],
                type: [this.job.type, Validators.required],
                start_date: [this.job.start_date || ''],
                contract_duration: [
                    { value: this.job.contract_duration || '', disabled: this.disableContractDuration }
                ],
                number_of_hires: [this.job.number_of_hires, Validators.required],
                salary: this.fb.group({
                    from: [this.job.salary.from, [Validators.required, Validators.min(0)]],
                    to: [this.job.salary.to, [Validators.required, Validators.min(1)]],
                    period: [this.job.salary.period || 'yearly'],
                    currency: [this.job.salary.currency ? this.job.salary.currency : 'ZAR', Validators.required],
                    hide: [this.job.salary.hide || false],
                    single: [this.job.salary.single]
                }),
                description: [''],
                requirements: [''],
                role: [this.job.role],
                details: this.fb.group({
                    requirements: [''],
                    qualifications: [''],
                    roleSummary: [''],
                    pain: [''],
                    sellTheJob: ['']
                })
            },
            {
                //validator: EditorValidator.validate,
                validators: [EditorValidator, CompareTwoFieldsValidator]
            }
        );
        if (this.job.start_date) {
            this.jobDetailsForm.patchValue({
                start_date: moment(this.job.start_date, 'YYYY-MM-DD HH:mm Z')
            });
        }
        this.setContractDuration();
        this.defineBullets(this.job.description || '', this.job.requirements || '');
        //this.inputAddress = this.job.location;
        this.applicationsForm = this.fb.group({
            status: [
                ['published', 'internal', 'private'].indexOf(this.job.status) !== -1 ? this.job.status : 'published',
                Validators.required
            ],
            industry: [this.job.industry || '', Validators.required],
            vonq_category: [this.job.vonq_category || '', Validators.required],
            seniority: [this.job.seniority || '', Validators.required],
            education: [this.job.education, Validators.required],
            //experience: [this.job.experience, Validators.required],
            years_experience: [this.job.years_experience || '', Validators.required],
            pipeline: [
                this.job.pipeline ||
                    (this.pipelineOptions && this.pipelineOptions.length ? this.pipelineOptions[0].value : ''),
                Validators.required
            ],
            resume_matching_threshold: [
                this.job && this.job.resume_matching_threshold > -1 ? this.job.resume_matching_threshold : 60
            ],
            do_not_match: [this.job.do_not_match],
            upload_resume_required: [
                this.job.hasOwnProperty('upload_resume_required') ? this.job.upload_resume_required : true
            ]
        });
        const status = this.applicationsForm.get('status').value;
        this.isShowAdvertisingFields = status == 'published';
        this.checkValidatorsForStatus(status);
        // Contract Duration
        const jobTypeValue = this.jobDetailsForm.get('type').value;
        this.toggleDisableOfContractDuration(jobTypeValue);
        this.subscribtions.add(
            this.jobDetailsForm.get('type').valueChanges.subscribe((value) => {
                // console.log('type', value);
                this.toggleDisableOfContractDuration(value);
            })
        );
        this.subscribtions.add(
            this.jobDetailsForm.get('title').valueChanges.subscribe((title: string) => {
                // console.log('Title changed 1:', title);
                const value = title.trim().toLowerCase();
                this.filteredTitlesOptions = this._filter(value);
            })
        );

        this.subscribtions.add(
            this.jobDetailsForm
                .get('title')
                .valueChanges.pipe(
                    filter((value) => value.length > 1),
                    debounceTime(1500),
                    distinct()
                )
                .subscribe((title: string) => {
                    this.loadSkillsForTitle(title);
                })
        );

        // Location
        const locationControl = this.jobDetailsForm.get('location');
        this.subscribtions.add(
            this.jobDetailsForm.get('is_remote').valueChanges.subscribe((value) => {
                if (value) {
                    locationControl.clearValidators();
                    locationControl.updateValueAndValidity();
                } else {
                    locationControl.setValidators([Validators.required]);
                    locationControl.updateValueAndValidity();
                }
            })
        );
        this.subscribtions.add(
            this.applicationsForm.get('status').valueChanges.subscribe((value) => {
                this.checkValidatorsForStatus(value);
                this.isShowAdvertisingFields = value == 'published';
            })
        );

        this.subscribtions.add(
            this.jobDetailsForm.get('details').valueChanges.subscribe((details: any) => {
                console.log('Details changed', details);
                // this.onGenerateDescription();
                if (details.requirements) {
                    this.jobDescription.requirements = details.requirements;
                }
                if (details.qualifications) {
                    this.jobDescription.qualifications = details.qualifications;
                }
                if (details.roleSummary) {
                    this.jobDescription.roleSummary = details.roleSummary;
                }
                if (details.pain) {
                    this.jobDescription.pain = details.pain;
                }
                if (details.sellTheJob) {
                    this.jobDescription.sellTheJob = details.sellTheJob;
                }
            })
        );
    }

    private toggleDisableOfContractDuration(value) {
        if (value !== 'temporary' && value !== 'freelance' && value !== 'fixed_term' && value !== null) {
            this.disableContractDuration = true;
            this.jobDetailsForm.get('contract_duration').disable();
            this.jobDetailsForm.get('contract_duration').patchValue('');
            this.jobDetailsForm.get('contract_duration').clearValidators();
            this.jobDetailsForm.get('contract_duration').updateValueAndValidity();
        } else {
            if (value !== null) {
                this.disableContractDuration = false;
                this.jobDetailsForm.get('contract_duration').enable();
                this.jobDetailsForm.get('contract_duration').setValidators([Validators.required]);
                this.jobDetailsForm.get('contract_duration').updateValueAndValidity();
            }
        }
    }

    onChangeSection(section: string) {
        this.activeSection = section;
        this.editorAutofocusFix();
        if (section === 'applications') {
            setTimeout(() => {
                this.onHcSliderChange();
            }, 100);
        }
    }

    toggleSalaryField() {
        this.job.salary.single = !this.job.salary.single;
        this.jobDetailsForm.get('salary.single').patchValue(this.job.salary.single);
        const salaryToControl = this.jobDetailsForm.get('salary.to');
        if (this.job.salary.single) {
            salaryToControl.clearValidators();
            salaryToControl.updateValueAndValidity();
        } else {
            salaryToControl.setValidators([Validators.required]);
        }
    }

    async onSaveDraft(event) {
        event.preventDefault();

        if (this.jdEnabled && this.activeSection === 'job-details') {
            // clearing validators
            this.jobDetailsForm.get('description').updateValueAndValidity();
            this.jobDetailsForm.get('description').clearValidators();
            this.jobDetailsForm.get('requirements').updateValueAndValidity();
            this.jobDetailsForm.get('requirements').clearValidators();
        }

        const form = this.getActiveForm();
        if (!form.valid) {
            this.formHelper.markFormGroupTouched(form);
            console.log('❌ FORM IS INVALID', form);
            return;
        }

        if (this.updatedCategories && this.updatedCategories.length) {
            this.jobsStore.dispatch(new fromJobsStoreActions.AddJobsCategories(this.updatedCategories));
        }
        console.log('✅ FORM IS VALID', { ...this.job, ...form.value });
        if (form.value.status) {
            form.value.status = 'BUILD';
        }
        const jobData = this.prepareJobData(form.value);
        const job: SdkJob = await this.jobService.saveJob(jobData, this.user);
        console.log('RESPONSE FROM SAVE CALL:', job);
        this.contentLoading = false;
        this.jobsStore.dispatch(new fromJobsStoreActions.UpdateJobSuccess(job));
        this.jobsStore.dispatch(new fromJobsStoreActions.LoadJobs());

        if (this.jdEnabled) {
            // save description
            this.jobService
                .saveJobDescriptionDetails(job, this.jobDescription, this.isJdAiCompleted())
                .then(() => console.log('+ job description details saved'))
                .catch((error) => console.error(error));
        }
        from([1])
            .pipe(delay(1000))
            .subscribe(() => this.onBackClick());
    }

    prepareJobData(formValue: any) {
        const jobData = { ...this.job, ...formValue };
        if (jobData.start_date && typeof jobData.start_date !== 'string') {
            try {
                jobData.start_date = jobData.start_date.format('YYYY-MM-DD HH:mm Z');
            } catch (error) {
                console.error(error);
            }
        }
        if (this.jdEnabled) {
            jobData.creationMode = 'jdAI';
            jobData.jdAiCompleted = this.isJdAiCompleted();
        } else {
            jobData.creationMode = 'standard';
        }
        delete jobData.details;
        return jobData;
    }

    async onSave(event) {
        console.log('➡️ onSave');
        event.preventDefault();
        try {
            if (this.jdEnabled && this.activeSection === 'job-details') {
                // clearing validators
                this.jobDetailsForm.get('description').updateValueAndValidity();
                this.jobDetailsForm.get('description').clearValidators();
                this.jobDetailsForm.get('requirements').updateValueAndValidity();
                this.jobDetailsForm.get('requirements').clearValidators();
                if (!this.jobDetailsForm.get('description').valid) {
                    this.jobDetailsForm.patchValue({ description: '<p>-</p>' });
                }
                if (!this.jobDetailsForm.get('requirements').valid) {
                    this.jobDetailsForm.patchValue({ requirements: '<p>-</p>' });
                }
            }

            const form = this.getActiveForm();
            if (form) {
                if (this.job.salary.single && form.get('salary.from')) {
                    form.get('salary.to').setValue(form.get('salary.from').value);
                    form.get('salary.to').markAsDirty();
                }

                if (!form.valid) {
                    this.formHelper.markFormGroupTouched(form);
                    console.log('❌ FORM IS INVALID', form);
                    return;
                }
                // console.log('formValue', form.value);

                // VALID
                this.contentLoading = true;
                if (this.updatedCategories && this.updatedCategories.length) {
                    this.jobsStore.dispatch(new fromJobsStoreActions.AddJobsCategories(this.updatedCategories));
                }

                const jobData = this.prepareJobData(form.value);
                // console.log(jobData);
                const job: SdkJob = await this.jobService.saveJob(jobData, this.user);
                this.job = job;
                this.jobsStore.dispatch(new fromJobsStoreActions.UpdateJobSuccess(this.job));
                this.jobsStore.dispatch(new fromJobsStoreActions.LoadJobs());

                console.log('✅ RESPONSE FROM SAVE CALL:', { ...job });
                this.contentLoading = false;
                if (job.created && job.id) {
                    // this.setMatchingCriteria();
                    this.jobIdChanged.emit(job.id);
                    if (this.jdEnabled) {
                        // save description
                        this.jobService
                            .saveJobDescriptionDetails(job, this.jobDescription, this.isJdAiCompleted())
                            .then(() => console.log('+ job description details saved'))
                            .catch((error) => console.error(error));

                        this.goToNextSection();
                    } else {
                        this.onChangeSection('applications');
                    }
                } else {
                    this.goToNextSection();
                }
            } else {
                console.log(this.activeSection, 'NO FORM => Go to next section');
                if (this.jdEnabled) {
                    if (this.activeSection === 'details') {
                        console.log('1. Go from details');
                        console.log('2. Generate description');
                        // generate description
                        this.onGenerateDescription();
                        console.log('3. Save details');
                        this.jobService
                            .saveJobDescriptionDetails(this.job, this.jobDescription, this.isJdAiCompleted())
                            .catch((error) => console.error(error));
                        console.log('4. Update job');
                        const updData = {
                            description: this.jobDetailsForm.get('description').value || '',
                            requirements: ''
                        };
                        this.jobService
                            .updateJob(this.job.id, updData)
                            .then(() => console.log('descr updated'))
                            .catch((error) => console.error(error));
                    }

                    if (this.activeSection === 'description') {
                        console.log('5. Update job');
                        const updData = {
                            description: this.jobDetailsForm.get('description').value || '',
                            requirements: ''
                        };
                        this.jobService
                            .updateJob(this.job.id, updData)
                            .then(() => console.log('descr updated'))
                            .catch((error) => console.error(error));
                    }
                }
                this.goToNextSection();
            }
        } catch (error) {
            this.contentLoading = false;
            console.error(error);
            alert('Error');
        }
    }

    async onSaveAll(event) {
        event.preventDefault();

        if (this.jdEnabled && this.activeSection === 'job-details') {
            // clearing validators
            this.jobDetailsForm.get('description').updateValueAndValidity();
            this.jobDetailsForm.get('description').clearValidators();
            this.jobDetailsForm.get('requirements').updateValueAndValidity();
            this.jobDetailsForm.get('requirements').clearValidators();
        }

        const form = this.getActiveForm();
        const oldThreshold = this.job.resume_matching_threshold;
        if (this.job.salary.single && form.get('salary.from')) {
            form.get('salary.to').setValue(form.get('salary.from').value);
            form.get('salary.to').markAsDirty();
        }
        if (form && !form.valid) {
            this.formHelper.markFormGroupTouched(form);
            if (
                (form.get('description') && !form.get('description').valid) ||
                (form.get('requirements') && !form.get('requirements').valid)
            ) {
                window.scrollTo(0, document.body.scrollHeight);
            }
            return;
        }
        const dirtyForms = [];
        this.sections.forEach((section) => {
            const form = this.getActiveForm(section);
            if (form && form.dirty) {
                dirtyForms.push({ section, form });
            }
        });
        let valid = true;
        for (let { section, form } of dirtyForms) {
            if (!form.valid) {
                this.formHelper.markFormGroupTouched(form);
                this.onChangeSection(section);
                valid = false;
                break;
            }
        }
        if (!valid || dirtyForms.length === 0) {
            return;
        }
        this.contentLoading = true;
        if (this.updatedCategories && this.updatedCategories.length) {
            this.jobsStore.dispatch(new fromJobsStoreActions.AddJobsCategories(this.updatedCategories));
        }

        let updData = {};
        dirtyForms.forEach(({ section, form }, idx) => {
            updData = { ...updData, ...this.prepareJobData(form.value) };
        });
        const updatedJob = { ...this.job, ...updData };
        console.log('JOB TO SAVE ===========>', updatedJob);
        const job: SdkJob = await this.jobService.saveJob(updatedJob, this.user);
        this.job = job;
        this.jobsStore.dispatch(new fromJobsStoreActions.UpdateJobSuccess(this.job));
        this.jobsStore.dispatch(new fromJobsStoreActions.LoadJobs());
        console.log('✅ RESPONSE FROM SAVE CALL:', { ...job });
        this.contentLoading = false;
        this.onJobUpdate.emit(this.job);
        this.contentLoading = false;
        if (this.activeSection === 'applications' && oldThreshold !== this.job.resume_matching_threshold) {
            // console.log('updateCandidatesStatuses');
            this.jobService.updateCandidatesStatuses(this.job.id).subscribe();
        }
        if (this.jdEnabled) {
            this.jobService
                .saveJobDescriptionDetails(job, this.jobDescription, this.isJdAiCompleted())
                .then(() => console.log('⛳️ + job description details saved'))
                .catch((error) => console.error(error));
        }
        this.closeModal();
    }

    private getActiveForm(activeSection: string = ''): FormGroup {
        const section = activeSection || this.activeSection;
        let form;
        switch (section) {
            case 'job-details':
                form = this.jobDetailsForm;
                break;
            case 'applications':
                form = this.applicationsForm;
                break;
        }
        return form;
    }

    private goToNextSection() {
        console.log('-> goToNextSection');
        const index = this.sections.indexOf(this.activeSection);
        if (index + 1 >= this.sections.length) {
            console.log('PUBLISHED');
            this.jobPublished.emit(this.job);
            this.router.navigateByUrl(`${this.baseUrl}/jobs/${this.job.id}`);
            setTimeout(() => this.closeModal(), 1000);
        } else {
            const nextIndex = index + 1 < this.sections.length ? index + 1 : 0;
            this.activeSection = this.sections[nextIndex];
            this.editorAutofocusFix();
            // console.log('activeSection', this.sections[nextIndex]);
        }
    }

    get saveBtnText() {
        let text = 'Save';
        if (this.activeSection !== 'applications') {
            text = 'Next';
        } else {
            if (this.job.status && this.job.status !== 'BUILD') {
                text = 'Save';
            } else {
                text = 'Publish';
            }
        }
        return text;
    }

    get isNewOrDraft() {
        return this.job.status === 'BUILD';
    }

    onBackClick() {
        if (this.isNewOrDraft) {
            this.router.navigateByUrl(`${this.baseUrl}/jobs`);
            this.closeModal();
        } else {
            this.setEditMode.emit(false);
        }
    }

    getWeighting(type) {
        const currentValue = this.applicationsForm.get('weighting').value;
        if (!currentValue) return 0;
        let ret = Math.round(currentValue[type]);
        let offset = 100 - this.totalWeighting;
        const keys = Object.keys(currentValue).sort((a, b) => {
            const offsetA = Math.round(currentValue[a]) - currentValue[a];
            const offsetB = Math.round(currentValue[b]) - currentValue[b];
            return offsetA - offsetB;
        });

        if (offset < 0) keys.reverse();
        let i = 0;
        const step = offset > 0 ? -1 : 1;
        while (offset !== 0) {
            if (keys[i] === type) {
                ret -= step;
                offset = 0;
            } else {
                offset += step;
                i++;
            }
        }

        return ret;
    }

    get totalWeighting() {
        const currentValue = this.applicationsForm.get('weighting').value;
        return Object.keys(currentValue).reduce((a, c) => (a += Math.round(currentValue[c])), 0);
    }

    get matchingKeys() {
        return Object.keys(this.matchingMap);
    }

    get weightingKeys() {
        // console.log(this.stageWeighting, Object.keys(this.stageWeighting))
        return Object.keys(this.stageWeighting);
    }

    onHcSliderChange() {
        const value = this.applicationsForm.get('resume_matching_threshold').value;
        if (this.hcSlider) {
            const handler = this.hcSlider.el.nativeElement.children[0].children[1];
            if (handler) {
                handler.innerHTML = value;
            }
        }
    }

    onHcSliderChangeWeighting(e, input) {
        if (this.applicationsForm && this.applicationsForm.get('weighting')) {
            const val = e.value;
            const currentValue = this.applicationsForm.get('weighting').value;
            const keys = Object.keys(currentValue).filter((k) => k !== input);
            let points = keys.reduce((a, c) => (a += currentValue[c]), 0) + val;

            if (points === 100) return;
            points -= val;

            if (points > 0) {
                keys.forEach((key) => {
                    currentValue[key] = (currentValue[key] / points) * (100 - val + 0.00001);
                });
            } else {
                const average = (100 - val) / keys.length;
                keys.forEach((key) => {
                    currentValue[key] = average;
                });
            }

            this.applicationsForm.get('weighting').patchValue(currentValue);
        }
    }

    onChangeMatchingCriteria(e) {
        if (e) {
            this.applicationsForm.addControl('weighting', this.fb.group(this.stageWeighting));
        } else {
            this.applicationsForm.removeControl('weighting');
        }
    }

    onChangeDoNotMatch(e) {
        const handler = this.hcSlider.el.nativeElement.children[0].children[1];
        if (e.checked) {
            const value = 100;
            if (this.hcSlider && handler) {
                this.applicationsForm.get('resume_matching_threshold').patchValue(value);
                handler.innerHTML = 100;
            }
        } else {
            const value = this.job.resume_matching_threshold;
            this.applicationsForm.get('resume_matching_threshold').patchValue(value);
            if (this.hcSlider && handler) {
                handler.innerHTML = value;
            }
        }
    }

    onChangeJobLocation(e) {
        if (e.value === '#location') {
            this.createNewLocation = true;
            this.dropdownValueUpdated = false;
        }
    }

    onAddNewLocation(e) {
        this.createNewLocation = true;
        this.dropdownValueUpdated = false;
    }

    onChangePipeline(e) {
        if (e.value === '#pipeline') {
            this.createNewPipeline = true;
            this.dropdownValueUpdated = false;
        }
    }

    closeNewLocationForm() {
        this.createNewLocation = false;
        this.dropdownValueUpdated = true;
    }

    closeNewPipelineForm() {
        this.createNewPipeline = false;
        this.applicationsForm.get('pipeline').patchValue(this.job.pipeline);
        this.dropdownValueUpdated = true;
    }

    onCreateLocation($event) {
        this.tenantLocationOptions = [];
        this.locations.forEach((l) => {
            this.tenantLocationOptions.push({
                name: l.name,
                location: l.location_short,
                id: l.location,
                currency: l.currency
            });
        });
        this.tenantLocationOptions.push({
            name: $event.name,
            location: $event.location_short || $event.location,
            id: $event.location,
            currency: $event.currency
        });
        this.tenantLocationOptions.push({
            name: 'Add a new location',
            id: '#location',
            location: 'Add a new location'
        });
        this.setSuggestions = true;
        setTimeout(() => {
            this.setSuggestions = false;
        }, 1000);
    }

    onSavePipeline() {
        if (!this.pipelineForm.valid) {
            this.formHelper.markFormGroupTouched(this.pipelineForm);
            return false;
        }
        const value = this.pipelineForm.value;
        value.id = this.utilities.generateUID(10);
        this.contentLoading = true;
        this.tenantService.updatePipeline(value.id, value);
        if (value.default_pipeline) {
            this.pipelines.forEach((p) => (p.default_pipeline = false));
        }
        this.pipelines = [...this.pipelines, value];
        this.createNewPipeline = false;
        this.pipelines.forEach((item) => {
            this.tenantService.updatePipeline(item.id, item);
        });
        this.createNewPipeline = false;
        const length = this.pipelineOptions.length;
        let pipe = `${this.pipelineForm.value.name}: `;
        this.pipelineForm.value.stages.forEach((s, i) => {
            if (i === this.pipelineForm.value.stages.length - 1) {
                pipe += s.stage_name;
            } else {
                pipe += s.stage_name + ' > ';
            }
        });
        this.pipelineOptions.splice(length - 1, 0, {
            label: pipe,
            value: this.pipelineForm.value.id
        });
        this.applicationsForm.get('pipeline').patchValue(this.pipelineForm.value.id);
        this.dropdownValueUpdated = true;
        this.contentLoading = false;
        this.pipelineFormInit();
    }

    onAddStage() {
        if (!this.pipelineForm.valid) {
            this.formHelper.markFormGroupTouched(this.pipelineForm);
            return false;
        }
        const stage = this.pipelineForm.controls.stages as FormArray;
        const length = stage.controls.length;

        stage.insert(
            length - 1,
            this.fb.group({
                stage_name: ['', Validators.required],
                icon: '👍'
            })
        );
    }

    get stages() {
        return this.pipelineForm.controls['stages'];
    }

    onDeleteStage(index: number) {
        (this.pipelineForm.controls['stages'] as FormArray).removeAt(index);
    }

    get description() {
        return this.jobDetailsForm.get('description');
    }

    get requirements() {
        return this.jobDetailsForm.get('requirements');
    }

    onCategoriesChange(categories) {
        console.log('Current value for categories:', categories);
        this.updatedCategories = categories;

        const catValues = categories.map((cat) => cat.id);

        this.jobDetailsForm.get('category').setValue(catValues);
        this.jobDetailsForm.get('category').markAsDirty();
    }

    onLocationAdded(location) {
        // console.log('onLocationChange', location);
        const locValues = location.map((loc) => loc.id);

        this.jobDetailsForm.get('location').setValue(locValues);
        this.jobDetailsForm.get('location').markAsDirty();
        if (location.length === 1) {
            this.jobDetailsForm
                .get('salary')
                .get('currency')
                .setValue(location[0].currency || 'ZAR');
            this.jobDetailsForm
                .get('salary')
                .get('currency')
                .markAsDirty();
            this.dropdownValueUpdated = false;
            setTimeout(() => {
                this.dropdownValueUpdated = true;
            }, 100);
        }
    }

    closeModal() {
        if (this.isNewOrDraft && this.action !== 'takeOwnership') {
            this.router.navigateByUrl(`${this.baseUrl}/jobs`);
        }

        this.closeComponent.emit(null);
        this.setEditMode.emit(false);
    }

    onChangeJobStatus(e) {
        const status = e.value;
        this.applicationsForm.get('status').setValue(status);
        // if (status === 'published' || status === 'internal') {
        //     this.applicationsForm.get('job_listing').setValue('default');
        // } else if (status === 'private') {
        //     this.applicationsForm.get('job_listing').setValue('hidden');
        // }
    }

    changeInForm(form: FormGroup) {
        form.markAsDirty();
        // this.setContractDuration();
    }

    onChangeType() {
        this.toggleDisableOfContractDuration(this.jobDetailsForm.controls.type.value);
    }

    private setContractDuration(): void {}

    checkValidatorsForStatus(status) {
        const auxiliaryFields = ['industry', 'vonq_category', 'seniority', 'education', 'years_experience'];

        auxiliaryFields.forEach((field) => {
            if (status !== 'published') {
                this.applicationsForm.controls[field].clearValidators();
                this.applicationsForm.controls[field].updateValueAndValidity();
            } else {
                this.applicationsForm.controls[field].setValidators([Validators.required]);
                this.applicationsForm.controls[field].updateValueAndValidity();
            }
        });
    }

    showTakeOwnershipConfirmModal(isShow: boolean) {
        this.isShowTakeOwnershipModal = isShow;
    }

    onTakeOwnershipConfirm() {
        if (this.takeOwnershipConfirm.invalid) {
            this.takeOwnershipConfirm.markAsDirty();
            return;
        }

        const form = this.getActiveForm();
        Object.assign(this.job, form.value);
        this.showTakeOwnershipConfirmModal(false);
        const assign_to_another = this.takeOwnershipConfirm.get('assign_to_another').value;
        this.job.owner = assign_to_another ? this.takeOwnershipConfirm.get('recruiter').value : this.user.id;

        this.contentLoading = true;
        this.jobService.takeOwnership(this.job, this.job.id).subscribe((response: any) => {
            this.contentLoading = false;
            this.job.owner = response.owner;
            this.job.hiring_managers = response.hiring_managers;

            const data = {
                id: this.job.id,
                data: this.job
            };
            this.jobsStore.dispatch(new fromJobsStoreActions.UpdateJob(data));
            if (this.job.owner === this.user.id) {
                this.router.navigateByUrl(`${this.baseUrl}/jobs/${this.job.id}`);
            } else {
                this.closeModal();
            }
        });
    }

    onChangeHiringType(type: string): void {
        this.hiringType = type;
        this.jobDetailsForm.controls['hiring_role'].patchValue(type);
    }

    onTakeOwnership(event) {
        event.preventDefault();
        const form = this.getActiveForm();
        // if (!form.valid) {
        //     this.formHelper.markFormGroupTouched(form);
        //     console.log('FORM IS INVALID');
        //     console.log(form);
        //     return;
        // }
        if (this.user.role === 'account_owner') {
            this.showTakeOwnershipConfirmModal(true);
            // console.log(this.job);
        } else {
            Object.assign(this.job, form.value);
            this.contentLoading = true;
            this.jobService.takeOwnership(form.value, this.job.id).subscribe((response: any) => {
                this.job.owner = response.owner;
                this.job.hiring_managers = response.hiring_managers;

                this.contentLoading = false;
                const data = {
                    id: this.job.id,
                    data: this.job
                };
                this.jobsStore.dispatch(new fromJobsStoreActions.UpdateJob(data));
                this.router.navigateByUrl(`${this.baseUrl}/jobs/${this.job.id}`);
            });
        }
    }

    // Job Descriptoin AI
    addAdditionalSkill(event: any): void {
        const input = event.input;
        const value = (event.value || '').trim();
        // Add our fruit
        if (value) {
            this.additionalSkills.push({ name: value });
        }

        // Clear the input value
        if (input) {
            input.value = '';
        }

        // console.log(this.additionalSkills);
        this.jobDescription.additionalSkills = this.additionalSkills.map((s) => s.name);
        // this.onGenerateDescription();
    }

    removeAdditionalSkill(skill): void {
        const index = this.additionalSkills.indexOf(skill);
        if (index >= 0) {
            this.additionalSkills.splice(index, 1);
        }
        this.jobDescription.additionalSkills = this.additionalSkills.map((s) => s.name);
        // this.onGenerateDescription();
    }

    onSkillClick(skill: { name: string; selected: boolean }) {
        skill.selected = !skill.selected;
        const selectedSkills = this.skillsList.filter((p) => p.selected).map((p) => p.name);
        this.jobDescription.skills = selectedSkills;
        // console.log(this.jobDescription);
        // this.onGenerateDescription();
    }

    onPainPointClick(point: { name: string; selected: boolean }) {
        point.selected = !point.selected;
        const selectedPoints = this.painPoints.filter((p) => p.selected).map((p) => p.name);
        this.jobDescription.pain = selectedPoints;
        // console.log(this.jobDescription);
        // this.onGenerateDescription();
    }

    onMoreSkills() {
        const title = this.jobDetailsForm.get('title').value;
        // console.log('Suggest more skills for', title);
        if (title && title.length > 2) {
            this.skillsLoading = true;
            this.emsiService.skills(title).subscribe(
                (skills: string[]) => {
                    this.skillsLoading = false;
                    this.skillsList = this.skillsList.filter((s) => s.selected);
                    skills.forEach((s) => {
                        if (!this.skillsList.find((skill) => skill.name === s)) {
                            this.skillsList.push({ name: s, selected: false });
                        }
                    });
                },
                (errorResponse) => {
                    this.skillsLoading = false;
                    console.error(errorResponse.error);
                }
            );
        }
    }

    onGenerateRequirements() {
        this.showRequirementsModal = true;
    }

    onGenerateQualifications() {
        console.log('onGenerateQualifications');
        this.showQualificationsModal = true;
        // this.contentLoading = true;
        // const title = this.jobDetailsForm.get('title').value;

        // this.emsiService.qualifications(title).subscribe(
        //     (response) => {
        //         // console.log('Qualifications:', response);
        //         this.contentLoading = false;

        //         let qualifications = '<ul>';
        //         (response || []).forEach((item) => {
        //             qualifications += `<li>${item}</li>`;
        //         });
        //         qualifications += '</ul>';
        //         this.jobDescription.qualifications = qualifications;
        //         this.jobDetailsForm.get('details').patchValue({ qualifications });
        // this.onGenerateDescription();
        //     },
        //     (errorResponse) => {
        //         console.error(errorResponse.error);
        //         this.contentLoading = false;
        //     }
        // );
    }

    onGenerateRoleSummary() {
        this.contentLoading = true;
        const title = this.jobDetailsForm.get('title').value;
        const skills = this.jobDescription.skills.join(', ');
        const additionalSkills = this.jobDescription.additionalSkills.join(', ');

        this.emsiService.roleSummary(title, skills, additionalSkills).subscribe(
            (roleSummary) => {
                // console.log('RoleSummary:', roleSummary);
                this.jobDescription.roleSummary = roleSummary;
                this.contentLoading = false;
                this.jobDetailsForm.get('details').patchValue({ roleSummary });
                // this.onGenerateDescription();
            },
            (errorResponse) => {
                console.error(errorResponse.error);
                this.contentLoading = false;
            }
        );
    }

    onGenerateSellTheJob() {
        this.contentLoading = true;
        const title = this.jobDetailsForm.get('title').value;
        const pain = this.jobDescription.pain.join(', ');
        this.emsiService.sellTheJob(title, pain).subscribe(
            (response) => {
                // console.log('sellTheJob:', response);
                this.contentLoading = false;
                this.jobDescription.sellTheJob = response;
                this.jobDetailsForm.get('details').patchValue({ sellTheJob: response });
                // this.onGenerateDescription();
            },
            (errorResponse) => {
                console.error(errorResponse.error);
                this.contentLoading = false;
            }
        );
    }

    onCloseJobRequirementsModal(data) {
        this.showRequirementsModal = false;
        let requirements = '<ul>';
        (data || []).forEach((item) => {
            requirements += `<li>${item}</li>`;
        });
        requirements += '</ul>';
        this.jobDescription.requirements = requirements;
        this.jobDetailsForm.get('details.requirements').patchValue(requirements);
        // console.log(this.jobDetailsForm.value);
        // this.onGenerateDescription();
    }

    onCloseJobQualificationsModal(data) {
        this.showQualificationsModal = false;
        let qualifications = '<ul>';
        (data || []).forEach((item) => {
            qualifications += `<li>${item}</li>`;
        });
        qualifications += '</ul>';
        this.jobDescription.qualifications = qualifications;
        this.jobDetailsForm.get('details.qualifications').patchValue(qualifications);
        // console.log(this.jobDetailsForm.value);
        // this.onGenerateDescription();
    }

    onGenerateDescription() {
        console.log('😒');
        const sellTheJob = this.jobDescription.sellTheJob || '';
        const roleSummary = this.jobDescription.roleSummary || '';
        const requirements = (this.jobDescription.requirements || '<p>-</p>').split('\n').join('<br>');
        const qualifications = (this.jobDescription.qualifications || '').split('\n').join('<br>');
        let description = '';
        description += `<p>${sellTheJob}</p><p><br></p>`;
        description += `<p>${roleSummary}</p><p><br></p>`;
        description += `<p><strong>Requirements</strong></p><p>${requirements}</p><p><br></p>`;
        description += `<p><strong>Qualifications</strong></p><p>${qualifications}</p><p><br></p>`;
        this.jobDetailsForm.patchValue({ description });
        this.jobDetailsForm.markAsDirty();
        this.jobDescription.result = description;
        this.job.description = description;
        this.job.requirements = '-';
        console.log('👩‍🌾 Description updated:', description);
    }

    isJdAiCompleted() {
        const vals = values(
            pick(this.jobDescription, ['skills', 'sellTheJob', 'roleSummary', 'requirements', 'qualifications'])
        );
        return vals.every((v) => v.length);
    }

    loadSkillsForTitle(title = '') {
        if (title && title.length > 2) {
            this.skillsLoading = true;
            this.emsiService.skills(title).subscribe(
                (skills: string[]) => {
                    this.skillsLoading = false;
                    // console.log('response:', skills);
                    this.skillsList = this.skillsList.filter((s) => s.selected);
                    skills.forEach((s) => {
                        if (!this.skillsList.find((skill) => skill.name === s)) {
                            this.skillsList.push({ name: s, selected: false });
                        }
                    });

                    console.log('Got skills for', title, ':', this.skillsList);
                },
                (errorResponse) => {
                    this.skillsLoading = false;
                    console.error(errorResponse.error);
                }
            );
        }
    }
}
