import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { User } from '../../../core/models/user';
import { FormHelperService } from './../../../core/services/form-helper.service';
import * as fromStore from './../../../store';
import * as fromSelectors from './../../../store/selectors';

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectComponent),
            multi: true
        }
    ]
})
export class MultiSelectComponent implements ControlValueAccessor, OnInit, OnChanges {
    menuIsVisible = false;
    newItemMode = false;
    selectedValue = [];
    selectedItems = [];
    contentLoading = false;
    newUserForm: FormGroup;
    _users: User[] = [];
    _items: any[] = [];
    _itemsCopy: any[] = [];
    clearIcon = false;
    @Input() inviteUsers: boolean;
    @Output() selectUpdated = new EventEmitter<any>();
    @Input() allowedChange: boolean;
    @Input() userType: string[];
    @Input() label: string;
    @Input() filterUsers: string[];
    @Input() excludeId: string[] = [];
    @Input() set owner(v) {
        if (v) {
            setTimeout(() => {
                let index = this.selectedItems.findIndex((u) => u.id === v);
                if (index >= 0) {
                    this.selectedItems.splice(index, 1);
                    this.selectedValue = this.selectedItems.map((si) => si.id);
                    this.propagateChange(this.selectedValue);
                }
                if (this._items) {
                    let item = this._items.find((u) => u.id === v);
                    if (item) item.selected = true;
                }
            }, 100);
        }
    }
    @ViewChild('input') input: ElementRef;
    msgs: Message[] = [];
    user: User;
    constructor(
        private elRef: ElementRef,
        private fb: FormBuilder,
        private formHelper: FormHelperService,
        private store: Store<fromStore.State>
    ) {
        if (!this.filterUsers) {
            this.filterUsers = [];
        }
        this.newUserForm = this.fb.group({
            full_name: [
                '',
                [Validators.required, Validators.minLength(2), Validators.pattern('\\b\\w+\\b(?:.*?\\b\\w+\\b){1}')]
            ],
            email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$')]]
        });
    }

    // Click outside of drop-down
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.elRef.nativeElement.children[0].contains(event.target)) {
            this.menuIsVisible = false;
            this._items = this._itemsCopy;
            this._items = this._items.filter((item) => this.excludeId.indexOf(item.id) === -1);
            this.checkUnique();
            this.clearIcon = false;
            this.input.nativeElement.value = null;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterUsers) {
            this.checkUnique();
        }
    }

    ngOnInit(): void {
        this.store.pipe(select(fromSelectors.getUsersLoading)).subscribe((loading: boolean) => {
            this.contentLoading = loading;
        });
        this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
            this.user = { ...user };
        });
        this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.selectedItems = [];
            if (this._items.length && users.length && this._items.length !== users.length) {
                this.newItemMode = false;
                const existingIds = this._items.map((u) => u.id);
                const newItems = users.filter(
                    (u) => existingIds.indexOf(u.id) === -1 && (u.activated || (u.status && u.status === 'active'))
                );
                this.newUserForm.reset();
                newItems.forEach((user) => {
                    const u = { ...user };
                    if (this.userType) {
                        if (
                            !this._items.find((userItem) => userItem.id === u.id) &&
                            u.role &&
                            this.userType.indexOf(u.role) !== -1
                        ) {
                            u.selected = true;
                            this._items.push(u);
                            this._itemsCopy.push(u);
                            this.selectedItems.push(u);
                        }
                    } else {
                        if (!this._items.find((userItem) => userItem.id === u.id) && u.role) {
                            u.selected = true;
                            this._items.push(u);
                            this._itemsCopy.push(u);
                            this.selectedItems.push(u);
                        }
                    }
                });
                if (this.selectedItems.length > 0) {
                    this.propagateChange([...this.selectedValue, ...this.selectedItems.map((si) => si.id)]);
                }
            } else {
                users
                    .filter((u) => u.activated)
                    .forEach((user) => {
                        const u = { ...user };
                        if (this.userType) {
                            if (
                                !this._items.find((userItem) => userItem.id === u.id) &&
                                u.role &&
                                this.userType.indexOf(u.role) !== -1
                            ) {
                                this._items.push(u);
                                this._itemsCopy.push(u);
                            }
                        } else {
                            if (!this._items.find((userItem) => userItem.id === u.id) && u.role) {
                                this._items.push(u);
                                this._itemsCopy.push(u);
                            }
                        }
                    });
            }
            this.setSelected();
        });
        this.store.pipe(select(fromSelectors.getUsersError)).subscribe((error: any) => {
            this.contentLoading = false;
            if (error) {
                this.msgs.push({ severity: 'error', detail: error.error.error || 'Error' });
            } else {
                this.msgs = [];
            }
        });
    }

    private setSelected() {
        if (this.selectedValue && this.selectedValue.length) {
            this.selectedValue.forEach((sv) => {
                const item = this._itemsCopy.find((_item) => _item.id === sv);
                if (item) {
                    item.selected = true;
                    this.selectedItems.push(item);
                }
            });
        }
    }

    writeValue(value: any) {
        if (value !== undefined) {
            // console.log('VALUE PASSED TO COMPONENT:', value);
            this.selectedValue = value;
            this.setSelected();
        }
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    onMenuToggle() {
        this.menuIsVisible = !this.menuIsVisible;
    }

    onSelectItem(item) {
        this._items = this._itemsCopy;
        this._items = this._items.filter((item) => this.excludeId.indexOf(item.id) === -1);
        this.input.nativeElement.value = null;

        item.selected = true;
        this.selectedItems.push(item);
        this.menuIsVisible = false;

        this.selectedValue = this.selectedItems.map((si) => si.id);

        this.propagateChange(this.selectedValue);
        this.selectUpdated.emit();
    }
    // onAddNewClick() {
    //     this.menuIsVisible = false;
    //     this.newItemMode = true;
    // }

    // onAddNewItem(event) {
    //     event.preventDefault();
    //     const form = this.newUserForm;
    //     if (!form.valid) {
    //         this.formHelper.markFormGroupTouched(form);
    //         return;
    //     }
    //     this.contentLoading = true;
    //     const data = form.getRawValue();
    //     console.log('Data Role', data.role);
    //     data.role = this.userType || 'employee';
    //     this.store.dispatch(new fromActions.CreateUser(data));
    // }

    onCancelAddNewItem() {
        this.newItemMode = false;
    }

    onRemoveFromSelected(item) {
        this.selectedItems = this.selectedItems.filter((si) => si.id !== item.id);
        const menuItem = this._itemsCopy.find((mi) => mi.id === item.id);
        menuItem.selected = false;

        this.selectedValue = this.selectedItems.map((si) => si.id);
        this.propagateChange(this.selectedValue);
        this.selectUpdated.emit();
    }

    onFilterUsers(e) {
        if (!this.filterUsers) {
            this.filterUsers = [];
        }
        this._items = this._itemsCopy
            .filter((j) => {
                if (j.email) {
                    const title = j.full_name ? j.full_name.toLowerCase() : j.first_name.toLowerCase();
                    const email = j.email.toLowerCase();
                    const query = e.target.value.toLowerCase().trim();
                    const queryWords = query.split(' ').filter((word) => word);
                    const matchedByName = queryWords.every((word) => title.indexOf(word) !== -1);
                    const matchedByEmail = queryWords.every((word) => email.indexOf(word) !== -1);
                    return matchedByName || matchedByEmail;
                }
            })
            .filter((a) => this.filterUsers.indexOf(a.id) === -1);

        this._items = this._items.filter((item) => this.excludeId.indexOf(item.id) === -1);
        this.input.nativeElement.value.length > 0 ? (this.clearIcon = true) : (this.clearIcon = false);
    }

    onClear() {
        this._items = this._itemsCopy;
        this._items = this._items.filter((item) => this.excludeId.indexOf(item.id) === -1);
        this.clearIcon = false;
        this.input.nativeElement.value = null;
    }

    onClickOnLayer() {
        this.input.nativeElement.click();
        this.input.nativeElement.focus();
    }

    checkUnique() {
        if (this.filterUsers) {
            this._items = this._itemsCopy
                .filter((a) => this.filterUsers.indexOf(a.id) === -1)
                .filter((item) => this.excludeId.indexOf(item.id) === -1);
        }
    }
}
