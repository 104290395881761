import { AbstractControl } from '@angular/forms';
export class CompensationValidation {
    static MatchField(AC: AbstractControl) {
        if (!AC.value.percentage && !AC.value.amount) {
            AC.get('percentage').setErrors({ required: true });
        } else if (AC.value.percentage && AC.value.percentage > 100) {
            AC.get('percentage').setErrors({ incorrect: true });
        } else {
            AC.get('percentage').setErrors(null);
            return null;
        }
    }
}
