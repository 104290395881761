<div class="form-content-box-bottom">
    <div class="form-content-box-bottom-holder">
        <div class="selected-items-block">
            <div class="selected-items-holder">
                <ng-container *ngIf="selectedItems && selectedItems.length">
                    <div class="selected-item-holder" *ngFor="let item of selectedItems">
                        <div class="selected-item">
                            <div class="selected-item-icon" [ngStyle]="{ backgroundColor: item.user_color }">
                                <img
                                    src="{{ item.icon_url_small }}"
                                    alt=""
                                    *ngIf="item.icon_url_small"
                                    width="30"
                                    height="30"
                                />
                                <span *ngIf="!item.icon_url_small"> {{ item | initials }} </span>
                            </div>
                            <div class="selected-item-name">
                                <span>{{ item.first_name }} {{ item.last_name }}</span>
                                <span class="selected-item-name-email" *ngIf="item.email"> {{ item.email }} </span>
                            </div>
                            <div class="selected-item-actions">
                                <span class="close-icon">
                                    <svg-icon
                                        [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"
                                        src="/assets/images/icons/icons-8-trash-can.svg"
                                        (click)="onRemoveFromSelected(item)"
                                    ></svg-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="form-block selected-item-holder">
                    <div class="select-item" *ngIf="!newItemMode">
                        <div class="select-dd">
                            <div class="selected-row">
                                <!-- <span class="placeholder">Add to this position</span> -->
                                <input
                                    #input
                                    type="text"
                                    class="placeholder"
                                    (click)="onMenuToggle()"
                                    (keyup)="onFilterUsers($event)"
                                />
                                <i
                                    *ngIf="clearIcon"
                                    class="pi pi-times-circle clear-icon"
                                    style="color: #c8c8c8;font-weight: 700;"
                                    (click)="onClear()"
                                ></i>

                                <div
                                    class="add-overlay"
                                    [ngClass]="{ hidden: menuIsVisible }"
                                    (click)="onClickOnLayer()"
                                >
                                    <span class="plus"></span>
                                    <span class="text">Add {{ label }}</span>
                                </div>
                            </div>
                            <div
                                class="select-dd-menu"
                                *ngIf="menuIsVisible && (user.role !== 'recruiter' || allowedChange)"
                            >
                                <div
                                    class="menu-item"
                                    *ngFor="let item of _items | sortByOrder: 'first_name'"
                                    (click)="onSelectItem(item)"
                                    [ngClass]="{ selected: item.selected }"
                                >
                                    <span>{{ item.first_name }} {{ item.last_name }}</span>
                                    <span *ngIf="item.email" class="menu-item-email">{{ item.email }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="new-item" *ngIf="newItemMode">
    <form [formGroup]="newUserForm" class="flex-row flex-aic pos-rel">
        <input type="text" class="form-input" placeholder="Full Name" formControlName="full_name" />
        <input type="text" class="form-input ml20" placeholder="Email Address" formControlName="email" />
        <button class="btn btn-primary w60 ml20" (click)="onAddNewItem($event)">Add</button>
        <button class="btn btn-primary-inversed btn-w60 ml20" (click)="onCancelAddNewItem($event)">Cancel</button>
        <div class="light-overlay" *ngIf="contentLoading"></div>
    </form>
    <p-messages [(value)]="msgs"></p-messages>
</div>
