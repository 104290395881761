import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-experience',
    templateUrl: './experience.component.html',
    styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {
    workExperience = [];
    colorPalette = [
        '#fc5c65',
        '#fd9644',
        '#fed330',
        '#26de81',
        '#2bcbba',
        '#45aaf2',
        '#4b7bec',
        '#a55eea',
        '#eb3b5a',
        '#20bf6b',
        '#f7b731',
        '#0fb9b1',
        '#2d98da',
        '#3867d6',
        '#8854d0',
        '#4b6584',
        '#778ca3'
    ];
    @Input() employmentExperience = [];
    employmentSummary = [];
    hasWork = false;

    constructor() {}

    ngOnInit(): void {
        this.employmentSummary = this.employmentExperience.slice(0);
        console.log('init', this.employmentSummary, this.employmentSummary.length);
        // this.employmentSummary = [
        //     {
        //         company: "HelloCrowd, Inc",
        //         description: "Product owner of several exciting SaaS products including Learn, the groundbreaking learning and development platform that connects vendors<ul><li>Creating sprints based on backlog prioritisation, through balancing clients</li><li>In charge of the day-to-day running and success of our projects</li><li>Overseeing the team of developers</li><li>Managing development of products</li><li>Defining functions and keeping projects on track</li><li>Conduit between clients, the development team and vendors</li><li>Encouraging and maintaining a productive work environment</li></ul>",
        //         end_date: "Current",
        //         start_date: "11/2018",
        //         title: "Product Owner"
        //     },
        //     {
        //         company: "By Word of Mouth",
        //         description: "Producing events at By Word of Mouth's exclusive venue: The Polo Room<ul><li>Personally responsible for 50% of By Word of Mouth's annual eventing turnover</li><li>Event direction and execution across a broad scope of events</li><li>Successfully managing events for large corporates on a day-to-day basis</li><li>Stakeholder, supplier and client management</li><li>Building and maintaining strong client relationships: creating bonds with new clients and improving existing client relationships</li><li>Securing event bookings</li><li>Ensuring the client experience exceeds expectations, resulting in return</li><li>Managing The Polo Room team</li></ul>",
        //         end_date: "11/2018",
        //         start_date: "11/2017",
        //         title: "Senior Event Manager"
        //     },
        //     {
        //         company: "By Word of Mouth",
        //         end_date: "06/2016",
        //         start_date: "01/2014",
        //         title: "Senior Event Manager"
        //     },
        //     {
        //         company: "Twitter",
        //         end_date: "03/2012",
        //         start_date: "01/2008",
        //         title: "Senior Event Manager"
        //     }
        // ];
        const start_year = this.employmentSummary.sort((a: any, b: any) => {
            const start = moment(a.start_date, 'MM/YYYY').unix();
            const end = moment(b.start_date, 'MM/YYYY').unix();
            return start - end;
        });
        let min_year = moment(start_year[0].start_date, 'MM/YYYY').year();
        let now = new Date().getFullYear();
        console.log(min_year, now);
        for (let i = min_year; i <= now; i++) {
            this.workExperience.push({
                year: i,
                work: []
            });
        }
        let i = 0;
        this.employmentSummary.forEach((s: any) => {
            const start = moment(s.start_date, 'MM/YYYY').year();
            if (s.end_date === 'Current') {
                s.current = true;
                this.hasWork = true;
                s.end_date_f = moment().format('MM/YYYY');
            }
            const index = this.workExperience.findIndex((v) => v.year === start);
            if (index > -1) {
                let a = moment(s.start_date, 'MM/YYYY');
                let b = moment(s.current ? s.end_date_f : s.end_date, 'MM/YYYY');
                s.duration = b.diff(a, 'month');
                let hasDuplicate;
                for (const w of this.workExperience) {
                    if (w.work) {
                        hasDuplicate = w.work.find((w) => {
                            return w.company === s.company;
                        });
                    }
                    if (hasDuplicate) {
                        break;
                    }
                }
                // console.log('hasDuplicate', hasDuplicate);
                if (hasDuplicate) {
                    this.workExperience[index].work.push({
                        duration: s.duration,
                        month_started: moment(s.start_date, 'MM/YYYY').format('M'),
                        company: s.company,
                        index: hasDuplicate.index,
                        color: hasDuplicate.color,
                        current: s.current || false
                    });
                } else {
                    this.workExperience[index].work.push({
                        duration: s.duration,
                        month_started: moment(s.start_date, 'MM/YYYY').format('M'),
                        company: s.company,
                        index: i,
                        color: this.colorPalette[i],
                        current: s.current || false
                    });
                    i += 1;
                }
            }
        });
        console.log(this.workExperience);
    }
}
