<h3>Applications by Time of Day</h3>
<div class="graph-before-activity-overview">
    <div class="day-activity" *ngFor="let day of graph">
        <span class="day-title">{{ day.title }}</span>
        <div class="hour-activity" *ngFor="let item of day.time">
            <strong
                *ngIf="ranges.length && item.contribution > 0"
                [ngStyle]="{
                    backgroundColor: getColor(item.contribution)
                }"
            >
            </strong>
            <div class="tooltip">
                <div *ngIf="item.contribution > 0">
                    {{ item.contribution }} application<ng-container *ngIf="item.contribution > 1">s</ng-container>
                </div>
                <div *ngIf="item.contribution === 0">No applications</div>
            </div>
        </div>
    </div>
    <div class="labels-block">
        <div class="am"><span>AM</span></div>
        <div class="label" *ngFor="let item of graph[0].time">
            {{ item.label }}
        </div>
        <div class="pm"><span>PM</span></div>
    </div>
    <div class="contrib-legend">
        <ul class="legend">
            <li *ngFor="let color of colors" [ngStyle]="{ backgroundColor: color }"></li>
        </ul>
    </div>
</div>
