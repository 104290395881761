<p-dialog
    [(visible)]="showModal"
    (onHide)="onHideModal()"
    [style]="{ width: '550px' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [dismissableMask]="true"
    [showHeader]="false"
    class="decline-modal"
>
    <div class="p-header p-header-new bg-green">
        <p>Criminal and ID Verification for {{ candidate.first_name }} {{ candidate.last_name }}</p>
        <button class="close-btn" (click)="onHideModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="p-body p-body-new fz15">
        <form [formGroup]="criminalCheckForm" autocomplete="off">
            <div class="form-row">
                <app-auto-dropdown
                    [options]="verificationTypeOptions"
                    [newStyle]="true"
                    [control]="criminalCheckForm.controls.type"
                    [placeholder]="'Verification Type'"
                    [ngClass]="{
                        invalid: !criminalCheckForm.controls.type.valid && criminalCheckForm.controls.type.touched
                    }"
                ></app-auto-dropdown>
                <strong class="price mla"
                    >ZAR {{ prices.types[criminalCheckForm.get('type').value] | number: '1.2-2' }}</strong
                >
            </div>
            <div class="form-row">
                <p-checkbox
                    name="identityVerification"
                    binary="true"
                    label="Add Identity Verification"
                    formControlName="identityVerification"
                    inputId="identityVerification"
                ></p-checkbox>
                <span class="price mla">ZAR {{ prices.identityVerification | number: '1.2-2' }}</span>
            </div>
        </form>
    </div>
    <div class="p-footer p-footer-new">
        <span class="text">All prices quoted exclude VAT at 15%</span>
        <button class="btn mla" (click)="onSubmit($event)">Approve for ZAR {{ total | number: '1.2-2' }}</button>
    </div>
    <app-loader *ngIf="contentLoading" class="posa"></app-loader>
</p-dialog>
