<div [formGroup]="form" class="pb30">
    <div class="accordion-array" formArrayName="certificates">
        <p-accordion *ngFor="let certificate of certificates['controls']; let i = index" [formGroupName]="i">
            <p-accordionTab class="section-wrap" [selected]="certificateInvalid[i]">
                <p-header>
                    <div class="flex-row">
                        <div class="mr10">
                            <div *ngIf="certificate.valid" class="success-icon-wrap">
                                <img
                                    class="success-icon"
                                    src="/assets/images/icons/check.svg"
                                    alt=""
                                    width="10"
                                    height="10"
                                />
                            </div>
                            <div *ngIf="!certificate.valid">
                                <img
                                    class="success-icon"
                                    src="/assets/images/icons/warning.svg"
                                    alt=""
                                    width="15"
                                    height="15"
                                />
                            </div>
                        </div>
                        <div class="accordion-header">
                            <div class="accordion-header-title">
                                <h4 class="position" *ngIf="certificate.get('name').value">
                                    {{ certificate.get('name').value | titlecase }}
                                </h4>
                                <h4 class="position" *ngIf="!certificate.get('name').value">
                                    New Certificate or License
                                </h4>
                            </div>
                        </div>
                    </div>
                </p-header>
                <div class="flex-row jcb">
                    <mat-form-field class="w-50 mr15 licence-name" appearance="outline">
                        <mat-label>Licence or Certificate Name</mat-label>
                        <input type="text" placeholder="Licence or Certificate Name" formControlName="name" matInput />
                        <mat-error *ngIf="form.get('certificates').controls[i].controls.name.invalid">
                            Licence or Certificate Name is required
                        </mat-error>
                    </mat-form-field>
                    <div class="addCertificateFile w-50 ml15">
                        <label class="add-section-button certificate" *ngIf="!file[i]">
                            <img src="/assets/images/plus-icon.svg" alt="" />
                            <span>Add Supporting Document</span>
                            <input
                                class="file"
                                type="file"
                                formControlName="document"
                                (change)="onCertificateAdd($event, i)"
                            />
                        </label>
                        <div class="existing-files" *ngIf="file[i]">
                            <span>
                                <img src="/assets/images/icons/files-icon-pdf.svg" alt="pdf icon" />
                            </span>
                            <div class="file-block-holder">
                                <span class="title"
                                    >{{
                                        file[i].name.length > 28 ? (file[i].name | slice: 0:28) + '..' : file[i].name
                                    }}&nbsp;
                                    <span class="thin" *ngIf="file.size">{{ convertSize(file[i].size) }}</span>
                                </span>
                                <div class="label-row">
                                    <span class="thin" *ngIf="file[i].lastModifiedDate">{{
                                        file[i].lastModifiedDate | date: 'MMM d, y'
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="error-message" *ngIf="uploadError && uploadError[i]">
                            {{ uploadError[i] }}
                        </div>
                    </div>
                </div>

                <div class="close-icon-wrap">
                    <span class="close-icon">
                        <svg-icon
                            [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"
                            src="/assets/images/icons/icons-8-trash-can.svg"
                            (click)="onSectionRemove(i)"
                        ></svg-icon>
                    </span>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <button class="add-section-button" (click)="onSectionAdd()">
        <img src="/assets/images/plus-icon.svg" alt="" />
        <span>Add Certification or License</span>
    </button>
</div>
