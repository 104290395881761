import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { AuthService as SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';
import { Intercom } from 'ng-intercom';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './../../core/services/user.service';
import { Logout, State } from './../../store/reducers';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    defaultTenantId = 'hellocrowd';

    dataSource: any = {
        loading: false
    };

    _loading: Subject<boolean>;
    _unauthorized: Subject<boolean>;
    private ssoEmail = new BehaviorSubject<any>(null);

    constructor(
        private http: HttpClient,
        private cookie: CookieService,
        private socialAuthService: SocialAuthService,
        public intercom: Intercom,
        private store: Store<State>,
        private userService: UserService,
        public auth: AngularFireAuth
    ) {
        this._loading = new Subject();
        this._unauthorized = new Subject();
    }

    public get $loading(): Observable<boolean> {
        return this._loading.asObservable();
    }
    public get loading(): boolean {
        return this.dataSource.loading;
    }
    public set loading(value: boolean) {
        this.dataSource.loading = value;
        this._loading.next(value);
    }

    public get $unauthorized(): Observable<boolean> {
        return this._unauthorized.asObservable();
    }
    public set unauthorized(value: boolean) {
        this._unauthorized.next(value);
    }

    onGoogleSignin() {
        return this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    signInWithGoogle(authData) {
        return this.http.post(`${environment.apiUrl}/auth/oauth/google`, authData);
    }

    signUpWithGoogle(data) {
        return this.http.post(`${environment.apiUrl}/auth/oauth/signup-google`, {
            ...data,
            source: 'jobs'
        });
    }

    signin(email, password) {
        return this.http.post(`${environment.apiUrl}/auth/signin`, {
            email,
            password,
            source: 'jobs'
        });
    }

    signup(data) {
        return this.http.post(`${environment.apiUrl}/auth/signup`, {
            ...data,
            source: 'jobs'
        });
    }

    userSignup(email) {
        return this.http.post(`${environment.apiUrl}/auth/send-email`, {
            email,
            source: 'jobs'
        });
    }

    verifyOtp(email, otp) {
        return this.http.post(`${environment.apiUrl}/auth/user-signup-verify-otp`, {
            email,
            otp,
            source: 'jobs'
        });
    }

    resetPassword(email) {
        return this.http.post(`${environment.apiUrl}/auth/reset-password`, {
            email,
            source: 'jobs'
        });
    }

    setPassword(password, password_reset_token, invitation_code) {
        return this.http.post(`${environment.apiUrl}/auth/set-password`, {
            password,
            password_reset_token,
            invitation_code,
            source: 'jobs'
        });
    }

    getFromInvitationCode(code: string) {
        return this.http.get(`${environment.apiUrl}/auth/invitation-code?code=${code}`);
    }

    completeAgencySignup(data) {
        return this.http.post(`${environment.apiUrl}/auth/agency-signup`, data);
    }

    agencySignupGetSignature(data) {
        return this.http.post(`${environment.apiUrl}/auth/agency-signup/get-signature`, data);
    }

    checkUserExists(email) {
        return this.http.post(`${environment.apiUrl}/auth/check-user`, { email });
    }

    setSession(authResult) {
        this.cookie.set('access_token', authResult.access_token, 12, '/');
    }

    logout() {
        this.socialAuthService.signOut();
        this.cookie.delete('access_token', '/');
        this.store.dispatch(new Logout());
        this.intercom.shutdown();
        // this.msalService.logout();
    }

    isLoggedIn() {
        return this.cookie.get('access_token') ? true : false;
    }

    getAuthorizationToken() {
        return this.cookie.get(`access_token`);
    }

    getUserData() {
        return new Promise((resolve, reject) => {
            this.http.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${environment.geoipKey}`).subscribe(
                (userData) => resolve(userData),
                (error) => {
                    console.error(error);
                    return resolve(null);
                }
            );
        });
    }
    getCompanyByEmail(email) {
        return this.http.post(`${environment.apiUrl}/email`, { email });
    }

    ssoSignIn(domain, data = null) {
        return this.http.post(`${environment.apiUrl}/signin-sso`, { domain, ...data });
    }

    ssoSignOut(data) {
        return this.http.post(`${environment.apiUrl}/signout-sso`, data);
    }

    getUserByEmail(email) {
        return this.http.get(`${environment.apiUrl}/auth/user-data?email=${email}`);
    }

    setSsoemail(val) {
        this.ssoEmail.next(val);
    }

    getSsoemail(): Observable<any> {
        return this.ssoEmail.asObservable();
    }

    validateRecaptcha(token: string) {
        return this.http.get(`${environment.apiUrl}/validate-recaptcha?token=${token}`);
    }

    authenticateFirebase() {
        this.userService.getToken().subscribe(
            (response: any) => {
                // console.log('TOKEN:', response.token);
                this.auth
                    .signInWithCustomToken(response.token)
                    .then((authResponse) => {
                        // console.log(authResponse);
                    })
                    .catch((error) => console.error(error));
            },
            (errorResponse) => console.error(errorResponse)
        );
    }

    isPhoneUnique(phone: string) {
        return this.http.get(`${environment.apiUrl}/auth/phone-unique?phone=${encodeURIComponent(phone)}`);
    }

    phoneIsUniqueValidator(): AsyncValidatorFn {
        console.log('running phoneIsUniqueValidator');
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return this.isPhoneUnique(control.value).pipe(
                map((res) => {
                    return res ? null : { phoneIsUniqueValidator: true };
                })
            );
        };
    }
}
