import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'app-msal-signout',
    template: `
        <p>
            Signout
        </p>
    `,
    styles: []
})
export class MsalSignoutComponent implements OnInit {
    constructor(private msal: MsalService) {
        this.msal.logout();
    }

    ngOnInit(): void {}
}
