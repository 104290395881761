import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromStagesData from '../reducers/stagesData.reducer';

export const getStagesDataEntities = createSelector(
    fromFeature.getStagesDataState,
    fromStagesData.getStagesDataEntities
);

export const getAllStagesData = createSelector(getStagesDataEntities, (entities) => {
    return entities;
});

export const getStagesDataLoading = createSelector(fromFeature.getStagesDataState, fromStagesData.getStagesDataLoading);

export const getStagesDataLoaded = createSelector(fromFeature.getStagesDataState, fromStagesData.getStagesDataLoaded);

export const getLocationsStagesData = createSelector(
    fromFeature.getStagesDataState,
    fromStagesData.getStagesDataLocations
);
