import { AbstractControl } from '@angular/forms';
export class MinTwoWordsValidator {
    static validate(ac: AbstractControl) {
        const fullName: string = ac.get('fullName').value;
        const words = fullName.split(' ').filter((el) => el.length);
        if (words.length < 2) {
            ac.get('fullName').setErrors({ LessThenTwoWords: true });
        } else {
            return null;
        }
    }
}
