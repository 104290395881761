<app-experience
    *ngIf="candidate.employmentHistory && candidate.employmentHistory.length"
    [employmentExperience]="candidate.employmentHistory"
></app-experience>
<div class="content-block" *ngIf="candidate.summary">
    <h3>Executive Summary</h3>
    <p [innerHTML]="candidate.executive_summary || candidate.employment_summary"></p>
</div>

<div class="content-block experience-wrap" *ngIf="candidate.employmentHistory && candidate.employmentHistory.length">
    <h3>Work Experience</h3>
    <p-accordion>
        <p-accordionTab *ngFor="let work of candidate.employmentHistory">
            <p-header>
                <div class="title">
                    <div class="position">{{ work.title | titlecase }}</div>
                    -
                    <div class="company">{{ work.company | titlecase }}</div>
                </div>
                <span class="date">{{ work.start_date || 'Unknown' }} - {{ work.end_date || 'Unknown' }}</span>
            </p-header>
            <div class="description" [innerHTML]="work.description"></div>
        </p-accordionTab>
    </p-accordion>
</div>

<div class="content-block education-wrap" *ngIf="candidate.educationHistory && candidate.educationHistory.length">
    <h3>Education</h3>
    <div class="education-item" *ngFor="let edu of candidate.educationHistory">
        <div class="title">
            <div class="position" *ngIf="edu.degree">
                {{ edu.area_of_study }}
                <span>({{ edu.degree }})</span>
            </div>
            -
            <div class="company">{{ edu.school || 'Unknown school' }}</div>
        </div>
        <span class="date" *ngIf="edu.start_date">
            {{ edu.start_date || 'Unknown' }} - {{ edu.end_date || 'Unknown' }}</span
        >
    </div>
</div>

<div class="content-block" *ngIf="candidate.certificates && candidate.certificates.length">
    <h3>Licenses and Certifications</h3>
    <div class="language" *ngFor="let certificate of candidate.certificates">
        <div class="title">
            <div class="position" *ngIf="certificate.name">{{ certificate.name }}</div>
        </div>
    </div>
</div>

<div class="content-block education-wrap" *ngIf="candidate.languages && candidate.languages.length">
    <h3>Languages</h3>
    <div class="language education-item" *ngFor="let lang of candidate.languages">
        <div class="title">
            <div class="position" *ngIf="lang.language_name">{{ lang.language_name }}</div>
        </div>
        <span class="date" *ngIf="lang.native">Native</span>
        <div class="date" *ngIf="!lang.native">
            <p>
                Read: {{ lang.read | titlecase }} | Write: {{ lang.read | titlecase }} | Speak:
                {{ lang.speak | titlecase }}
            </p>
        </div>
    </div>
</div>
