import { Stage } from './stage';
export interface ITag {
    hash?: string;
    color: string;
    title?: string;
    label?: string;
    added_at?: any;
    user_id?: string;
}

export class Job {
    id?: string;
    title: string;
    company: string;
    ref: string;
    category: string[];
    location: string;
    is_remote: boolean;
    job_type: string;
    start_date?: string;
    contract_duration?: string;
    number_of_hires?: number;
    education: string;
    single_salary: boolean;
    salary_from?: number;
    salary_to?: number;
    salary_period: string;
    salary_period_text?: string;
    salary_currency?: string;
    hide_salary: boolean;
    description: string;
    requirements: string;
    job_role: string;
    job_listing: string;
    job_listing_transformed?: string;
    resume_upload_required: boolean;
    email_missing_info: boolean;
    email_suggestions: boolean;
    application_field_name: string;
    application_field_email: string;
    application_field_phone: string;
    application_field_current_location: string;
    application_field_employment_equity_status: string;
    application_field_gender: string;
    application_field_experience_summary: string;
    application_field_work_history: string;
    application_field_education: string;
    application_field_cover_letter: string;
    applications?: number;
    business_unit?: string;
    cost_centre?: string;
    questionnaire: string;
    questions?: any[];
    questions_list?: any[];
    hiring_managers: string[];
    recruiters: string[];
    recruitment_agencies?: string[];
    hr_business_partners?: string[];
    hiring_panels?: string[];
    default_email_name: string;
    step_completed?: number;
    status?: string;
    created?: string;
    updated?: string;
    created_at?: string;
    updated_at?: number;
    stages?: Stage[];
    owner?: string;
    show_position_rating?: boolean;
    location_city?: string;
    location_country?: string;
    pool?: boolean;
    tags?: ITag[];
    sovren_parsed_job?: object;
    sovren_categories?: {
        AppliedCategoryWeights?: any[];
        SuggestedCategoryWeights?: any[];
    };
    resume_matching_threshold?: number;
    edit_matching_criteria?: boolean;
    do_not_match?: boolean;
    weighting?: any;
    pipeline?: string;
    job_with_pipeline?: boolean;
    location_short?: string;
    candidates_last_activity?: number;
    all_agencies_added?: boolean;
    industry?: string;
    vonq_category?: string;
    vonq_campaigns?: Array<{ campaignId: string; status: string }>;
    seniority?: string;
    years_experience?: string;
    owner_full_name?: string;
    map?: string;
    job_view_url?: string;
    upload_resume?: boolean;
}

export class SdkJob {
    id?: string;
    title: string;
    ref: string;
    company: string;
    category: string[];
    contract_duration: string;
    location: string[];
    business_unit?: string;
    cost_centre?: string;
    is_remote: boolean;
    type: string;
    number_of_hires: number;
    education: string;
    experience: string;
    salary: {
        from: number;
        to?: number;
        period?: string;
        currency?: string;
        single: boolean;
        hide: boolean;
    };
    role: string;
    description: string;
    requirements: string;
    hiring_managers: string[];
    recruiters: string[];
    recruitment_agencies: string[];
    hr_business_partners?: string[];
    hiring_panels?: string[];
    start_date: string;
    status: string;
    owner: string;
    default_email_name: string;
    applications: number;
    resume_matching_threshold: number;
    do_not_match: boolean;
    pipeline: string;
    joborder_file?: string | null;
    industry: string;
    years_experience: number;
    seniority: string;
    vonq_category: string;
    vonq_campaigns?: Array<{ campaignId: string; status: string }>;
    stages_stats: Array<{ id: string; order: number; candidates: number }>;
    stages?: any[];
    candidates?: any[];
    upload_resume_required?: boolean;
    tags?: ITag[];
    created?: boolean;
    created_at?: number;
    created_by?: string;
    updated_at?: number;
    closed_at?: number;
    hold_at?: number;
    published_at?: number;
    private_at?: number;
    questions_list?: any;
    candidates_last_activity?: number;
    owner_full_name?: string;
    hiring_role?: string;
    salary_period_text?: string;
    all_agencies_added?: boolean;
    map?: string;
    job_view_url?: string;
    rating?: any;
    applicants?: any[];
    visits?: any;
    offer_signs?: any;
    jdAiCompleted?: boolean;
    creationMode?: string;
}
