import { PromoteService } from '@app/core/services/promote.service';
import { CandidateService } from './candidate.service';
import { EmailService } from './email.service';
import { EmployeesService } from './employees.service';
import { WorkflowService } from './workflow.service';
import { EsigningService } from './esigning.service';
import { FormHelperService } from './form-helper.service';
import { IntegrationsService } from './integrations.service';
import { InterviewsService } from './interviews.service';
import { JobService } from './job.service';
import { OffersService } from './offers.service';
import { PreferencesService } from './preferences.service';
import { QuestionnaireService } from './questionnaire.service';
import { UserService } from './user.service';
import { UtilitiesService } from './utilities.service';
import { XLSXService } from './xlsx.service';
import { SurveyService } from './surveys.service';
import { EventService } from './event.service';

export const services: any[] = [
    UserService,
    JobService,
    CandidateService,
    UtilitiesService,
    QuestionnaireService,
    FormHelperService,
    EmailService,
    PreferencesService,
    XLSXService,
    InterviewsService,
    OffersService,
    PromoteService,
    IntegrationsService,
    EmployeesService,
    EsigningService,
    SurveyService,
    WorkflowService,
    EventService
];

export * from './questionnaire.service';
export * from './integrations.service';
export * from './offers.service';
export * from './promote.service';
export * from './candidate.service';
export * from './candidate.service';
export * from './job.service';
export * from './preferences.service';
export * from './user.service';
export * from './utilities.service';
export * from './email.service';
export * from './interviews.service';
export * from './xlsx.service';
