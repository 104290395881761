<div [formGroup]="form">
    <div class="accordion-array" formArrayName="education">
        <p-accordion *ngFor="let education of educationHistory['controls']; let i = index" [formGroupName]="i">
            <p-accordionTab class="section-wrap" [selected]="educationInvalid[i]">
                <p-header>
                    <div class="flex-row">
                        <div class="mr10">
                            <div *ngIf="education.valid" class="success-icon-wrap">
                                <img
                                    class="success-icon"
                                    src="/assets/images/icons/check.svg"
                                    alt=""
                                    width="10"
                                    height="10"
                                />
                            </div>
                            <div *ngIf="!education.valid">
                                <img
                                    class="success-icon"
                                    src="/assets/images/icons/warning.svg"
                                    alt=""
                                    width="15"
                                    height="15"
                                />
                            </div>
                        </div>
                        <div class="accordion-header">
                            <div class="accordion-header-title">
                                <h4 class="position" *ngIf="education.get('area_of_study').value">
                                    {{ education.get('area_of_study').value | titlecase }}
                                </h4>
                                <h4 class="position" *ngIf="!education.get('area_of_study').value">New Education</h4>
                                <span class="company"> - {{ education.get('school').value | titlecase }}</span>
                            </div>
                            <div class="date">
                                {{ education.get('start_date').value || 'Unknown' }} -
                                {{ education.get('end_date').value || 'Unknown' }}
                            </div>
                        </div>
                    </div>
                </p-header>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Name of Institution</mat-label>
                    <input type="text" placeholder="Name of Institution" formControlName="school" matInput />
                    <mat-error *ngIf="form.get('education').controls[i].controls.school.invalid">
                        Name of Institution is required
                    </mat-error>
                </mat-form-field>
                <div class="flex-row">
                    <mat-form-field class="dropdown-container w-50 pr15" appearance="outline">
                        <mat-label>Qualification</mat-label>
                        <mat-select formControlName="degree">
                            <mat-option *ngFor="let degree of degreesOptions" [value]="degree.value">
                                {{ degree.label }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('education').controls[i].controls.degree.invalid">
                            Qualification is required
                        </mat-error>
                    </mat-form-field>
                    <div class="form-block flex-1 pl15 pr15 w-20">
                        <app-datepicker ngDefaultControl formControlName="start_date" placeholder="From">
                        </app-datepicker>
                        <mat-error
                            *ngIf="
                                form.get('education').controls[i].controls.start_date.touched &&
                                form.get('education').controls[i].controls.start_date.invalid
                            "
                        >
                            Date is required
                        </mat-error>
                    </div>
                    <div class="form-block flex-1 pl15 w-20">
                        <app-datepicker
                            ngDefaultControl
                            formControlName="end_date"
                            placeholder="To"
                            hasCheckbox="true"
                            checkboxText="I’m currently studying here"
                        >
                        </app-datepicker>
                        <mat-error
                            *ngIf="
                                form.get('education').controls[i].controls.end_date.touched &&
                                form.get('education').controls[i].controls.end_date.invalid
                            "
                        >
                            Date is required
                        </mat-error>
                    </div>
                </div>

                <div class="flex-row">
                    <mat-form-field class="flex-1 w-50 pr15" appearance="outline">
                        <mat-label>Area of Study</mat-label>
                        <input type="text" placeholder="Area of Study" formControlName="area_of_study" matInput />
                        <mat-error *ngIf="form.get('education').controls[i].controls.area_of_study.invalid">
                            Area of Study is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="flex-1 w-50 pl15" appearance="outline">
                        <mat-label>GPA / Score</mat-label>
                        <input type="text" placeholder="GPA / Score" formControlName="score" matInput />
                    </mat-form-field>
                </div>
                <div class="close-icon-wrap">
                    <span class="close-icon">
                        <svg-icon
                            [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"
                            src="/assets/images/icons/icons-8-trash-can.svg"
                            (click)="onSectionRemove(i)"
                        ></svg-icon>
                    </span>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <button class="add-section-button" (click)="onSectionAdd()">
        <img src="/assets/images/plus-icon.svg" alt="" />
        <span>Add Education</span>
    </button>
</div>
