import { Action } from '@ngrx/store';
import { SdkJob } from './../../../../../../core/models/job';

// load jobs
export const LOAD_JOBS = '[Jobs] Load Jobs';
export const LOAD_JOBS_FAIL = '[Jobs] Load Jobs Fail';
export const LOAD_JOBS_SUCCESS = '[Jobs] Load Jobs Success';

export class LoadJobs implements Action {
    readonly type = LOAD_JOBS;
}

export class LoadJobsFail implements Action {
    readonly type = LOAD_JOBS_FAIL;
    constructor(public payload: any) {}
}

export class LoadJobsSuccess implements Action {
    readonly type = LOAD_JOBS_SUCCESS;
    constructor(public payload: SdkJob[]) {}
}

// load jobs
export const LOAD_JOB = '[Jobs] Load Job';
export const LOAD_JOB_FAIL = '[Jobs] Load Job Fail';
export const LOAD_JOB_SUCCESS = '[Jobs] Load Job Success';

export class LoadJob implements Action {
    readonly type = LOAD_JOB;
    constructor(public payload: { id: string }) {}
}

export class LoadJobFail implements Action {
    readonly type = LOAD_JOB_FAIL;
    constructor(public payload: any) {}
}

export class LoadJobSuccess implements Action {
    readonly type = LOAD_JOB_SUCCESS;
    constructor(public payload: SdkJob) {}
}

// create job
export const CREATE_JOB = '[Jobs] Create Job';
export const CREATE_JOB_FAIL = '[Jobs] Create Job Fail';
export const CREATE_JOB_SUCCESS = '[Jobs] Create Job Success';

export class CreateJob implements Action {
    readonly type = CREATE_JOB;
    constructor(public payload: SdkJob) {}
}

export class CreateJobFail implements Action {
    readonly type = CREATE_JOB_FAIL;
    constructor(public payload: any) {}
}

export class CreateJobSuccess implements Action {
    readonly type = CREATE_JOB_SUCCESS;
    constructor(public payload: SdkJob) {}
}

// update job
export const UPDATE_JOB = '[Jobs] Update Job';
export const UPDATE_JOB_FAIL = '[Jobs] Update Job Fail';
export const UPDATE_JOB_SUCCESS = '[Jobs] Update Job Success';
export const UPDATE_JOB_FIELDS_IN_LIST = '[Jobs] Update Job Fields in List';

export class UpdateJob implements Action {
    readonly type = UPDATE_JOB;
    constructor(public payload: { id: string; data: SdkJob }) {}
}

export class UpdateJobFail implements Action {
    readonly type = UPDATE_JOB_FAIL;
    constructor(public payload: any) {}
}

export class UpdateJobSuccess implements Action {
    readonly type = UPDATE_JOB_SUCCESS;
    constructor(public payload: SdkJob) {}
}

export class UpdateJobFieldsInList implements Action {
    readonly type = UPDATE_JOB_FIELDS_IN_LIST;
    constructor(public payload: { id: string; data: any }) {}
}

// update jobs
export const UPDATE_JOBS = '[Jobs] Update Jobs';
export const UPDATE_JOBS_FAIL = '[Jobs] Update Jobs Fail';
export const UPDATE_JOBS_SUCCESS = '[Jobs] Update Jobs Success';
export const UPDATE_JOBS_FIELDS_IN_LIST = '[Jobs] Update Jobs Fields in List';

export class UpdateJobs implements Action {
    readonly type = UPDATE_JOBS;
    constructor(public payload: Array<{ id: string; data: any }>) {}
}

export class UpdateJobsFail implements Action {
    readonly type = UPDATE_JOBS_FAIL;
    constructor(public payload: any) {}
}

export class UpdateJobsSuccess implements Action {
    readonly type = UPDATE_JOBS_SUCCESS;
    constructor(public payload: SdkJob[]) {}
}

// delete job

export const DELETE_JOB = '[Jobs] Delete Job';

export class DeleteJob implements Action {
    readonly type = DELETE_JOB;
    constructor(public payload: string) {}
}

// bulk delete jobs
export const BULK_DELETE_JOBS = '[Jobs] Bulk Delete Jobs';
export const BULK_DELETE_JOBS_FAIL = '[Jobs] Bulk Delete Jobs Fail';
export const BULK_DELETE_JOBS_SUCCESS = '[Jobs] Bulk Delete Jobs Success';

export class BulkDeleteJobs implements Action {
    readonly type = BULK_DELETE_JOBS;
    constructor(public payload: string[]) {}
}

export class BulkDeleteJobsFail implements Action {
    readonly type = BULK_DELETE_JOBS_FAIL;
    constructor(public payload: any) {}
}

export class BulkDeleteJobsSuccess implements Action {
    readonly type = BULK_DELETE_JOBS_SUCCESS;
    constructor(public payload: string[]) {}
}

export type JobsAction =
    | LoadJobs
    | LoadJobsFail
    | LoadJobsSuccess
    | LoadJob
    | LoadJobFail
    | LoadJobSuccess
    | CreateJob
    | CreateJobFail
    | CreateJobSuccess
    | UpdateJob
    | UpdateJobFail
    | UpdateJobSuccess
    | UpdateJobFieldsInList
    | UpdateJobs
    | UpdateJobsFail
    | UpdateJobsSuccess
    | BulkDeleteJobs
    | BulkDeleteJobsFail
    | BulkDeleteJobsSuccess
    | DeleteJob;
