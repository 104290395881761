import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsService } from '@app/core/services/ms.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { mergeMap, tap } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { ServerHashParamKeys } from 'msal';
import { from } from 'rxjs';

@Injectable()
export class MsInterceptor implements HttpInterceptor {
    constructor(
        private ms: MsService,
        private auth: MsalService,
        private router: Router,
        private route: ActivatedRoute,
        private broadcastService: BroadcastService,
        private cookie: CookieService
    ) {}

    handleError(err) {
        if (err instanceof HttpErrorResponse) {
            console.error(err);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const scopes = this.auth.getScopesForEndpoint(req.url);
        if (!scopes) {
            return next.handle(req);
        }
        let token;
        return from(
            this.auth
                .acquireTokenSilent({ scopes })
                .then((response) => {
                    token =
                        response.tokenType === ServerHashParamKeys.ID_TOKEN
                            ? response.idToken.rawIdToken
                            : response.accessToken;
                    const authHeader = `Bearer ${token}`;
                    if (req.url !== 'https://realm.mongodb.com/api/client/v2.0/app/testingd9-swxkf/graphql') {
                        return req.clone({
                            setHeaders: {
                                Authorization: authHeader
                            }
                        });
                    } else {
                        return req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${localStorage.getItem('realmToken')}`
                            }
                        });
                    }
                })
                .catch((error) => {
                    if (req.url.indexOf('graph.microsoft.com') !== -1) {
                        this.handleError(error);
                        const token = this.cookie.get('msal_access_token');
                        console.log('[Interceptor] Using token from cookies:', token);
                        this.broadcastService.broadcast('msal:notAuthorized', error);
                        if (token && token.length) {
                            if (req.url !== 'https://realm.mongodb.com/api/client/v2.0/app/testingd9-swxkf/graphql') {
                                return req.clone({
                                    setHeaders: {
                                        Authorization: token
                                    }
                                });
                            } else {
                                return req.clone({
                                    setHeaders: {
                                        Authorization: `Bearer ${localStorage.getItem('realmToken')}`
                                    }
                                });
                            }
                        } else {
                            if (req.url.indexOf('graph.microsoft.com/v1.0') !== -1) {
                                this.auth
                                    .loginPopup({
                                        extraScopesToConsent: ['Calendars.ReadWrite', 'Contacts.ReadWrite']
                                    })
                                    .then((response) => {
                                        let accessToken = response.accessToken;
                                        return req.clone({
                                            setHeaders: {
                                                Authorization: `Bearer ${accessToken}`
                                            }
                                        });
                                    })
                                    .catch((error) => {
                                        console.error('ERROR GETTING TOKEN');
                                        return req.clone({
                                            setHeaders: {
                                                Authorization: 'X-UNAUTHORIZED'
                                            }
                                        });
                                    });
                            }
                        }
                    } else {
                        return req.clone({
                            setHeaders: {
                                Authorization: 'X-UNAUTHORIZED'
                            }
                        });
                    }
                })
        ).pipe(mergeMap((nextReq) => next.handle(nextReq)));
    }
}
