import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-graph-activity-overview',
    templateUrl: './graph-activity-overview.component.html',
    styleUrls: ['./graph-activity-overview.component.scss']
})
export class GraphActivityOverviewComponent implements OnInit {
    graph = [
        { title: 'Mon', time: [] },
        { title: 'Tue', time: [] },
        { title: 'Wed', time: [] },
        { title: 'Thu', time: [] },
        { title: 'Fr', time: [] },
        { title: 'Sat', time: [] },
        { title: 'Sun', time: [] }
    ];
    colors = ['#d8f0e3', '#b1e0c7', '#89d1ab', '#62c18f', '#3bb273'];
    ranges;
    @Input() analytics;
    constructor() {}

    ngOnInit(): void {
        this.graph.forEach((g) => {
            g.time.push(
                { label: '12', value: '12:00 a.m.', contribution: 0 },
                { label: '1', value: '1:00 a.m.', contribution: 0 },
                { label: '2', value: '2:00 a.m.', contribution: 0 },
                { label: '3', value: '3:00 a.m.', contribution: 0 },
                { label: '4', value: '4:00 a.m.', contribution: 0 },
                { label: '5', value: '5:00 a.m.', contribution: 0 },
                { label: '6', value: '6:00 a.m.', contribution: 0 },
                { label: '7', value: '7:00 a.m.', contribution: 0 },
                { label: '8', value: '8:00 a.m.', contribution: 0 },
                { label: '9', value: '9:00 a.m.', contribution: 0 },
                { label: '10', value: '10:00 a.m.', contribution: 0 },
                { label: '11', value: '11:00 a.m.', contribution: 0 },
                { label: '12', value: '12:00 p.m.', contribution: 0 },
                { label: '1', value: '1:00 p.m.', contribution: 0 },
                { label: '2', value: '2:00 p.m.', contribution: 0 },
                { label: '3', value: '3:00 p.m.', contribution: 0 },
                { label: '4', value: '4:00 p.m.', contribution: 0 },
                { label: '5', value: '5:00 p.m.', contribution: 0 },
                { label: '6', value: '6:00 p.m.', contribution: 0 },
                { label: '7', value: '7:00 p.m.', contribution: 0 },
                { label: '8', value: '8:00 p.m.', contribution: 0 },
                { label: '9', value: '9:00 p.m.', contribution: 0 },
                { label: '10', value: '10:00 p.m.', contribution: 0 },
                { label: '11', value: '11:00 p.m.', contribution: 0 }
            );
        });
        // console.log('analytics', this.analytics);
        this.analytics.applicants.forEach((a) => {
            const day_of_week = moment.unix(a.applied_at).day();
            const hours = moment.unix(a.applied_at).hours();
            if (day_of_week === 0) {
                this.graph[6].time[hours].contribution += 1;
            } else {
                this.graph[day_of_week - 1].time[hours].contribution += 1;
            }
        });
        // console.log('graph', this.graph);
        const values = [];
        this.graph.forEach((a) => {
            a.time.forEach((t) => {
                if (t.contribution) {
                    values.push(t.contribution);
                }
            });
        });
        this.defineRanges(values);
    }

    defineRanges(values) {
        const max = Math.max(...values);
        const average = Math.ceil(max / 5);
        this.ranges = [average, average * 2, average * 3, average * 4, average * 5];
        // console.log(this.ranges, max, average);
    }

    getColor(value) {
        if (value === 0) {
            return;
        } else if (value >= this.ranges[4]) {
            return this.colors[4];
        } else if (value >= this.ranges[3]) {
            return this.colors[3];
        } else if (value >= this.ranges[2]) {
            return this.colors[2];
        } else if (value >= 0) {
            return this.colors[1];
        }
    }
}
