import * as fromCountries from './../actions/countries.action';
export interface CountriesState {
    entities: any[];
    loaded: boolean;
    loading: boolean;
    error: null;
}

export const initialState: CountriesState = {
    entities: [],
    loaded: false,
    loading: false,
    error: null
};

export function reducer(state = initialState, action: fromCountries.CountriesAction): CountriesState {
    switch (action.type) {
        case fromCountries.LOAD_COUNTRIES: {
            return { ...state, loading: true, error: null };
        }

        case fromCountries.LOAD_COUNTRIES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            };
        }

        case fromCountries.LOAD_COUNTRIES_SUCCESS: {
            const entities = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                entities,
                error: null
            };
        }
    }

    return state;
}
