import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UtilitiesService } from '@app/core/services';
import { MsalModule } from '@azure/msal-angular';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthServiceConfig, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { IntercomModule } from 'ng-intercom';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, PageNotFoundComponent, UnsupportedBrowserComponent } from './core/components';
// import { CompletedOffersComponent } from './core/components/completed-offers/completed-offers.component';
import { LandingPageComponent } from './core/components/landing-page/landing-page.component';
import { MsalSignoutComponent } from './core/components/msal-signout/msal-signout.component';
import { GraphQLModule } from './graphql.module';
import { getSaver, SAVER } from './libs/saver.provider';
import { SharedModule } from './modules/shared/shared.module';
import { CustomSerializer, effects, metaReducers, reducers } from './store';

let config = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.googleClientId)
    }
]);

export function provideConfig() {
    return config;
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        LandingPageComponent,
        UnsupportedBrowserComponent,
        MsalSignoutComponent
    ],
    imports: [
        GraphQLModule,
        AppRoutingModule,
        BrowserModule,
        SharedModule,
        BrowserAnimationsModule,
        SocialLoginModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        StoreRouterConnectingModule.forRoot(),
        ToastrModule.forRoot(),
        EffectsModule.forRoot(effects),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        IntercomModule.forRoot({
            appId: environment.intercomAppId,
            updateOnRouterChange: true
        }),
        MsalModule.forRoot(
            {
                auth: {
                    clientId: environment.msalClientId,
                    authority: 'https://login.microsoftonline.com/organizations',
                    redirectUri: environment.msalRedirectUrl
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE // set to true for IE 11
                }
            },
            {
                popUp: !isIE,
                consentScopes: [
                    'user.read',
                    'openid',
                    'profile',
                    'Calendars.ReadWrite',
                    'Contacts.ReadWrite',
                    'Group.ReadWrite.All',
                    'OnlineMeetings.ReadWrite'
                ],
                unprotectedResources: [],
                protectedResourceMap: [['https://graph.microsoft.com/v1.0/me', ['user.read']]],
                extraQueryParameters: {}
            }
        ),
        RecaptchaV3Module,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule
    ],
    providers: [
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        },
        {
            provide: RouterStateSerializer,
            useClass: CustomSerializer
        },
        { provide: SAVER, useFactory: getSaver },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
        UtilitiesService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
