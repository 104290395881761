<mat-form-field appearance="outline" class="dd-full-width" [ngClass]="{ 'new-form-field': newStyle }">
    <mat-label>{{ placeholder }}</mat-label>
    <input
        #matInput
        type="text"
        matInput
        [matAutocomplete]="auto"
        [formControl]="filterControl"
        (focus)="onfocus()"
        (blur)="onblur()"
    />
    <!-- <mat-icon matSuffix>expand_more</mat-icon> -->

    <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="optionSelected($event.option)"
        [class]="'auto-dd-panel'"
    >
        <mat-option
            *ngFor="let data of filteredOptions | async"
            [value]="data"
            [disabled]="data.selected"
            [ngClass]="{ 'no-access': data.addAccessClass }"
        >
            {{ data.label ? data.label : data.value }}
        </mat-option>
    </mat-autocomplete>
    <div class="clear-icon" (click)="onclear()" *ngIf="showClear">
        <img src="/assets/images/icons8-delete.svg" width="16" height="16" alt="" />
    </div>
</mat-form-field>
