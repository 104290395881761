import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-employee-answers',
    templateUrl: './employee-answers.component.html',
    styleUrls: ['./employee-answers.component.scss']
})
export class EmployeeAnswersComponent implements OnInit {
    @Input() answers;
    constructor() {}

    ngOnInit(): void {
        console.log(this.answers);
    }

    dateFormat(date) {
        return moment.unix(date).format('DD MMMM YYYY');
    }
}
