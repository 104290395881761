<div [formGroup]="form">
    <div class="accordion-array" formArrayName="languages">
        <p-accordion
            *ngFor="let language of languages['controls']; let i = index"
            [formGroupName]="i"
            (onOpen)="toggleNative(i)"
            (onClose)="toggleNative(i)"
        >
            <p-accordionTab class="section-wrap" [selected]="languageInvalid[i]">
                <p-header>
                    <div class="flex-row">
                        <div class="mr10">
                            <div *ngIf="language.valid" class="success-icon-wrap">
                                <img
                                    class="success-icon"
                                    src="/assets/images/icons/check.svg"
                                    alt=""
                                    width="10"
                                    height="10"
                                />
                            </div>
                            <div *ngIf="!language.valid">
                                <img
                                    class="success-icon"
                                    src="/assets/images/icons/warning.svg"
                                    alt=""
                                    width="15"
                                    height="15"
                                />
                            </div>
                        </div>
                        <div class="accordion-header">
                            <div class="accordion-header-title">
                                <h4 class="position" *ngIf="language.get('language_name').value">
                                    {{ language.get('language_name').value | titlecase }}
                                </h4>
                                <h4 class="position" *ngIf="!language.get('language_name').value">New Language</h4>
                            </div>
                            <div class="date" *ngIf="isShowNative[i] && language.get('is_native').value">
                                Native
                            </div>
                            <div
                                class="date"
                                *ngIf="
                                    isShowNative[i] &&
                                    !language.get('is_native').value &&
                                    (language.get('speak').value ||
                                        language.get('read').value ||
                                        language.get('write').value)
                                "
                            >
                                <span *ngIf="language.get('speak').value"
                                    >Speak: {{ language.get('speak').value | titlecase }} |
                                </span>
                                <span *ngIf="language.get('read').value"
                                    >Read: {{ language.get('read').value | titlecase }} |
                                </span>
                                <span *ngIf="language.get('write').value"
                                    >Write: {{ language.get('write').value | titlecase }}</span
                                >
                            </div>
                        </div>
                    </div>
                </p-header>
                <div class="flex-row jcb">
                    <mat-form-field class="w-66 pr15" appearance="outline">
                        <mat-label>Language</mat-label>
                        <input type="text" placeholder="Language" formControlName="language_name" matInput />
                        <mat-error *ngIf="form.get('languages').controls[i].controls.language_name.invalid">
                            Language Name is required
                        </mat-error>
                    </mat-form-field>

                    <p-checkbox
                        class="flex-row w-33 pl15 pt15 jcc"
                        name="native"
                        label="Native"
                        binary="true"
                        formControlName="is_native"
                        value="language[i].is_native"
                        (onChange)="onNativeClick(i)"
                    >
                    </p-checkbox>
                </div>
                <div class="flex-row jcb" *ngIf="!form.get('languages').controls[i].controls.is_native.value === true">
                    <mat-form-field class="dropdown-container w-33 flex-1 pr15" appearance="outline">
                        <mat-label>Speak</mat-label>
                        <mat-select formControlName="speak">
                            <mat-option *ngFor="let level of levelOptions" [value]="level.value">
                                {{ level.label }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('languages').controls[i].controls.speak.invalid">
                            Speak level is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="dropdown-container w-33 flex-1 pl15 pr15" appearance="outline">
                        <mat-label>Read</mat-label>
                        <mat-select formControlName="read">
                            <mat-option *ngFor="let level of levelOptions" [value]="level.value">
                                {{ level.label }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('languages').controls[i].controls.read.invalid">
                            Read level is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="dropdown-container w-33 flex-1 pl15 pr15" appearance="outline">
                        <mat-label>Write</mat-label>
                        <mat-select formControlName="write">
                            <mat-option *ngFor="let level of levelOptions" [value]="level.value">
                                {{ level.label }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('languages').controls[i].controls.write.invalid">
                            Write level is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="close-icon-wrap">
                    <span class="close-icon">
                        <svg-icon
                            [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"
                            src="/assets/images/icons/icons-8-trash-can.svg"
                            (click)="onSectionRemove(i)"
                        ></svg-icon>
                    </span>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <button class="add-section-button" (click)="onSectionAdd()">
        <img src="/assets/images/plus-icon.svg" alt="" />
        <span>Add Language</span>
    </button>
</div>
