import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TenantService } from './../../../core/services/tenant.service';

@Component({
    selector: 'app-list-filter',
    templateUrl: './list-filter.component.html',
    styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit {
    view = 'list';
    filterVisible = false;
    @Input() allItemsText: string;
    @Input() allItems: any;
    @Input() allItemsCopy: any;
    @Input() availableFilters: string[] = [];
    @Input() jobPage: boolean = false;
    @Input() statusFilter: string = 'LIVE';
    @Input() newStatusFilters: string[] = ['published', 'private', 'draft'];
    filteredFilters: string[] = [];

    @Input() filter: any;
    @Output() filterChange = new EventEmitter<any>();
    @Output() ownerFilterChange = new EventEmitter<string>();
    @Output() jobStatusFilterChange = new EventEmitter<string>();
    @Output() jobStatusNewFilterChange = new EventEmitter<string[]>();
    search: string;

    defaultState: any = {
        city: [],
        country: [],
        employers: [],
        reference: [],
        education: { level: null, major: null, school: null },
        location: { position: null },
        experience: { min: null, max: null },
        type: { option: null },
        skills: {
            name: null,
            mix_exp: null,
            max_exp: null,
            recently_used: false
        }
    };

    filtersObj: any = { ...this.defaultState };
    educationOptions = [
        { label: 'Master / Post-Graduate / PhD', value: 'masters' },
        { label: 'Bachelor / Graduate', value: 'bachelors' },
        { label: 'Vocational / Diploma / Associates degree', value: 'vocational' },
        { label: 'GCSE / A-Level / Highschool / GED', value: 'school' }
    ];

    yearsOptions = [
        { label: '1 year', value: 1 },
        { label: '2 years', value: 2 },
        { label: '3 years', value: 3 },
        { label: '4 years', value: 4 },
        { label: '5 years', value: 5 },
        { label: '6 years', value: 6 },
        { label: '7 years', value: 7 },
        { label: '8 years', value: 8 },
        { label: '9 years', value: 9 },
        { label: '10 year', value: 10 }
    ];

    jobNewOptions = [
        { label: 'Published', value: 'published' },
        { label: 'Private', value: 'private' },
        { label: 'Internal', value: 'internal' },
        { label: 'On Hold', value: 'hold' },
        { label: 'Closed', value: 'closed' },
        { label: 'Draft', value: 'draft' },
        { label: 'All', value: 'all' }
    ];

    jobTypeOptions = [
        { label: 'Full Time', value: 'permanent' },
        { label: 'Temporary', value: 'temporary' },
        { label: 'Fixed Term', value: 'fixed_term' },
        { label: 'Freelance', value: 'freelance' },
        { label: 'Internship', value: 'internship' }
    ];

    tenantLocationOptions: SelectItem[] = [];

    @HostListener('document:click', ['$event'])
    onClick(event): void {
        const ev = event;
        const path = event.path || (event.composedPath && event.composedPath());
        if (!path) {
            return;
        }
        const isListChildClick = path.filter((el) => el.className === 'list-filter').length;
        if (!isListChildClick) {
            this.filterVisible = false;
            this.search = null;
            this.allItems = this.allItemsCopy;
        }
    }

    constructor(private tenantService: TenantService) {
        this.tenantService.init();
    }

    ngOnInit() {
        this.filteredFilters = this.availableFilters;

        this.tenantService.locations().subscribe((locations: any[]) => {
            if (locations) {
                locations.forEach((l) => {
                    if (l.location === 'any') {
                        this.tenantLocationOptions.push({ label: `${l.name}`, value: l.location });
                    } else {
                        this.tenantLocationOptions.push({ label: `${l.name}: ${l.location}`, value: l.location });
                    }
                });
            }
        });

        if (this.allItemsCopy && this.allItemsCopy.length) {
            this.alphabeticalSort(this.allItemsCopy);
        }
        if (this.allItems && this.allItems.length) {
            this.alphabeticalSort(this.allItems);
        }
    }

    private alphabeticalSort(list: any[]) {
        list.sort((a, b) => {
            if (a.label === 'All Jobs' || a.label === 'My Jobs' || b.label === 'All Jobs' || b.label === 'My Jobs') {
                return 0;
            }
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
    }

    onSearchKeyup(event) {
        this.allItems = this.allItemsCopy.filter((j) => {
            if (j.label) {
                const label = j.label.toLowerCase();
                const query = this.search.toLowerCase().trim();
                const queryWords = query.split(' ').filter((word) => word);
                const matched = queryWords.every((word) => label.indexOf(word) !== -1);
                return matched;
            }
        });
        // this.filteredFilters =
        //     this.search && this.search.length
        //         ? this.availableFilters.filter((af) => af.indexOf(this.search.toLowerCase()) !== -1)
        //         : this.availableFilters;
    }

    onFilterItemSelect(event) {
        console.log('onFilterItemSelect', event);
        this.view = event;
    }

    onClearFilters() {
        this.view = 'category';
        this.search = '';
        this.filterVisible = !this.filterVisible;
        // this.filter = [];
        // this.filterVisible = false;
        // this.filtersObj = { ...this.defaultState };
        // this.filterChange.emit([]);
    }

    onFilterToggle(view = 'list') {
        console.log('onFilterToggle');
        this.view = view;
        this.search = '';
        this.filterVisible = !this.filterVisible;
    }

    onFilterEditDone() {
        console.log(this.filtersObj);
        this.transformFilters();
        this.view = 'list';
    }

    transformFilters() {
        // console.log(this.availableFilters);
        // console.log('TRANSFORM FILTERS:');
        this.filter = [];
        for (const key in this.filtersObj) {
            if (this.filtersObj.hasOwnProperty(key) && this.availableFilters.indexOf(key) !== -1) {
                const filterObj = this.filtersObj[key];
                console.log(key);
                switch (key) {
                    case 'city':
                    case 'country':
                    case 'employers':
                    case 'reference':
                        if (filterObj.length) {
                            this.filter.push({
                                type: key,
                                presentedValue: filterObj.join(', '),
                                value: filterObj
                            });
                        }
                        break;
                    case 'education':
                        if (filterObj.level || filterObj.education || filterObj.school) {
                            this.filter.push({
                                type: key,
                                presentedValue: Object.values(filterObj)
                                    .filter((v) => v)
                                    .join(', '),
                                value: filterObj
                            });
                        }
                        break;
                    case 'location':
                        if (filterObj.position) {
                            this.filter.push({
                                type: key,
                                presentedValue: Object.values(filterObj)
                                    .filter((v) => v)
                                    .join(', '),
                                value: filterObj
                            });
                        }
                        break;
                    case 'type':
                        if (filterObj.option) {
                            this.filter.push({
                                type: key,
                                presentedValue: Object.values(filterObj)
                                    .filter((v) => v)
                                    .join(', '),
                                value: filterObj
                            });
                        }
                        break;
                    case 'experience':
                        if (filterObj.min || filterObj.max) {
                            let presentedValue;
                            if (filterObj.min && filterObj.max) {
                                presentedValue = `${filterObj.min} - ${filterObj.max} years`;
                            } else if (filterObj.min && !filterObj.max) {
                                presentedValue = `from ${filterObj.min} years`;
                            } else if (!filterObj.min && filterObj.max) {
                                presentedValue = `up to ${filterObj.min} years`;
                            }
                            this.filter.push({ type: key, presentedValue, value: filterObj });
                        }
                        break;

                    case 'skills':
                        if (filterObj.name) {
                            let presentedValue = filterObj.name;
                            if (filterObj.min_exp && filterObj.max_exp) {
                                presentedValue += `, ${filterObj.min_exp} - ${filterObj.max_exp} years of exp.`;
                            } else if (filterObj.min_exp && !filterObj.max_exp) {
                                presentedValue += `, from ${filterObj.min_exp} years of exp.`;
                            } else if (!filterObj.min_exp && filterObj.max_exp) {
                                presentedValue += `, up to ${filterObj.min_exp} years of exp.`;
                            }
                            if (filterObj.recently_used) {
                                presentedValue += ', recently used';
                            }
                            this.filter.push({
                                type: key,
                                presentedValue,
                                value: filterObj
                            });
                        }
                        break;
                }
            }
        }

        console.log('FILTER:');
        console.log(this.filter);
        this.filterChange.emit(this.filter);
    }

    onRemoveFromSelected(type) {
        console.log('onRemoveFromSelected', type);
        this.filter = this.filter.filter((f) => f.type !== type);
        if (this.filtersObj[type]) {
            this.filterChange.emit(this.filter);
            this.filtersObj[type] = this.defaultState[type];
        }
    }

    onOwnerFilterChange(value) {
        this.ownerFilterChange.emit(value);
        this.filterVisible = false;

        this.search = null;
        this.allItems = this.allItemsCopy;
    }

    onJobStatusFilterChange(value) {
        this.jobStatusFilterChange.emit(value);
    }

    selectDropDown(event: any, field: string, subfield: string): void {
        this.filtersObj[field][subfield] = event.value;
    }

    onChangeStatus(event) {
        const selectedValue = event.itemValue;
        if (selectedValue === 'all' && this.newStatusFilters.indexOf(selectedValue) !== -1) {
            this.jobNewOptions.forEach(({ value }) => {
                if (this.newStatusFilters.indexOf(value) === -1) {
                    this.newStatusFilters.push(value);
                }
            });
        }

        if (this.newStatusFilters.length !== this.jobNewOptions.length) {
            this.newStatusFilters = this.newStatusFilters.filter((val) => val !== 'all');
        }

        this.jobStatusNewFilterChange.emit(this.newStatusFilters);
    }
}
