import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { CandidateService, JobService } from '@app/core/services';
import * as fromJobsStore from '@app/products/hire/modules/jobs/store';
import * as fromJobCandiatesSelector from '@app/products/hire/modules/jobs/store/selectors/jobCandidates.selector';
import * as fromStore from '@app/store';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SdkJob } from './../../../../../../core/models/job';

@Component({
    selector: 'app-candidate-modal-people',
    templateUrl: './candidate-modal-people.component.html',
    styleUrls: ['./candidate-modal-people.component.scss']
})
export class CandidateModalPeopleComponent implements OnInit, OnChanges, OnDestroy {
    @Input() showCandidateModal;
    @Input() candidateData;
    @Input() initialJob;
    @Input() candidateDataList;
    @Input() candidateNoJobs;
    @Output() closeModal: EventEmitter<any> = new EventEmitter();
    selectedCandidateIdx;
    selectedCandidate;
    private unsubscribe = new Subject<void>();
    candidateId;
    jobId;
    updateRatingData;
    loading = false;
    jobSubscription: Subscription;

    constructor(
        private store: Store<fromStore.State>,
        private cdr: ChangeDetectorRef,
        private candidateService: CandidateService,
        private jobService: JobService,
        private jobsStore: Store<fromJobsStore.JobsState>
    ) {}

    ngOnInit(): void {
        if (this.initialJob) {
            this.jobId = this.initialJob.id;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.candidateData && changes.candidateData.currentValue) {
            this.candidateId = this.candidateData.candidateId;
            this.jobId = this.candidateData.jobId;
            this.transformList();
        }
    }

    closeCandidateModal() {
        if (this.updateRatingData) {
            const [candidateId, jobId, rating] = this.updateRatingData;

            this.store.dispatch(
                new fromJobsStore.UpdateJobCandidate({
                    jobId,
                    candidateId,
                    data: { rating }
                })
            );
        }
        this.closeModal.next();
    }

    closeModalFromCandidate() {
        this.closeModal.next();
    }

    changeCandidate(direction) {
        this.loading = true;
        this.candidateData = null;
        if (direction === 'next') {
            this.selectedCandidateIdx++;
        } else {
            this.selectedCandidateIdx--;
        }
        this.initialJob = null;
        const candidateId = this.candidateDataList[this.selectedCandidateIdx];
        this.candidateService.getCandidateJobs(candidateId).subscribe((jobIds: string[]) => {
            let jobId = null;
            if (jobIds.length) {
                jobId = jobIds[0];
                this.jobId = jobId;
                this.getJobCandidates(jobId, candidateId)
                    .pipe(take(1))
                    .subscribe(() => {
                        this.candidateNoJobs = false;
                        this.selectedCandidate = null;
                    });
            }
            if (jobId) {
                this.jobSubscription = this.getJob(jobId)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(
                        (job: SdkJob) => {
                            this.initialJob = { ...job };
                            this.jobId = jobId;
                            this.candidateData = { jobId, candidateId };
                            this.loading = false;
                            this.cdr.markForCheck();
                        },
                        (error) => console.error(error)
                    );
            } else {
                this.jobId = jobId;
                this.candidateData = { jobId, candidateId };
                this.loading = false;
            }
        });
    }

    transformList() {
        this.candidateDataList = this.unique(this.candidateDataList);
        this.selectedCandidateIdx = this.candidateDataList.findIndex((candidateId) => candidateId === this.candidateId);
    }

    unique(arr) {
        let result = [];
        for (let str of arr) {
            if (!result.includes(str)) {
                result.push(str);
            }
        }
        return result;
    }

    changeCandidateRating(data) {
        this.updateRatingData = data;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
    }

    getJob(jobId: string) {
        const observable = new Observable((subscriber) => {
            this.jobSubscription = this.jobsStore.pipe(select(fromJobsStore.getJobById, { jobId })).subscribe(
                (job: SdkJob) => {
                    if (job) {
                        subscriber.next(job);
                        subscriber.complete();
                    } else {
                        this.jobService.getJob(jobId).subscribe(
                            (job: SdkJob) => {
                                if (job) {
                                    subscriber.next(job);
                                    subscriber.complete();
                                } else {
                                    subscriber.error('Not found');
                                }
                            },
                            (error) => subscriber.error(error)
                        );
                    }
                },
                (error) => subscriber.error(error)
            );
        });
        return observable;
    }

    getJobCandidates(jobId: string, candidateId: string) {
        const observable = new Observable((subscriber) => {
            this.jobsStore
                .pipe(select(fromJobCandiatesSelector.getJobCandidates, { jobId }))
                .pipe(take(1))
                .subscribe(
                    (candidates: any) => {
                        if (candidates && candidates.length) {
                            subscriber.next();
                            subscriber.complete();
                        } else {
                            this.store.dispatch(new fromJobsStore.LoadJobCandidatesOne({ jobId, candidateId }));
                            subscriber.next();
                            subscriber.complete();
                        }
                    },
                    (error) => {
                        this.store.dispatch(new fromJobsStore.LoadJobCandidatesOne({ jobId, candidateId }));
                        subscriber.next();
                        subscriber.complete();
                    }
                );
        });
        return observable;
    }
}
