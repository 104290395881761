<div class="content-block" *ngFor="let item of answers">
    <ng-container>
        <p-accordion>
            <p-accordionTab [disabled]="!item?.completed || (item.expired && !item?.completed)">
                <p-header>
                    <ng-container *ngIf="item?.completed">
                        <div class="status-icon success">
                            <div class="holder"></div>
                        </div>
                        <div class="text-block">
                            <div class="title">
                                <div class="position">{{ item?.survey_title }}</div>
                                <span class="sensitive" *ngIf="item?.questions?.sensitive">Sensitive</span>
                            </div>
                            <span class="date"> Complete: {{ dateFormat(item?.completed_at) }} </span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!item?.completed">
                        <div class="status-icon error" *ngIf="item.expired">
                            <div class="holder"></div>
                        </div>
                        <div class="status-icon empty" *ngIf="!item.expired">
                            <div class="holder"></div>
                        </div>
                        <div class="text-block">
                            <div class="title">
                                <div class="position">{{ item?.survey_title }}</div>
                            </div>
                            <span class="date" *ngIf="item.expired">Expired</span>
                            <span class="date" *ngIf="!item.expired">In Progress</span>
                        </div>
                    </ng-container>
                </p-header>
                <div class="description">
                    <div class="question-answers">
                        <div class="question-block" *ngFor="let question of item?.data">
                            <h3 class="question-text">{{ question.question }}</h3>
                            <p class="answer-text" *ngIf="question.type !== 'file_upload'">
                                {{ question.answer || '-' }}
                            </p>
                            <p class="answer-text" *ngIf="question.type === 'file_upload'">
                                <a
                                    [href]="question.answer.file"
                                    [title]="question.answer.original_name"
                                    class="file-block-frame"
                                    *ngIf="question?.answer?.file"
                                >
                                    {{ question.answer.original_name }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </ng-container>
</div>
