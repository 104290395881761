<p-dialog
    [(visible)]="showModal"
    (onHide)="onHideModal()"
    [style]="{ width: '1000px' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [dismissableMask]="true"
    [showHeader]="false"
    class="decline-modal"
>
    <div class="p-header p-header-new bg-green">
        <p>Requirements</p>
        <button class="close-btn" (click)="onHideModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="p-body p-body-new fz15">
        <div *ngFor="let item of suggestions" class="checkbox-row">
            <mat-checkbox [(ngModel)]="item.selected" (ngModelChange)="onItemSelected()">
                {{ item.name }}
            </mat-checkbox>
        </div>
    </div>
    <div class="p-footer p-footer-new requirements-footer">
        <button class="btn btn-simple mla" (click)="onShowMore()">Show More</button>
        <button class="btn" (click)="onSubmit()">Confirm</button>
    </div>
    <app-loader *ngIf="contentLoading" class="posa"></app-loader>
</p-dialog>
