import * as Realm from 'realm-web';
import { environment } from '@env/environment';

// export const APP_ID = 'stag-hellocrowd-uhnut';
export const APP_ID = environment.mongoRealm.APP_ID;

// const graphqlUrl = `https://realm.mongodb.com/api/client/v2.0/app/testingd9-swxkf/graphql`;
const graphqlUrl = environment.mongoRealm.graphqlUrl;

// Connect to your MongoDB Realm app
// const app = new Realm.App(APP_ID);
const app = new Realm.App({ id: APP_ID });
// const user = await realmApp.logIn(Realm.Credentials.anonymous())

// Get a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
    if (!app.currentUser) {
        const user = await app.logIn(Realm.Credentials.emailPassword('faizan.afzal@purelogics.net', 'purelogics1554'));
    }
    // If no user is logged in, log in an anonymous user
    // const db = user.mongoClient('mongodb-atlas').db('timeless-is')
    // The logged in user's access token might be stale,
    // Refreshing custom data also refreshes the access token
    else {
        await app.currentUser.refreshCustomData();
    }

    let tkn = app.currentUser?.accessToken as string;
    if (tkn !== undefined) {
        localStorage.setItem('realmToken', tkn);
    }

    // Get a valid access token for the current user
    return app;
}

export { graphqlUrl, getValidAccessToken };
