<mat-form-field appearance="outline" class="example-chip-list">
    <mat-label>{{ title }}</mat-label>
    <mat-chip-list #chipList aria-label="Fruit selection">
        <mat-chip
            [disabled]="disabled"
            *ngFor="let suggestion of suggestionsCopy"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(suggestion)"
        >
            <span>{{ suggestion.name }}</span>
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
            #catInput
            [readonly]="disabled"
            [formControl]="fruitCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
        />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" (change)="triggerChange($event)">
        <mat-option *ngFor="let fruit of filteredProds | async" [value]="fruit">
            {{ fruit.name }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
