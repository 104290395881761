import { Injectable } from '@angular/core';
import { JobService } from '@app/core/services/job.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as jobCandidatesActions from '../actions/jobCandidates.action';

@Injectable()
export class JobCandidatesEffect {
    constructor(private actions$: Actions, private jobService: JobService) {}

    @Effect() LoadJobCandidates$: Observable<Action> = this.actions$.pipe(
        ofType(jobCandidatesActions.LOAD_JOB_CANDIDATES),
        map((action: jobCandidatesActions.LoadJobCandidates) => action.payload),
        switchMap((jobId: string) => {
            return this.jobService.getCandidates(jobId).pipe(
                map((candidates: any) => new jobCandidatesActions.LoadJobCandidatesSuccess({ jobId, candidates })),
                catchError((error) => of(new jobCandidatesActions.LoadJobCandidatesFail(error)))
            );
        })
    );

    @Effect() LoadJobCandidate$: Observable<Action> = this.actions$.pipe(
        ofType(jobCandidatesActions.LOAD_JOB_CANDIDATES_ONE),
        map((action: jobCandidatesActions.LoadJobCandidatesOne) => action.payload),
        switchMap((data: { jobId: string; candidateId: string }) => {
            return this.jobService.getCandidatesOne(data.jobId, data.candidateId).pipe(
                map(
                    (candidates: any) =>
                        new jobCandidatesActions.LoadJobCandidatesSuccess({ jobId: data.jobId, candidates })
                ),
                catchError((error) => of(new jobCandidatesActions.LoadJobCandidatesFail(error)))
            );
        })
    );
}
