import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserService, UtilitiesService } from '@app/core/services';
import { FormHelperService } from '@app/core/services/form-helper.service';

@Component({
    selector: 'app-candidate-certificates',
    templateUrl: './candidate-certificates.component.html',
    styleUrls: ['./candidate-certificates.component.scss']
})
export class CandidateCertificatesComponent implements OnInit {
    @Input() form: FormGroup;

    @Input() set formData(data: any) {
        if (data && this.form) {
            this.populateForm(data);
        }
    }

    supportedFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    isFileUploading = false;
    uploadError = {};
    uploadItem: any;
    file: any[] = [];
    certificateInvalid = [];

    constructor(
        private fb: FormBuilder,
        public formHelper: FormHelperService,
        private utilities: UtilitiesService,
        private userService: UserService
    ) {}

    ngOnInit(): void {}

    get certificates() {
        return this.form.get('certificates') as FormArray;
    }

    populateForm(data): void {
        data.forEach((c: any) => {
            this.certificates.push(
                this.fb.group({
                    name: [c.name, Validators.required],
                    document: [c.document],
                    description: [c.description],
                    uploadedAt: [c.uploadedAt],
                    url: [c.url]
                })
            );
            this.file.push({
                name: c.name,
                size: c.size,
                lastModifiedDate: c.uploadedAt
            });
            !c.name ? this.certificateInvalid.push(true) : this.certificateInvalid.push(false);
        });
        this.formHelper.markFormGroupTouched(this.form);
    }

    onSectionAdd(): void {
        this.certificates.push(
            this.fb.group({
                name: ['', Validators.required],
                document: [''],
                uploadedAt: [''],
                url: ['']
            })
        );
        this.certificateInvalid.push(true);
    }

    onSectionRemove(i: number): void {
        this.file.splice(i, 1);
        this.certificates.removeAt(i);
        this.certificateInvalid.splice(i, 1);
    }

    onCertificateAdd(event, index): void {
        if (!this.isFileUploading) {
            const files = event.target.files || event.dataTransfer.files;
            const file = files[0];
            this.isFileUploading = true;
            if (this.validateFileType(file, this.supportedFileTypes)) {
                this.uploadFile(
                    {
                        id: this.utilities.generateUID(10),
                        file,
                        uploadStarted: false,
                        uploadFinished: false,
                        progress: 0,
                        success: false,
                        text: file.name
                    },
                    index
                );
                this.file[index] = {
                    name: file.name,
                    size: file.size,
                    lastModifiedDate: file.lastModifiedDate
                };
            } else {
                this.uploadError[index] = 'Only supported formats are: pdf, jpg, png';
                this.isFileUploading = false;
                setTimeout(() => (this.uploadError[index] = null), 10000);
            }
        }
    }

    uploadFile(item, index): void {
        this.uploadItem = item;
        this.utilities
            .readFile(item.file)
            .then((fileValue: any) => {
                item.uploadStarted = true;
                item.uploadFinished = false;
                item.progress = 0;

                const uploadProgressInterval = setInterval(() => {
                    item.progress = item.progress + 1 < 100 ? item.progress + 1 : item.progress;
                }, 400);
                const formData: { document: any; email?: string } = {
                    document: fileValue
                };
                this.userService.uploadCertificate(formData).subscribe(
                    (response: HttpResponse<any>) => {
                        // console.log('📬 Uploaded:', response);
                        const resp: any = response;
                        this.certificates.value[index].document = resp.fileName;
                        this.certificates.value[index].url = resp.url;
                        this.certificates.value[index].uploadedAt = Math.floor(Date.now() / 1000);
                        setTimeout(() => {
                            this.isFileUploading = false;
                        }, 2000);
                    },
                    (response: HttpErrorResponse) => {
                        console.error(response);
                    }
                );
            })
            .catch((error) => {
                console.error(error);
                console.error('Error reading uploaded file');
                this.isFileUploading = false;
                this.uploadItem = null;
            });
    }

    convertSize(size: number) {
        const kb = size / 1000;
        const mb = size / 1000 / 1000;

        return mb > 1 ? `${mb.toFixed(2)} MB` : `${Math.round(kb)} KB`;
    }

    private validateFileType(file: File, types: string[]) {
        return types.indexOf(file.type) !== -1;
    }
}
