import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { JobCategory } from './../../../../../../core/models/cateogory';

import * as fromCategories from './categories.reducer';
import * as fromJobCandidates from './jobCandidates.reducer';
import * as fromJobs from './jobs.reducer';
import * as fromStagesData from './stagesData.reducer';

export interface JobsState {
    jobs: fromJobs.JobsListState;
    jobCandidates: fromJobCandidates.CandidatesListState;
    stagesData: fromStagesData.StagesDataListState;
    categories: JobCategory[];
}

export const reducers: ActionReducerMap<JobsState> = {
    jobs: fromJobs.reducer,
    jobCandidates: fromJobCandidates.reducer,
    stagesData: fromStagesData.reducer,
    categories: fromCategories.reducer
};

export const getJobsFeatureState = createFeatureSelector<JobsState>('jobs');

export const getJobsState = createSelector(getJobsFeatureState, (state: JobsState) => state.jobs);

export const getStagesDataState = createSelector(getJobsFeatureState, (state: JobsState) => state.stagesData);

export const getJobCandidatesState = createSelector(getJobsFeatureState, (state: JobsState) => state.jobCandidates);

export const getJobsCategoriesState = createSelector(getJobsFeatureState, (state: JobsState) => state.categories);
