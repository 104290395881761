import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelperService } from '@app/core/services/form-helper.service';

@Component({
    selector: 'app-candidate-languages',
    templateUrl: './candidate-languages.component.html',
    styleUrls: ['./candidate-languages.component.scss']
})
export class CandidateLanguagesComponent implements OnInit {
    @Input() form: FormGroup;

    @Input() set formData(data: any) {
        if (data && this.form) {
            this.populateForm(data);
        }
    }

    languageInvalid = [];
    isShowNative = [];

    levelOptions = [
        { value: 'beginner', label: 'Beginner' },
        { value: 'classroom study', label: 'Classroom Study' },
        { value: 'intermediate', label: 'Intermediate' },
        { value: 'advanced', label: 'Advanced' },
        { value: 'fluent', label: 'Fluent' }
    ];

    constructor(private fb: FormBuilder, public formHelper: FormHelperService) {}

    ngOnInit(): void {}

    get languages() {
        return this.form.get('languages') as FormArray;
    }

    populateForm(data) {
        data.forEach((item) => {
            const formData = {
                language_name: [item.language_name || '', Validators.required],
                language_code: [item.language_code || ''],
                read: [item.read || '', Validators.required],
                speak: [item.speak || '', Validators.required],
                write: [item.write || '', Validators.required],
                is_native: [item.is_native || false, Validators.required]
            };
            this.isShowNative.push(true);
            this.languages.push(this.fb.group(formData));
            !item.language_name || !item.language_code || !item.read || !item.speak || !item.write
                ? this.languageInvalid.push(true)
                : this.languageInvalid.push(false);
        });
        this.formHelper.markFormGroupTouched(this.form);
    }

    onSectionRemove(i: number) {
        this.languages.removeAt(i);
        this.languageInvalid.splice(i, 1);
        this.isShowNative.splice(i, 1);
    }

    onSectionAdd() {
        this.languages.push(
            this.fb.group({
                language_name: ['', Validators.required],
                language_code: [''],
                read: ['', Validators.required],
                speak: ['', Validators.required],
                write: ['', Validators.required],
                is_native: [false, Validators.required]
            })
        );
        this.languageInvalid.push(true);
        this.isShowNative.push(false);
    }

    onNativeClick(i) {
        if (this.languages.controls[i].value.is_native === true) {
            this.languages.controls[i].patchValue({
                read: 'fluent',
                speak: 'fluent',
                write: 'fluent'
            });
        } else {
            this.languages.controls[i].patchValue({
                read: '',
                speak: '',
                write: ''
            });
        }
    }

    toggleNative(i) {
        this.isShowNative[i] = !this.isShowNative[i];
        console.log(this.languages);
    }
}
