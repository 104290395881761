import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobService } from '@app/core/services';

@Component({
    selector: 'app-set-rating',
    templateUrl: './set-rating.component.html',
    styleUrls: ['./set-rating.component.scss']
})
export class SetRatingComponent implements OnInit {
    @Input() video;
    @Input() set dataUpdated(v) {
        if (v) {
            this.setRating();
        }
    }
    @Input() videos;
    @Input() jobId;
    @Input() candidateId;
    @Input() userRole;
    @Input() userId;

    @Output() ratesUpdated: EventEmitter<any> = new EventEmitter();
    ratingStatus;

    stars: any[] = [{ index: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
    constructor(private jobService: JobService) {}

    ngOnInit(): void {
        this.setRating();
    }

    setRating() {
        this.video.rating =
            (this.video &&
                this.video.rates &&
                this.video.rates.find((r) => r.id === this.userId) &&
                this.video.rates.find((r) => r.id === this.userId).rating) ||
            '';
        this.stars = this.stars.map((star, idx) => {
            if (!this.video.rating) {
                return star;
            }

            star.active = idx + 1 <= this.video.rating;

            return star;
        });
        this.ratingStatus =
            this.video.rating === 1
                ? 'Very Poor'
                : this.video.rating === 2
                ? 'Poor'
                : this.video.rating === 3
                ? 'Average'
                : this.video.rating === 4
                ? 'Good'
                : this.video.rating === 5
                ? 'Excellent'
                : 'Not Assessed';
    }

    onHoverStars(index) {
        this.stars.forEach((s) => (s.hover = false));
        for (let i = 0; i <= index; i++) {
            this.stars[i].hover = true;
        }
    }

    onClearStars() {
        this.stars.forEach((s) => (s.hover = false));
    }

    onEvaluateAnswer(index, questionId, stageId) {
        if (this.userRole === 'recruitment_agency' || this.userRole === 'agency_user') {
            return false;
        }
        // console.log(index, questionId, stageId);
        this.stars.forEach((s) => (s.active = false));
        for (let i = 0; i <= index; i++) {
            this.stars[i].active = true;
        }
        const video = this.videos.find((v) => v.id === questionId);
        if (video) {
            video.rating = index + 1;
        }
        this.jobService
            .evaluateCandidateVideoAnswer(this.jobId, this.candidateId, stageId, {
                id: questionId,
                rating: index + 1
            })
            .subscribe((response: any) => {
                // DONE
                // console.log(this.videos, questionId, index);
                let video = this.videos.find((c) => {
                    if (c.id === questionId) {
                        return c;
                    }
                });
                video.rating = index + 1;
                video.ratings = [];
                for (let i = 0; i < 5; i++) {
                    i < video.rating ? video.ratings.push(video.rating) : video.ratings.push(0);
                }

                if (!this.video.rates) {
                    this.video.rates = [];
                }
                const rate = this.video.rates.find((r) => r.id === this.userId);
                if (rate) {
                    rate.rating = index + 1;
                } else {
                    this.video.rates.push({
                        id: this.userId,
                        rating: index + 1
                    });
                }
                this.ratesUpdated.emit(true);
                this.setRating();
            });
    }
}
