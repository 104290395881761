import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CandidateService } from '@app/core/services';
import { Candidate } from './../../../../../core/models/candidate';

@Component({
    selector: 'app-criminal-check-modal',
    templateUrl: './criminal-check-modal.component.html',
    styleUrls: ['./criminal-check-modal.component.scss']
})
export class CriminalCheckModalComponent implements OnInit {
    @Input() jobId: string;
    @Input() candidate: Candidate;
    showModal: boolean = true;
    @Output() closeModal = new EventEmitter<number>();
    @Output() assignmentAdded = new EventEmitter<any>();
    criminalCheckForm: FormGroup;
    prices = {
        types: {
            standard: 160,
            premium: 260
        },
        identityVerification: 10
    };
    verificationTypeOptions = [
        { label: 'Standard (24 to 48 hours)', value: 'standard' },
        { label: 'Premium (12 to 24 hours)', value: 'premium' }
    ];
    contentLoading = false;

    constructor(private fb: FormBuilder, private candidateService: CandidateService) {}

    ngOnInit(): void {
        console.log('CriminalCheckModalComponent', this.candidate);
        this.criminalCheckForm = this.fb.group({
            type: ['standard', [Validators.required]],
            identityVerification: [false]
        });
    }

    onHideModal() {
        console.log('close');
        this.closeModal.emit(null);
    }

    async onSubmit(event) {
        event.preventDefault();
        console.log('onSubmit', this.criminalCheckForm.getRawValue());
        const formValue = this.criminalCheckForm.getRawValue();
        const postData = {
            data: formValue,
            totalPrice: this.total
        };
        this.contentLoading = true;
        try {
            const response = await this.candidateService
                .orderCriminalCheck(this.jobId, this.candidate.id, postData)
                .toPromise();
            console.log('orderCriminalCheck response:', response);
            this.assignmentAdded.next(response);
            this.contentLoading = false;
            this.onHideModal();
        } catch (error) {
            console.error(error);
            alert('Error!');
            this.contentLoading = false;
            this.onHideModal();
        }
    }

    get total() {
        let totalPrice = 0;
        const selectedType = this.criminalCheckForm.get('type').value;
        if (this.prices.types[selectedType]) {
            totalPrice += this.prices.types[selectedType];
        }
        if (this.criminalCheckForm.get('identityVerification').value) {
            totalPrice += this.prices.identityVerification;
        }
        return totalPrice;
    }
}
