// Question constants

export const questionTypeOptions = [
    { label: 'Multiple Choice', value: 'multiple' },
    { label: 'Single Choice', value: 'single' },
    { label: 'Text Field', value: 'text-field' },
    { label: 'Paragraph', value: 'paragraph' },
    { label: 'Star Rating', value: 'stars' },
    { label: 'One Way Video Question', value: 'one-way-video' },
    { label: 'Document Upload', value: 'document-upload' }
];

export const videoQuestionOption = {
    review_time: [
        { label: '30 seconds', value: 30 },
        { label: '60 seconds', value: 60 },
        { label: '120 seconds', value: 120 }
    ],
    answer_time: [
        { label: '30 seconds', value: 30 },
        { label: '60 seconds', value: 60 },
        { label: '120 seconds', value: 120 }
    ],
    number_of_takes: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 }
    ]
};

// Email template constants

export const fromOptions = [
    { label: 'Job Owner Email', value: 'owner' },
    { label: 'Recruiter Initiating Email', value: 'recruiter' }
];

export const delayOptions = [
    { label: 'None, send immidiatly', value: 'none' },
    { label: '1 hour', value: 3600 },
    { label: '6 hours', value: 3600 * 6 },
    { label: '1 day', value: 3600 * 24 },
    { label: '7 days', value: 3600 * 24 * 7 }
];

export const insertPlaceholders = [
    { label: 'Company Name', value: 'company_name' },
    { label: 'Job Title', value: 'job_title' },
    { label: 'Job Location', value: 'job_location' },
    { label: 'Candidate First Name', value: 'candidate_first_name' },
    { label: 'Candidate Full Name', value: 'candidate_full_name' },
    // { label: 'Missing Fields', value: 'missing_fields' },
    // { label: 'Sender Email', value: 'sender_email' }
    // { label: 'Sender Name', value: 'sender_name' },
    // { label: 'Sender Company', value: 'sender_company' },
    // { label: 'Tenant Name', value: 'tenant_name' },
    // { label: 'Recruiter', value: 'recruiter' },
    { label: 'Job Owner', value: 'job_owner' }
];
export const insertPlaceholdersLinks = [
    { label: 'Candidate Dashboard', value: 'candidate_dashboard' },
    { label: 'Jobs Portal', value: 'jobs_portal' }
];

// Preferences constants

export const dateFormats = [
    { value: 'd/m/yyyy', label: 'Day / Month / Year' },
    { value: 'm/d/yyyy', label: 'Month / Day / Year' },
    { value: 'yyyy/m/d/', label: 'Year/ Month / Day' }
];

export const accountTypeOptions = [
    { label: 'Account Owner', value: 'account_owner' },
    { label: 'Administrator', value: 'admin' },
    { label: 'Recruiter', value: 'recruiter' },
    { label: 'User', value: 'user' },
    // { label: 'Hiring Manager', value: 'hiring_manager' },
    // { label: 'HR Business Partner', value: 'hr_business_partner' },
    { label: 'Recruitment Agency', value: 'recruitment_agency' },
    { label: 'Agency User', value: 'agency_user' }
];

export const iconTypeOptions = [
    { label: '👍', value: '👍' },
    { label: '🤖', value: '🤖' },
    { label: '💥', value: '💥' },
    { label: '🔥', value: '🔥' },
    { label: '👩‍🎓', value: '👩‍🎓' },
    { label: '👨‍⚖️', value: '👨‍⚖️' },
    { label: '👨‍💻', value: '👨‍💻' },
    { label: '🥊', value: '🥊' },
    { label: '📹', value: '📹' },
    { label: '💡', value: '💡' },
    { label: '🗓️', value: '🗓️' },
    { label: '📑', value: '📑' },
    { label: '🤓', value: '🤓' },
    { label: '🙎🏼', value: '🙎🏼' },
    { label: '🖊️', value: '🖊️' },
    { label: '⚡', value: '⚡' },
    { label: '✏️', value: '✏️' },
    { label: '🎉', value: '🎉' },
    { label: '🎾', value: '🎾' },
    { label: '🥁', value: '🥁' },
    { label: '📞', value: '📞' },
    { label: '🖥️', value: '🖥️' },
    { label: '📋', value: '📋' },
    { label: '🔶', value: '🔶' },
    { label: '📔', value: '📔' },
    { label: '⏰', value: '⏰' },
    { label: '🍭', value: '🍭' },
    { label: '🦌', value: '🦌' },
    { label: '🙏🏼', value: '🙏🏼' }
];
