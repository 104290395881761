import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-employee-modal',
    templateUrl: './employee-modal.component.html',
    styleUrls: ['./employee-modal.component.scss']
})
export class EmployeeModalComponent implements OnInit {
    @Input() showEmployeeModal;
    @Input() employeeData;
    @Input() employeeDataList;
    @Output() closeModal: EventEmitter<any> = new EventEmitter();
    selectedEmployeeIdx;
    constructor() {}

    ngOnInit(): void {
        this.transformList();
        // console.log(this.showEmployeeModal, this.employeeData, this.employeeDataList, this.selectedEmployeeIdx);
    }

    closeEmployeeModal() {
        this.closeModal.next();
    }

    changeEmployee(direction) {
        if (direction === 'next') {
            this.selectedEmployeeIdx++;
        } else {
            this.selectedEmployeeIdx--;
        }
        this.employeeData.employeeId = this.employeeDataList[this.selectedEmployeeIdx];
        this.employeeData.item = null;
    }

    transformList() {
        this.employeeDataList = this.unique(this.employeeDataList);
        this.selectedEmployeeIdx = this.employeeDataList.findIndex(
            (employeeId) => employeeId === this.employeeData.employeeId
        );
    }

    unique(arr) {
        let result = [];
        for (let str of arr) {
            if (!result.includes(str)) {
                result.push(str);
            }
        }
        return result;
    }
}
