<ng-container *ngIf="!contentLoading">
    <div class="interview-header flex-row jcb">
        <div class="interview-header-author">
            <div class="img-block" *ngIf="candidate.profile_image_link">
                <img [src]="candidate.profile_image_link" alt="profile image" />
            </div>
            <div class="interview-header-title flex-col">
                <div class="author">
                    Interview with
                    <span>{{ candidate.first_name | titlecase }} {{ candidate.last_name | titlecase }}</span>
                </div>
                <div class="interview-header-date">
                    {{ filledInterview?.interview_date | amParse: 'YYYY-MM-DD' | amDateFormat: 'D MMM YYYY (dddd)' }}
                    {{ filledInterview?.start_time }} to {{ filledInterview?.to }}
                </div>
            </div>
        </div>

        <div class="interview-header-join" *ngIf="filledInterview?.join_url">
            <a [href]="filledInterview?.join_url" target="_blank"
                ><img src="/assets/images/icons/ms-teams.svg" alt="" /><span>Join Microsoft Teams Meeting</span></a
            >
        </div>

        <div class="interview-header-actions">
            <button class="btn btn-primary-inversed" (click)="onSaveScoreCardDraft()">Save Draft</button>
            <button class="btn btn-primary" (click)="onSaveScoreCard()">Submit Feedback</button>
            <button class="close" (click)="closeModal()"><img src="/assets/images/icons/icons-8-delete.svg" /></button>
        </div>
    </div>

    <div class="interview-content">
        <div class="interview-content__overview interview-content-block">
            <div class="sections-nav">
                <div
                    class="section-nav-link"
                    *ngFor="let section of sectionsOverview"
                    (click)="onChangeSection(section, 'overview')"
                    [ngClass]="{ active: activeSectionOverview === section }"
                >
                    <span>{{ section | titlecase }}</span>
                </div>
            </div>
            <div class="interview-overview">
                <div class="content-section" *ngIf="activeSectionOverview === 'overview'">
                    <app-candidate-overview
                        *ngIf="job && candidate"
                        [offerAssessment]="offerAssessment"
                        [candidate]="candidate"
                        [resumeThreshold]="resumeThreshold"
                        [jobId]="job.id"
                        [disableFiles]="true"
                        [doNotMatch]="job?.do_not_match"
                    ></app-candidate-overview>
                </div>
                <div class="content-section" *ngIf="activeSectionOverview === 'details'">
                    <app-candidate-details [candidate]="candidate"></app-candidate-details>
                </div>

                <div class="content-section" *ngIf="activeSectionOverview === 'resume'">
                    <ng-container *ngIf="candidate && (candidate.resume_link || candidate.resume_link_new)">
                        <pdf-viewer
                            *ngIf="candidate.resume_file_type !== 'docx'"
                            [src]="candidate.resume_link || candidate.resume_link_new"
                            [render-text]="true"
                            [original-size]="false"
                            [autoresize]="true"
                            style="display: block; max-width: 100%"
                        ></pdf-viewer>
                        <a
                            [href]="candidate.resume_link"
                            [title]="candidate.resume_link"
                            class="file-block-frame"
                            *ngIf="candidate.resume_file_type === 'docx'"
                        >
                            <span class="file-icon">
                                <img alt="" width="20" src="/assets/images/icons/files-icon-docx.svg" />
                            </span>
                            <div class="file-block-holder">
                                <span class="title">{{ candidate.resume_file }} </span>
                            </div>
                        </a>
                    </ng-container>
                </div>
                <div class="content-section" *ngIf="activeSectionOverview === 'questions'">
                    <app-candidate-questions
                        *ngIf="job && candidate && user"
                        [questionnaires]="questionnaires"
                        [candidate]="candidate"
                        [personality_assessment]="personality_assessment"
                        [logicTest]="logicTest"
                        [stagesData]="stagesData"
                        [radar_chart_data]="radar_chart_data"
                        [stageId]="stageId"
                        [job]="job"
                        [user]="user"
                        [videos]="videos"
                        [personalityProfileScores]="personalityProfileScores"
                        hideButtons="true"
                    ></app-candidate-questions>
                </div>

                <div class="content-section" *ngIf="activeSectionOverview === 'history'">
                    <app-candidate-item-timeline
                        *ngIf="job && candidate"
                        [job]="job"
                        [candidate]="candidate"
                        hideButtons="true"
                    >
                    </app-candidate-item-timeline>
                </div>
            </div>
        </div>

        <div class="interview-content__scorecard interview-content-block">
            <div class="sections-nav">
                <div
                    class="section-nav-link"
                    *ngFor="let section of sectionsScorecard"
                    (click)="onChangeSection(section, 'scorecard')"
                    [ngClass]="{ active: activeSectionScorecard === section }"
                >
                    <span>{{ section | titlecase }}</span>
                </div>
                <div class="behalf-vote flex-row aic" *ngIf="potentialBehalfUsers && potentialBehalfUsers.length">
                    <span class="behalf-vote__label">Scoring for:</span>
                    <div class="behalf-vote__dropdown">
                        <p-dropdown
                            class="clear-drop"
                            [options]="potentialBehalfUsers"
                            [dropdownIcon]="'pi pi-chevron-down'"
                            (onChange)="changeBehalfUser($event)"
                        >
                            <ng-template let-item pTemplate="selectedItem">
                                <div class="info-circle" [ngStyle]="{ 'background-color': item?.value.color }">
                                    {{ item?.label | titlecase | slice: 0:1 }}
                                </div>
                                <span>{{ item?.label }} {{ user.id === item?.value.id ? ' (Me)' : '' }}</span>
                            </ng-template>
                            <ng-template let-new pTemplate="item">
                                <div class="info-circle" [ngStyle]="{ 'background-color': new?.value.color }">
                                    {{ new?.label | titlecase | slice: 0:1 }}
                                </div>
                                <span>{{ new?.label }} {{ user.id === new?.value.id ? ' (Me)' : '' }}</span>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="interview-scorecard">
                <div class="content-section" *ngIf="activeSectionScorecard === 'scorecard'">
                    <app-interview-scorecard
                        [jobId]="jobId"
                        [candidateId]="candidate.id"
                        [interview]="filledInterview"
                        [changedId]="changedBehalfUserId"
                        *ngIf="filledInterview"
                    ></app-interview-scorecard>
                </div>

                <div class="content-section" *ngIf="activeSectionScorecard === 'comments'"></div>
            </div>
        </div>
    </div>
</ng-container>

<app-loader *ngIf="contentLoading"></app-loader>
