<div class="phone-block">
    <!-- name="phone" -->
    <input
        #phoneInput
        type="text"
        autocomplete="off"
        ng2TelInput
        [ng2TelInputOptions]="{
            initialDialCode: true,
            nationalMode: false,
            initialCountry: 'za',
            autoHideDialCode: false
        }"
        placeholder=" "
        class="form-input"
        (intlTelInputObject)="telInstance($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        (keyup)="onChange($event)"
        (change)="onChange($event)"
        (countryChange)="onCountryChange($event)"
        maxlength="16"
    />
</div>
