import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeAgo',
    pure: false
})
export class TimeAgoPipe implements PipeTransform {
    constructor() {}

    transform(pubdate: Date): unknown {
        // time since message was sent in seconds
        const delta = (new Date().getTime() - pubdate.getTime()) / 1000;

        // All values are in seconds
        const timeIntervals = {
            year: 31536000,
            month: 2592000,
            week: 604800,
            day: 86400,
            hour: 3600,
            minute: 60,
            second: 1
        };
        let counter;
        for (const i in timeIntervals) {
            counter = Math.floor(delta / timeIntervals[i]);
            if (counter > 0) {
                if (counter === 1) {
                    // singular (1 day ago)
                    return counter + ' ' + i + ' ago';
                } else {
                    // plural (2 days ago)
                    return counter + ' ' + i + 's ago';
                }
            }
        }

        return 'loading';
    }
}
