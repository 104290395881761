<div [formGroup]="form">
    <div class="accordion-array" formArrayName="employment_history">
        <p-accordion *ngFor="let work of form.get('employment_history')['controls']; let i = index" [formGroupName]="i">
            <p-accordionTab class="section-wrap" [selected]="experienceInvalid[i]">
                <p-header>
                    <div class="flex-row">
                        <div class="mr10">
                            <div *ngIf="work.valid" class="success-icon-wrap">
                                <img
                                    class="success-icon"
                                    src="/assets/images/icons/check.svg"
                                    alt=""
                                    width="10"
                                    height="10"
                                />
                            </div>
                            <div *ngIf="!work.valid">
                                <img
                                    class="success-icon"
                                    src="/assets/images/icons/warning.svg"
                                    alt=""
                                    width="15"
                                    height="15"
                                />
                            </div>
                        </div>
                        <div class="accordion-header">
                            <div class="accordion-header-title">
                                <h4 class="position" *ngIf="work.get('title').value">
                                    {{ work.get('title').value | titlecase }}
                                </h4>
                                <h4 class="position" *ngIf="!work.get('title').value">New work experience</h4>
                                <span class="company"> - {{ work.get('company').value | titlecase }}</span>
                            </div>
                            <div class="date">
                                {{ work.get('start_date').value || 'Unknown' }} -
                                {{ work.get('end_date').value || 'Unknown' }}
                            </div>
                        </div>
                    </div>
                </p-header>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Job Title</mat-label>
                    <input type="text" placeholder="Job Title" formControlName="title" matInput />
                    <mat-error *ngIf="form.get('employment_history').controls[i].controls.title.invalid">
                        Job Title is required
                    </mat-error>
                </mat-form-field>
                <div class="flex-row jcb">
                    <mat-form-field class="flex-1" appearance="outline">
                        <mat-label>Company</mat-label>
                        <input type="text" placeholder="Company" formControlName="company" matInput />
                        <mat-error *ngIf="form.get('employment_history').controls[i].controls.company.invalid">
                            Company is required
                        </mat-error>
                    </mat-form-field>
                    <div class="form-block ml20 w-20">
                        <app-datepicker ngDefaultControl formControlName="start_date" placeholder="From">
                        </app-datepicker>
                        <mat-error
                            *ngIf="
                                form.get('employment_history').controls[i].controls.start_date.touched &&
                                form.get('employment_history').controls[i].controls.start_date.invalid
                            "
                        >
                            Date is required
                        </mat-error>
                    </div>
                    <div class="form-block ml20 w-20">
                        <app-datepicker
                            ngDefaultControl
                            formControlName="end_date"
                            placeholder="To"
                            hasCheckbox="true"
                            checkboxText="I’m currently employed here"
                        >
                        </app-datepicker>
                        <mat-error
                            *ngIf="
                                form.get('employment_history').controls[i].controls.end_date.touched &&
                                form.get('employment_history').controls[i].controls.end_date.invalid
                            "
                        >
                            Date is required
                        </mat-error>
                    </div>
                </div>
                <div
                    class="editor-wrapper"
                    [ngClass]="{
                        'editor-has-error':
                            form.get('employment_history').controls[i].controls.description.touched &&
                            form.get('employment_history').controls[i].controls.description.invalid
                    }"
                >
                    <app-editor formControlName="description">
                        <p-header>
                            <div>
                                <span class="ql-formats">
                                    <button class="ql-bold" aria-label="Bold"></button>
                                    <button class="ql-italic" aria-label="Italic"></button>
                                    <button class="ql-underline" aria-label="Underline"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-list" value="ordered" type="button"></button>
                                    <button class="ql-list" value="bullet" type="button"></button>
                                </span>
                                <span class="ql-formats">
                                    <button aria-label="Remove Styles" class="ql-clean" type="button"></button>
                                </span>
                            </div>
                        </p-header>
                    </app-editor>
                    <div
                        class="error-message"
                        *ngIf="
                            form.get('employment_history').controls[i].controls.description.touched &&
                            form.get('employment_history').controls[i].controls.description.invalid
                        "
                    >
                        Description is required
                    </div>
                </div>
                <div class="close-icon-wrap">
                    <span class="close-icon">
                        <svg-icon
                            [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"
                            src="/assets/images/icons/icons-8-trash-can.svg"
                            (click)="onSectionRemove(i)"
                        ></svg-icon>
                    </span>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <button class="add-section-button" (click)="onSectionAdd()">
        <img src="/assets/images/plus-icon.svg" alt="" />
        <span>Add Experience</span>
    </button>
</div>
