import { Component, ElementRef, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
    @Input() placeholder;
    @Input() hasCheckbox;
    @Input() checkboxText;
    _restrictedStartDate;
    @Input() set restrictStartDate(value: string) {
        this._restrictedStartDate = value;
        this.dateFrom = this._restrictedStartDate.split('/');
        this.yearsList.forEach((data, index) => {
            if (Number(this.dateFrom[1]) === data.title) {
                this.dateFromIndex = index;
                this.dateFromGroup = data.group;
            }
            if (Number(this.dateFrom[1]) > data.title) {
                data.disabled = true;
                return;
            }
        });
    }
    groupsNum = 0;
    dateFromIndex;
    dateFromGroup;
    dateFrom;

    calendarVisible = false;

    activeView = 'years';
    startYear = 1960;
    currentYear = new Date().getFullYear();
    startYearIndex = 0;
    disabledCalendar = false;
    selectedYearIndex: number;
    selectedYear;
    yearsList = [];
    displayYearsList = [];

    monthsList = [
        { title: 'Jan', value: '01', disabled: false },
        { title: 'Feb', value: '02', disabled: false },
        { title: 'Mar', value: '03', disabled: false },
        { title: 'Apr', value: '04', disabled: false },
        { title: 'May', value: '05', disabled: false },
        { title: 'Jun', value: '06', disabled: false },
        { title: 'Jul', value: '07', disabled: false },
        { title: 'Aug', value: '08', disabled: false },
        { title: 'Sep', value: '09', disabled: false },
        { title: 'Oct', value: '10', disabled: false },
        { title: 'Nov', value: '11', disabled: false },
        { title: 'Dec', value: '12', disabled: false }
    ];

    form = new FormGroup({
        checkbox: new FormControl(false)
    });

    value: string;
    onChange = (_: any) => {};
    onTouched = () => {};

    @HostListener('document:click', ['$event'])
    clickedOutside(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.calendarVisible = false;
        }
    }

    constructor(private eRef: ElementRef) {
        this.initYears();
        this.displayYearsList = this.yearsList.slice(this.startYearIndex, this.startYearIndex + 10);
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    ngOnInit() {}

    ngOnChanges() {
        // console.log('ngOnChanges');
    }

    initYears() {
        const betweenYears = this.currentYear - this.startYear;
        for (let i = 0; i <= betweenYears; i++) {
            this.yearsList.push({ title: this.startYear + i, position: i });
        }
        this.groupsNum = Math.ceil((betweenYears + 1) / 10);
        const groups = [];
        for (let i = this.groupsNum; i > 0; i--) {
            groups.push(i);
        }
        const resultsArr = [];
        const yearsArr = this.yearsList.slice(0).reverse();

        groups.forEach((item, index) => {
            const items = yearsArr.splice(0, 10).map((el) => {
                el.group = item;
                return el;
            });
            resultsArr.push(...items);
        });

        this.yearsList = resultsArr.slice(0).reverse();
        this.startYearIndex = this.yearsList.length - 10;
    }

    onShowCalendar() {
        this.calendarVisible = !this.calendarVisible;
        this.activeView = 'years';
    }

    onPreviousYears() {
        if (this.activeView === 'years') {
            this.startYearIndex = this.startYearIndex - 10;
            if (this.startYearIndex < 0) {
                this.startYearIndex = 0;
            }
            this.displayYearsList = this.yearsList.slice(this.startYearIndex, this.startYearIndex + 10);
        }
        if (this.activeView === 'months') {
            this.selectedYearIndex -= 1;
            this.selectedYear = this.yearsList[this.selectedYearIndex].title;
            this.clearDisabledMonths();
            this.setDisabledMonths();
        }
    }

    onNextYears() {
        if (this.activeView === 'years') {
            this.startYearIndex = this.startYearIndex + 10;
            this.displayYearsList = this.yearsList.slice(this.startYearIndex, this.startYearIndex + 10);
        }
        if (this.activeView === 'months') {
            this.selectedYearIndex += 1;
            this.selectedYear = this.yearsList[this.selectedYearIndex].title;
            this.clearDisabledMonths();
            this.setDisabledMonths();
        }
    }

    onSelectYear(event, year, index) {
        event.stopPropagation();
        this.selectedYearIndex = index;
        this.selectedYear = year.title;
        this.activeView = 'months';
        this.setDisabledMonths();
    }

    setDisabledMonths() {
        if (this._restrictedStartDate) {
            if (Number(this.selectedYear) === Number(this.dateFrom[1])) {
                for (const month of this.monthsList) {
                    month.disabled = true;
                    if (this.dateFrom[0] === month.value) {
                        break;
                    }
                }
            } else {
                for (const month of this.monthsList) {
                    month.disabled = false;
                }
            }
        }
    }

    onCheckboxChange() {
        if (this.form.value.checkbox) {
            this.disabledCalendar = true;
            this.value = 'Current';
            this.onChange(this.value);
            setTimeout(() => {
                this.calendarVisible = false;
                this.activeView = 'years';
            }, 1000);
        } else {
            this.disabledCalendar = false;
            this.value = '';
            this.onChange(this.value);
        }
    }

    clearDisabledMonths() {
        for (const month of this.monthsList) {
            month.disabled = false;
        }
    }

    onSelectMonth(month: string) {
        this.activeView = 'years';
        this.value = `${month}/${this.selectedYear}`;
        this.calendarVisible = false;
        this.onChange(this.value);
    }

    disabledPreviousBtn() {
        const currentViewGroup = this.displayYearsList[this.displayYearsList.length - 1].group;
        if (this.activeView === 'years') {
            // if (
            //     this._restrictedStartDate &&
            //     this._restrictedStartDate.length &&
            //     this.dateFromGroup + 1 >= currentViewGroup
            // ) {
            //     return true;
            // }
            return this.displayYearsList[0].group === 1;
        }
        if (this.activeView === 'months') {
            if (
                this._restrictedStartDate &&
                this._restrictedStartDate.length &&
                this.dateFromIndex - this.selectedYearIndex >= 0
            ) {
                return true;
            }
            return this.selectedYearIndex === 0;
        }
    }

    disabledNextBtn() {
        if (this.activeView === 'years') {
            const currentViewGroup = this.displayYearsList[this.displayYearsList.length - 1].group;
            return this.groupsNum === currentViewGroup;
        }
        if (this.activeView === 'months') {
            return this.selectedYearIndex === this.yearsList.length - 1;
        }
    }

    ngOnDestroy() {}
}
