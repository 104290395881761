<div class="container candidate-component">
    <div class="candidate-component__profile" *ngIf="employee">
        <div class="employee-actions">
            <button class="progress employee-actions__dropdown" (click)="editProfile()">
                Edit Profile
            </button>
            <div class="rating employee-actions__dropdown" [ngClass]="{ show: showQuickActionsDropdown }">
                <button class="fdr aic" (click)="onQuickActionsClick($event)">
                    <span>Quick Actions</span>
                </button>
                <div class="content-rating">
                    <div class="action-btn" *ngIf="employee.pending" (click)="onAddEmployeeClick($event)">
                        <img src="assets/images/icons-8-add-user-male.svg" height="14px" alt="Add user icon" />
                        <span>Add Employee</span>
                    </div>
                    <div class="action-btn" *ngIf="!employee.pending" (click)="onShowWorkflowModal()">
                        <img src="assets/images/workflow-icons/icons-8-workflow.svg" width="13px" alt="Workflow icon" />
                        <span>Send Workflow</span>
                    </div>
                </div>
            </div>
            <div class="blocker" *ngIf="!candidateLoaded"></div>
        </div>
        <div class="candidate-main-info">
            <div class="img-block" *ngIf="employee?.personal?.profile_image">
                <div
                    class="img-bg"
                    [ngStyle]="{ 'background-image': 'url(' + employee.personal.profile_image + ')' }"
                ></div>
                <!-- <img [src]="employee.personal.profile_image" alt="profile image" /> -->
            </div>
            <strong class="name">{{ employee.first_name | titlecase }} {{ employee.last_name | titlecase }}</strong>
            <p class="position-block">
                <span class="position">{{ employee.position | titlecase }} </span>
            </p>
            <div class="unverified-label" *ngIf="employee.unverified">
                Unverified
            </div>
            <ul class="info-list">
                <li *ngIf="employee?.job_details?.business_unit">
                    <strong>
                        <img src="assets/images/icons-8-people.png" height="14px" alt="People icon" />
                    </strong>
                    <span>{{ employee?.job_details?.business_unit }}</span>
                </li>
                <li *ngIf="employee?.job_details?.location">
                    <strong>
                        <img src="assets/images/location.svg" height="14px" alt="Location icon" />
                    </strong>
                    <span>{{ employee?.job_details?.location }}</span>
                </li>
            </ul>
            <ng-container *ngIf="employee && employee.email">
                <div class="candidate-profile-row-separator"></div>
                <div class="title">
                    <span>Tags</span>
                </div>
                <app-employee-tags [employee]="employee"></app-employee-tags>
            </ng-container>

            <div class="candidate-profile-row-separator"></div>
            <div class="candidate-profile-row">
                <div class="title">
                    <span>Contact</span>
                </div>
                <ul class="info-list">
                    <li>
                        <strong><img src="assets/images/email-send.svg" height="13px" alt="Email icon"/></strong>
                        <span class="nowrap">{{ employee.email }}</span>
                    </li>
                    <li>
                        <strong><img src="assets/images/icons-8-touchscreen.svg" alt="Touch screen"/></strong>
                        <span>{{ employee.phone }}</span>
                    </li>
                    <li>
                        <strong><img src="assets/images/icons-8-home.svg" alt="Touch screen"/></strong>
                        <span>{{ employee.location }}</span>
                    </li>
                    <li>
                        <strong><img src="assets/images/icons-8-facebook-old.svg" alt="Facebook"/></strong>
                        <span>{{ employee.facebook || '-' }}</span>
                    </li>
                    <li>
                        <strong><img src="assets/images/icons-8-twitter-circled.svg" alt="Twitter"/></strong>
                        <span>{{ employee.twitter || '-' }}</span>
                    </li>
                    <li>
                        <strong><img src="assets/images/icons-8-linkedin.svg" alt="Linkedin"/></strong>
                        <span>{{ employee.linkedin || '-' }}</span>
                    </li>
                    <li>
                        <strong><img src="assets/images/icons-8-github.svg" alt="Github"/></strong>
                        <span>{{ employee.github || '-' }}</span>
                    </li>
                    <li>
                        <strong><img src="assets/images/icons-8-instagram.svg" alt="Instagram"/></strong>
                        <span>{{ employee.instagram || '-' }}</span>
                    </li>
                </ul>
            </div>
            <div class="candidate-profile-row-separator"></div>
            <div class="candidate-profile-row" *ngIf="employee?.reported_chain">
                <div class="title">
                    <span>REPORTING CHAIN</span>
                </div>
                <div class="reportting-chain-item active">
                    <div class="user">
                        {{ employee?.reported_chain.first_name }} {{ employee?.reported_chain.last_name }}
                    </div>
                    <div class="position">{{ employee?.reported_chain?.relationship }}</div>
                    <span>
                        <svg
                            width="9px"
                            height="14px"
                            viewBox="0 0 9 14"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <g id="Recruit-Setup" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g
                                    id="Scorecard---Default"
                                    transform="translate(-1131.000000, -752.000000)"
                                    fill="#000000"
                                >
                                    <polygon
                                        id="Path"
                                        points="1135 752 1135 763.191406 1131.72266 760.015625 1131.02734 760.734375 1135.5 765.074219 1139.97266 760.734375 1139.27734 760.015625 1136 763.191406 1136 752"
                                    ></polygon>
                                </g>
                            </g>
                        </svg>
                    </span>
                </div>
                <div class="reportting-chain-item">
                    <div class="user">{{ employee.first_name }} {{ employee.last_name }}</div>
                    <div class="position">Regional Sales Manager</div>
                </div>
            </div>
        </div>
    </div>
    <div class="candidate-component__info" *ngIf="employee">
        <div class="sections-nav">
            <div
                class="section-nav-link"
                *ngFor="let section of sections"
                (click)="onChangeSection(section)"
                [ngClass]="{
                    active: activeSection === section
                }"
            >
                <span>{{ section | titlecase }}</span>
            </div>
        </div>
        <div class="candidate-content">
            <div class="content-section" *ngIf="activeSection === 'job'">
                <div class="content-block information-box-folder">
                    <div class="information-box-title">
                        <h4>Work Information</h4>
                        <img src="/assets/images/icons-8-unlock.svg" />
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Employee ID</h5>
                            <span>{{ employee?.employee_id || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Employee Status</h5>
                            <span>{{ (employee?.status | titlecase) || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Date of Joining</h5>
                            <span *ngIf="employee?.start_date || employee?.work_information?.joining_at">{{
                                employee?.start_date || employee?.work_information?.joining_at
                                    | amParse: 'DD-MM-YYYY'
                                    | amDateFormat: 'DD MMMM YYYY'
                            }}</span>
                            <span *ngIf="!employee?.start_date && !employee?.work_information?.joining_at">-</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Employee Type</h5>
                            <span>{{ (employee?.job_details?.type | titlecase) || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Department</h5>
                            <span>{{ (employee?.job_details?.department | titlecase) || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Designation or Title</h5>
                            <span>{{ employee?.job_details?.designation || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Business Unit</h5>
                            <span>{{ employee?.job_details?.business_unit || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Office Location</h5>
                            <span>{{ (employee?.job_location | titlecase) || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Sub Department</h5>
                            <span>{{ employee?.job_details?.sub_department || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Primary Team</h5>
                            <span>{{ employee?.job_details?.primary_team || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Additional Teams</h5>
                            <span>{{ employee?.job_details?.additional_teams || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Level</h5>
                            <span>{{ employee?.job_details?.level || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Cost Centre</h5>
                            <span>{{ employee?.job_details?.cost_centr || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Role</h5>
                            <span *ngIf="employee && candidateLoaded">{{
                                utilitiesService?.getUserRole(employee?.role)
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="content-block information-box-folder">
                    <div class="information-box-title">
                        <h4>Additional Work Information</h4>
                        <img src="/assets/images/icons-8-unlock.svg" />
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Shift</h5>
                            <span>{{ employee?.job_details?.shift || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>ID Card Number</h5>
                            <span>{{ employee?.job_details?.id_card || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Probation Start Date</h5>
                            <span *ngIf="employee?.job_details?.probation_start_date">{{
                                employee?.job_details?.probation_start_date
                                    | amParse: 'DD-MM-YYYY'
                                    | amDateFormat: 'DD MMMM YYYY'
                            }}</span>
                            <span *ngIf="!employee?.job_details?.probation_start_date">-</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Probation End Date</h5>
                            <span *ngIf="employee?.job_details?.probation_end_date">{{
                                employee?.job_details?.probation_end_date
                                    | amParse: 'DD-MM-YYYY'
                                    | amDateFormat: 'DD MMMM YYYY'
                            }}</span>
                            <span *ngIf="!employee?.job_details?.probation_end_date">-</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Previous Employer</h5>
                            <span>{{ employee?.job_details?.previous_company_name || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Previous Start Date</h5>
                            <span *ngIf="employee?.job_details?.previous_start_date">{{
                                employee?.job_details?.previous_start_date
                                    | amParse: 'DD-MM-YYYY'
                                    | amDateFormat: 'DD MMMM YYYY'
                            }}</span>
                            <span *ngIf="!employee?.job_details?.previous_start_date">-</span>
                        </div>
                    </div>
                </div>
                <div class="content-block information-box-folder">
                    <div class="information-box-title">
                        <h4>Work Contact Details</h4>
                        <img src="/assets/images/icons-8-unlock.svg" />
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Workstation Number</h5>
                            <span>{{ employee?.job_details?.workstation_number || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Email Address</h5>
                            <span>{{ employee?.work_email || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Phone (Work)</h5>
                            <span>{{ employee?.job_details?.work_phone || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Phone (Mobile)</h5>
                            <span>{{ employee?.phone || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Phone (Home)</h5>
                            <span>{{ employee?.job_details?.home_phone || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns"></div>
                    </div>
                </div>
                <div
                    class="content-block information-box-folder reporting-information"
                    *ngIf="employee?.job_details?.reporting?.length"
                >
                    <div class="information-box-title">
                        <h4>Reporting Information</h4>
                        <img src="/assets/images/icons-8-unlock.svg" />
                    </div>
                    <div class="information-box-row" style="flex-wrap: wrap;">
                        <div class="flex-48" *ngFor="let report of employee.job_details.reporting; let i = index">
                            <h5 *ngIf="report?.relationship">
                                {{ utilitiesService.transformRoleToText(report.relationship) }}
                            </h5>
                            <div class="reporting-user">
                                <div class="reporting-user-icon" [ngStyle]="{ backgroundColor: report.user_color }">
                                    <span> {{ report | initials }} </span>
                                </div>
                                <div class="reporting-user-name">
                                    <span>{{ report.first_name }} {{ report.last_name }}</span>
                                    <span class="email">{{ report.email }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-block information-box-folder">
                    <div class="information-box-title">
                        <h4>Notice Period Information</h4>
                        <img src="/assets/images/icons-8-lock.svg" />
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Notice Period Duration</h5>
                            <span>{{ employee?.notice_period?.duration || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Notice Period Start Date</h5>
                            <span>{{ employee?.notice_period?.start_date || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Notice Period End Date</h5>
                            <span>{{ employee?.contact_details?.end_date || '-' }}</span>
                        </div>
                    </div>
                </div>
                <div class="content-block information-box-folder">
                    <div class="information-box-title">
                        <h4>Termination Information</h4>
                        <img src="/assets/images/icons-8-lock.svg" />
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Termination Date</h5>
                            <span>{{ employee?.termination_information?.date || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Termination Category</h5>
                            <span>{{ employee?.termination_information?.category || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Termination Reason</h5>
                            <span>{{ employee?.termination_information?.reason || '-' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-section" *ngIf="activeSection === 'personal'">
                <div class="content-block information-box-folder">
                    <div class="information-box-title">
                        <h4>Personal Information</h4>
                        <img src="/assets/images/icons-8-unlock.svg" />
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Gender</h5>
                            <span>{{ employee?.gender || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Ethnicity</h5>
                            <span>{{ employee?.ethnicity || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Date of Birth</h5>
                            <span *ngIf="employee?.personal?.date_of_birth">{{
                                employee?.personal?.date_of_birth | amParse: 'DD-MM-YYYY' | amDateFormat: 'DD MMMM YYYY'
                            }}</span>
                            <span *ngIf="!employee?.personal?.date_of_birth">-</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Marital Status</h5>
                            <span>{{ (employee?.personal?.marital_status | titlecase) || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Languages Spoken</h5>
                            <span>{{ employee?.personal?.languages || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Blood Group</h5>
                            <span>{{ employee?.personal?.blood_group || '-' }}</span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Identity Number</h5>
                            <span>{{ employee?.personal?.identity_number || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns"></div>
                    </div>
                </div>
                <div class="content-block information-box-folder">
                    <div class="information-box-title">
                        <h4>Personal Contact Information</h4>
                        <img src="/assets/images/icons-8-unlock.svg" />
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Home Address</h5>
                            <span>
                                {{ employee?.personal?.residential_address?.street }} <br />
                                {{ employee?.personal?.residential_address?.city }} <br />
                                {{ employee?.personal?.residential_address?.state }} <br />
                                {{ employee?.personal?.residential_address?.country }} <br />
                                {{ employee?.personal?.residential_address?.code }}
                            </span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Communication Address</h5>
                            <span>
                                {{ employee?.personal?.postal_address?.street }} <br />
                                {{ employee?.personal?.postal_address?.city }} <br />
                                {{ employee?.personal?.postal_address?.state }} <br />
                                {{ employee?.personal?.postal_address?.country }} <br />
                                {{ employee?.personal?.postal_address?.code }}
                            </span>
                        </div>
                    </div>
                    <div class="information-box-row">
                        <div class="information-box-columns">
                            <h5>Email Address (Personal)</h5>
                            <span>{{ employee?.personal_email || '-' }}</span>
                        </div>
                        <div class="information-box-separator"></div>
                        <div class="information-box-columns">
                            <h5>Phone (Mobile)</h5>
                            <span>{{ employee?.phone || '-' }}</span>
                        </div>
                    </div>
                </div>

                <div class="content-block information-box-folder">
                    <div class="information-box-title">
                        <h4>Dependant Information</h4>
                        <img src="/assets/images/icons-8-lock.svg" />
                    </div>
                    <div class="salary-table" *ngIf="employee?.personal?.dependents?.length">
                        <div class="salary-table-tr salary-table-th">
                            <div class="item">Name</div>
                            <div class="item">Gender</div>
                            <div class="item">Relationship</div>
                            <div class="item">Date of Birth</div>
                        </div>
                        <div class="salary-table-tr salary-table-td" *ngFor="let item of employee.personal.dependents">
                            <div class="item">{{ item.full_name }}</div>
                            <div class="item">{{ item.gender }}</div>
                            <div class="item">{{ item.relationship | titlecase }}</div>
                            <div class="item">
                                {{ item.date_of_birth | amParse: 'DD-MM-YYYY' | amDateFormat: 'DD MMMM YYYY' }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="not-found-block"
                        *ngIf="!employee?.personal?.dependents || employee?.personal?.dependents?.length === 0"
                    >
                        No Dependant Information Found
                    </div>
                </div>

                <div class="content-block information-box-folder">
                    <div class="information-box-title">
                        <h4>Emergency Information</h4>
                        <img src="/assets/images/icons-8-lock.svg" />
                    </div>
                    <div class="salary-table" *ngIf="employee?.personal?.emergency_contacts?.length">
                        <div class="salary-table-tr salary-table-th">
                            <div class="item">Name</div>
                            <div class="item">Phone</div>
                            <div class="item">Relationship</div>
                            <div class="item">Email</div>
                        </div>
                        <div
                            class="salary-table-tr salary-table-td"
                            *ngFor="let item of employee.personal.emergency_contacts"
                        >
                            <div class="item">{{ item.full_name }}</div>
                            <div class="item">{{ item.phone }}</div>
                            <div class="item">{{ item.relationship | titlecase }}</div>
                            <div class="item">{{ item.email }}</div>
                        </div>
                    </div>
                    <div
                        class="not-found-block"
                        *ngIf="
                            !employee?.personal?.emergency_contacts ||
                            employee?.personal?.emergency_contacts?.length === 0
                        "
                    >
                        No Emergency Contacts Found
                    </div>
                </div>
            </div>
            <div class="content-section" *ngIf="activeSection === 'employment & education'">
                <div class="content-block information-box-folder" *ngIf="employee.summary">
                    <div class="information-box-title">
                        <h4>Executive Summary</h4>
                    </div>
                    <p [innerHTML]="employee.summary"></p>
                </div>
                <div
                    class="content-block experience-wrap"
                    *ngIf="employee.employment_history && employee.employment_history.length"
                >
                    <h3>Work Experience</h3>
                    <p-accordion>
                        <p-accordionTab *ngFor="let work of employee.employment_history">
                            <p-header>
                                <div class="title">
                                    <div class="position">{{ work.title | titlecase }}</div>
                                    -
                                    <div class="company">{{ work.company | titlecase }}</div>
                                </div>
                                <span class="date"
                                    >{{ work.start_date || 'Unknown' }} - {{ work.end_date || 'Unknown' }}</span
                                >
                            </p-header>
                            <div class="description" [innerHTML]="work.description"></div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="content-block education-wrap" *ngIf="employee.education && employee.education.length">
                    <h3>Education</h3>
                    <div class="education-item" *ngFor="let edu of employee.education">
                        <div class="title">
                            <div class="position" *ngIf="edu.degree">
                                {{ edu.area_of_study }}
                                <span>({{ edu.degree }})</span>
                            </div>
                            -
                            <div class="company">{{ edu.school || 'Unknown school' }}</div>
                        </div>
                        <span class="date" *ngIf="edu.start_date">
                            {{ edu.start_date || 'Unknown' }} - {{ edu.end_date || 'Unknown' }}</span
                        >
                    </div>
                </div>

                <div
                    class="content-block certifications-wrap"
                    *ngIf="employee.certificates && employee.certificates.length"
                >
                    <h3>Licenses and Certifications</h3>
                    <div class="item" *ngFor="let certificate of employee.certificates">
                        <div class="title">
                            <div class="position" *ngIf="certificate.name">{{ certificate.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-section" *ngIf="activeSection === 'compensation'">
                <div class="content-block information-box-folder" *ngIf="employee?.salary?.length">
                    <div class="information-box-title">
                        <h4>Salary</h4>
                        <img src="/assets/images/icons-8-lock.svg" />
                    </div>
                    <div class="salary-table">
                        <div class="salary-table-tr salary-table-th">
                            <div class="item">Annual Salary</div>
                            <div class="item">Start Date</div>
                            <div class="item">End Date</div>
                            <div class="item">Reason</div>
                        </div>
                        <div
                            class="salary-table-tr salary-table-td"
                            *ngFor="let item of employee.salary; let last = last; let i = index"
                        >
                            <div class="item">{{ item.currency }} {{ item.salary | number }}</div>
                            <div class="item">
                                {{ item.effective_date | amParse: 'DD-MM-YYYY' | amDateFormat: 'DD MMMM YYYY' }}
                            </div>
                            <div class="item">
                                <ng-container *ngIf="item.end_date && !last">
                                    {{ item.end_date | amParse: 'DD-MM-YYYY' | amDateFormat: 'DD MMMM YYYY' }}
                                </ng-container>
                                <div class="current" *ngIf="last">
                                    Current
                                </div>
                            </div>
                            <div class="item">{{ item?.reason || '-' }}</div>
                        </div>
                    </div>
                </div>
                <div class="content-block information-box-folder" *ngIf="employee?.bonuses?.length">
                    <div class="information-box-title">
                        <h4>Bonuses</h4>
                        <img src="/assets/images/icons-8-lock.svg" />
                    </div>
                    <div class="salary-table">
                        <div class="salary-table-tr salary-table-th">
                            <div class="item">Amount</div>
                            <div class="item">Start Date</div>
                            <div class="item">End Date</div>
                            <div class="item">Type</div>
                        </div>
                        <div
                            class="salary-table-tr salary-table-td"
                            *ngFor="let item of employee.bonuses; let last = last; let i = index"
                        >
                            <div class="item">{{ item.currency }} {{ item.amount | number }}</div>
                            <div class="item">
                                {{ item.effective_date | amParse: 'DD-MM-YYYY' | amDateFormat: 'DD MMMM YYYY' }}
                            </div>
                            <div class="item">
                                <div class="current">
                                    Current
                                </div>
                            </div>
                            <div class="item">{{ item?.type | titlecase }}</div>
                        </div>
                    </div>
                </div>
                <div class="content-block information-box-folder" *ngIf="employee?.allowances?.length">
                    <div class="information-box-title">
                        <h4>Allowances</h4>
                        <img src="/assets/images/icons-8-lock.svg" />
                    </div>
                    <div class="salary-table">
                        <div class="salary-table-tr salary-table-th">
                            <div class="item">Amount</div>
                            <div class="item">Start Date</div>
                            <div class="item">End Date</div>
                            <div class="item">Type</div>
                        </div>
                        <div
                            class="salary-table-tr salary-table-td"
                            *ngFor="let item of employee.allowances; let last = last; let i = index"
                        >
                            <div class="item">{{ item.currency || '-' }} {{ item.amount | number }}</div>
                            <div class="item">
                                {{ item.effective_date | amParse: 'DD-MM-YYYY' | amDateFormat: 'DD MMMM YYYY' }}
                            </div>
                            <div class="item">
                                <div class="current">
                                    Current
                                </div>
                            </div>
                            <div class="item">{{ item?.type | titlecase }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-section" *ngIf="activeSection === 'time off'">
                <div class="content-block information-box-folder" *ngIf="employee?.time_off?.length">
                    <div class="information-box-title">
                        <h4>Time Off</h4>
                        <img src="/assets/images/icons-8-lock.svg" />
                    </div>
                    <div class="salary-table">
                        <div class="salary-table-tr salary-table-th">
                            <div class="item">Time Off Type</div>
                            <div class="item">Total</div>
                            <div class="item">Available</div>
                            <div class="item">Consumed</div>
                        </div>
                        <div class="salary-table-tr salary-table-td" *ngFor="let item of employee.time_off">
                            <div class="item">
                                <div class="dot green" *ngIf="item.type === 'earned'"></div>
                                <div class="dot yellow" *ngIf="item.type === 'sick'"></div>
                                {{ item.type | titlecase }}
                            </div>
                            <div class="item">
                                {{ item.amount }}
                            </div>
                            <div class="item">
                                {{ item.amount }}
                            </div>
                            <div class="item">0</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-section" *ngIf="activeSection === 'files'">
                <div
                    class="content-block files-section information-box-folder"
                    *ngFor="let item of documents | keyvalue"
                >
                    <div class="information-box-title">
                        <h4>{{ item.key }}</h4>
                    </div>
                    <div class="information-box-row" *ngIf="item?.value?.length">
                        <div class="flex-48" *ngFor="let file of item.value">
                            <div class="existing-files" (click)="onShowDocument(file)">
                                <a class="file-block-frame" [title]="file.name"
                                    ><span class="file-icon">
                                        <img
                                            *ngIf="file.name"
                                            src="/assets/images/icons/files-icon-{{
                                                utilitiesService.getExtension(file.name)
                                            }}.svg"
                                            alt=""
                                        />
                                    </span>
                                    <div class="file-block-holder">
                                        <span class="title">
                                            <strong>{{ file.original_name }}</strong>
                                        </span>
                                        <div class="label-row">
                                            <span class="file-size thin" *ngIf="file.size">
                                                {{ utilitiesService.convertSize(file.size) }}
                                            </span>
                                            <span class="thin" *ngIf="file.uploaded_at">{{
                                                file.uploaded_at | date: 'd MMMM, y'
                                            }}</span>
                                            <span class="document-status">
                                                <div class="status-icon success" *ngIf="file.status === 'approved'">
                                                    <div class="holder"></div>
                                                </div>
                                                <div class="status-icon error" *ngIf="file.status === 'rejected'">
                                                    <div class="holder"></div>
                                                </div>
                                                <div
                                                    class="status-icon warning"
                                                    *ngIf="!file.status || file.status === 'pending'"
                                                >
                                                    <div class="holder">
                                                        <div class="fill"></div>
                                                        <div class="mark"></div>
                                                    </div>
                                                </div>
                                            </span>
                                            <div class="upload-icon" (click)="onDownloadDocumnt($event, file)">
                                                <img src="/assets/images/download.svg" height="14" alt="Upload Icon" />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="upload-documents-block">
                        <input
                            class="file hide-input"
                            type="file"
                            name=""
                            [id]="item.key"
                            (change)="onFileUpload($event)"
                        />

                        <div class="upload-btn-block" #uploadOptionsDropUp>
                            <div class="label-button" (click)="onAddDocumentBtnClick(item.key)">
                                <svg-icon
                                    [svgStyle]="{ 'width.px': 13, 'height.px': 13 }"
                                    src="/assets/images/plus-icon.svg"
                                ></svg-icon>
                                <span>Add Document</span>
                            </div>
                            <div class="options-drop-up" [ngClass]="{ visible: showUploaderOptions[item.key]?.show }">
                                <div class="documents-category">
                                    <div class="documents-category-lists" *ngIf="item.key === 'Onboarding Documents'">
                                        <ng-container *ngFor="let type of uploadedTypesOptions[0].types">
                                            <label
                                                *ngIf="!type.exist"
                                                [for]="item.key"
                                                class="upload-item"
                                                (click)="onUploadItemClick(type.value, item.key)"
                                            >
                                                <span class="upload-item-text">{{ type.label }}</span>
                                                <span class="upload-item-size">&lt; 5MB</span>
                                            </label>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-section" *ngIf="activeSection === 'answers'">
                <app-employee-answers *ngIf="employee.answers" [answers]="employee.answers"></app-employee-answers>
            </div>
            <div class="content-section" *ngIf="activeSection === 'history'">
                <app-employee-item-timeline *ngIf="employee" [employee]="employee"></app-employee-item-timeline>
            </div>
        </div>
    </div>
</div>

<app-employee-item-edit
    *ngIf="editMode"
    (close)="onCloseEditMode()"
    (documentUpdated)="documentUpdated($event)"
    (employmentSectionUpdated)="employmentSectionUpdated()"
    [employee]="employee"
>
</app-employee-item-edit>
<app-loader *ngIf="contentLoading" class="posa"></app-loader>
<app-document-preview
    *ngIf="documentReviewModal.show"
    [file]="documentReviewModal.data"
    [employeeId]="documentReviewModal.employeeId"
    (close)="onClosePreviewModal()"
    (documentUpdated)="onDocumentUpdated($event)"
>
</app-document-preview>

<p-dialog
    [(visible)]="workflowModal.visible"
    (onHide)="onHideModal()"
    [style]="{ width: '460px' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [dismissableMask]="true"
    [showHeader]="false"
    class="decline-modal"
>
    <div class="p-header p-header-new bg-green">
        Add To Workflow
        <button class="close-btn" (click)="onHideModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="p-body p-body-new">
        <p>Please select the workflow that you'd like to add {{ employee?.first_name }} to:</p>
        <div class="form-block">
            <div class="input-block" *ngIf="workflowModal.visible">
                <app-auto-dropdown [options]="workflowModal.options" [control]="workflowControl" placeholder="Workflow">
                </app-auto-dropdown>
            </div>
        </div>
    </div>
    <div class="p-footer p-footer-new">
        <div class="action">
            <button
                class="btn add-workflow-btn"
                [disabled]="!workflowControl || !workflowControl.value"
                (click)="onAddToWorkflow()"
            >
                Add to Workflow
            </button>
        </div>
    </div>
    <app-loader *ngIf="requestLoading" class="posa"></app-loader>
</p-dialog>
