import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-overall-rating',
    templateUrl: './overall-rating.component.html',
    styleUrls: ['./overall-rating.component.scss']
})
export class OverallRatingComponent implements OnInit, OnChanges {
    @Input() item;
    @Input() jobId;
    @Input() candidateId;
    @Output() setVote: EventEmitter<any> = new EventEmitter();

    ratingStatus;
    stars: any[] = [{ index: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
    constructor() {}

    ngOnInit(): void {
        this.setRating();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.item) {
            this.setRating();
        }
    }

    setRating() {
        this.stars = [{ index: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
        this.stars = this.stars.map((star, idx) => {
            if (idx + 1 <= this.item.rating) {
                star.active = true;
            }
            return star;
        });

        this.ratingStatus =
            this.item.rating === 1
                ? 'Poor'
                : this.item.rating === 2
                ? 'Fair'
                : this.item.rating === 3
                ? 'Good'
                : this.item.rating === 4
                ? 'Very Good'
                : this.item.rating === 5
                ? 'Excellent'
                : 'Not Assessed';
    }

    onHoverStars(index) {
        this.stars.forEach((s) => (s.hover = false));
        for (let i = 0; i <= index; i++) {
            this.stars[i].hover = true;
        }
    }

    onClearStars() {
        this.stars.forEach((s) => (s.hover = false));
    }

    onEvaluateAnswer(index) {
        this.setVote.emit(index);
    }
}
