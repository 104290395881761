<div class="rating-wrapper">
    <div class="evaluate-question">
        <div class="star without-rating" [ngClass]="{ active: item.rating === 0 }" (click)="onEvaluateAnswer(-1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">
                <path
                    fill="#637381"
                    d="M19.495.21l1.01 1.01L1.934 19.79l-1.01-1.01 4.652-4.652.325-1.536L.474 7.701l7.266-.767L10.714.26l2.691 6.038 6.09-6.09zm-8.78 3.56L8.71 8.269l-4.899.516L7.47 12.08l-.042.196 4.896-4.896-1.609-3.611zm5.79 3.46l4.45.47-5.428 4.892 1.515 7.147-6.328-3.65-6.328 3.65.105-.494 2.874-2.874 3.35-1.932L14.98 16.9l-1.02-4.819 3.657-3.296-2.412-.254 1.3-1.3z"
                    opacity=".5"
                />
            </svg>
        </div>
        <div
            class="star"
            *ngFor="let star of stars; let i = index"
            (mouseenter)="onHoverStars(i)"
            (mouseleave)="onClearStars()"
            (click)="onEvaluateAnswer(i)"
            [ngClass]="{ active: star.active || star.hover }"
        >
            <svg
                width="20px"
                height="19px"
                viewBox="-2 -2 17 17"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <g id="Symbols" stroke="none" stroke-width="0.5" fill="none" fill-rule="evenodd">
                    <g id="3.5-star" transform="translate(-40.000000, 0.000000)" stroke="#455565">
                        <polygon
                            id="Path"
                            points="47 0 44.972656 4.804688 40 5.347656 43.71875 8.859375 42.671875 14 47 11.363281 51.328125 14 50.28125 8.859375 54 5.347656 49.027344 4.804688"
                        ></polygon>
                    </g>
                </g>
            </svg>
        </div>
    </div>
    <span
        class="status-rating"
        [ngClass]="{
            Bad: item.rating < 3 && item.rating > 0,
            Average: item.rating >= 3 && item.rating < 4,
            Good: item.rating >= 4
        }"
        *ngIf="item.rating"
        >{{ ratingStatus }}</span
    >
    <span class="status-rating" *ngIf="!item.rating">Not Assessed</span>
</div>
