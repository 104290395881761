import { AbstractControl } from '@angular/forms';

export class EditorValidator {
    static validate(AC: AbstractControl) {
        let description = AC.get('description').value;
        let requirements = AC.get('requirements').value;
        let cleanDescription;
        let cleanRequirements;
        cleanDescription = document.createElement('DIV');
        cleanDescription.innerHTML = description;
        cleanRequirements = document.createElement('DIV');
        cleanRequirements.innerHTML = requirements;
        if (cleanDescription && !cleanDescription.textContent) {
            AC.get('description').setErrors({ required: true });
        }
        if (cleanRequirements && !cleanRequirements.textContent) {
            AC.get('requirements').setErrors({ required: true });
        }
    }
}
