<div class="light-overlay" *ngIf="contentLoading"></div>
<div class="message-form" [ngClass]="{ opened: isOpened }">
    <div class="tags-container">
        <div
            class="tag"
            *ngFor="let tag of employee.tags || []"
            [style.color]="getTagStyle(tag.color).color"
            [style.background]="getTagStyle(tag.color).background"
        >
            {{ tag.title || tag.hash || '-' }}
            <svg
                class="delete-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                (click)="onDeleteTag(tag.title || tag.hash)"
            >
                <path
                    [style.fill]="getTagStyle(tag.color).color"
                    fill-rule="nonzero"
                    d="M.5.029L.029.5l.237.234L3.529 4l-3.5 3.5.471.471 3.5-3.5 3.263 3.266.237.234.471-.471-.234-.237L4.471 4l3.5-3.5L7.5.029 4 3.529.734.266z"
                />
            </svg>
        </div>
        <app-small-loader *ngIf="isSaving"></app-small-loader>
        <div class="add-tag" (click)="showOpened()">
            <span *ngIf="!isOpened"><img src="/assets/images/icons/signs.svg"/></span>
            <span *ngIf="isOpened"><img src="/assets/images/icons/unsigns.svg"/></span>
        </div>
    </div>
    <form class="main-form" [formGroup]="tagForm" [ngClass]="{ hidden: !isOpened }">
        <div style="position: relative;">
            <!-- <app-editor #pEditor formControlName="title" [placeholder]="'Search / Add New'"></app-editor> -->
            <input
                type="text"
                class="form-input"
                formControlName="title"
                (keyup)="onNewTagTitleKeyup($event)"
                [placeholder]="'Search / Add New'"
            />
            <div class="hash-suggestion" *ngIf="showDropdown && tagWithTitles.length > 0">
                <ng-container *ngIf="tagWithTitles.length > 0 && showDropdown">
                    <p
                        class="hash"
                        *ngFor="let tag of tagWithTitles"
                        (click)="placeHash(tag)"
                        [ngClass]="{ added: tag?.added }"
                        [style.color]="getTagStyle(tag.color).color"
                        [style.background]="getTagStyle(tag.color).background"
                    >
                        {{ tag.title || tag.hash || '-' }}
                    </p>
                </ng-container>
            </div>

            <div
                class="hash-colors"
                [style.top.px]="suggestionPosition?.top"
                [style.left.px]="suggestionPosition?.left"
                *ngIf="createMode"
            >
                <div
                    class="hash-color"
                    *ngFor="let color of hashColors"
                    [style.background]="color"
                    (click)="onChangeHashColor(color)"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        *ngIf="lastHash && lastHash.color === color"
                    >
                        <path
                            [style.fill]="getTagStyle(color).color"
                            fill-rule="nonzero"
                            d="M14.5.793l-9 9-3.648-3.645-.352-.355-.707.707.355.352L5.5 11.207 15.207 1.5z"
                        />
                    </svg>
                </div>
            </div>
        </div>
    </form>
</div>
