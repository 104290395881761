<div class="list-filter">
    <button class="btn all-items" [ngClass]="{ active: !filter.length }" (click)="onClearFilters()">
        {{ allItemsText }}
    </button>
    <div class="filter-content" [ngClass]="{ visible: filterVisible }" *ngIf="allItems">
        <ng-container *ngIf="view === 'category'">
            <div class="search-text">
                <input type="text" placeholder="Search Filters" (keyup)="onSearchKeyup($event)" [(ngModel)]="search" />
            </div>
            <ul class="filter-options">
                <li *ngFor="let item of allItems" (click)="onOwnerFilterChange(item.value)">
                    <div class="img-block">
                        <img src="/assets/images/icons/personal-details.svg" [alt]="item.label" />
                    </div>
                    <span>{{ item.label }}</span>
                </li>
            </ul>
        </ng-container>
    </div>
    <div class="job-status-filter" *ngIf="jobPage">
        <p-multiSelect
            [options]="jobNewOptions"
            [(ngModel)]="newStatusFilters"
            [filter]="false"
            [showHeader]="false"
            (onChange)="onChangeStatus($event)"
            [dropdownIcon]="false"
        >
            <ng-template let-selectedStatuses pTemplate="selectedItems">
                Job Status{{ selectedStatuses && selectedStatuses.length > 1 ? 'es' : '' }}:
                <span *ngFor="let selectedStatus of selectedStatuses; let i = index"
                    >{{ i > 0 && selectedStatuses && i < selectedStatuses.length ? ', ' : ''
                    }}{{ selectedStatus | titlecase }}</span
                >
            </ng-template>
            <ng-template let-status let-i="index" pTemplate="item">
                <span>{{ status.label }}</span>
            </ng-template>
        </p-multiSelect>
    </div>
    <ng-container *ngIf="filter && filter.length">
        <div class="filter-presentation-block" *ngFor="let f of filter">
            <div class="filter-presentation-name">{{ f.type | titlecase | replace: '_':' ' }}:</div>
            <div class="filter-presentation-value">{{ f.presentedValue | titlecase }}</div>
            <button class="btn remove-filter-btn">
                <img
                    src="/assets/images/close.svg"
                    alt="Remove from selected"
                    width="12"
                    height="12"
                    (click)="onRemoveFromSelected(f.type)"
                />
            </button>
        </div>
    </ng-container>
    <div class="add-filter-block">
        <button class="btn add-filter-btn" (click)="onFilterToggle()">+ Add Filter</button>
        <div class="filter-content" [ngClass]="{ visible: filterVisible }">
            <ng-container *ngIf="view === 'list'">
                <div class="search-text">
                    <input
                        type="text"
                        placeholder="Search Filters"
                        (keyup)="onSearchKeyup($event)"
                        [(ngModel)]="search"
                    />
                </div>
                <ul class="filter-options">
                    <li
                        (click)="onFilterItemSelect('city')"
                        *ngIf="filteredFilters && filteredFilters.indexOf('city') !== -1"
                    >
                        <div class="img-block"><img src="/assets/images/america.svg" alt="city" /></div>
                        <span>City</span>
                    </li>
                    <li
                        (click)="onFilterItemSelect('country')"
                        *ngIf="filteredFilters && filteredFilters.indexOf('country') !== -1"
                    >
                        <div class="img-block"><img src="/assets/images/america.svg" alt="country" /></div>
                        <span>Country</span>
                    </li>
                    <li
                        (click)="onFilterItemSelect('education')"
                        *ngIf="filteredFilters && filteredFilters.indexOf('education') !== -1"
                    >
                        <div class="img-block"><img src="/assets/images/student_center.svg" alt="education" /></div>
                        <span>Education</span>
                    </li>
                    <li
                        (click)="onFilterItemSelect('reference')"
                        *ngIf="filteredFilters && filteredFilters.indexOf('reference') !== -1"
                    >
                        <div class="img-block"><img src="/assets/images/resume-filter.svg" alt="experience" /></div>
                        <span>Reference</span>
                    </li>
                    <li
                        (click)="onFilterItemSelect('location')"
                        *ngIf="filteredFilters && filteredFilters.indexOf('location') !== -1"
                    >
                        <div class="img-block">
                            <img src="/assets/images/america.svg" alt="city" />
                        </div>
                        <span>Location</span>
                    </li>
                    <li
                        (click)="onFilterItemSelect('type')"
                        *ngIf="filteredFilters && filteredFilters.indexOf('type') !== -1"
                    >
                        <div class="img-block">
                            <img src="/assets/images/company-filter.svg" alt="employers" />
                        </div>
                        <span>Job Type</span>
                    </li>
                    <li
                        (click)="onFilterItemSelect('employers')"
                        *ngIf="filteredFilters && filteredFilters.indexOf('employers') !== -1"
                    >
                        <div class="img-block"><img src="/assets/images/company-filter.svg" alt="employers" /></div>
                        <span>Employers</span>
                    </li>
                    <li
                        (click)="onFilterItemSelect('experience')"
                        *ngIf="filteredFilters && filteredFilters.indexOf('experience') !== -1"
                    >
                        <div class="img-block"><img src="/assets/images/resume-filter.svg" alt="experience" /></div>
                        <span>Expereince Years</span>
                    </li>
                    <li
                        (click)="onFilterItemSelect('skills')"
                        *ngIf="filteredFilters && filteredFilters.indexOf('skills') !== -1"
                    >
                        <div class="img-block"><img src="/assets/images/clipboard-filter.svg" alt="skills" /></div>
                        <span>Skills</span>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="view === 'city'">
                <div class="filter-title-block">
                    <div class="img-block"><img src="/assets/images/america.svg" alt="city" /></div>
                    <span>City</span>
                </div>
                <div class="filter-content-block"><p-chips [(ngModel)]="filtersObj.city"></p-chips></div>
                <div class="filter-finish-block" (click)="onFilterEditDone()"><span>Done</span></div>
            </ng-container>
            <ng-container *ngIf="view === 'country'">
                <div class="filter-title-block">
                    <div class="img-block"><img src="/assets/images/america.svg" alt="country" /></div>
                    <span>Country</span>
                </div>
                <div class="filter-content-block"><p-chips [(ngModel)]="filtersObj.country"></p-chips></div>
                <div class="filter-finish-block" (click)="onFilterEditDone()"><span>Done</span></div>
            </ng-container>
            <ng-container *ngIf="view === 'reference'">
                <div class="filter-title-block">
                    <div class="img-block"><img src="/assets/images/resume-filter.svg" alt="experience" /></div>
                    <span>Reference</span>
                </div>
                <div class="filter-content-block"><p-chips [(ngModel)]="filtersObj['reference']"></p-chips></div>
                <div class="filter-finish-block" (click)="onFilterEditDone()"><span>Done</span></div>
            </ng-container>
            <ng-container *ngIf="view === 'education'">
                <div class="filter-title-block">
                    <div class="img-block"><img src="/assets/images/student_center.svg" alt="education" /></div>
                    <span>Education</span>
                </div>
                <div class="filter-content-block">
                    <div class="form-row">
                        <app-auto-dropdown
                            [options]="educationOptions"
                            (onChange)="selectDropDown($event, 'education', 'level')"
                            placeholder="Level"
                            [value]="filtersObj.education.level"
                        >
                        </app-auto-dropdown>
                    </div>
                    <div class="form-row">
                        <input
                            type="text"
                            class="form-input"
                            placeholder="Major"
                            [(ngModel)]="filtersObj.education.major"
                        />
                    </div>
                    <div class="form-row">
                        <input
                            type="text"
                            class="form-input"
                            placeholder="School"
                            [(ngModel)]="filtersObj.education.school"
                        />
                    </div>
                </div>
                <div class="filter-finish-block" (click)="onFilterEditDone()"><span>Done</span></div>
            </ng-container>
            <ng-container *ngIf="view === 'location'">
                <div class="filter-title-block">
                    <div class="img-block"><img src="/assets/images/america.svg" alt="city" /></div>
                    <span>Location</span>
                </div>
                <div class="filter-content-block">
                    <div class="form-row">
                        <app-auto-dropdown
                            [options]="tenantLocationOptions"
                            (onChange)="selectDropDown($event, 'location', 'position')"
                            placeholder="Location"
                            [value]="filtersObj.location.position"
                        >
                        </app-auto-dropdown>
                    </div>
                </div>
                <div class="filter-finish-block" (click)="onFilterEditDone()"><span>Done</span></div>
            </ng-container>
            <ng-container *ngIf="view === 'type'">
                <div class="filter-title-block">
                    <div class="img-block"><img src="/assets/images/company-filter.svg" alt="employers" /></div>
                    <span>Job Type</span>
                </div>
                <div class="filter-content-block">
                    <div class="form-row">
                        <app-auto-dropdown
                            [options]="jobTypeOptions"
                            (onChange)="selectDropDown($event, 'type', 'option')"
                            placeholder="Job Type"
                            [value]="filtersObj.type.option"
                        >
                        </app-auto-dropdown>
                    </div>
                </div>
                <div class="filter-finish-block" (click)="onFilterEditDone()"><span>Done</span></div>
            </ng-container>
            <ng-container *ngIf="view === 'employers'">
                <div class="filter-title-block">
                    <div class="img-block"><img src="/assets/images/company-filter.svg" alt="employers" /></div>
                    <span>Employers</span>
                </div>
                <div class="filter-content-block"><p-chips [(ngModel)]="filtersObj.employers"></p-chips></div>
                <div class="filter-finish-block" (click)="onFilterEditDone()"><span>Done</span></div>
            </ng-container>
            <ng-container *ngIf="view === 'experience'">
                <div class="filter-title-block">
                    <div class="img-block"><img src="/assets/images/resume-filter.svg" alt="experience" /></div>
                    <span>Expereince Years</span>
                </div>
                <div class="filter-content-block">
                    <div class="form-row">
                        <app-auto-dropdown
                            [options]="yearsOptions"
                            (onChange)="selectDropDown($event, 'education', 'level')"
                            placeholder="Minimum"
                            [value]="filtersObj.education.level"
                        >
                        </app-auto-dropdown>
                    </div>
                    <div class="form-row">
                        <app-auto-dropdown
                            [options]="yearsOptions"
                            [options]="yearsOptions"
                            (onChange)="selectDropDown($event, 'experience', 'max')"
                            placeholder="Maximum"
                            [value]="filtersObj.experience.max"
                        >
                        </app-auto-dropdown>
                    </div>
                </div>
                <div class="filter-finish-block" (click)="onFilterEditDone()"><span>Done</span></div>
            </ng-container>
            <ng-container *ngIf="view === 'skills'">
                <div class="filter-title-block">
                    <div class="img-block"><img src="/assets/images/clipboard-filter.svg" alt="skills" /></div>
                    <span>Skills</span>
                </div>
                <div class="filter-content-block">
                    <div class="form-row">
                        <input
                            type="text"
                            class="form-input"
                            placeholder="Skill Name"
                            [(ngModel)]="filtersObj.skills.name"
                        />
                    </div>
                    <div class="form-row">
                        <app-auto-dropdown
                            [options]="yearsOptions"
                            (onChange)="selectDropDown($event, 'skills', 'min_exp')"
                            placeholder="Minimum Experience"
                            [value]="filtersObj.skills.min_exp"
                            [disabled]="!filtersObj.skills.name || !filtersObj.skills.name.length"
                        >
                        </app-auto-dropdown>
                    </div>
                    <div class="form-row">
                        <app-auto-dropdown
                            [options]="yearsOptions"
                            (onChange)="selectDropDown($event, 'skills', 'max_exp')"
                            placeholder="Maximum Experience"
                            [value]="filtersObj.skills.max_exp"
                            [disabled]="!filtersObj.skills.name || !filtersObj.skills.name.length"
                        >
                        </app-auto-dropdown>
                    </div>
                    <div class="form-row">
                        <p-checkbox
                            binary="true"
                            label="Must be recently used"
                            [(ngModel)]="filtersObj.skills.recently_used"
                            [disabled]="!filtersObj.skills.name || !filtersObj.skills.name.length"
                        ></p-checkbox>
                    </div>
                </div>
                <div class="filter-finish-block" (click)="onFilterEditDone()"><span>Done</span></div>
            </ng-container>
        </div>
    </div>
</div>
