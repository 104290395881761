<div class="maintenance-container">
    <div class="text">
        <strong class="title">
            <img src="/assets/images/andteam.svg" width="120" height="30" alt="&team" />
        </strong>
        <p>
            Unfortunately the site is down for a bit of scheduled maintenance right now. We’ll be back in a few hours.
            You can follow us on <a href="https://twitter.com/_andteam">twitter</a> for updates.
        </p>
    </div>
    <img src="/assets/images/maintenance.svg" width="192" height="157" alt="Maintenance" />
</div>
