<div #scrollContainer class="container main-container">
    <div class="title-block">
        <div class="title-actions">
            <div class="title-block-column">
                <svg-icon src="/assets/images/icon-ampersand.svg" class="mr20"></svg-icon>
                <h1 class="page-title">
                    {{ isCreatedNewRequest ? 'Thank you!' : 'How can we help you today?' }}
                </h1>
            </div>
            <button
                *ngIf="newHire && !isCreatedNewRequest"
                class="btn btn-primary w140 mla"
                (click)="createDraft($event)"
            >
                Submit
            </button>
        </div>
        <div class="section-separator mt20"></div>
    </div>
    <div class="content-wrapper">
        <ng-container *ngIf="!isCreatedNewRequest">
            <div class="flex-row mb40">
                <div class="select-type-wrap mr30" (click)="navigateTo()">
                    <svg-icon src="/assets/images/icons-8-submit-resume.svg"></svg-icon>
                    <p>I’m looking for a job</p>
                </div>

                <div class="select-type-wrap" (click)="onNewHire()" [class.active]="newHire">
                    <svg-icon src="/assets/images/icons-8-find-user-male.svg"></svg-icon>
                    <p>I’m looking for a new hire</p>
                </div>
            </div>
            <ng-container *ngIf="!newHire">
                <div class="blocks-separator mt30 mb30"></div>
                <div class="flex-row mb30">
                    <p>I am already hiring for a position, log me into my dashboard:</p>
                    <button type="button" class="btn btn-primary w140 mla" (click)="routeNavigate()">
                        My Dashboard
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="newHire">
                <app-request-position></app-request-position>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isCreatedNewRequest">
            <div class="flex-row mb30">
                <p class="mta mb0">
                    Your position request has been logged and will be assigned to a recruiter who will be in contact
                    with you shortly.
                </p>
            </div>
            <div class="flex-row">
                <button type="button" class="btn btn-primary w140" (click)="routeNavigate()">
                    My Dashboard
                </button>
            </div>
        </ng-container>
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>
