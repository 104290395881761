<p-autoComplete
    #ac
    [(ngModel)]="_value"
    (ngModelChange)="triggerChange($event)"
    [suggestions]="results"
    (completeMethod)="search($event)"
    (onKeyUp)="onKeyUp($event)"
    [multiple]="true"
    [dropdown]="true"
    field="name"
>
</p-autoComplete>
