<div class="loader">
    <svg class="static">
        <path
            class="hc_logo"
            d="M24.2 19.9c0 1.1 0.9 2 2 2c1.1 0 2-0.9 2-2c0-1.1-0.9-2-2-2C25.1 17.9 24.2 18.8 24.2 19.9z M25 16.4 c3.7 0 6.9 2.4 8.1 5.7l1.2 0c0 0 0.1 0 0.1 0c-1.3-4-5-6.8-9.4-6.8c-1.6 0-3 0.4-4.4 1l1.2 0.8C22.8 16.6 23.9 16.4 25 16.4z M34.7 24.5l-1 0.4c0 0 0 0.1 0 0.1c0 2-0.7 3.9-1.9 5.4l0.5 1.3c1.6-1.8 2.6-4.1 2.6-6.7c0-0.2 0-0.4 0-0.6 C34.8 24.5 34.8 24.5 34.7 24.5z M34.3 22.7l-12.9 0l-0.2-2c-0.1-0.9-1.2-0.8-1.4 0l-0.5 2c-0.3 1.1 0.5 1.8 1.1 2l2.6 1l-2.1 4.9 c-0.2 0.5 0.3 1.3 1.2 0.6l3.9-2.9l4.9 4.4c0.6 0.5 1.2 0 0.9-0.7l-2.3-6.4l5.1-1.8C35.4 23.6 35.3 22.7 34.3 22.7z M25 33.7 c-4.8 0-8.6-3.9-8.6-8.6c0-1.7 0.5-3.4 1.4-4.7l-0.9 0l0 0c-0.1 0-0.2 0-0.4-0.1c-0.8 1.4-1.2 3-1.2 4.8c0 5.4 4.4 9.8 9.8 9.8 c2 0 3.8-0.6 5.4-1.6l-0.9-0.8C28.2 33.2 26.7 33.7 25 33.7z M16.9 19.7l6.4 0c0.5 0 0.6-0.6 0.2-0.8L17.7 15 C15.8 14.5 14.7 19.5 16.9 19.7z M16 17.1c0.2-1.2 0.9-2 1.5-1.9c0.6 0.1 1 1.2 0.8 2.3c-0.2 1.2-0.9 2-1.5 1.9 C16.2 19.4 15.8 18.3 16 17.1z"
        ></path>
    </svg>
    <svg class="circular">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="1.2" stroke-miterlimit="10"></circle>
    </svg>
</div>
