<div class="interview-settings">
    <form [formGroup]="interviewSettingsForm" (ngSubmit)="submitForm()">
        <div class="form-row">
            <div class="datepicker-style form-control half">
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Select an Interview Date</mat-label>
                    <mat-select formControlName="interview_date">
                        <mat-option *ngFor="let data of startDateTypeOptions" [value]="data.value">
                            {{ data.viewValue }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.interview_date.errors?.required">Field required!</mat-error>
                </mat-form-field> -->
                <mat-form-field class="example-full-width" (click)="picker.open()">
                    <input
                        [matDatepickerFilter]="disableWeekend"
                        [min]="today"
                        formControlName="interview_date"
                        matInput
                        [matDatepicker]="picker"
                        placeholder="Select an Interview Date"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="form-control quarter">
                <input
                    class="form-input"
                    formControlName="start_time"
                    [ngxTimepicker]="timepicker"
                    [format]="24"
                    min="08:00"
                    max="16:00"
                />
                <label
                    [ngClass]="{ hide: interviewSettingsForm.get('start_time').value }"
                    class="timepicker-label"
                    for=""
                    >Start Time</label
                >
                <ngx-material-timepicker #timepicker [minutesGap]="5"></ngx-material-timepicker>
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Start Time</mat-label>
                    <mat-select formControlName="start_time">
                        <mat-option *ngFor="let data of startTimeTypeOptions" [value]="data.value">
                            {{ data.viewValue }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.start_time.errors?.required">Field required!</mat-error>
                </mat-form-field> -->
            </div>

            <div class="form-control quarter">
                <app-auto-dropdown
                    [options]="durationTypeOptions"
                    [newStyle]="true"
                    [control]="interviewSettingsForm.get('duration')"
                    placeholder="Duration"
                ></app-auto-dropdown>
            </div>
        </div>
        <div class="form-row ">
            <div class="form-control half">
                <app-auto-dropdown
                    [options]="interviewTypeOptions"
                    [newStyle]="true"
                    [control]="interviewSettingsForm.get('type')"
                    placeholder="Interview Type"
                ></app-auto-dropdown>
            </div>

            <!-- Online providers -->
            <div class="form-control half" *ngIf="interviewSettingsForm.get('type').value === 'online'">
                <app-auto-dropdown
                    *ngIf="providerTypeOptions && !contentLoading"
                    [options]="providerTypeOptions"
                    [newStyle]="true"
                    [control]="interviewSettingsForm.get('provider')"
                    placeholder="Online Meeting Provider"
                ></app-auto-dropdown>
            </div>

            <!-- Locations -->
            <div class="form-control half" *ngIf="interviewSettingsForm.get('type').value === 'person'">
                <app-auto-dropdown
                    *ngIf="tenantLocationOptions && !contentLoading"
                    [options]="tenantLocationOptions"
                    [newStyle]="true"
                    [control]="interviewSettingsForm.get('location')"
                    placeholder="Location"
                ></app-auto-dropdown>
                <!-- <app-ac-chips-material
                    *ngIf="tenantLocationOptions.length"
                    formControlName="location"
                    [suggestions]="tenantLocationOptions"
                    [setSuggestions]="setSuggestions"
                    title="Location"
                    key="location"
                    [ngClass]="{
                        invalid:
                            !interviewSettingsForm.controls.location.valid &&
                            interviewSettingsForm.controls.location.touched,
                        fullwidth: true
                    }"
                ></app-ac-chips-material> -->
            </div>
        </div>
        <div class="form-row">
            <div class="form-control">
                <app-auto-dropdown
                    *ngIf="scoreCardsTypeOptions && scoreCardsTypeOptions.length"
                    [options]="scoreCardsTypeOptions"
                    [newStyle]="true"
                    [control]="interviewSettingsForm.get('scorecard_id')"
                    [disabled]="editingDisabled"
                    placeholder="Scorecard"
                ></app-auto-dropdown>
            </div>
        </div>
    </form>
    <div class="scheduler-table">
        <h4>ATTENDEES</h4>
        <div class="attendees-col">
            <div class="attendee" *ngFor="let attendee of attendees">
                <div class="interview-checkbox" (click)="onChangeType($event, attendee)" [ngClass]="attendee.type">
                    <span class="pi pi-check"></span>
                </div>
                {{ attendee.name }}
                <ng-container *ngIf="attendee.text">
                    {{ '(' + attendee.text + ')' }}
                </ng-container>
            </div>
            <!-- <ng-container [formGroup]="attendeeForm">
                <input
                    type="text"
                    formControlName="name"
                    placeholder="Enter a name or email address"
                    class="form-input"
                />
            </ng-container> -->
        </div>
        <div class="schedule-col">
            <div class="schedule-header">
                <div class="day" *ngFor="let day of days">
                    {{ day.label }}
                    <div class="wrap">
                        <div class="time" *ngFor="let item of day.time">
                            {{ item.label }}
                            <div
                                [ngStyle]="{
                                    'height.px': attendees?.length * 40 - 6,
                                    width: (interviewSettingsForm.get('duration').value / 60) * 100 + '%',
                                    left: getLeftPosition()
                                }"
                                *ngIf="item.hover"
                                class="hover-line"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="schedule-holder" *ngFor="let attendee of attendees" [ngClass]="{ unable: attendee.unable }">
                <div class="day" *ngFor="let day of attendee.days">
                    <div class="time" *ngFor="let item of day.time">
                        <ng-container *ngFor="let timeline of item?.timelines">
                            <div
                                class="attendee-timeline"
                                [ngStyle]="{
                                    width: timeline?.duration * 100 + '%',
                                    left: (timeline?.start_time / 60) * 100 + '%'
                                }"
                                *ngIf="timeline?.status === 'busy'"
                            ></div>
                        </ng-container>
                        <div class="inactive"></div>
                        <!-- <div class="unchecked-label"></div> -->
                    </div>
                </div>
            </div>
            <!-- <div class="schedule-holder unable">
                <div class="day" *ngFor="let day of days">
                    <div class="time" *ngFor="let item of day.time">
                        <div class="inactive"></div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="footer-scheduler">
        <!-- <button type="button" class="btn btn-add" (click)="onAddAttendee()">Add Attendee</button> -->
        <p class="mla">(GMT+02:00) Johannesburg, - SAST</p>
    </div>
    <div class="flex-row">
        <button type="button" class="btn-primary btn mla" (click)="onSaveInterview()">Schedule Interview</button>
    </div>
</div>

<app-loader *ngIf="contentLoading"></app-loader>
