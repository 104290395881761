import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { User } from '../../../../../core/models/user';
import { CandidateService } from './../../../../../core/services/candidate.service';
import { UserService } from './../../../../../core/services/user.service';
import { UtilitiesService } from './../../../../../core/services/utilities.service';
import * as fromStore from './../../../../../store';
import * as fromSelectors from './../../../../../store/selectors';

@Component({
    selector: 'app-candidate-item-feedback',
    templateUrl: './candidate-item-feedback.component.html',
    styleUrls: ['./candidate-item-feedback.component.scss']
})
export class CandidateItemFeedbackComponent implements OnInit {
    @Input() jobId;
    @Input() job;
    @Input() candidateId;
    @Input() feedback;
    @Input() currentRating;
    @Output() public feedbackUpdate = new EventEmitter<any>();
    positionSpecificCategories: any[] = [];
    user: User;
    users: User[];
    contentLoading = false;
    view = 'default';
    jobOwner = false;
    initialStars = Array(5).fill({ active: false });

    constructor(
        private candidateService: CandidateService,
        private userService: UserService,
        private fb: FormBuilder,
        private store: Store<fromStore.State>,
        private utilities: UtilitiesService
    ) {
        // Get users
        this.contentLoading = true;
        this.userService.getUsers().subscribe(
            (users: User[]) => {
                this.users = users;
                this.contentLoading = false;
            },
            (err) => {
                this.contentLoading = false;
            }
        );
    }

    ngOnInit() {
        // Get user
        this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
            this.user = user;
            if (this.job.owner === this.user.id) {
                this.jobOwner = true;
            }
        });
    }

    onSaveRating(value) {
        const data = {
            comments: null,
            created: new Date().getTime(),
            rating: value,
            position_rating: this.positionSpecificCategories
                .slice(0)
                .filter((cat) => cat.value)
                .map((cat) => ({ id: cat.id, value: cat.value }))
        };
        this.contentLoading = true;
        this.candidateService.updateFeedback(this.jobId, this.candidateId, data).subscribe(
            (response: any) => {
                this.contentLoading = false;
                if (response.feedback) {
                    this.feedback = response.feedback;
                    this.feedbackUpdate.next(response.feedback);
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }
}
