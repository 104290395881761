<ng-container *ngIf="this.interview?.scorecard && this.interview?.scorecard?.overall_rating">
    <div class="scorecard-description">
        <h3>Overall Rating</h3>
        <p>What is your overall recommendation for this candidate</p>
    </div>

    <div class="scorecard-votes">
        <button
            class="scorecard-vote good"
            [ngClass]="{ active: currentUserOverallVote?.vote === 5 }"
            (click)="setOverallRating(5)"
        >
            <svg class="icon-wrap" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.981 10.738l-.01.049-.006.029-.995 4.982-.002.008-.001.003c-.142.68-.745 1.191-1.467 1.191h-5.086C9.509 17 8.64 16.64 8 16H7V9.5h.305c.736 0 1.413-.404 1.762-1.053L10 5.364V4h.523c1 0 1.727.874 1.727 2 0 .846-.5 3-.5 3h4.75c.828 0 1.5.672 1.5 1.5 0 .08-.011.156-.023.233l.004.005z"
                />
            </svg>
            <span>Strong Hire</span>
        </button>

        <button
            class="scorecard-vote good"
            [ngClass]="{ active: currentUserOverallVote?.vote === 4 }"
            (click)="setOverallRating(4)"
        >
            <svg class="icon-wrap" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.981 10.738l-.01.049-.006.029-.995 4.982-.002.008-.001.003c-.142.68-.745 1.191-1.467 1.191h-5.086C9.509 17 8.64 16.64 8 16H7V9.5h.305c.736 0 1.413-.404 1.762-1.053L10 5.364V4h.523c1 0 1.727.874 1.727 2 0 .846-.5 3-.5 3h4.75c.828 0 1.5.672 1.5 1.5 0 .08-.011.156-.023.233l.004.005z"
                />
            </svg>
            <span>Hire</span>
        </button>

        <button
            class="scorecard-vote bad"
            [ngClass]="{ active: currentUserOverallVote?.vote === 2 }"
            (click)="setOverallRating(2)"
        >
            <svg class="icon-wrap" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.981 10.738l-.01.049-.006.029-.995 4.982-.002.008-.001.003c-.142.68-.745 1.191-1.467 1.191h-5.086C9.509 17 8.64 16.64 8 16H7V9.5h.305c.736 0 1.413-.404 1.762-1.053L10 5.364V4h.523c1 0 1.727.874 1.727 2 0 .846-.5 3-.5 3h4.75c.828 0 1.5.672 1.5 1.5 0 .08-.011.156-.023.233l.004.005z"
                />
            </svg>
            <span>No Hire</span>
        </button>

        <button
            class="scorecard-vote bad"
            [ngClass]="{ active: currentUserOverallVote?.vote === 1 }"
            (click)="setOverallRating(1)"
        >
            <svg class="icon-wrap" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.981 10.738l-.01.049-.006.029-.995 4.982-.002.008-.001.003c-.142.68-.745 1.191-1.467 1.191h-5.086C9.509 17 8.64 16.64 8 16H7V9.5h.305c.736 0 1.413-.404 1.762-1.053L10 5.364V4h.523c1 0 1.727.874 1.727 2 0 .846-.5 3-.5 3h4.75c.828 0 1.5.672 1.5 1.5 0 .08-.011.156-.023.233l.004.005z"
                />
            </svg>
            <span>Strong No Hire</span>
        </button>
    </div>

    <div class="overall-form">
        <div class="form-block">
            <textarea [(ngModel)]="overallComment" placeholder="Add an overall comment"></textarea>
        </div>
    </div>
</ng-container>
<form class="overall-form" [formGroup]="criteriaForm">
    <div class="form-block skills-block" *ngFor="let formGroup of criteriaForm.controls; index as i">
        <div [formGroupName]="i">
            <h3 class="skills-block__title">{{ formGroup['controls']['name']['value'] }}</h3>
            <p>{{ formGroup['controls']['description']['value'] }}</p>
            <app-overall-rating
                (setVote)="setCriteriaVote($event, criteriaForm.controls[i])"
                [item]="{ rating: formGroup['controls']['vote']['value'] }"
            ></app-overall-rating>
            <textarea
                *ngIf="this.interview.scorecard && this.interview.scorecard.allow_comments"
                formControlName="comment"
                placeholder="Note"
            ></textarea>
        </div>
    </div>
</form>
