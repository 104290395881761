<div class="overlay" *ngIf="showEmployeeModal" (click)="closeEmployeeModal()"></div>

<div
    *ngIf="showEmployeeModal && employeeData"
    class="candidate-sidebar-block"
    [ngClass]="{ active: showEmployeeModal }"
>
    <div class="controls">
        <button class="close close-modal-btn" (click)="closeEmployeeModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
        <button class="prev" (click)="changeEmployee('prev')" [disabled]="selectedEmployeeIdx <= 0">
            <img src="/assets/images/icons/icons-8-left.svg" />
        </button>
        <button
            class="next"
            (click)="changeEmployee('next')"
            [disabled]="selectedEmployeeIdx >= employeeDataList.length - 1"
        >
            <img src="/assets/images/icons/icons-8-left.svg" />
        </button>
    </div>
    <app-employee-item [employeeId]="employeeData.employeeId" [employeeData]="employeeData.item"> </app-employee-item>
</div>
