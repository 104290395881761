import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EmsiService } from './../../../../../core/services/emsi.service';

@Component({
    selector: 'app-job-requirements-modal',
    templateUrl: './job-requirements-modal.component.html',
    styleUrls: ['./job-requirements-modal.component.scss']
})
export class JobRequirementsModalComponent implements OnInit {
    @Input() title: string;
    @Input() skills: string[];
    @Input() additionalSkills: string[];
    showModal: boolean = true;
    @Output() closeModal = new EventEmitter<any>();
    contentLoading = false;
    suggestions: any[] = [];

    constructor(private fb: FormBuilder, private emsiService: EmsiService) {}

    ngOnInit(): void {
        // console.log('on init job requirements modal', this.title, this.skills, this.additionalSkills);
        this.contentLoading = true;
        this.emsiService
            .responsibilities(this.title, this.skills.join(', '), this.additionalSkills.join(','))
            .subscribe(
                (suggestions) => {
                    this.suggestions = suggestions.map((s) => ({ name: s, selected: false }));
                    // console.log('Requirements suggestions:', this.suggestions);
                    this.contentLoading = false;
                },
                (errorResponse) => {
                    this.contentLoading = false;
                    console.error(errorResponse.error);
                }
            );
    }

    onHideModal() {
        this.closeModal.emit(null);
    }

    async onSubmit() {
        try {
            const requirements = this.suggestions.filter((s) => s.selected).map((s) => s.name);
            this.closeModal.emit(requirements);
        } catch (error) {
            console.error(error);
            alert('Error!');
            this.contentLoading = false;
            this.closeModal.emit(null);
        }
    }

    onShowMore() {
        this.contentLoading = true;
        this.emsiService
            .responsibilities(this.title, this.skills.join(', '), this.additionalSkills.join(','))
            .subscribe(
                (suggestions) => {
                    this.suggestions = this.suggestions.filter((s) => s.selected);
                    suggestions.forEach((s) => {
                        if (!this.suggestions.find((suggestion) => suggestion.name === s)) {
                            this.suggestions.push({ name: s, selected: false });
                        }
                    });
                    // console.log('Requirements suggestions:', this.suggestions);
                    this.contentLoading = false;
                },
                (errorResponse) => {
                    this.contentLoading = false;
                    console.error(errorResponse.error);
                }
            );
    }

    onItemSelected() {
        // console.log('Suggestions seletion changed:', this.suggestions);
    }
}
