import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as JobsCategoriesAction from '../actions/categories.action';
import { TenantService } from './../../../../../../core/services/tenant.service';
import { getJobsCategoriesState, JobsState } from './../reducers';

@Injectable()
export class JobsCategoriesEffect {
    constructor(private actions$: Actions, private store: Store<JobsState>, private tenantService: TenantService) {}

    @Effect() LoadJobsCategories$: Observable<Action> = this.actions$.pipe(
        ofType(JobsCategoriesAction.LOAD_JOBS_CATEGORIES),
        switchMap(() => {
            this.tenantService.init();
            return this.tenantService.categories().pipe(
                map((categories: any) => new JobsCategoriesAction.LoadJobsCategoriesSuccess(categories)),
                catchError((error) => of(new JobsCategoriesAction.LoadJobsCategoriesFail(error)))
            );
        })
    );

    @Effect() AddJobsCategories$: Observable<Action> = this.actions$.pipe(
        ofType(JobsCategoriesAction.ADD_JOBS_CATEGORIES),
        withLatestFrom(this.store.pipe(select(getJobsCategoriesState))),
        map(([action, categories]) => categories),
        map((categories) => new JobsCategoriesAction.UpdateJobsCategories(categories))
    );

    @Effect() UpdateJobsCategories$: Observable<Action> = this.actions$.pipe(
        ofType(JobsCategoriesAction.UPDATE_JOBS_CATEGORIES),
        map((action: JobsCategoriesAction.UpdateJobsCategories) => action.payload),
        switchMap((categories) => {
            return this.tenantService.updateCategories(categories).pipe(
                map((categories: any) => new JobsCategoriesAction.LoadJobsCategoriesSuccess(categories)),
                catchError((error) => of(new JobsCategoriesAction.LoadJobsCategoriesFail(error)))
            );
        })
    );
}
