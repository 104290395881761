import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { SdkJob } from './../models/job';
import { catchError, map, take } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilitiesService {
    tenantId = 'undefined';
    constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) {}

    readFile(file): Promise<{ name: string; size: number; mimetype: string; data: string }> {
        return new Promise((resolve, reject) => {
            // console.log(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const result: any = reader.result;
                resolve({
                    name: file.name,
                    size: file.size,
                    mimetype: file.type,
                    data: result.split(',')[1]
                });
            };
        });
    }

    readCsvFile(file): Promise<{ name: string; size: number; mimetype: string; data: string }> {
        return new Promise((resolve, reject) => {
            // console.log(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const result: any = reader.result;
                resolve({
                    name: file.name,
                    size: file.size,
                    mimetype: file.type,
                    data: result
                });
            };
        });
    }

    readAndResizeImage(file, maxWidth, maxHeight) {
        return new Promise((resolve, reject) => {
            const w: any = window;
            const d: any = document;
            const image = new w.Image();

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                image.src = reader.result;
            };
            image.onload = () => {
                let tempW = image.width;
                let tempH = image.height;
                if (tempW > tempH) {
                    if (tempW > maxWidth) {
                        tempH *= maxWidth / tempW;
                        tempW = maxWidth;
                    }
                } else {
                    if (tempH > maxHeight) {
                        tempW *= maxHeight / tempH;
                        tempH = maxHeight;
                    }
                }
                const canvas = d.createElement('canvas');
                canvas.width = tempW;
                canvas.height = tempH;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, tempW, tempH);
                const dataURL = canvas.toDataURL('image/png');
                resolve({ name: file.name, size: file.size, mimetype: file.type, data: dataURL });
            };
        });
    }

    generateUID(length = 5) {
        let pool1: any = 'ABCDEFGHIJKLMNOPQRSTUVQWXYZ';
        let pool2: any = '123456789ABCDEFGHIJKLMNOPQRSTUVQWXYZ';
        let shuffled = '';
        let charIndex = 0;
        pool1 = pool1.split('');
        pool2 = pool2.split('');
        const firstLetterIndex = Math.floor(pool1.length * Math.random());
        while (pool2.length > 0) {
            charIndex = Math.floor(pool2.length * Math.random());
            shuffled += pool2[charIndex];
            pool2.splice(charIndex, 1);
        }
        return pool1[firstLetterIndex] + shuffled.substring(0, length - 1);
    }

    setTenant(tenantId: string) {
        this.tenantId = tenantId;
    }

    getTenant() {
        if (this.tenantId === 'undefined') {
            const urlPart = window.location.pathname.split('/tenant/')[1];
            if (urlPart) {
                this.tenantId = urlPart.split('/')[0];
            }
        }
        return this.tenantId;
    }

    // getTenant() {
    //     const tenant = window.location.pathname.split('/')[2];
    //     return tenant || 'undefined';
    // }

    getHireBaseUrl() {
        return `/tenant/${this.getTenant()}/hire`;
    }

    isLocalDevelopment() {
        const url = window.location.hostname;
        return url.indexOf('hire.local') !== -1;
    }

    getCountries() {
        return this.http.get<any[]>(`${environment.apiUrl}/countries`).pipe(
            map((countries) => {
                return countries.sort((a: any, b: any) => {
                    const labelA = a.name.toUpperCase();
                    const labelB = b.name.toUpperCase();
                    return labelA.localeCompare(labelB);
                });
            })
        );
    }

    /* tslint:disable */
    isEqual(obj1, obj2) {
        var i,
            l,
            leftChain = [],
            rightChain = [];

        function compare2Objects(x, y) {
            var p;

            // remember that NaN === NaN returns false
            // and isNaN(undefined) returns true
            if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
                return true;
            }

            // Compare primitives and functions.
            // Check if both arguments link to the same object.
            // Especially useful on the step where we compare prototypes
            if (x === y) {
                return true;
            }

            // Works in case when functions are created in constructor.
            // Comparing dates is a common scenario. Another built-ins?
            // We can even handle functions passed across iframes
            if (
                (typeof x === 'function' && typeof y === 'function') ||
                (x instanceof Date && y instanceof Date) ||
                (x instanceof RegExp && y instanceof RegExp) ||
                (x instanceof String && y instanceof String) ||
                (x instanceof Number && y instanceof Number)
            ) {
                return x.toString() === y.toString();
            }

            // At last checking prototypes as good as we can
            if (!(x instanceof Object && y instanceof Object)) {
                return false;
            }

            if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
                return false;
            }

            if (x.constructor !== y.constructor) {
                return false;
            }

            if (x.prototype !== y.prototype) {
                return false;
            }

            // Check for infinitive linking loops
            if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
                return false;
            }

            // Quick checking of one object being a subset of another.
            // todo: cache the structure of arguments[0] for performance
            for (p in y) {
                if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                    return false;
                } else if (typeof y[p] !== typeof x[p]) {
                    return false;
                }
            }

            for (p in x) {
                if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                    return false;
                } else if (typeof y[p] !== typeof x[p]) {
                    return false;
                }

                switch (typeof x[p]) {
                    case 'object':
                    case 'function':
                        leftChain.push(x);
                        rightChain.push(y);

                        if (!compare2Objects(x[p], y[p])) {
                            return false;
                        }

                        leftChain.pop();
                        rightChain.pop();
                        break;

                    default:
                        if (x[p] !== y[p]) {
                            return false;
                        }
                        break;
                }
            }

            return true;
        }

        if (!compare2Objects(obj1, obj2)) {
            return false;
        }

        return true;
    }
    /* tslint:enable */

    fromNow(date) {
        const today = moment()
            .utc()
            .startOf('day')
            .valueOf();
        const timeinmilisec = today - date;
        const days = Math.floor(timeinmilisec / (1000 * 60 * 60 * 24));
        let value = '';
        if (days === -1) {
            value = 'Today';
        } else if (days === 0) {
            value = 'Yesterday';
        } else if (days > 0) {
            value = days + 1 + 'd ago';
        }
        return value;
    }

    fromNowExt(date) {
        let today =
            moment()
                .utc()
                .startOf('day')
                .valueOf() / 1000;
        let timeinmilisec = today - date;
        let days = Math.floor(timeinmilisec / (60 * 60 * 24));
        let value = '';
        if (days === -1) {
            value = 'Today';
        } else if (days === 0) {
            value = 'Yesterday';
        } else if (days > 0) {
            if (days > 729) {
                value = Math.floor(days / 365) + ' years ago';
            } else if (days >= 365 && days < 729) {
                value = '1 year ago';
            } else if (days > 60 && days < 365) {
                value = Math.floor(days / 30.41) + ' months ago';
            } else if (days === 30) {
                value = '1 month ago';
            } else if (days > 6 && days < 14) {
                value = '1 week ago';
            } else if (days >= 14) {
                value = Math.floor(days / 7) + ' weeks ago';
            } else {
                value = days + 1 + ' days ago';
            }
            // console.log(value, days);
        }
        return value;
    }

    omit(obj, properties) {
        return Object.entries(obj)
            .filter(([key]) => !properties.includes(key))
            .reduce((newObj, [key, val]) => ({ ...newObj, [key]: val }), {});
    }

    isBottomOfPage() {
        return window.innerHeight + Math.ceil(window.pageYOffset + 1) >= document.body.offsetHeight;
    }

    arrayMove(arr, old_index, new_index) {
        while (old_index < 0) {
            old_index += arr.length;
        }
        while (new_index < 0) {
            new_index += arr.length;
        }
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }

    getRadarChartData() {
        return {
            labels: ['Extroversion', 'Agreeableness', 'Conscientiousness', 'Neuroticism', 'Openness'],
            datasets: [
                {
                    label: 'Second Dataset',
                    data: [0, 0, 0, 0, 0],
                    fill: true,
                    backgroundColor: 'rgba(76, 217, 100, 0.3)',
                    borderColor: '#4cd964',
                    borderWidth: 1,
                    pointRadius: 0,
                    pointHoverRadius: 0
                },
                {
                    label: 'Avarage Dataset',
                    data: [72, 72, 72, 72, 72],
                    fill: true,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    borderColor: '#e5e5ea',
                    borderWidth: 1,
                    pointRadius: 0,
                    pointHoverRadius: 0
                }
            ]
        };
    }

    getRadarChartOptions() {
        return {
            scale: {
                pointLabels: { fontSize: 15, fontColor: '#000000' },
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 120,
                    stepSize: 40,
                    fontColor: '#525f7f',
                    fontStyle: 'bold',
                    padding: 100,
                    backdropColor: 'transparent',
                    userCallback: (label, index, labels) => {
                        if (index === 1) {
                            return 'LOW';
                        } else if (index === 2) {
                            return 'NEUTRAL';
                        } else if (index === 3) {
                            return 'HIGH';
                        } else {
                            return '';
                        }
                    }
                }
            },
            legend: { display: false, labels: { fontColor: 'rgb(255, 99, 132)' } }
        };
    }

    filterDropDown(keyword: string, options: SelectItem[]): SelectItem[] {
        keyword = keyword.toLowerCase();
        return options.filter((item) => item.label.toLowerCase().includes(keyword));
    }

    getResumeThreshold(job: SdkJob) {
        let resumeThreshold = 0;
        resumeThreshold = job.resume_matching_threshold || 0;
        if (!resumeThreshold && job && job.stages && job.stages.find((s) => s.id === 'applied')) {
            const appliedStage = job.stages.find((s) => s.id === 'applied');
            if (appliedStage.resume_matching_threshold) {
                resumeThreshold = appliedStage.resume_matching_threshold;
            }
        }

        return resumeThreshold;
    }

    convertMonthsToYears(months: number) {
        if (months === 1) {
            return `${months} month`;
        } else if (months < 12) {
            return `${months} months`;
        } else {
            const years = Math.floor(months / 12);
            const remains = (months % 12) / 12;
            if (remains < 0.25) {
                return years === 1 ? `1 year` : `${years} years`;
            } else if (remains >= 0.25 && remains < 0.75) {
                return `${years}.5 years`;
            } else {
                return `${years + 1} years`;
            }
        }
    }

    isSafari() {
        return (
            navigator &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('Safari') != -1 &&
            navigator.userAgent.indexOf('Chrome') == -1
        );
    }

    convertSize(size: number) {
        const kb = size / 1000;
        const mb = size / 1000 / 1000;
        return mb > 1 ? `${mb.toFixed(2)} MB` : `${Math.round(kb)} KB`;
    }

    getExtension(filename) {
        const allowedExtensions = ['pdf'];
        const newFilename = filename.toLowerCase().slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
        if (allowedExtensions.indexOf(newFilename) !== -1) {
            return newFilename;
        } else {
            return 'docx';
        }
    }

    setTitle(title = '') {
        let str = title.length ? `&team - ${title}` : '&team';
        // console.log('setTitle', str);
        this.titleService.setTitle(str);
    }

    replaceData() {
        return {
            '[[company_name]]': 'Company Name',
            '[[candidate.first_name]]': 'Candidate First Name',
            '[[candidate.last_name]]': 'Candidate Last Name',
            '[[employee.first_name]]': 'Employee First Name',
            '[[employee.last_name]]': 'Employee Last Name',
            '[[manager.first_name]]': 'Manager First Name',
            '[[manager.mobile]]': 'Manager Mobile'
        };
    }

    replaceAllWith(text, data) {
        if (text && text.length) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    text = text.split(key).join(data[key]);
                }
            }
            return text;
        } else {
            return '';
        }
    }

    darkerColor(color, percent): string {
        /* tslint:disable */
        var num = parseInt(color.replace('#', ''), 16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) - amt,
            B = ((num >> 8) & 0x00ff) - amt,
            G = (num & 0x0000ff) - amt;
        return (
            '#' +
            (
                0x1000000 +
                (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
                (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
                (G < 255 ? (G < 1 ? 0 : G) : 255)
            )
                .toString(16)
                .slice(1)
        );
        /* tslint:enable */
    }

    transformRoleToText(role) {
        switch (role) {
            case 'manager':
                return 'Manager';
            case 'recruiter':
                return 'Recruiter';
            case 'hr_business_partner':
                return 'HR Business Partner';
            case 'onboarding_business_partner':
                return 'Onboarding Business Partner';
            case 'hr_admin':
                return 'HR Admin';
            case 'payroll_business_partner':
                return 'Payroll Business Partner';
            default:
                return null;
        }
    }

    getUserRole(role = null) {
        switch (role) {
            case 'employee':
                return 'Employee';
            case 'account_owner':
                return 'Owner';
            case 'admin':
                return 'Administrator';
            case 'recruiter':
                return 'Recruiter';
            case 'hr_business_partner':
                return 'HR Business Partner';
            case 'onboarding_business_partner':
                return 'Onboarding Business Partner';
            case 'hr_admin':
                return 'HR Admin';
            case 'payroll_business_partner':
                return 'Payroll Business Partner';
            default:
                return 'Employee';
        }
    }

    // convertToPDF(candidateId, link, documentId) {
    //     return this.http.post(
    //         `${this.apiURL}/tenants/${this.getTenant()}/candidates/${candidateId}/documents/convert-docx-pdf`,
    //         {
    //             link,
    //             documentId
    //         }
    //     );
    // }

    // uploadImage(data: any) {
    //     return this.http.post(`${this.apiURL}/upload-image`, data).pipe(catchError((error: any) => throwError(error)));
    // }
}
