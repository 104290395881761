import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilitiesService } from './utilities.service';

@Injectable({
    providedIn: 'root'
})
export class InterviewsService {
    apiURL: string = environment.apiUrl;
    saveInterviewScorecard = new Subject<any>();
    saveInterviewScorecardDraft = new Subject<any>();
    updatedCandidateInterview = new Subject<any>();

    constructor(private http: HttpClient, private utilities: UtilitiesService) {}

    addScorecard(data) {
        return this.http
            .post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/scorecards`, data)
            .pipe(catchError((error: any) => throwError(error)));
    }

    updateScorecard(data, id) {
        return this.http
            .put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/scorecards/${id}`, data)
            .pipe(catchError((error: any) => throwError(error)));
    }

    getScorecards() {
        return this.http
            .get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/scorecards`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    deleteScorecard(id) {
        return this.http.delete(`${this.apiURL}/tenants/${this.utilities.getTenant()}/scorecards/${id}`);
    }

    getCandidateInterview(jobId, candidateId) {
        return this.http
            .get(
                `${
                    this.apiURL
                }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/interviews`
            )
            .pipe(catchError((error: any) => throwError(error)));
    }

    createCandidateInterview(jobId, candidateId, data) {
        return this.http
            .post(
                `${
                    this.apiURL
                }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/interviews`,
                data
            )
            .pipe(catchError((error: any) => throwError(error)));
    }

    updateCandidateInterview(jobId, candidateId, interviewId, data) {
        return this.http
            .put(
                `${
                    this.apiURL
                }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/interviews/${interviewId}`,
                data
            )
            .pipe(catchError((error: any) => throwError(error)));
    }

    updateCandidateInterviewScores(jobId, candidateId, data, interviewId) {
        return this.http
            .put(
                `${
                    this.apiURL
                }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/interviews/${interviewId}/scores`,
                data
            )
            .pipe(catchError((error: any) => throwError(error)));
    }

    deleteCandidateInterview(jobId, candidateId, interviewId) {
        return this.http
            .delete(
                `${
                    this.apiURL
                }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/interviews/${interviewId}`
            )
            .pipe(catchError((error: any) => throwError(error)))
            .toPromise();
    }

    calculateRating(interview, user) {
        if (!interview.criteria_votes) {
            return false;
        }
        const sum = [];
        let sumVotes = 0;
        for (let userId in interview.criteria_votes) {
            if (userId && interview.criteria_votes[userId]) {
                if (userId === user.id) {
                    interview.criteria_votes[userId].forEach((a) => {
                        sum.push(a.vote);
                        sumVotes += a.vote;
                    });
                    interview.allowShowScore = interview.criteria_votes[userId].every((v) => v.vote);
                }
            }
        }
        interview.averageRating = (sumVotes / sum.length).toFixed(1);
        if (interview.averageRating === 5) {
            interview.ratingStatus = 'Excellent';
        } else if (interview.averageRating >= 4) {
            interview.ratingStatus = 'Very Good';
        } else if (interview.averageRating >= 3) {
            interview.ratingStatus = 'Good';
        } else if (interview.averageRating >= 2) {
            interview.ratingStatus = 'Fair';
        } else {
            interview.ratingStatus = 'Poor';
        }
    }
}
