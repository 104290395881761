<form [formGroup]="hireForm">
    <div class="form-content-section">
        <div class="form-content-holder">
            <h3 class="mb30 mt30">Job Details</h3>
            <p class="mb20">
                Enter your job details top the best of your ability. The better the information that you provide during
                this process the better we will be able to assist you.
            </p>
            <div class="flex-row mb30">
                <div class="hiringType mr30" [class.active]="hiringType === 'new'" (click)="onChangeHiringType('new')">
                    I’m hiring for a new role?
                </div>
                <div class="hiringType" [class.active]="hiringType === 'exist'" (click)="onChangeHiringType('exist')">
                    I’m hiring for an existing role?
                </div>
            </div>
            <div class="form-block">
                <mat-form-field appearance="outline">
                    <mat-label>Job title</mat-label>
                    <input matInput formControlName="title" />
                </mat-form-field>
                <div class="error-message" *ngIf="!hireForm.controls.title.valid && hireForm.controls.title.touched">
                    Job title is required
                </div>
            </div>
            <div class="form-block">
                <div class="input-block">
                    <div class="input-block right-option-block">
                        <app-ac-chips-material
                            *ngIf="tenantLocationOptions.length"
                            formControlName="location"
                            [suggestions]="tenantLocationOptions"
                            [setSuggestions]="setSuggestions"
                            title="Location"
                            key="location"
                            (onValueChange)="onLocationAdded($event)"
                            (onAddNewLocation)="onAddNewLocation($event)"
                            [ngClass]="{
                                invalid: !hireForm.controls.location.valid && hireForm.controls.location.touched
                            }"
                        ></app-ac-chips-material>
                        <div class="right-option">
                            <p-checkbox
                                label="Remote"
                                formControlName="is_remote"
                                binary="true"
                                inputId="is_remote"
                            ></p-checkbox>
                        </div>
                    </div>
                    <div
                        class="error-message"
                        *ngIf="!hireForm.get('location').valid && hireForm.get('location').touched"
                    >
                        Primary job location is required
                    </div>
                    <div class="input-block"></div>
                </div>
            </div>
            <div class="blocks-separator mt30 mb30"></div>
            <div class="form-block">
                <div class="form-block-columns">
                    <div class="form-block-col">
                        <div class="input-block">
                            <app-auto-dropdown
                                [options]="jobTypeOptions"
                                [newStyle]="true"
                                [control]="hireForm.controls.type"
                                [placeholder]="'Job Type'"
                                (onChange)="changeInForm(hireForm)"
                                [ngClass]="{
                                    invalid: !hireForm.controls.type.valid && hireForm.controls.type.touched
                                }"
                            ></app-auto-dropdown>
                        </div>
                        <div
                            class="error-message"
                            *ngIf="!hireForm.controls.type.valid && hireForm.controls.type.touched"
                        >
                            Job type is required
                        </div>
                    </div>
                    <div class="form-block-col-separator"></div>
                    <div class="form-block-col">
                        <div class="input-block">
                            <app-auto-dropdown
                                [options]="hiresOptions"
                                [newStyle]="true"
                                [control]="hireForm.controls.number_of_hires"
                                [placeholder]="'Positions Available'"
                                (onChange)="changeInForm(hireForm)"
                                [ngClass]="{
                                    invalid:
                                        !hireForm.controls.number_of_hires.valid &&
                                        hireForm.controls.number_of_hires.touched
                                }"
                            ></app-auto-dropdown>
                        </div>
                        <div
                            class="error-message"
                            *ngIf="
                                !hireForm.controls.number_of_hires.valid && hireForm.controls.number_of_hires.touched
                            "
                        >
                            Number of positions is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-block">
                <div class="form-block-columns">
                    <div class="form-block-col form-block-col-50">
                        <div class="input-block">
                            <mat-form-field appearance="outline" (click)="picker1.open()">
                                <mat-label>Ideal Start Date</mat-label>
                                <input
                                    formControlName="start_date"
                                    matInput
                                    [matDatepicker]="picker1"
                                    autocomplete="off"
                                />
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-block-col-separator"></div>
                    <div class="form-block-col">
                        <div class="input-block">
                            <app-auto-dropdown
                                [disabled]="disableContractDuration"
                                [options]="contractDuration"
                                [newStyle]="true"
                                [control]="hireForm.controls.contract_duration"
                                [placeholder]="'Contract Duration (if applicable)'"
                                (onChange)="changeInForm(hireForm)"
                                [ngClass]="{
                                    invalid:
                                        !hireForm.controls.contract_duration.valid &&
                                        hireForm.controls.contract_duration.touched &&
                                        (hireForm.controls.type.value === 'temporary' ||
                                            hireForm.controls.type.value === 'fixed_term' ||
                                            hireForm.controls.type.value === 'freelance')
                                }"
                            ></app-auto-dropdown>
                        </div>
                        <div
                            class="error-message"
                            *ngIf="
                                !hireForm.controls.contract_duration.valid &&
                                hireForm.controls.contract_duration.touched &&
                                (hireForm.controls.type.value === 'temporary' ||
                                    hireForm.controls.type.value === 'fixed_term' ||
                                    hireForm.controls.type.value === 'freelance')
                            "
                        >
                            Contract Duration is required
                        </div>
                    </div>
                </div>
            </div>
            <h3 class="mt60 mb30">Budget</h3>
            <div formGroupName="salary">
                <div class="form-block">
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="currencyOptions"
                                    [newStyle]="true"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [control]="hireForm.get('salary.currency')"
                                    [placeholder]="'Currency'"
                                    (onChange)="changeInForm(hireForm)"
                                    [ngClass]="{
                                        invalid:
                                            !hireForm.get('salary.currency').valid &&
                                            hireForm.get('salary.currency').touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    !hireForm.get('salary.currency').valid && hireForm.get('salary.currency').touched
                                "
                            >
                                Currency required
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="salaryOptions"
                                    [newStyle]="true"
                                    [control]="hireForm.get('salary.period')"
                                    [placeholder]="'Frequency'"
                                    (onChange)="changeInForm(hireForm)"
                                    [ngClass]="{
                                        invalid:
                                            !hireForm.get('salary.period').valid &&
                                            hireForm.get('salary.period').touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                            <div
                                class="error-message"
                                *ngIf="!hireForm.get('salary.period').valid && hireForm.get('salary.period').touched"
                            >
                                Frequency required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-block">
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label *ngIf="!singleSalary">From</mat-label>
                                        <mat-label *ngIf="singleSalary">Salary</mat-label>
                                        <input
                                            type="text"
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                precision: 0,
                                                align: 'left'
                                            }"
                                            currencyMask
                                            placeholder="0"
                                            formControlName="from"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                                <div
                                    class="error-message"
                                    *ngIf="!hireForm.get('salary.from').valid && hireForm.get('salary.from').touched"
                                >
                                    Salary required
                                </div>
                            </div>
                        </div>
                        <div class="form-block-col-separator" *ngIf="!singleSalary"></div>
                        <div class="form-block-col" *ngIf="!singleSalary">
                            <div class="input-block">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>To</mat-label>
                                        <input
                                            type="text"
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                precision: 0,
                                                align: 'left'
                                            }"
                                            currencyMask
                                            placeholder="0"
                                            formControlName="to"
                                            maxlength="20"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div
                                class="error-message"
                                *ngIf="
                                    hireForm.controls.salary.controls.to.errors?.WrongSalary &&
                                    hireForm.controls.salary.controls.to.touched
                                "
                            >
                                Cannot be less than "From"
                            </div>
                        </div>
                    </div>
                    <p class="form-tip danger" (click)="toggleSalaryField()" *ngIf="!singleSalary">
                        Use Single Salary
                    </p>
                    <p class="form-tip danger" (click)="toggleSalaryField()" *ngIf="singleSalary">
                        Use Salary Range
                    </p>
                </div>
            </div>
            <h3 class="mt60 mb0">Minimum Job Requirement</h3>
            <div class="form-block">
                <div class="form-block-columns">
                    <div class="form-block-col">
                        <p class="mt30 mb30">
                            Please tell us the minimum requirements of the role you wish to fill. Please be as specific
                            as possible around skills, previous experience and any required ceritifactions or education.
                        </p>
                    </div>
                </div>
                <div class="form-block-columns">
                    <div class="form-block-col">
                        <mat-form-field appearance="outline">
                            <textarea
                                matInput
                                formControlName="description"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="8"
                                resize="vertical"
                            ></textarea>
                        </mat-form-field>
                        <div
                            class="error-message"
                            *ngIf="!hireForm.controls.description.valid && hireForm.controls.description.touched"
                        >
                            Description is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="overlay" *ngIf="createNewLocation" (click)="closeNewLocationForm()"></div>
<app-new-location
    *ngIf="timeZones"
    [timeZones]="timeZones"
    [locations]="locations"
    action="create"
    [ngClass]="{ active: createNewLocation }"
    (close)="closeNewLocationForm()"
    (createLocation)="onCreateLocation($event)"
>
</app-new-location>
<app-loader *ngIf="contentLoading"></app-loader>
