<div class="inner">
    <div class="legend">
        <div class="item" *ngFor="let dataset of data.legendLabel">
            <div class="square" [ngStyle]="{ backgroundColor: dataset.backgroundColor }"></div>
            <div class="title">{{ dataset.label }}</div>
        </div>
    </div>
    <p-chart [id]="id" type="line" [data]="data" [options]="options" height="218px"></p-chart>
    <div *ngIf="id === 'candidates' && (data?.datasets)[0]?.data?.length === 1" class="not-enough-block">
        Not enough data
    </div>
</div>
