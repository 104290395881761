import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { environment } from './../../../../../../../environments/environment';
import { MsService } from './../../../../../../core/services/ms.service';

@Component({
    selector: 'app-interview-select-service',
    templateUrl: './interview-select-service.component.html',
    styleUrls: ['./interview-select-service.component.scss']
})
export class InterviewSelectServiceComponent implements OnInit, OnDestroy {
    @Output() selectService: EventEmitter<any> = new EventEmitter();
    nextEnabled = false;

    isMsalAuthenticated: Subscription;

    servicesList = [
        {
            id: 'google',
            icon: '/assets/images/services-icons/google-calendar.png',
            title: 'Google Calendar',
            enabled: false
        },
        {
            id: 'office',
            icon: '/assets/images/services-icons/office.png',
            title: 'Office 365',
            enabled: true
        },
        {
            id: 'exchange',
            icon: '/assets/images/services-icons/microsoft-exchange.png',
            title: 'Microsoft Exchange',
            enabled: false
        }
    ];

    activeService;

    constructor(private msalService: MsService) {}

    ngOnInit(): void {
        // this.msalService.logout();
    }

    selectScheduleService(service) {
        this.activeService = service;
        if (service === 'office') {
            console.log('Redirect URL:', environment.msalRedirectUrl);
            this.isMsalAuthenticated = this.msalService.$isAuthenticated.subscribe((isAuthenticated) => {
                console.log('MSAL authenticated status:', isAuthenticated);
                this.nextEnabled = isAuthenticated;
                console.log('Next enabled:', this.nextEnabled);
            });
            if (!this.msalService.getAuthenticated()) {
                console.log('===> msalService authenticate');
                this.msalService.authenticate();
            }
        } else {
            console.log('Some other service');
            this.nextEnabled = true;
        }
    }

    goToNextStep() {
        this.selectService.emit(this.activeService);
    }

    ngOnDestroy(): void {
        if (this.isMsalAuthenticated) {
            this.isMsalAuthenticated.unsubscribe();
        }
    }
}
