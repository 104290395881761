export class Questionnaire {
    id: string;
    title: string;
    questions: number;
    created: string;
    selected?: boolean;
    type?: string;
    sensitive?: boolean;
    has_job?: boolean;
}
