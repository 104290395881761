import { DecimalPipe } from '@angular/common';
import { Pipe } from '@angular/core';

@Pipe({
    name: 'hundredAmount'
})
export class CurrencyPromotePipe extends DecimalPipe {
    transform(value: string): any {
        if (value == undefined) {
            return '';
        }
        let n = parseInt(value);

        const rx = /(\d+)(\d{3})/;
        return String(n).replace(/^\d+/, (w) => {
            let res = w;
            while (rx.test(res)) {
                res = res.replace(rx, '$1,$2');
            }
            return res + '.00';
        });
    }
}
