import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SdkJob } from '@app/core/models';
import { JobService } from '@app/core/services';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import * as fromStore from '../../store';
import * as fromStoreSelectors from '../../store/selectors/jobs.selector';

@Component({
    selector: 'app-add-role-modal',
    templateUrl: './add-role-modal.component.html',
    styleUrls: ['./add-role-modal.component.scss']
})
export class AddRoleModalComponent implements OnInit, OnDestroy {
    @Input() candidate;
    @Output() close: EventEmitter<any> = new EventEmitter();
    contentLoading = false;
    subscriptions: Subscription = new Subscription();
    jobTypeOptions = [];
    jobControl: FormControl;
    constructor(private fb: FormBuilder, private store: Store<fromStore.JobsState>, private jobService: JobService) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.store.pipe(select(fromStoreSelectors.getAllJobs)).subscribe((jobs: SdkJob[]) => {
                jobs.filter((j) => {
                    let applied_job_ids = this.candidate.job_id || [];
                    let declined_job_ids = this.candidate.declinedJobs || [];
                    if (
                        (j.status === 'published' || j.status === 'private') &&
                        applied_job_ids.indexOf(j.id) === -1 &&
                        declined_job_ids.indexOf(j.id) === -1
                    ) {
                        return true;
                    } else if (
                        j.status === 'internal' &&
                        this.candidate.employee &&
                        applied_job_ids.indexOf(j.id) === -1 &&
                        declined_job_ids.indexOf(j.id) === -1
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }).forEach((j) => {
                    this.jobTypeOptions.push({
                        value: j.id,
                        label: j.title
                    });
                    this.jobTypeOptions.sort((a, b) => {
                        let optionA = a.label;
                        let optionB = b.label;
                        return optionA.localeCompare(optionB);
                    });
                });
                console.log('Candidate -', this.candidate, 'Jobs length -', this.jobTypeOptions.length);
            })
        );
        this.jobControl = this.fb.control('', Validators.required);
    }

    onHideModal() {
        this.close.emit();
    }

    onAddCandidate() {
        if (this.jobControl.valid) {
            this.contentLoading = true;
            this.jobService
                .addJob(this.jobControl.value, { emails: [this.candidate.email], source: 'email' })
                .pipe(first())
                .subscribe(
                    (response) => {
                        this.contentLoading = false;
                        this.onHideModal();
                        console.log(response);
                    },
                    (error) => console.error(error)
                );
        } else {
            this.jobControl.markAsTouched();
        }
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
}
