import { Injectable } from '@angular/core';
import { UtilitiesService } from '@app/core/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { User } from './../../core/models/user';

import * as fromServices from '../../core/services';
import * as countriesActions from './../actions/countries.action';

@Injectable()
export class CountriesEffects {
    constructor(private actions$: Actions, private utilities: UtilitiesService) {}
    @Effect() loadCountries$: Observable<Action> = this.actions$.pipe(
        ofType(countriesActions.LOAD_COUNTRIES),
        switchMap(() => {
            return this.utilities.getCountries().pipe(
                map((countries) => new countriesActions.LoadCountriesSuccess(countries)),
                catchError((error) => of(new countriesActions.LoadCountriesFail(error)))
            );
        })
    );
}
