<div class="overlay" *ngIf="showCandidateModal" (click)="closeCandidateModal()"></div>

<div
    *ngIf="showCandidateModal && candidateData"
    class="candidate-sidebar-block"
    [ngClass]="{ active: showCandidateModal }"
>
    <div class="controls change-candidates-controls">
        <button class="close" (click)="closeCandidateModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
        <button class="prev" (click)="changeCandidate('prev')" [disabled]="selectedCandidateIdx <= 0">
            <img src="/assets/images/icons/icons-8-left.svg" />
        </button>
        <button
            class="next"
            (click)="changeCandidate('next')"
            [disabled]="selectedCandidateIdx >= candidateDataList?.length - 1"
        >
            <img src="/assets/images/icons/icons-8-left.svg" />
        </button>
    </div>
    <app-candidate-item
        [jobId]="candidateData.jobId"
        [initialJob]="initialJob"
        [candidateId]="candidateData.candidateId"
        (closeModal)="closeModalFromCandidate()"
        (changeJobOnCandidate)="closeModalFromCandidate()"
        (changeRating)="changeCandidateRating($event)"
        (ownerUpdated)="changeCandidateOwner($event)"
    ></app-candidate-item>
</div>
