import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilitiesService } from '@app/core/services';
import { JobCategory } from './../../../core/models/cateogory';

@Component({
    selector: 'app-ac-chips',
    templateUrl: './ac-chips.component.html',
    styleUrls: ['./ac-chips.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AcChipsComponent),
            multi: true
        }
    ]
})
export class AcChipsComponent implements ControlValueAccessor, OnInit {
    _value: JobCategory[] = [];
    results: JobCategory[];
    @Input() suggestions: JobCategory[];
    @Output() public onValueChange = new EventEmitter<JobCategory[]>();
    @ViewChild('ac')
    ac: ElementRef;
    constructor(private utilities: UtilitiesService) {}

    search(event) {
        this.results = event.query.length
            ? this.suggestions.slice().filter((s) => s.name.toLowerCase().indexOf(event.query.toLowerCase()) !== -1)
            : this.suggestions.slice();
    }

    onKeyUp(event: KeyboardEvent) {
        let tokenInput = event.target as any;
        if (event.code === 'Enter' || event.code === 'Space') {
            if (tokenInput.value && tokenInput.value.length && tokenInput.value.trim().length) {
                const inputVal = tokenInput.value.trim().toLowerCase();

                if (!this._value.find((v) => v.name === inputVal)) {
                    //need to add new or existing
                    const existing = this.suggestions.find((s) => s.name === inputVal);
                    if (existing) {
                        this._value.push(existing);
                    } else {
                        this._value.push({
                            name: inputVal,
                            title: inputVal,
                            hidden: false,
                            id: this.utilities.generateUID(10).toLowerCase()
                        });
                    }
                    this.changeValue(this._value);
                }
                tokenInput.value = '';
                this.search({ query: 'somerandomstring' });
            } else {
                event.preventDefault();
                event.stopPropagation();
            }
        } else {
            tokenInput.value = tokenInput.value.replace(/[^\w]/gi, '');
        }
    }

    ngOnInit() {
        console.log('onInit', this.suggestions);
    }

    writeValue(value: any) {
        if (value !== undefined && Array.isArray(value)) {
            // console.log('VALUE PASSED TO COMPONENT:', value);
            const values = [];
            value.forEach((v) => {
                const item = this.suggestions.find((s) => s.id === v);
                if (item) {
                    values.push(item);
                }
            });
            this._value = values;
        }
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    triggerChange(event) {
        this.changeValue(this._value);
    }

    changeValue(val) {
        this.onValueChange.next(val);
        const modelValues = val.map((v) => v.id);
        // console.log(modelValues);
        this.propagateChange(modelValues);
    }
}
