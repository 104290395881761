import { SelectItem } from 'primeng';

export const jobTypeOptions = [
    { label: 'Permanent', value: 'permanent' },
    { label: 'Temporary', value: 'temporary' },
    { label: 'Internship', value: 'internship' },
    { label: 'Fixed Term', value: 'fixed_term' },
    { label: 'Freelance', value: 'freelance' }
];

export const gendersTypeOptions: SelectItem[] = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
];

export const ethnicityTypeOptions: SelectItem[] = [
    { value: 'African', label: 'African' },
    { value: 'Indian', label: 'Indian' },
    { value: 'Coloured', label: 'Coloured' },
    { value: 'White', label: 'White' },
    { value: 'Other', label: 'Other' }
];

export const datePickerFormat = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

export const employeeStatusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
];

export const userTypesOptions = [
    {
        label: 'Employee',
        value: 'employee'
    },
    {
        label: 'Owner',
        value: 'account_owner'
    },
    {
        label: 'Administrator',
        value: 'admin'
    },
    {
        label: 'Recruiter',
        value: 'recruiter'
    },
    {
        label: 'HR Business Partner',
        value: 'hr_business_partner'
    },
    {
        label: 'Onboarding Business Partner',
        value: 'onboarding_business_partner'
    },
    {
        label: 'HR Admin',
        value: 'hr_admin'
    },
    {
        label: 'Payroll Business Partner',
        value: 'payroll_business_partner'
    }
];

export const jobDepartmentOptions = [
    { value: 'Intelligent workplace', label: 'Intelligent Workplace' },
    { value: 'Intelligent Business Applications', label: 'Intelligent Business applications' },
    { value: 'Intelligent Security', label: 'Intelligent Security' },
    { value: 'Intelligent Infrastructure', label: 'Intelligent Infrastructure' },
    { value: 'Intelligent Customer Experience', label: 'Intelligent Customer Experience' },
    { value: 'geography', label: 'Geography' },
    { value: 'cross go-to-market', label: 'Cross Go-To-Market' },
    { value: 'mea business services', label: 'MEA Business Services' },
    { value: 'services', label: 'Services' },
    { value: 'human resources', label: 'Human Resources' },
    { value: 'finance', label: 'Finance' },
    { value: 'i&t', label: 'I&T' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'risk', label: 'Risk' },
    { value: 'compliance & legal', label: 'Compliance & Legal' },
    { value: 'other', label: 'Other' }
];

export const jobDesignationOptions = [{ label: 'Regional Sales Manager', value: 'regional sales manager' }];

export const jobBusinessUnitOptions = [
    { label: 'Intelligent Business Applications', value: 'Intelligent Business Applications' }
];

export const jobLevelOptions: SelectItem[] = [{ label: '1', value: '1' }];

export const jobShiftOptions: SelectItem[] = [
    { label: 'Day Shift', value: 'day shift' },
    { label: 'Night Shift', value: 'night shift' }
];

export const relationshipOptions = [
    {
        label: 'Manager',
        value: 'manager'
    },
    {
        label: 'HR Business Partner',
        value: 'hr_business_partner'
    },
    {
        label: 'Onboarding Business Partner',
        value: 'onboarding_business_partner'
    },
    {
        label: 'HR Admin',
        value: 'hr_admin'
    },
    {
        label: 'Payroll Business Partner',
        value: 'payroll_business_partner'
    }
];

export const bloodGroupOptions: SelectItem[] = [
    { value: 'O+', label: 'O+' },
    { value: 'O-', label: 'O-' },
    { value: 'A+', label: 'A+' },
    { value: 'A-', label: 'A-' },
    { value: 'B+', label: 'B+' },
    { value: 'B-', label: 'B-' },
    { value: 'AB+', label: 'AB+' },
    { value: 'AB-', label: 'AB-' }
];
export const maritalStatusOptions: SelectItem[] = [
    { value: 'single', label: 'Single' },
    { value: 'married', label: 'Married' },
    { value: 'cohabiting', label: 'Cohabiting' },
    { value: 'divorced', label: 'Divorced' },
    { value: 'widowed', label: 'Widowed' },
    { value: 'civil partnership', label: 'Civil Partnership' },
    { value: 'prefer not to say', label: 'Prefer Not To Say' }
];
export const currencyOptions: SelectItem[] = [
    { label: 'ZAR - South African Rand', value: 'ZAR' },
    { label: 'USD - US Dollar', value: 'USD' },
    { label: 'KES - Kenyan Shilling', value: 'KES' },
    { label: 'TZS - Tanzanian Shilling', value: 'TZS' },
    { label: 'UGX - Ugandan Shilling', value: 'UGX' },
    { label: 'NGN - Nigerian Naira', value: 'NGN' }
];
export const languagesOptions = [
    { name: 'Afar', id: 'Afar' },
    { name: 'Abkhazian', id: 'Abkhazian' },
    { name: 'Avestan', id: 'Avestan' },
    { name: 'Afrikaans', id: 'Afrikaans' },
    { name: 'Akan', id: 'Akan' },
    { name: 'Amharic', id: 'Amharic' },
    { name: 'Aragonese', id: 'Aragonese' },
    { name: 'Arabic', id: 'Arabic' },
    { name: 'Assamese', id: 'Assamese' },
    { name: 'Avaric', id: 'Avaric' },
    { name: 'Aymara', id: 'Aymara' },
    { name: 'Azerbaijani', id: 'Azerbaijani' },
    { name: 'Bashkir', id: 'Bashkir' },
    { name: 'Belarusian', id: 'Belarusian' },
    { name: 'Bulgarian', id: 'Bulgarian' },
    { name: 'Bihari languages', id: 'Bihari languages' },
    { name: 'Bislama', id: 'Bislama' },
    { name: 'Bambara', id: 'Bambara' },
    { name: 'Bengali', id: 'Bengali' },
    { name: 'Tibetan', id: 'Tibetan' },
    { name: 'Breton', id: 'Breton' },
    { name: 'Bosnian', id: 'Bosnian' },
    { name: 'Catalan; Valencian', id: 'Catalan; Valencian' },
    { name: 'Chechen', id: 'Chechen' },
    { name: 'Chamorro', id: 'Chamorro' },
    { name: 'Corsican', id: 'Corsican' },
    { name: 'Cree', id: 'Cree' },
    { name: 'Czech', id: 'Czech' },
    { name: 'Chuvash', id: 'Chuvash' },
    { name: 'Welsh', id: 'Welsh' },
    { name: 'Danish', id: 'Danish' },
    { name: 'German', id: 'German' },
    { name: 'Dzongkha', id: 'Dzongkha' },
    { name: 'Ewe', id: 'Ewe' },
    { name: 'English', id: 'English' },
    { name: 'Esperanto', id: 'Esperanto' },
    { name: 'Spanish; Castilian', id: 'Spanish; Castilian' },
    { name: 'Estonian', id: 'Estonian' },
    { name: 'Basque', id: 'Basque' },
    { name: 'Persian', id: 'Persian' },
    { name: 'Fulah', id: 'Fulah' },
    { name: 'Finnish', id: 'Finnish' },
    { name: 'Fijian', id: 'Fijian' },
    { name: 'Faroese', id: 'Faroese' },
    { name: 'French', id: 'French' },
    { name: 'Western Frisian', id: 'Western Frisian' },
    { name: 'Irish', id: 'Irish' },
    { name: 'Gaelic; Scottish Gaelic', id: 'Gaelic; Scottish Gaelic' },
    { name: 'Galician', id: 'Galician' },
    { name: 'Guarani', id: 'Guarani' },
    { name: 'Gujarati', id: 'Gujarati' },
    { name: 'Manx', id: 'Manx' },
    { name: 'Hausa', id: 'Hausa' },
    { name: 'Hebrew', id: 'Hebrew' },
    { name: 'Hindi', id: 'Hindi' },
    { name: 'Hiri Motu', id: 'Hiri Motu' },
    { name: 'Croatian', id: 'Croatian' },
    { name: 'Haitian; Haitian Creole', id: 'Haitian; Haitian Creole' },
    { name: 'Hungarian', id: 'Hungarian' },
    { name: 'Armenian', id: 'Armenian' },
    { name: 'Herero', id: 'Herero' },
    { name: 'Interlingue; Occidental', id: 'Interlingue; Occidental' },
    { name: 'Igbo', id: 'Igbo' },
    { name: 'Sichuan Yi; Nuosu', id: 'Sichuan Yi; Nuosu' },
    { name: 'Inupiaq', id: 'Inupiaq' },
    { name: 'Ido', id: 'Ido' },
    { name: 'Icelandic', id: 'Icelandic' },
    { name: 'Italian', id: 'Italian' },
    { name: 'Inuktitut', id: 'Inuktitut' },
    { name: 'Japanese', id: 'Japanese' },
    { name: 'Javanese', id: 'Javanese' },
    { name: 'Georgian', id: 'Georgian' },
    { name: 'Kongo', id: 'Kongo' },
    { name: 'Kikuyu; Gikuyu', id: 'Kikuyu; Gikuyu' },
    { name: 'Kuanyama; Kwanyama', id: 'Kuanyama; Kwanyama' },
    { name: 'Kazakh', id: 'Kazakh' },
    { name: 'Kalaallisut; Greenlandic', id: 'Kalaallisut; Greenlandic' },
    { name: 'Central Khmer', id: 'Central Khmer' },
    { name: 'Kannada', id: 'Kannada' },
    { name: 'Korean', id: 'Korean' },
    { name: 'Kanuri', id: 'Kanuri' },
    { name: 'Kashmiri', id: 'Kashmiri' },
    { name: 'Kurdish', id: 'Kurdish' },
    { name: 'Komi', id: 'Komi' },
    { name: 'Cornish', id: 'Cornish' },
    { name: 'Kirghiz; Kyrgyz', id: 'Kirghiz; Kyrgyz' },
    { name: 'Latin', id: 'Latin' },
    { name: 'Luxembourgish; Letzeburgesch', id: 'Luxembourgish; Letzeburgesch' },
    { name: 'Ganda', id: 'Ganda' },
    { name: 'Limburgan; Limburger; Limburgish', id: 'Limburgan; Limburger; Limburgish' },
    { name: 'Lingala', id: 'Lingala' },
    { name: 'Lao', id: 'Lao' },
    { name: 'Lithuanian', id: 'Lithuanian' },
    { name: 'Luba-Katanga', id: 'Luba-Katanga' },
    { name: 'Latvian', id: 'Latvian' },
    { name: 'Malagasy', id: 'Malagasy' },
    { name: 'Marshallese', id: 'Marshallese' },
    { name: 'Maori', id: 'Maori' },
    { name: 'Macedonian', id: 'Macedonian' },
    { name: 'Malayalam', id: 'Malayalam' },
    { name: 'Mongolian', id: 'Mongolian' },
    { name: 'Marathi', id: 'Marathi' },
    { name: 'Malay', id: 'Malay' },
    { name: 'Maltese', id: 'Maltese' },
    { name: 'Burmese', id: 'Burmese' },
    { name: 'Nauru', id: 'Nauru' },
    { name: 'Nepali', id: 'Nepali' },
    { name: 'Ndonga', id: 'Ndonga' },
    { name: 'Dutch; Flemish', id: 'Dutch; Flemish' },
    { name: 'Norwegian', id: 'Norwegian' },
    { name: 'Ndebele, South; South Ndebele', id: 'Ndebele, South; South Ndebele' },
    { name: 'Navajo; Navaho', id: 'Navajo; Navaho' },
    { name: 'Chichewa; Chewa; Nyanja', id: 'Chichewa; Chewa; Nyanja' },
    { name: 'Ojibwa', id: 'Ojibwa' },
    { name: 'Oromo', id: 'Oromo' },
    { name: 'Oriya', id: 'Oriya' },
    { name: 'Ossetian; Ossetic', id: 'Ossetian; Ossetic' },
    { name: 'Panjabi; Punjabi', id: 'Panjabi; Punjabi' },
    { name: 'Pali', id: 'Pali' },
    { name: 'Polish', id: 'Polish' },
    { name: 'Pushto; Pashto', id: 'Pushto; Pashto' },
    { name: 'Portuguese', id: 'Portuguese' },
    { name: 'Quechua', id: 'Quechua' },
    { name: 'Romansh', id: 'Romansh' },
    { name: 'Rundi', id: 'Rundi' },
    { name: 'Romanian; Moldavian; Moldovan', id: 'Romanian; Moldavian; Moldovan' },
    { name: 'Russian', id: 'Russian' },
    { name: 'Kinyarwanda', id: 'Kinyarwanda' },
    { name: 'Sanskrit', id: 'Sanskrit' },
    { name: 'Sardinian', id: 'Sardinian' },
    { name: 'Sindhi', id: 'Sindhi' },
    { name: 'Northern Sami', id: 'Northern Sami' },
    { name: 'Sango', id: 'Sango' },
    { name: 'Sinhala; Sinhalese', id: 'Sinhala; Sinhalese' },
    { name: 'Slovak', id: 'Slovak' },
    { name: 'Slovenian', id: 'Slovenian' },
    { name: 'Samoan', id: 'Samoan' },
    { name: 'Shona', id: 'Shona' },
    { name: 'Somali', id: 'Somali' },
    { name: 'Albanian', id: 'Albanian' },
    { name: 'Serbian', id: 'Serbian' },
    { name: 'Swati', id: 'Swati' },
    { name: 'Sotho, Southern', id: 'Sotho, Southern' },
    { name: 'Sundanese', id: 'Sundanese' },
    { name: 'Swedish', id: 'Swedish' },
    { name: 'Swahili', id: 'Swahili' },
    { name: 'Tamil', id: 'Tamil' },
    { name: 'Telugu', id: 'Telugu' },
    { name: 'Tajik', id: 'Tajik' },
    { name: 'Thai', id: 'Thai' },
    { name: 'Tigrinya', id: 'Tigrinya' },
    { name: 'Turkmen', id: 'Turkmen' },
    { name: 'Tagalog', id: 'Tagalog' },
    { name: 'Tswana', id: 'Tswana' },
    { name: 'Tonga (Tonga Islands)', id: 'Tonga (Tonga Islands)' },
    { name: 'Turkish', id: 'Turkish' },
    { name: 'Tsonga', id: 'Tsonga' },
    { name: 'Tatar', id: 'Tatar' },
    { name: 'Twi', id: 'Twi' },
    { name: 'Tahitian', id: 'Tahitian' },
    { name: 'Uighur; Uyghur', id: 'Uighur; Uyghur' },
    { name: 'Ukrainian', id: 'Ukrainian' },
    { name: 'Urdu', id: 'Urdu' },
    { name: 'Uzbek', id: 'Uzbek' },
    { name: 'Venda', id: 'Venda' },
    { name: 'Vietnamese', id: 'Vietnamese' },
    { name: 'Walloon', id: 'Walloon' },
    { name: 'Wolof', id: 'Wolof' },
    { name: 'Xhosa', id: 'Xhosa' },
    { name: 'Yiddish', id: 'Yiddish' },
    { name: 'Yoruba', id: 'Yoruba' },
    { name: 'Zhuang; Chuang', id: 'Zhuang; Chuang' },
    { name: 'Chinese', id: 'Chinese' },
    { name: 'Zulu', ida: 'Zulu' }
];
export const mapFieldOptions = [
    { value: 'employee.full_name', label: 'Employee Full Name' },
    { value: 'employee.first_name', label: 'Employee First Name' },
    { value: 'employee.last_name', label: 'Employee Last Name' },
    { value: 'employee.address', label: 'Contact address' },
    { value: 'employee.street', label: 'Street' },
    { value: 'employee.city', label: 'City' },
    { value: 'employee.state', label: 'Province' },
    { value: 'employee.country', label: 'Country' },
    { value: 'employee.zip_code', label: 'Postal Code' },
    { value: 'employee.email_private', label: 'Email(Private)' },
    { value: 'employee.email_work', label: 'Email(Work)' },
    { value: 'employee.mobile_phone', label: 'Mobile phone' },
    { value: 'employee.gender', label: 'Gender' },
    { value: 'employee.ethnicity', label: 'Ethnicity' },
    { value: 'employee.date_of_birth', label: 'Date of birth' },
    { value: 'employee.identity_number', label: 'Identity number' },
    { value: 'employee.marital_status', label: 'Marital status' },
    { value: 'employee.blood_group', label: 'Blood group' },
    { value: 'employee.residential_street', label: 'Residential street' },
    { value: 'employee.residential_city', label: 'Residential city' },
    { value: 'employee.residential_state', label: 'Residential state' },
    { value: 'employee.residential_country', label: 'Residential country' },
    { value: 'employee.residential_zip_code', label: 'Residential zip code' },
    { value: 'employee.annual_leave', label: 'Annual Leave' },

    { value: 'employee.postal_street', label: 'Postal street' },
    { value: 'employee.postal_city', label: 'Postal city' },
    { value: 'employee.postal_state', label: 'Postal state' },
    { value: 'employee.postal_country', label: 'Postal country' },
    { value: 'employee.postal_zip_code', label: 'Postal zip code' },

    { value: 'employee.partner_name', label: "Partner's name" },
    { value: 'employee.partner_gender', label: "Partner's gender" },
    { value: 'employee.partner_date_of_birth', label: "Partner's date of birth" },

    { value: 'employee.petrol_allowance', label: 'Petrol Allowance' },
    { value: 'employee.mobile_phone_allowance', label: 'Mobile Phone Allowance' },

    { value: 'offer.funeral_cover', label: 'Funeral Cover' },
    { value: 'offer.motor_vehicle_allowance', label: 'Motor Vehicle Allowance' },
    { value: 'offer.petrol_allowance', label: 'Petrol Allowance' },
    { value: 'offer.mobile_phone_allowance', label: 'Mobile Phone Allowance' },
    { value: 'offer.time_off', label: 'Time Off - Earned' },
    { value: 'offer.created_at', label: 'Created at' },
    { value: 'offer.effective_date', label: 'Effective Date' },
    { value: 'offer.currency', label: 'Currency' },
    { value: 'offer.annual_amount', label: 'Annual Salary' },
    { value: 'offer.bonus_amount', label: 'Bonus Amount' },
    { value: 'offer.commission_amount', label: 'Commission Amount' },
    { value: 'offer.hr_full_name', label: "HR partner's full name" },
    { value: 'offer.hr_first_name', label: "HR partner's first name" },
    { value: 'offer.hr_last_name', label: "HR partner's last name" },
    { value: 'offer.hr_email', label: "HR partner's email" },
    { value: 'offer.hr_designation', label: "HR partner's designation" },
    { value: 'offer.date_of_joining', label: 'Joining date' },
    { value: 'offer.monthly_amount', label: 'Monthly Amount' },
    { value: 'job.title', label: 'Job title' },
    { value: 'job.job_type', label: 'Job type' },
    { value: 'job.location', label: 'Job location' },
    { value: 'job.location_city', label: 'Job location city' },
    { value: 'job.location_short', label: 'Job location short' },
    { value: 'job.previous_company_name', label: 'Previous Employer' },
    { value: 'job.previous_start_date', label: 'Previous Start Date' },
    { value: 'recruiter.full_name', label: "Recruiter's full name" },
    { value: 'recruiter.first_name', label: "Recruiter's first name" },
    { value: 'recruiter.last_name', label: "Recruiter's last name" },
    { value: 'recruiter.email', label: "Recruiter's email" },
    { value: 'recruiter.title', label: "Recruiters's title" },
    { value: 'hiring_manager.full_name', label: "Hiring manager's full name" },
    { value: 'hiring_manager.first_name', label: "Hiring manager's first name" },
    { value: 'hiring_manager.last_name', label: "Hiring manager's last name" },
    { value: 'hiring_manager.email', label: "Hiring manager's email" },
    { value: 'hiring_manager.title', label: "Hiring manager's title" }
];
export const documentTypeOptions = [
    { value: 'Letter of Appointment', label: 'Letter of Appointment', name: 'LOA' },
    { value: 'Addendum to Letter of Appointment', label: 'Addendum to Letter of Appointment', name: 'Addendum' },
    { value: 'Benefit Letter', label: 'Benefit Letter', name: 'Benefit Letter' },
    { value: 'Sanlam GLI Option', label: 'Sanlam GLI Option', name: 'GLI' },
    { value: 'Sanlam Beneficiary nomination', label: 'Sanlam Beneficiary Nomination', name: 'Sanlam Beneficiary Nom' },
    { value: 'Sanlam Funeral Cover', label: 'Sanlam Funeral Cover', name: 'Funeral Cover' },
    {
        value: 'Alexander Forbes Beneficiary Nomination',
        label: 'Alexander Forbes Beneficiary Nomination',
        name: 'AF Beneficiary Nom'
    },
    { value: 'Provident Fund New Member', label: 'Provident Fund New Member', name: 'PF New Member' },
    { value: 'Kaelo GAP Cover', label: 'Kaelo GAP Cover', name: 'GAP Cover' },
    { value: 'Kaelo Doctor Nomination', label: 'Kaelo Doctor Nomination', name: 'Kaelo Doctor Nom' },
    { value: 'Emergency Contact Form', label: 'Emergency Contact Form', name: 'Emergency Contact Form' },
    { value: 'New Employee Details', label: 'New Employee Details', name: 'Personal Info' },
    { value: 'Dept of Labour EEA1', label: 'Dept of Labour EEA1', name: 'EEA1' },
    { value: 'Payroll Giving', label: 'Payroll Giving', name: 'Payroll Giving' },
    { value: 'Passport', label: 'Passport', name: 'Passport' },
    { value: 'Identity Document', label: 'Identity Document', name: 'ID Copy' },
    { value: 'Marriage Certificate', label: 'Marriage Certificate', name: 'Marriage Cert' },
    {
        value: 'Beneficiary ID / Birth Certificates',
        label: 'Beneficiary ID / Birth Certificates',
        name: 'Beneficiary ID/BC'
    },
    { value: "Driver's License", label: "Driver's License", name: 'Drivers Lic' },
    { value: 'Bank Letter', label: 'Bank Letter', name: 'Bank Letter' },
    { value: 'Fixed Term Contract', label: 'Fixed Term Contract', name: 'FTC' },
    { value: 'Addendum to Fixed Term Contract', label: 'Addendum to Fixed Term Contract', name: 'Addendum to FTC' },
    { value: 'Discovery New Member Application', label: 'Discovery New Member Application', name: 'Discovery New' },
    { value: 'Discovery Membership Transfer', label: 'Discovery Membership Transfer', name: 'Discovery Transfer' },
    { value: 'Discovery Vitality Application', label: 'Discovery Vitality Application', name: 'Vitality New' },
    { value: 'Discovery Add Dependent', label: 'Discovery Add Dependent', name: 'Discovery Add Dep' },
    { value: 'Discovery Add New Born Baby', label: 'Discovery Add New Born Baby', name: 'Discovery Add Baby' },
    { value: 'Membership Certificate', label: 'Membership Certificate', name: 'Membership Cert' },
    { value: 'Keycare Application', label: 'KeyCare Application', name: 'KeyCare New' },
    { value: 'Keycare Income Verification', label: 'KeyCare Income Verification', name: 'KeyCare Inc Verification' },
    { value: 'Kaelo Underwriting', label: 'Kaelo Underwriting', name: 'Kaelo Underwriting' },
    { value: 'Kaelo Application', label: 'Kaelo Application', name: 'Kaelo New' },
    { value: 'Criminal Background Check', label: 'Criminal Background Check', name: 'Crim Checks' },
    { value: 'Mock Payslip', label: 'Mock Payslip', name: 'Mock Payslip' },
    { value: 'Previous Company Payslips', label: 'Previous Company Payslips', name: 'Previous Payslips' },
    { value: 'resume', label: 'Resume', name: 'CV' },
    { value: 'Offer Letter', label: 'Offer Letter', name: 'Offer Letter/Email' },
    { value: 'AOD Take on or Acknowledgement', label: 'AOD Take on or Acknowledgement', name: 'AOD Takeon' },
    { value: 'Tax number', label: 'Tax Number', name: 'Tax no' },
    { value: 'Labour Broker Agreement', label: 'Labour Broker Agreement', name: 'LB Agreement' },
    { value: 'Service Provider Agreement', label: 'Service Provider Agreement', name: 'SP Agreement' },
    {
        value: 'Independent Contractor Questionnaire',
        label: 'Independent Contractor Questionnaire',
        name: 'Ind Cont Questionnaire'
    },
    { value: 'Internship Agreement', label: 'Internship Agreement', name: 'Intern Agreement' },
    { value: 'Work Permit', label: 'Work Permit', name: 'Work permit' }
];

export const uploadedTypesOptions: any[] = [
    {
        title: 'Onboarding Documents',
        types: documentTypeOptions
    }
    // {
    //     title: 'Employee Documents',
    //     types: [
    //         { value: 'Resume' },
    //         { value: 'Portfolio' },
    //         { value: 'Certificate' },
    //         { value: 'Cover Letter' },
    //         { value: 'Letter of Employment' },
    //         { value: 'Employment Contract' },
    //         { value: 'Other' }
    //     ]
    // },
    // {
    //     title: 'Personal Files',
    //     types: [
    //         { value: 'Identity Document', single: true },
    //         { value: 'Passport', single: true },
    //         { value: 'Work Permit / Visa', single: true },
    //         { value: 'Proof of Address' },
    //         { value: 'Proof of Bank Account' },
    //         { value: 'Marriage Certificate' },
    //         { value: 'Tax Registration Certificate' },
    //         { value: 'Other' }
    //     ]
    // },
];
