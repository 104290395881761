<div class="light-overlay" *ngIf="contentLoading"></div>
<div class="d-flex justify-content-end">
    <button *ngIf="!hideButtons" class="btn_add" (click)="onAddNote()">
        <img src="/assets/images/icon-plus.svg" width="22" height="22" />
        <span>Add Note</span>
    </button>
</div>
<div class="message-form">
    <!--<div class="tags-container">-->
    <!-- <img src="/assets/images/tags/tags.svg" /> -->
    <!-- <div
            class="tag"
            *ngFor="let tag of candidate.tags || []"
            [style.color]="getTagStyle(tag.color).color"
            [style.background]="getTagStyle(tag.color).background"
        >
            {{ titleCase(tag.hash) }}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                (click)="onDeleteTag(tag.hash)"
            >
                <path
                    [style.fill]="getTagStyle(tag.color).color"
                    fill-rule="nonzero"
                    d="M.5.029L.029.5l.237.234L3.529 4l-3.5 3.5.471.471 3.5-3.5 3.263 3.266.237.234.471-.471-.234-.237L4.471 4l3.5-3.5L7.5.029 4 3.529.734.266z"
                />
            </svg>
        </div> -->
    <!--</div>-->
    <form [formGroup]="commentForm" [ngClass]="{ hide: !commentFormShow }">
        <div style="position: relative;">
            <app-editor #pEditor formControlName="description" placeholder="Start typing…" [style]="{ height: '81px' }">
            </app-editor>
            <div
                class="hash-suggestion"
                [style.top.px]="suggestionPosition?.top"
                [style.left.px]="suggestionPosition?.left"
                *ngIf="!createMode && currentHash && tagWithTitles.length > 0"
            >
                <p
                    class="hash"
                    *ngFor="let tag of tagWithTitles"
                    (click)="placeHash(tag)"
                    [ngClass]="{ added: tag?.added }"
                    [style.color]="getTagStyle(tag.color).color"
                    [style.background]="getTagStyle(tag.color).background"
                >
                    {{ tag.title }}
                </p>
            </div>
            <div
                class="create-new hash-suggestion"
                [style.top.px]="suggestionPosition?.top"
                [style.left.px]="suggestionPosition?.left"
                *ngIf="!createMode && tagWithTitles.length === 0 && currentHash.length > 0"
                (click)="onCreateNew()"
            >
                <img src="/assets/images/tags/plus.svg" />Create a new tag
            </div>
            <div
                class="hash-colors"
                [style.top.px]="suggestionPosition?.top"
                [style.left.px]="suggestionPosition?.left"
                *ngIf="createMode"
            >
                <div
                    class="hash-color"
                    *ngFor="let color of hashColors"
                    [style.background]="color"
                    (click)="onChangeHashColor(color)"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        *ngIf="lastHash && lastHash.color === color"
                    >
                        <path
                            [style.fill]="getTagStyle(color).color"
                            fill-rule="nonzero"
                            d="M14.5.793l-9 9-3.648-3.645-.352-.355-.707.707.355.352L5.5 11.207 15.207 1.5z"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <label>
            <span>
                Hit <strong><img src="/assets/images/tags/enter-key.svg" />Enter</strong> to post</span
            >
        </label>
    </form>
</div>
<div class="audit">
    <div class="audit-item" *ngFor="let item of audit; let ind = index" [ngClass]="{ last: ind === audit.length - 1 }">
        <ng-container *ngIf="item.type === 'rating'">
            <div class="audit-item-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16">
                    <path
                        fill="#384E5D"
                        d="M8 .96l-.46 1.095L5.952 5.82l-4.96.54 3.71 3.5-1.055 5.163L8 12.375l4.348 2.648-1.051-5.164 3.707-3.5-4.957-.539L8 .96zm0 2.575L9.355 6.75l3.391.367-2.539 2.399.703 3.46L8 11.204l-2.914 1.774.707-3.461-2.535-2.399 3.387-.367L8 3.535z"
                    />
                </svg>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            <strong style="line-height: 22px;">A Candidate Rating</strong>
                            was added by
                            <strong>{{ item?.user?.first_name }} {{ item?.user?.last_name }}.</strong>
                        </p>
                        <p>
                            <svg
                                class="star"
                                *ngFor="let rate of item.rating"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="15"
                                viewBox="0 0 16 15"
                            >
                                <path
                                    fill="#F7B500"
                                    d="M7.5 0L5.328 5.148 0 5.73 3.984 9.492 2.863 15 7.5 12.175 12.137 15 11.016 9.492 15 5.73 9.672 5.148z"
                                    transform="translate(.438)"
                                />
                            </svg>
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'contact-details'">
            <div class="audit-item-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
                    <path
                        fill="#384E5D"
                        d="M2.475 0c-.823 0-1.5.677-1.5 1.5v9c0 .823.677 1.5 1.5 1.5H5v-1H2.475c-.282 0-.5-.219-.5-.5v-9c0-.281.218-.5.5-.5h4.5v3h3v1h1V3.293L7.682 0H2.475zm5.5 1.707L9.268 3H7.975V1.707zm4.207 4.297c-.33.002-.657.13-.903.38l-4.623 4.704-.705 2.963 2.961-.705.1-.098 4.605-4.523c.504-.493.508-1.317.01-1.815l-.535-.535c-.25-.25-.58-.373-.91-.371zm.005.994c.07 0 .141.027.198.084l.535.535c.112.112.112.281-.002.393v.002l-4.506 4.424-1.111.265.263-1.11 4.428-4.505.002-.002c.055-.057.124-.086.194-.086z"
                    />
                </svg>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            Candidate <strong>Contact Details</strong> were updated by
                            <strong>{{ item?.user?.first_name }} {{ item?.user?.last_name }} </strong>
                        </p>
                        <p class="p-extra">
                            <span *ngFor="let field of item.updated_fields; let i = index">
                                {{ field }}<ng-container *ngIf="i !== item.updated_fields.length - 1">,</ng-container
                                ><ng-container *ngIf="i === item.updated_fields.length - 1">.</ng-container>
                            </span>
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'offer-letter'">
            <div class="audit-item-img">
                <div class="inner">
                    <div class="dot" [ngClass]="item?.class"></div>
                </div>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            Sent <strong>Offer Letter</strong> by
                            <strong>{{ item?.user?.first_name }} {{ item?.user?.last_name }} </strong>
                            <span class="reset-email-block" *ngIf="item.action_url">
                                <span class="dots"><i></i><i></i><i></i></span>
                                <div class="dropdown">
                                    <button class="btn btn-dropdown" (click)="onResendEmail(item)">Resend email</button>
                                    <button class="btn btn-dropdown" (click)="copyURL(item.action_url)">
                                        Copy action URL
                                    </button>
                                </div>
                            </span>
                        </p>
                        <p class="email-activity">Action Required email: {{ item.email_activity }}</p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'offer-accepted'">
            <div class="audit-item-img">
                <div class="status-icon success">
                    <div class="holder"></div>
                </div>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p><strong>Offer Accepted</strong> by Candidate</p>
                    </div>

                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'offer'">
            <div class="audit-item-img" style="margin-top: 0;">
                <ng-container *ngIf="item.status">
                    <div class="status-icon success">
                        <div class="holder"></div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!item.status">
                    <div class="status-icon error">
                        <div class="holder"></div>
                    </div>
                </ng-container>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            <strong style="line-height: 22px;"
                                >{{ item?.user?.first_name }} {{ item?.user?.last_name }}</strong
                            >
                            {{ item.status ? 'accepted' : 'declined' }}
                            by
                            <strong>{{ item.candidate_name }}</strong>
                            on behalf of Candidate
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'offer-letter-workflow'">
            <div class="audit-item-img" style="margin-top: 0;">
                <img src="assets/images/icons/icons-8-workflow.svg" alt="Workflow Icon" />
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            Assigned <strong>{{ item?.workflow_title }}</strong> by
                            <strong>{{ item?.user?.first_name }} {{ item?.user?.last_name }}</strong>
                            <!-- <span class="reset-email-block" *ngIf="item.job_id && item.candidate_id && !item.completed">
                                <span class="dots"><i></i><i></i><i></i></span>
                                <div class="dropdown">
                                    <button class="btn btn-dropdown" (click)="onSendReminderForOfferLetter(item)">
                                        Resend email
                                    </button>
                                </div>
                            </span> -->
                        </p>
                        <p class="email-activity" *ngIf="item.signing_name && !item.completed">
                            Out for signing: {{ item.signing_name }} ({{ item?.role }})
                        </p>
                        <p class="email-activity" *ngIf="item.completed">
                            Complete
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'internal'">
            <div class="audit-item-img" style="margin-top: 0;">
                <div class="inner">
                    <div class="dot"></div>
                </div>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            <strong style="line-height: 22px;">&team </strong> {{ item.param || 'added' }}
                            {{ getTags(item.text).length > 1 ? 'tags' : 'a tag' }}
                            <span
                                class="tag"
                                *ngFor="let tag of getTags(item.text)"
                                [style.color]="getTagStyle(tag.color).color"
                                [style.background]="getTagStyle(tag.color).background"
                                >{{ titleCase(tag.hash) }}&nbsp;</span
                            >
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'tags'">
            <div class="audit-item-img" style="margin-top: 0;">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                    <path
                        fill="#637381"
                        d="M7.438 0c-.403 0-.786.16-1.063.445L.437 6.43c-.578.582-.578 1.535 0 2.117l4.016 4.015c.582.579 1.535.579 2.117 0l5.989-5.937c.28-.281.441-.664.441-1.063V1.5c0-.824-.676-1.5-1.5-1.5H7.437zm0 1H11.5c.281 0 .5.219.5.5v4.063c0 .132-.055.261-.148.355l-5.985 5.937c-.199.2-.508.196-.703 0l-4.016-4.02c-.203-.198-.203-.503-.003-.702l5.937-5.985c.094-.093.223-.148.356-.148zM10.5 2c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5z"
                    />
                </svg>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            <strong style="line-height: 22px;"
                                >{{ item?.user?.first_name }} {{ item?.user?.last_name }}</strong
                            >
                            {{ item.param || 'added' }}
                            {{ getTags(item.text).length > 1 ? 'tags' : 'a tag' }}
                            <span
                                class="tag"
                                *ngFor="let tag of getTags(item.text)"
                                [style.color]="getTagStyle(tag.color).color"
                                [style.background]="getTagStyle(tag.color).background"
                                >{{ titleCase(tag.hash) }}&nbsp;</span
                            >
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'comment'">
            <div class="audit-item-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                    <path
                        fill="#384E5D"
                        d="M1.5 0C.676 0 0 .676 0 1.5v10c0 .824.676 1.5 1.5 1.5h8.207L13 9.707V1.5c0-.824-.676-1.5-1.5-1.5h-10zm0 1h10c.281 0 .5.219.5.5V9H9v3H1.5c-.281 0-.5-.219-.5-.5v-10c0-.281.219-.5.5-.5zm8.5 9h1.293L10 11.293V10z"
                    />
                </svg>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            <strong>{{ item?.user?.first_name }} {{ item?.user?.last_name }} </strong> added a note
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
                <div class="audit-item-message">
                    <div class="inner">{{ item.text }}</div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.email_name && item.type === 'created'">
            <div class="audit-item-img">
                <div class="inner">
                    <div class="dot" [ngClass]="item?.class"></div>
                </div>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            Added to <strong>{{ item.job_title }}</strong> job
                            <ng-container *ngIf="item.sourceText">
                                via <strong>{{ item.sourceText }}</strong></ng-container
                            >
                            <span
                                class="reset-email-block"
                                *ngIf="
                                    item.action_url &&
                                    ((item.email_name === 'create_profile_email' && !candidate.hasUser) ||
                                        (item.email_name !== 'create_profile_email' && !item.completed))
                                "
                            >
                                <span class="dots"><i></i><i></i><i></i></span>
                                <div class="dropdown">
                                    <button class="btn btn-dropdown" (click)="onResendEmail(item)">Resend email</button>
                                    <button class="btn btn-dropdown" (click)="copyURL(item.action_url)">
                                        Copy action URL
                                    </button>
                                </div>
                            </span>
                        </p>
                        <p class="email-activity">
                            <ng-container *ngIf="item.email_name === 'application_successful'"
                                >Application Successful</ng-container
                            >
                            <ng-container *ngIf="item.email_name === 'additional_questions'"
                                >Additional Questions</ng-container
                            >
                            <ng-container *ngIf="item.email_name === 'create_profile_email'"
                                >Create Your Profile</ng-container
                            >
                            email: {{ item.email_activity }}
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'delete'">
            <div class="audit-item-img">
                <div class="status-icon error">
                    <div class="holder"></div>
                </div>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p *ngIf="!item.added_by; else addedByAutoMatching">
                            <strong>{{ item.candidate_name }}</strong> was deleted from
                            <strong>{{ item.job_title }}</strong>
                        </p>
                        <p class="p-extra" *ngIf="item.reason">{{ item.reason }}</p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'assigned'">
            <div class="audit-item-img">
                <div class="inner">
                    <div class="dot" [ngClass]="item?.class"></div>
                </div>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            Assigned <strong>{{ item.assessment_title }}</strong> questions by
                            <strong>{{ item?.user?.first_name }} {{ item?.user?.last_name }} </strong>
                            <span class="reset-email-block" *ngIf="item.action_url && !item.completed">
                                <span class="dots"><i></i><i></i><i></i></span>
                                <div class="dropdown">
                                    <button class="btn btn-dropdown" (click)="onResendEmail(item)">Resend email</button>
                                    <button class="btn btn-dropdown" (click)="copyURL(item.action_url)">
                                        Copy action URL
                                    </button>
                                </div>
                            </span>
                        </p>
                        <p class="email-activity">Action Required email: {{ item.email_activity }}</p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!item.email_name && item.type === 'created'">
            <div class="audit-item-img">
                <div class="status-icon success">
                    <div class="holder"></div>
                </div>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p *ngIf="!item.added_by; else addedByAutoMatching">
                            <strong>{{ item.candidate_name }}</strong> was added to
                            <strong>{{ item.job_title }}</strong>
                            <ng-container *ngIf="item.sourceText">
                                via <strong>{{ item.sourceText }}</strong></ng-container
                            >
                        </p>
                        <ng-template #addedByAutoMatching>
                            <p *ngIf="item.added_by === 'matching'; else addedByCandidate">
                                <strong>{{ item.candidate_name }}</strong> was added to
                                <strong>{{ item.job_title }}</strong> via
                                <strong>AI Matching</strong>
                            </p>
                        </ng-template>
                        <ng-template #addedByCandidate>
                            <p *ngIf="item.added_by === 'candidate'; else addedByOther">
                                <strong>{{ item.candidate_name }}</strong> was added to
                                <strong>{{ item.job_title }}</strong> from
                                <strong>Career Portal</strong>
                            </p>
                        </ng-template>
                        <ng-template #addedByOther>
                            <p>
                                <strong>{{ item.candidate_name }}</strong> was added to
                                <strong>{{ item.job_title }}</strong> by
                                <strong>{{ item?.user?.first_name }} {{ item?.user?.last_name }}</strong>
                            </p>
                        </ng-template>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'stages_progress'">
            <div class="audit-item-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13">
                    <path
                        fill="#384E5D"
                        d="M8.488.023l-.707.704L10.055 3h-1.14C4.011 3 .03 6.984.03 11.887V13h1v-1.113C1.031 7.527 4.555 4 8.914 4h1.14L7.782 6.273l.707.704 3.48-3.477L8.489.023z"
                    />
                </svg>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            Moved to <strong>{{ item.stage_to_title }}</strong> stage by
                            <strong>{{ item?.user?.first_name }} {{ item?.user?.last_name }} </strong>
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.type === 'resume-upload'">
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>{{ item?.user?.first_name }} {{ item?.user?.last_name }} added resume</p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </ng-container>
    </div>
</div>
