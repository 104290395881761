import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { User } from '@app/core/models';
import { CandidateService, InterviewsService, UserService } from '@app/core/services';
import * as fromStore from '@app/store';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-interview-scorecard',
    templateUrl: './interview-scorecard.component.html',
    styleUrls: ['./interview-scorecard.component.scss']
})
export class InterviewScorecardComponent implements OnInit, OnChanges, OnDestroy {
    @Input() jobId;
    @Input() candidateId;
    @Input() interview;
    @Input() changedId;

    user: User;
    overallComment = '';

    currentUserOverallVote = {};
    currentUserCriteriaVotes;

    userSubscription: Subscription;
    saveInterviewScorecardSubscription: Subscription;
    saveInterviewScorecardDraftSubscription: Subscription;

    config: any[] = [];
    criteriaForm = new FormArray([]);
    usedId;
    interviewForLS: { criteria_votes: any; id?: any; scorecard_id?: any; overall_votes: any } = {
        criteria_votes: {},
        overall_votes: []
    };

    constructor(
        private candidateService: CandidateService,
        private interviewsService: InterviewsService,
        private userService: UserService,
        private store: Store<fromStore.State>
    ) {
        this.userSubscription = this.store.pipe(select(fromStore.getUserEntity)).subscribe((user: User) => {
            this.user = user;
            this.usedId = this.user.id;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.changedId) {
            this.usedId = this.changedId;
            this.initInterviewVotes();
        }
    }

    ngOnInit() {
        this.initInterviewVotes();

        this.saveInterviewScorecardSubscription = this.interviewsService.saveInterviewScorecard.subscribe(
            (idForSave) => {
                this.updateScoreCard(idForSave);
            }
        );

        this.saveInterviewScorecardDraftSubscription = this.interviewsService.saveInterviewScorecardDraft.subscribe(
            (idForSave) => {
                this.updateScoreCardDraft(idForSave);
            }
        );
    }

    resetSettings() {
        this.overallComment = '';

        this.currentUserOverallVote = {};
        this.currentUserCriteriaVotes = null;

        this.config = [];
        this.criteriaForm = new FormArray([]);
    }

    initInterviewVotes() {
        this.resetSettings();
        if (this.interview) {
            this.interview.overall_votes = this.interview.overall_votes ? this.interview.overall_votes : [];
            this.interview.criteria_votes = this.interview.criteria_votes ? this.interview.criteria_votes : {};

            if (this.interview.criteria_votes[this.usedId]) {
                this.currentUserCriteriaVotes = this.interview.criteria_votes[this.usedId];
            }
        }

        this.collectCurrentUserVotes();
        this.createGroup();
    }

    setOverallRating(vote) {
        const formedVote = {
            userId: this.usedId,
            comment: this.overallComment,
            vote
        };

        this.currentUserOverallVote = formedVote;
    }

    checkForDraft() {
        const checkForDraft = JSON.parse(localStorage.getItem('scorecard_draft'));

        let draftsUser = [];

        if (checkForDraft && checkForDraft[this.usedId]) {
            draftsUser = checkForDraft[this.usedId];
        }

        const existDraft = draftsUser.find((draft) => draft.id === this.interview.id);

        return existDraft;
    }

    createGroup() {
        const draft = this.checkForDraft();
        const scorecard_criteria = this.interview.scorecard.criteria ? this.interview.scorecard.criteria : [];

        scorecard_criteria.forEach((criteria) => {
            let foundVote = null;
            if (draft && draft.criteria_votes) {
                foundVote = draft.criteria_votes[this.usedId].find((vote) => vote.id === criteria.id);
            } else if (this.currentUserCriteriaVotes) {
                foundVote = this.currentUserCriteriaVotes.find((vote) => vote.id === criteria.id);
            }

            const group = new FormGroup({
                name: new FormControl(criteria.name),
                description: new FormControl(criteria.description),
                id: new FormControl(criteria.id),
                vote: new FormControl(foundVote && foundVote.vote ? foundVote.vote : null),
                comment: new FormControl(foundVote && foundVote.comment ? foundVote.comment : '')
            });

            this.criteriaForm.push(group);
        });
    }

    setCriteriaVote(vote, control) {
        control.get('vote').setValue(vote + 1);
    }

    updateInterviewOverAllVotes(id) {
        const existVoteIndex = this.interview.overall_votes.findIndex((vote) => vote.userId === id);
        this.currentUserOverallVote['comment'] = this.overallComment;
        if (existVoteIndex !== -1) {
            this.interview.overall_votes.splice(existVoteIndex, 1, this.currentUserOverallVote);
        } else {
            this.interview.overall_votes.push(this.currentUserOverallVote);
        }
    }

    updateInterviewOverAllVotesLS(id) {
        const existVoteIndex = this.interviewForLS.overall_votes.findIndex((vote) => vote.userId === id);
        this.currentUserOverallVote['comment'] = this.overallComment;
        if (existVoteIndex !== -1) {
            this.interviewForLS.overall_votes.splice(existVoteIndex, 1, this.currentUserOverallVote);
        } else {
            this.interviewForLS.overall_votes.push(this.currentUserOverallVote);
        }
    }

    collectCurrentUserVotes() {
        const draft = this.checkForDraft();
        let foundDraftVote = null;
        if (draft && draft.overall_votes) {
            foundDraftVote = draft.overall_votes.find((vote) => vote.userId === this.usedId);
        }

        const overall_vote = this.interview.overall_votes.find((vote) => vote.userId === this.usedId);

        if (foundDraftVote) {
            this.currentUserOverallVote = foundDraftVote;
            this.overallComment = this.currentUserOverallVote['comment'];
        } else if (overall_vote) {
            this.currentUserOverallVote = overall_vote;
            this.overallComment = this.currentUserOverallVote['comment'];
        }
    }

    saveCriteriaForm(id) {
        const formedCriteriaVote = this.criteriaForm.value;
        this.interview.criteria_votes[id] = formedCriteriaVote;
    }

    saveCriteriaFormLS(id) {
        const formedCriteriaVote = this.criteriaForm.value;
        this.interviewForLS.criteria_votes[id] = formedCriteriaVote;
    }

    updateScoreCard(id) {
        console.log('updateScoreCard', id);
        this.saveCriteriaForm(id);
        if (this.interview.scorecard && this.interview.scorecard.overall_rating) {
            this.updateInterviewOverAllVotes(id);
        }

        const interviewPostData = {
            userId: id,
            criteria_vote: this.interview.criteria_votes[id]
        };
        if (this.interview.scorecard && this.interview.scorecard.overall_rating && this.interview.overall_votes) {
            interviewPostData['overall_vote'] = this.interview.overall_votes.find((v) => v.userId === id);
        }

        // console.log('UPDATE DATA');
        // console.log(interviewPostData);
        const updatedInterview = { ...this.interview };

        delete updatedInterview.scorecard;

        this.interviewsService
            .updateCandidateInterviewScores(this.jobId, this.candidateId, interviewPostData, this.interview.id)
            .subscribe(() => {
                this.interviewsService.updatedCandidateInterview.next({
                    jobId: this.jobId,
                    candidateId: this.candidateId,
                    interviewPostData: updatedInterview,
                    interviewId: this.interview.id
                });
                this.removeVoteFromLocalStorage(id);
            });
    }

    removeVoteFromLocalStorage(id) {
        const scorecardDraftData = localStorage.getItem('scorecard_draft')
            ? JSON.parse(localStorage.getItem('scorecard_draft'))
            : {};
        const draftsUser = scorecardDraftData[id] ? scorecardDraftData[id] : [];
        const existDraft = draftsUser.findIndex((draft) => draft.id === this.interview.id);

        if (existDraft !== -1) {
            draftsUser.splice(existDraft, 1);
        }

        scorecardDraftData[id] = draftsUser;

        localStorage.setItem(`scorecard_draft`, JSON.stringify(scorecardDraftData));
    }

    updateScoreCardDraft(id) {
        this.saveCriteriaFormLS(id);
        if (this.interview.scorecard && this.interview.scorecard.overall_rating) {
            this.updateInterviewOverAllVotesLS(id);
        }
        const preparedObject = {
            id: this.interview.id,
            scorecard_id: this.interview.scorecard_id,
            overall_votes: this.interviewForLS.overall_votes,
            criteria_votes: this.interviewForLS.criteria_votes
        };
        const scorecardDraftData = localStorage.getItem('scorecard_draft')
            ? JSON.parse(localStorage.getItem('scorecard_draft'))
            : {};
        const draftsUser = scorecardDraftData[id] ? scorecardDraftData[id] : [];
        const existDraft = draftsUser.findIndex((draft) => draft.id === preparedObject.id);

        if (existDraft !== -1) {
            draftsUser.splice(existDraft, 1, preparedObject);
        } else {
            draftsUser.push(preparedObject);
        }
        scorecardDraftData[id] = draftsUser;
        localStorage.setItem(`scorecard_draft`, JSON.stringify(scorecardDraftData));
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.saveInterviewScorecardSubscription) {
            this.saveInterviewScorecardSubscription.unsubscribe();
        }
        if (this.saveInterviewScorecardDraftSubscription) {
            this.saveInterviewScorecardDraftSubscription.unsubscribe();
        }
    }
}
