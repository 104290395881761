import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormHelperService } from '@app/core/services/form-helper.service';
import { GeoService } from '@app/core/services/geo.service';
import { CompareTwoFieldsValidator } from '@app/core/validators/compareTwoFields.validator';
import { SelectItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { TenantService } from './../../../core/services/tenant.service';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'DD MMMM YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

@Component({
    selector: 'app-request-position',
    templateUrl: './request-position.component.html',
    styleUrls: ['./request-position.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class RequestPositionComponent implements OnInit, OnDestroy {
    timeZones;
    newHire = false;
    createNewLocation = false;
    dropdownValueUpdated = false;
    setSuggestions = false;
    contentLoading = false;

    hiringType = 'new';
    activeSection = 'job-details';
    tenantLocationOptions: any[] = [];
    companyOptions: SelectItem[] = [];
    jobTypeOptions: SelectItem[];
    contractDuration: SelectItem[];
    currencyOptions: SelectItem[];
    hiresOptions: SelectItem[];
    salaryOptions: SelectItem[];
    hireForm: FormGroup;
    disableContractDuration = true;

    autotags: any = {};
    locations: any[] = [];
    config: any = {};

    subscribtions: Subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private formHelper: FormHelperService,
        private geoService: GeoService,
        private tenantService: TenantService
    ) {
        this.tenantService.init();
        this.jobTypeOptions = [
            { label: 'Full Time', value: 'permanent' },
            { label: 'Temporary', value: 'temporary' },
            { label: 'Fixed Term', value: 'fixed_term' },
            { label: 'Freelance', value: 'freelance' },
            { label: 'Internship', value: 'internship' }
        ];

        this.contractDuration = [
            { label: 'More than 6 Months', value: 'more_6_month' },
            { label: '3 to 6 Months', value: '3_to_6_month' },
            { label: '1 to 3 Months', value: '1_to_3_month' },
            { label: 'Less than 1 Month', value: 'less_1_month' }
        ];

        this.currencyOptions = [
            { label: 'ZAR - South African Rand', value: 'ZAR' },
            { label: 'USD - US Dollar', value: 'USD' },
            { label: 'KES - Kenyan Shilling', value: 'KES' },
            { label: 'TZS - Tanzanian Shilling', value: 'TZS' },
            { label: 'UGX - Ugandan Shilling', value: 'UGX' },
            { label: 'NGN - Nigerian Naira', value: 'NGN' }
        ];

        this.salaryOptions = [
            { label: 'per hour', value: 'hourly' },
            { label: 'per day', value: 'dayly' },
            { label: 'per week', value: 'weekly' },
            { label: 'per month', value: 'monthly' },
            { label: 'per year', value: 'yearly' }
        ];
        this.hiresOptions = [];
        for (let i = 1; i <= 100; i++) {
            this.hiresOptions.push({ label: `${i} ${i === 1 ? 'hire' : 'hires'}`, value: i });
        }
        this.hiresOptions.push({ label: 'Ongoing', value: 'ongoing' });
    }

    ngOnInit(): void {
        this.getPreferenceAndTimeZone();
        this.initForm();
    }

    onSaveDraft(): { hireFormValue?: any; activeSection?: string } {
        this.hireForm.controls['company'].patchValue(this.config.title);
        this.setSalaryTo();
        if (!this.hireForm.valid) {
            this.formHelper.markFormGroupTouched(this.hireForm);
            return;
        }
        return { hireFormValue: this.hireForm.value, activeSection: this.activeSection };
    }

    onChangeHiringType(type: string): void {
        this.hiringType = type;
        this.hireForm.controls['hiring_role'].patchValue(type);
    }

    private setSalaryTo(): void {
        const single_salary = this.hireForm.get('salary.single').value;
        if (single_salary) {
            this.hireForm.get('salary.to').patchValue(this.hireForm.get('salary.from').value);
        }
    }

    private initForm(): void {
        this.hireForm = this.fb.group(
            {
                title: ['', Validators.required],
                company: ['', Validators.required],
                is_remote: [false],
                location: ['', Validators.required],
                contract_duration: [{ value: '', disabled: this.disableContractDuration }],
                hiring_role: ['new', Validators.required],
                start_date: [''],
                salary: this.fb.group({
                    from: ['', [Validators.required, Validators.min(0)]],
                    to: ['', [Validators.required, Validators.min(1)]],
                    period: ['yearly', Validators.required],
                    currency: [''],
                    hide: [''],
                    single: [false]
                }),
                description: ['', Validators.required],
                number_of_hires: ['', Validators.required],
                type: ['', Validators.required],
                status: ['BUILD']
            },
            {
                validators: [CompareTwoFieldsValidator]
            }
        );

        this.subscribtions.add(
            this.hireForm.get('is_remote').valueChanges.subscribe((value) => {
                if (value === false) {
                    this.hireForm.get('location').setValidators([Validators.required]);
                } else {
                    this.hireForm.get('location').clearValidators();
                }
                this.hireForm.get('location').updateValueAndValidity();
            })
        );

        const jobTypeValue = this.hireForm.get('type').value;
        this.toggleDisableOfContractDuration(jobTypeValue);

        this.subscribtions.add(
            this.hireForm.get('type').valueChanges.subscribe((value) => {
                this.toggleDisableOfContractDuration(value);
            })
        );
    }

    private toggleDisableOfContractDuration(value) {
        if (value !== 'temporary' && value !== 'freelance' && value !== 'fixed_term' && value !== null) {
            this.disableContractDuration = true;
            this.hireForm.get('contract_duration').disable();
            this.hireForm.get('contract_duration').patchValue('');
            this.hireForm.get('contract_duration').clearValidators();
            this.hireForm.get('contract_duration').updateValueAndValidity();
        } else {
            if (value !== null) {
                this.disableContractDuration = false;
                this.hireForm.get('contract_duration').enable();
                this.hireForm.get('contract_duration').setValidators([Validators.required]);
                this.hireForm.get('contract_duration').updateValueAndValidity();
            }
        }
    }

    private getPreferenceAndTimeZone(): void {
        this.contentLoading = true;
        this.subscribtions.add(
            forkJoin([
                this.geoService.getAllTimeZones(),
                this.tenantService.locations(),
                this.tenantService.autotags(),
                this.tenantService.config()
            ]).subscribe(([timeZones, locations, autotags, config]) => {
                this.locations = locations.filter((l) => l.name !== 'Any of the Dimension Data Offices');
                this.autotags = autotags;
                this.timeZones = timeZones;
                this.config = config;

                if (locations) {
                    this.locations.forEach((l) => {
                        this.tenantLocationOptions.push({
                            name: l.name,
                            location: l.location_short,
                            id: l.location,
                            currency: l.currency
                        });
                    });
                    this.tenantLocationOptions.push({
                        name: 'Add a new location',
                        id: '#location',
                        location: 'Add a new location'
                    });
                }
                if (this.autotags) {
                    this.autotags.ex_internal.forEach((n) => {
                        this.companyOptions.push({ label: n, value: n });
                    });
                }
                this.dropdownValueUpdated = true;
                this.contentLoading = false;
            })
        );
    }

    changeInForm(form: FormGroup) {
        form.markAsDirty();
        this.setContractDuration();
    }

    get singleSalary() {
        return this.hireForm.get('salary.single').value as boolean;
    }

    toggleSalaryField() {
        const single_salary = this.hireForm.get('salary.single').value;
        this.hireForm.get('salary.single').patchValue(!single_salary);
        if (single_salary) {
            this.hireForm.get('salary.to').clearValidators();
            this.hireForm.get('salary.to').updateValueAndValidity();
        } else {
            this.hireForm.get('salary.to').setValidators([Validators.required, Validators.min(1)]);
            this.hireForm.get('salary.to').updateValueAndValidity();
        }
    }

    private setContractDuration(): void {
        if (
            this.hireForm.controls.type.value === 'temporary' ||
            this.hireForm.controls.type.value === 'fixed_term' ||
            this.hireForm.controls.type.value === 'freelance'
        ) {
            this.hireForm.get('contract_duration').enable();
            this.hireForm.get('contract_duration').setValidators([Validators.required]);
            this.hireForm.get('contract_duration').updateValueAndValidity();
        } else {
            this.hireForm.get('contract_duration').patchValue('');
            this.hireForm.get('contract_duration').disable();
            this.hireForm.get('contract_duration').clearValidators();
            this.hireForm.get('contract_duration').updateValueAndValidity();
        }
    }

    onCreateLocation($event) {
        this.tenantLocationOptions = [];
        this.locations.forEach((l) => {
            this.tenantLocationOptions.push({
                name: l.name,
                location: l.location_short,
                id: l.location,
                currency: l.currency
            });
        });
        this.tenantLocationOptions.push({
            name: $event.name,
            location: $event.location_short || $event.location,
            id: $event.location,
            currency: $event.currency
        });
        this.tenantLocationOptions.push({
            name: 'Add a new location',
            id: '#location',
            location: 'Add a new location'
        });
        this.setSuggestions = true;
        setTimeout(() => {
            this.setSuggestions = false;
        }, 1000);
    }

    onLocationAdded(location) {
        const locValues = location.map((loc) => loc.id);

        this.hireForm.get('location').setValue(locValues);
        this.hireForm.get('location').markAsDirty();
        if (location.length === 1) {
            this.hireForm.get('salary.currency').setValue(location[0].currency || 'ZAR');
            this.hireForm.get('salary.currency').markAsDirty();
            this.dropdownValueUpdated = false;
            setTimeout(() => {
                this.dropdownValueUpdated = true;
            }, 100);
        }
    }

    onAddNewLocation(e) {
        this.createNewLocation = true;
        this.dropdownValueUpdated = false;
    }

    closeNewLocationForm() {
        this.createNewLocation = false;
        this.dropdownValueUpdated = true;
    }

    ngOnDestroy(): void {
        this.subscribtions.unsubscribe();
    }
}
