<div class="form-content-section" [formGroup]="locationForm">
    <div class="form-content-box">
        <div class="form-block">
            <div class="header-block">
                <label for="" class="form-block-label-title">{{
                    action === 'create' ? 'New Location' : locationForm.get('name').value
                }}</label>
                <button
                    *ngIf="action === 'update'"
                    (click)="onDeleteLocation()"
                    class="btn btn-primary-inversed btn-delete"
                >
                    <svg-icon
                        [svgStyle]="{ 'width.px': 11, 'height.px': 13, fill: '#000000' }"
                        src="/assets/images/delete.svg"
                    ></svg-icon>
                </button>
                <button class="btn btn-primary-inversed" (click)="closeNewLocationForm()">Cancel</button>
                <button *ngIf="action === 'create'" class="btn btn-primary" (click)="onSaveLocation()">Save</button>
                <button
                    *ngIf="action === 'update'"
                    class="btn btn-primary"
                    (click)="onUpdateLocation()"
                    [disabled]="locationForm?.value?.jobs > 0"
                    type="button"
                >
                    Save
                </button>
            </div>
            <div class="form-block">
                <div class="input-block">
                    <mat-form-field appearance="outline">
                        <mat-label>Location Name</mat-label>
                        <input autocomplete="off" type="text" formControlName="name" placeholder="Location" matInput />
                        <mat-error *ngIf="!locationForm.controls.name.valid && locationForm.controls.name.touched">
                            Location Name is required</mat-error
                        >
                    </mat-form-field>
                </div>
            </div>
            <ng-container *ngIf="!showFullAddress">
                <div class="form-block">
                    <div class="input-block">
                        <input type="hidden" formControlName="location" id="location-input" />
                        <mat-form-field appearance="outline">
                            <mat-label>Physical Address</mat-label>
                            <input
                                [readonly]="forbidEdit"
                                type="text"
                                formControlName="location"
                                placeholder="Enter the address"
                                ngx-google-places-autocomplete
                                #placesRef="ngx-places"
                                #locationInputRef
                                [options]="locationOptions"
                                (onAddressChange)="onLocationChange($event)"
                                [(value)]="inputAddress"
                                (change)="handleAddressTextChange($event)"
                                matInput
                            />
                            <mat-error
                                *ngIf="
                                    !locationForm.controls.location.valid &&
                                    locationForm.controls.location.touched &&
                                    !locationForm.controls.location?.errors?.exists
                                "
                            >
                                Physical Address is required
                            </mat-error>
                            <mat-error
                                *ngIf="
                                    locationForm.controls.location?.errors?.exists &&
                                    locationForm.controls.location.touched
                                "
                            >
                                Location already exists
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-block">
                    <img
                        class="powered-by-google"
                        src="/assets/images/powered-by-google2x.png"
                        alt="powered-by-google"
                    />
                </div>
            </ng-container>
            <ng-container formGroupName="address" *ngIf="showFullAddress">
                <div class="physical-address-block">
                    <h4>Physical Address</h4>
                    <button class="btn" (click)="onResetLocation()">Reset Location</button>
                </div>
                <div class="form-block">
                    <div class="input-block">
                        <mat-form-field appearance="outline">
                            <mat-label>Street</mat-label>
                            <input type="text" formControlName="streetAddress" placeholder="Location" matInput />
                            <mat-error
                                *ngIf="
                                    !locationForm.get('address').controls.streetAddress.valid &&
                                    locationForm.get('address').controls.streetAddress.touched
                                "
                            >
                                Street is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <div class="input-block">
                        <mat-form-field appearance="outline">
                            <mat-label>City</mat-label>
                            <input type="text" formControlName="addressLocality" placeholder="Location" matInput />
                            <mat-error
                                *ngIf="
                                    !locationForm.get('address').controls.addressLocality.valid &&
                                    locationForm.get('address').controls.addressLocality.touched
                                "
                            >
                                City is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <div class="input-block">
                        <mat-form-field appearance="outline">
                            <mat-label>State / Province</mat-label>
                            <input type="text" formControlName="addressRegion" placeholder="Location" matInput />
                            <mat-error
                                *ngIf="
                                    !locationForm.get('address').controls.addressRegion.valid &&
                                    locationForm.get('address').controls.addressRegion.touched
                                "
                            >
                                State / Province is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <div class="form-block-columns">
                        <div class="form-block-col flex-65">
                            <div class="input-block">
                                <app-auto-dropdown
                                    value="ZAR"
                                    [options]="countriesOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    [control]="locationForm.get('address').controls.addressCountry"
                                    [placeholder]="'Country'"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col flex-35">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Zip / Postal Code</mat-label>
                                    <input type="text" formControlName="postalCode" matInput />
                                    <mat-error
                                        *ngIf="
                                            !locationForm.get('address').controls.postalCode.valid &&
                                            locationForm.get('address').controls.postalCode.touched
                                        "
                                    >
                                        Field is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="line-separator"></div>

            <div class="form-block">
                <div class="input-block">
                    <app-auto-dropdown
                        value="ZAR"
                        [options]="currencyOptions"
                        [dropdownValueUpdated]="dropdownValueUpdated"
                        [newStyle]="true"
                        [control]="locationForm.controls.currency"
                        [placeholder]="'Default Currency'"
                    >
                    </app-auto-dropdown>
                    <div
                        class="error-message"
                        *ngIf="!locationForm.controls.currency.valid && locationForm.controls.currency.touched"
                    >
                        Currency is required
                    </div>
                </div>
            </div>
            <div class="form-block">
                <div class="input-block">
                    <app-auto-dropdown
                        [options]="timezoneOptions"
                        [newStyle]="true"
                        [dropdownValueUpdated]="dropdownValueUpdated"
                        [control]="locationForm.controls.timezone"
                        [placeholder]="'Timezone'"
                    >
                    </app-auto-dropdown>
                    <div
                        class="error-message"
                        *ngIf="!locationForm.controls.timezone.valid && locationForm.controls.timezone.touched"
                    >
                        Timezone is required
                    </div>
                </div>
            </div>
            <div class="form-block">
                <div class="input-block">
                    <p-checkbox
                        name="primary_location"
                        binary="true"
                        label="Primary Location"
                        formControlName="primary_location"
                        inputId="primary_location"
                    >
                    </p-checkbox>
                </div>
            </div>
            <!-- <div class="form-block-columns">
                <button *ngIf="action === 'create'" class="btn btn-primary btn-save" (click)="onSaveLocation()">
                    Save Location
                </button>
                <button *ngIf="action === 'update'" class="btn btn-primary btn-save" (click)="onUpdateLocation()">
                    Update Location
                </button>
            </div> -->
            <div class="form-block-columns">
                <p-messages [(value)]="msgs"></p-messages>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>
