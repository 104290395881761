import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UtilitiesService } from './utilities.service';

@Injectable({
    providedIn: 'root'
})
export class TenantService {
    tenantId: string;
    apiURL: string = environment.apiUrl;
    constructor(private utilities: UtilitiesService, private firestore: AngularFirestore, private http: HttpClient) {
        this.tenantId = this.utilities.getTenant();
    }

    init() {
        this.tenantId = this.utilities.getTenant();
    }

    config() {
        return this.firestore
            .collection(`tenants`)
            .doc(this.tenantId)
            .valueChanges()
            .pipe(
                take(1),
                map((item: any) => item.config)
            );
    }

    about() {
        return this.firestore
            .collection(`tenants`)
            .doc(this.tenantId)
            .valueChanges()
            .pipe(
                take(1),
                map((item: any) => ({
                    about: item.about || '',
                    perks: item.perks || ''
                }))
            );
    }

    integrations() {
        return this.firestore
            .collection(`tenants`)
            .doc(this.tenantId)
            .valueChanges()
            .pipe(
                take(1),
                map((item: any) => item.integrations)
            );
    }

    updateConfig(data) {
        console.log('updateConfig', data);
        return this.firestore
            .collection(`tenants`)
            .doc(this.tenantId)
            .update({ config: data });
    }

    getTitle() {
        return new Promise(async (resolve, reject) => {
            this.config().subscribe(
                (config: any) => {
                    return resolve(config.title || '');
                },
                (errorResponse) => reject(errorResponse)
            );
        });
    }

    getTitleRequest() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/title`);
    }

    portal() {
        return this.firestore
            .collection(`tenants`)
            .doc(this.tenantId)
            .valueChanges()
            .pipe(
                take(1),
                map((item: any) => item.portal)
            );
    }

    updatePortal(data) {
        return this.firestore
            .collection(`tenants`)
            .doc(this.tenantId)
            .update({ portal: data });
    }

    locations(): Observable<any[]> {
        return this.firestore
            .collection(`tenants/${this.tenantId}/locations`)
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((data) => {
                    data.sort((a: any, b: any) => {
                        const labelA = a.name.toUpperCase();
                        const labelB = b.name.toUpperCase();
                        return labelA.localeCompare(labelB);
                    });
                    return data;
                })
            );
    }

    getLocationByAddress(location): Observable<any> {
        return this.firestore
            .collection(`tenants/${this.tenantId}/locations`, (ref) => ref.where('location', '==', location).limit(1))
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((c) => {
                    return c[0];
                })
            );
    }

    getJobCountByLocation(location): Observable<any> {
        return this.firestore
            .collection(`tenants/${this.tenantId}/jobs-items`, (ref) =>
                ref
                    .where('location', 'array-contains', location)
                    .where('status', 'in', ['published', 'private', 'intternal', 'hold'])
            )
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((l: any[]) => {
                    const ids = l.map((j) => j.id);
                    return { count: l.length || 0, ids };
                })
            );
    }

    getEmployeesCountByLocation(location): Observable<any> {
        return this.firestore
            .collection(`tenants/${this.tenantId}/candidates`, (ref) => ref.where('location', '==', location))
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((l: any[]) => {
                    return l.length ? l.length : 0;
                })
            );
    }

    getWorkflowsCountByLocation(location): Observable<any> {
        return this.firestore
            .collection(`tenants/${this.tenantId}/workflows`, (ref) =>
                ref.where('rules.filters', 'array-contains-any', [
                    `location ~ == ~ '${location}' ~ &&`,
                    `location ~ == ~ '${location}' ~ ||`
                ])
            )
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((l: any[]) => {
                    return l.length ? l.length : 0;
                })
            );
    }

    saveLocation(id: string, data: any) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/locations`)
            .doc(id)
            .set(data);
    }

    updateLocation(id: string, data: any) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/locations`)
            .doc(id)
            .update(data);
    }

    removeLocation(id: string) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/locations`)
            .doc(id)
            .delete();
    }

    replaceLocation(data) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/replace-location`, data);
    }

    pipelines() {
        return this.firestore
            .collection(`tenants/${this.tenantId}/pipelines`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    pipelinesAsPromise(): Promise<any[]> {
        return new Promise(async (resolve, reject) => {
            this.pipelines().subscribe(
                (response) => resolve(response ? response : []),
                (errorResponse) => reject(errorResponse)
            );
        });
    }

    updatePipeline(id: string, data: any) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/pipelines`)
            .doc(id)
            .set(data);
    }

    removePipeline(id: string) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/pipelines`)
            .doc(id)
            .delete();
    }

    categories() {
        return this.firestore
            .collection(`tenants/${this.tenantId}/categories`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    updateCategories(categories: any[]) {
        return new Observable((subscriber) => {
            for (const category of categories) {
                this.firestore
                    .collection(`tenants/${this.tenantId}/categories`)
                    .doc(category.id)
                    .set(category);
            }

            subscriber.next(categories);
            subscriber.complete();
        });
    }

    updateCategory(id: string, data: any) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/categories`)
            .doc(id)
            .set(data);
    }

    removeCategory(id: string) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/categories`)
            .doc(id)
            .delete();
    }

    autotags() {
        return this.firestore
            .collection(`tenants/${this.tenantId}/autotag`)
            .valueChanges({ idField: 'id' })
            .pipe(
                take(1),
                map((item) => this.transformAutotags(item))
            );
    }

    transformAutotags(arr) {
        const internal = arr.find((item) => item.id === 'internal');
        const exinternal = arr.find((item) => item.id === 'ex_internal');
        return {
            internal: internal ? internal['data'] : [],
            ex_internal: exinternal ? exinternal['data'] : []
        };
    }

    updateAutotags(data: any) {
        if (data['ex_internal']) {
            this.firestore
                .collection(`tenants/${this.tenantId}/autotag`)
                .doc('ex_internal')
                .set({ data: data['ex_internal'] });
        }
        if (data['internal']) {
            this.firestore
                .collection(`tenants/${this.tenantId}/autotag`)
                .doc('internal')
                .set({ data: data['internal'] });
        }
    }

    getCandidatesInterviews() {
        return new Promise(async (resolve, reject) => {
            const interviews = [];
            // const candidates_interviews = await Firebase.getAll(`tenants/${tenantId}/candidates_interviews`);
            // for (let item of candidates_interviews) {
            //     for (let i of item.items) {
            //         if (jobIds.indexOf(i.jobId) > -1) {
            //             const candidate = await Candidate.getCandidateById(tenantId, item.id);
            //             if (candidate) {
            //                 i.candidate = {
            //                     last_name: candidate.last_name || 'C',
            //                     first_name: candidate.first_name || 'C',
            //                     id: candidate.id,
            //                     email: candidate.email,
            //                 };
            //                 if (candidate.profile_image) {
            //                     const url = candidate.profile_image;
            //                     const collection = 'Users';
            //                     try {
            //                         // const imageLinkUrl = await Firebase.download(url, collection);
            //                         // i.candidate.profile_image_link = imageLinkUrl;
            //                         i.candidate.profile_image_link = ''
            //                     } catch (error) {
            //                         console.error(error);
            //                     }
            //                 }
            //             }
            //             interviews.push(i);
            //         } else if (i.attendees) {
            //             for (let attendee of i.attendees) {
            //                 if (
            //                     attendee &&
            //                     attendee.emailAddress &&
            //                     attendee.emailAddress.address &&
            //                     attendee.emailAddress.address === userEmail &&
            //                     (attendee.type === 'required' || attendee.type === 'optional')
            //                 ) {
            //                     const candidate = await Candidate.getCandidateById(tenantId, item.id);
            //                     if (candidate) {
            //                         i.candidate = {
            //                             last_name: candidate.last_name || 'C',
            //                             first_name: candidate.first_name || 'C',
            //                             id: candidate.id,
            //                             email: candidate.email,
            //                         };
            //                         if (candidate.profile_image) {
            //                             const url = candidate.profile_image;
            //                             const collection = 'Users';

            //                             try {
            //                                 const imageLinkUrl = await Firebase.download(url, collection);
            //                                 i.candidate.profile_image_link = imageLinkUrl;
            //                             } catch (error) {
            //                                 console.error(error);
            //                             }
            //                         }
            //                     }
            //                     interviews.push(i);
            //                 }
            //             }
            //         }
            //     }
            // }

            return resolve(interviews);
        });
    }
}
