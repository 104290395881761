import { Action } from '@ngrx/store';
import { JobCategory } from './../../../../../../core/models/cateogory';

// Load Job categories
export const LOAD_JOBS_CATEGORIES = '[Jobs] Load Jobs Categories';
export const LOAD_JOBS_CATEGORIES_FAIL = '[Jobs] Load Jobs Categories Fail';
export const LOAD_JOBS_CATEGORIES_SUCCESS = '[Jobs] Load Jobs Categories Success';
export const ADD_JOBS_CATEGORIES = '[Jobs] Add Jobs Categories';
export const UPDATE_JOBS_CATEGORIES = '[Jobs] Update Jobs Categories';
export const DELETE_JOBS_CATEGORIES = '[Jobs] Delete Jobs Categories';
export const CLEAR_JOBS_CATEGORIES = '[Jobs] Clear Jobs Categories';

export class LoadJobsCategories implements Action {
    readonly type = LOAD_JOBS_CATEGORIES;
    constructor() {}
}

export class LoadJobsCategoriesFail implements Action {
    readonly type = LOAD_JOBS_CATEGORIES_FAIL;
    constructor(public payload: any) {}
}

export class LoadJobsCategoriesSuccess implements Action {
    readonly type = LOAD_JOBS_CATEGORIES_SUCCESS;
    constructor(public payload: JobCategory[]) {}
}

export class AddJobsCategories implements Action {
    readonly type = ADD_JOBS_CATEGORIES;
    constructor(public payload: JobCategory[]) {}
}

export class UpdateJobsCategories implements Action {
    readonly type = UPDATE_JOBS_CATEGORIES;
    constructor(public payload: JobCategory[]) {}
}

export class DeleteJobsCategories implements Action {
    readonly type = DELETE_JOBS_CATEGORIES;
    constructor(public payload: JobCategory[]) {}
}

export class ClearJobsCategories implements Action {
    readonly type = CLEAR_JOBS_CATEGORIES;
    constructor() {}
}

export type JobsCategoriesAction =
    | LoadJobsCategories
    | LoadJobsCategoriesFail
    | LoadJobsCategoriesSuccess
    | AddJobsCategories
    | UpdateJobsCategories
    | DeleteJobsCategories
    | ClearJobsCategories;
