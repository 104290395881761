import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../modules/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

    handleError(err) {
        if (err instanceof HttpErrorResponse) {
            if (err.status === 401 && err.url.indexOf('microsoft') === -1) {
                this.authService.unauthorized = true;
                this.authService.logout();
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                const queryParams: any = {};
                if (returnUrl.length > 1) {
                    queryParams.returnUrl = returnUrl;
                }
                if (this.router.url !== '/auth/signin') {
                    this.router.navigate(['/auth/signin'], { queryParams });
                }
            }
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this.authService.getAuthorizationToken();
        const whiteList = [
            'accounts.google.com',
            'ipgeolocation',
            'storage.googleapis.com',
            'emsiservices.com',
            'emsicloud.com'
        ];
        if (whiteList.some((item) => req.url.indexOf(item) !== -1)) {
            return next.handle(req);
        }
        const authReq =
            req.url === 'https://eu-west-1.aws.realm.mongodb.com/api/client/v2.0/app/testingd9-swxkf/graphql'
                ? req.clone({ setHeaders: { Authorization: `Bearer ${localStorage.getItem('realmToken')}` } })
                : req.clone({ setHeaders: { Authorization: 'Bearer ' + authToken } });
        return next.handle(authReq).pipe(
            tap(
                (event: HttpEvent<any>) => {},
                (err: any) => this.handleError(err)
            )
        );
    }
}
