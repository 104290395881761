import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-candidate-block',
    templateUrl: './candidate-block.component.html',
    styleUrls: ['./candidate-block.component.scss']
})
export class CandidateBlockComponent implements OnInit {
    @Input() candidate: any;
    @Input() candidateRating: any;
    @Input() selected: boolean;
    @Input() userRole: string;
    @Input() stage: string;
    @Output() onSelect = new EventEmitter<string>();
    @HostBinding('class.declined') variableName = false;
    constructor() {}

    ngOnInit() {
        this.checkDeclinedHost();
    }

    titleCase(str: string) {
        return str
            ? str
                  .replace(/#/g, '')
                  .split(/[-|_]/g)
                  .join(' ')
                  .replace(/\w*\S/g, (t) => t[0].toUpperCase() + t.substr(1))
            : '';
    }

    onSelectCandidateClick(event, candidateId) {
        event.preventDefault();
        event.stopPropagation();
        this.onSelect.next(candidateId);
    }

    checkDeclinedHost() {
        this.variableName =
            this.candidate?.appliedStageClass === 'deleted' ||
            this.candidate.currentStageClass === 'deleted' ||
            this.candidate.appliedStageClass === 'self-deleted' ||
            this.candidate.currentStageClass === 'self-deleted';
    }
    shortNameHandler(candidate) {
        let fullName = candidate.firstName + ' ' + candidate.lastName;
        return fullName
            .split(' ')
            .map((n) => n[0])
            .join('');
    }
}
