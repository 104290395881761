<div class="container candidate-component">
    <div class="candidate-component__profile" *ngIf="candidate">
        <div class="candidate-main-info">
            <div class="img-block" *ngIf="candidate.profile_image_link">
                <img [src]="candidate.profile_image_link" alt="profile image" />
            </div>
            <strong class="name">{{ candidate.first_name | titlecase }} {{ candidate.last_name | titlecase }}</strong>
            <p class="position-block" *ngIf="candidate.employment_history && candidate.employment_history.length">
                <span class="position">{{ candidate.employment_history[0].title | titlecase }} </span>
                <span class="place">@ {{ candidate.employment_history[0].company | titlecase }}</span>
            </p>
            <div class="information-circles">
                <!-- Enthnicity -->
                <div
                    class="info-circle"
                    *ngIf="candidate.ethnicity"
                    [pTooltip]="candidate.ethnicity"
                    tooltipPosition="bottom"
                >
                    {{ candidate.ethnicity | titlecase | slice: 0:1 }}
                </div>
                <!-- Gender -->
                <div
                    class="info-circle"
                    *ngIf="candidate.gender"
                    [pTooltip]="candidate.gender"
                    tooltipPosition="bottom"
                >
                    {{ candidate.gender | titlecase | slice: 0:1 }}
                </div>

                <div
                    class="info-circle relocate"
                    [ngClass]="{ ready: candidate.relocate, 'not-ready': !candidate.relocate }"
                    [pTooltip]="candidate.relocate ? 'Will relocate' : 'Will not relocate'"
                    tooltipPosition="bottom"
                    *ngIf="showRelocate"
                >
                    <img *ngIf="candidate.relocate" src="/assets/images/relocate.svg" alt="" />
                    <img *ngIf="!candidate.relocate" src="/assets/images/no-relocate.svg" alt="" />
                </div>
            </div>

            <ng-container *ngIf="currentRating && !contentLoading">
                <div class="candidate-rating-info flex-row jcc">
                    <svg
                        *ngFor="let key of currentRating | countIterable"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                    >
                        <path
                            fill="#F7B500"
                            d="M7.5 0L5.328 5.148 0 5.73 3.984 9.492 2.863 15 7.5 12.175 12.137 15 11.016 9.492 15 5.73 9.672 5.148z"
                            transform="translate(.438)"
                        />
                    </svg>
                </div>
            </ng-container>
        </div>
        <div class="candidate-profile-row tags">
            <div class="title">
                <span>Tags</span>
            </div>
            <div class="content">
                <app-candidate-tags
                    *ngIf="job && candidate"
                    [job]="job"
                    [candidate]="candidate"
                    [offerAudit]="offerAudit"
                    (tagUpdated)="tagUpdated($event)"
                >
                </app-candidate-tags>
            </div>
        </div>
        <div class="candidate-profile-row contact-row">
            <div class="title">
                <span>Contact</span>
            </div>
            <div class="content">
                <div class="detail-information">
                    <img src="assets/images/email-send.svg" height="13px" alt="Email icon" />
                    <p>
                        <a href="mailto:{{ candidate.email }}">{{ candidate.email }}</a>
                    </p>
                </div>
                <div class="detail-information">
                    <img src="assets/images/phone.svg" height="13px" alt="Phone icon" />
                    <p class="text">{{ candidate.phone || 'Add a phone number' }}</p>
                </div>
                <div class="detail-information">
                    <img src="assets/images/location.svg" height="14px" alt="Location icon" />
                    <p>{{ candidate.location || 'Unknown Location' }}</p>
                </div>
                <div class="detail-information" *ngIf="candidate.website_url">
                    <img src="assets/images/web.svg" height="13px" width="13px" alt="web icon" />
                    <p class="text">
                        <a href="{{ candidate.website_url }}">{{ candidate.website_url }}</a>
                    </p>
                </div>
                <div class="detail-information" *ngIf="candidate.facebook_url">
                    <img src="assets/images/facebook.svg" alt="Facebook icon" />
                    <p class="text">
                        <a href="{{ candidate.facebook_url }}"> {{ candidate.facebook_url }}</a>
                    </p>
                </div>
                <div class="detail-information" *ngIf="candidate.instagram_url">
                    <img src="assets/images/instagram_new.svg" alt="Instagram icon" />
                    <p class="text">
                        <a href="{{ candidate.instagram_url }}"> {{ candidate.instagram_url }}</a>
                    </p>
                </div>
                <div class="detail-information" *ngIf="candidate.github_url">
                    <img src="assets/images/github.svg" alt="Github icon" />
                    <p class="text">
                        <a href="{{ candidate.github_url }}"> {{ candidate.github_url }}</a>
                    </p>
                </div>
                <div class="detail-information" *ngIf="candidate.linkedin_url">
                    <img src="assets/images/linkedin.svg" alt="Linkedin icon" />
                    <p class="text">
                        <a href="{{ candidate.linkedin_url }}"> {{ candidate.linkedin_url }}</a>
                    </p>
                </div>
                <div class="detail-information" *ngIf="candidate.twitter_url">
                    <img src="assets/images/twitter.svg" alt="Twitter icon" />
                    <p class="text">
                        <a href="{{ candidate.twitter_url }}"> {{ candidate.twitter_url }}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="candidate-component__info">
        <div class="sections-nav">
            <button class="btn grey send-email" (click)="onShowEmailModal()">
                <img src="/assets/images/icons/icons-8-chat-bubble.svg" alt="" style="width: 14px; height: 11px;" />
            </button>
            <div
                class="section-nav-link"
                *ngFor="let section of sections"
                (click)="onChangeSection(section)"
                [ngClass]="{
                    active: activeSection === section,
                    danger: (section === 'questions' || section === 'assessments') && isKnockout(section) === 'danger',
                    warning: (section === 'questions' || section === 'assessments') && isKnockout(section) === 'warning'
                }"
            >
                <div class="circle warning"></div>
                <div class="circle danger"></div>
                <span>{{ section | titlecase }}</span>
            </div>
        </div>
        <div class="candidate-content">
            <div class="content-section" *ngIf="activeSection === 'overview'">
                <app-candidate-overview
                    *ngIf="candidate"
                    [candidate]="candidate"
                    (uploadFile)="uploadFile($event)"
                ></app-candidate-overview>
            </div>
            <div class="content-section" *ngIf="activeSection === 'details'">
                <app-candidate-details [candidate]="candidate"></app-candidate-details>
            </div>
            <div class="content-section" *ngIf="activeSection === 'resume'">
                <ng-container *ngIf="candidate && candidate.resume_link_new">
                    <pdf-viewer
                        [src]="candidate.resume_link_new"
                        [render-text]="true"
                        [original-size]="false"
                        [autoresize]="true"
                        style="display: block; max-width: 100%"
                    ></pdf-viewer>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<p-dialog
    [(visible)]="declineModalVisible"
    (onHide)="onShowModal(false)"
    [style]="{ width: '460px' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [dismissableMask]="true"
    [showHeader]="false"
    class="decline-modal"
    *ngIf="job"
    [formGroup]="declineForm"
>
    <div class="p-header">
        <svg
            width="19.4px"
            height="16.7px"
            viewBox="0 0 30 26"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <g id="Recruit-Setup" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Candidate-View---Details" transform="translate(-326.000000, -356.000000)">
                    <g id="icons8-error" transform="translate(326.000000, 356.000000)">
                        <path
                            d="M27.5675676,26 L1.62162162,26 C1.04027027,26 0.505135135,25.6888125 0.215675676,25.18425 C-0.072972973,24.6796875 -0.0713513514,24.0589375 0.221351351,23.556 L13.1943243,0.806 C13.4845946,0.307125 14.0181081,0 14.5945946,0 C15.1710811,0 15.7045946,0.307125 15.9956757,0.806 L28.9686486,23.556 C29.2613514,24.0589375 29.262973,24.6796875 28.9743243,25.18425 C28.6856757,25.6888125 28.1481081,26 27.5675676,26 Z"
                            id="Path"
                            fill="#FFC107"
                        ></path>
                        <path
                            d="M12.972973,21.240375 C12.972973,21.0218125 13.0110811,20.8219375 13.0889189,20.63425 C13.1651351,20.449 13.2745946,20.288125 13.4156757,20.15325 C13.5543243,20.01675 13.7254054,19.9103125 13.9216216,19.8339375 C14.1194595,19.7591875 14.3416216,19.719375 14.5897297,19.719375 C14.8378378,19.719375 15.0616216,19.7591875 15.2610811,19.8339375 C15.4637838,19.9103125 15.6348649,20.01675 15.7735135,20.15325 C15.9154054,20.288125 16.0248649,20.449 16.1002703,20.63425 C16.1781081,20.8219375 16.2162162,21.0218125 16.2162162,21.240375 C16.2162162,21.45975 16.1781081,21.659625 16.1002703,21.841625 C16.0240541,22.0244375 15.9145946,22.1820625 15.7735135,22.319375 C15.6348649,22.45425 15.4637838,22.559875 15.2610811,22.637875 C15.0616216,22.712625 14.8378378,22.75 14.5897297,22.75 C14.3408108,22.75 14.1194595,22.712625 13.9216216,22.6370625 C13.7254054,22.5590625 13.5543243,22.4534375 13.4156757,22.3185625 C13.2737838,22.18125 13.1643243,22.023625 13.0889189,21.8408125 C13.0110811,21.6588125 12.972973,21.4589375 12.972973,21.240375 Z M15.7945946,17.875 L13.3840541,17.875 L13.0427027,8.125 L16.1351351,8.125 L15.7945946,17.875 Z"
                            id="Shape"
                            fill="#fff"
                            fill-rule="nonzero"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
        Decline Candidates
    </div>
    <div class="p-body">
        <p>
            You are about to decline <b>1</b> candidate from <b>{{ job.title }}</b> role.
        </p>
        <p>Please provide a reason</p>
        <div style="position: relative">
            <textarea class="textarea" formControlName="reason"></textarea>
            <div class="error-message" *ngIf="!declineForm.get('reason').valid && declineForm.get('reason').touched">
                Reason is required
            </div>
        </div>
    </div>
    <div class="p-footer">
        <div class="action">
            <p-checkbox binary="true" label="Send decline notification email" formControlName="send_email">
            </p-checkbox>
            <button class="btn danger" (click)="onDecline()">
                Decline
            </button>
        </div>
    </div>
</p-dialog>
