import * as fromJobsCategoriesActions from '../actions/categories.action';
import { JobCategory } from './../../../../../../core/models/cateogory';

export function reducer(state = [], action: fromJobsCategoriesActions.JobsCategoriesAction): JobCategory[] {
    switch (action.type) {
        case fromJobsCategoriesActions.LOAD_JOBS_CATEGORIES_SUCCESS: {
            const categories = action.payload;
            return [...categories];
        }

        case fromJobsCategoriesActions.ADD_JOBS_CATEGORIES: {
            const data = action.payload;
            const newState = [...state];
            if (data && data.length) {
                data.forEach((item) => {
                    if (!newState.find((c) => c.name === item.name)) {
                        newState.push(item);
                    }
                });
            }
            return newState.sort();
        }

        case fromJobsCategoriesActions.UPDATE_JOBS_CATEGORIES: {
            const data = action.payload;
            const newState = [...data];
            // console.log('UPDATE_JOBS_CATEGORIES');
            // console.log(newState);
            return newState.sort();
        }

        case fromJobsCategoriesActions.DELETE_JOBS_CATEGORIES: {
            const ids = action.payload;
            const newState = [...state].filter((item) => ids.indexOf(item.id) !== -1);
            return newState.sort();
        }

        case fromJobsCategoriesActions.CLEAR_JOBS_CATEGORIES: {
            return [];
        }
    }

    return state;
}
