import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilitiesService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import {} from 'events';

@Component({
    selector: 'app-document-preview',
    templateUrl: './document-preview.component.html',
    styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit {
    @Input() file;
    @Input() employeeId;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() documentUpdated: EventEmitter<any> = new EventEmitter();
    contentLoading = false;
    form: FormGroup;
    showReason = false;
    loading = true;

    constructor(
        public utilitiesService: UtilitiesService,
        private employeeService: EmployeesService,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        console.log(this.file);
        this.form = this.fb.group({
            reason: ['', Validators.required]
        });
    }

    onClose() {
        this.close.emit();
    }

    async onApproveDocument() {
        this.contentLoading = true;
        await this.employeeService.approveDocument(this.employeeId, this.file.id);
        this.file.status = 'approved';
        this.documentUpdated.emit(this.file);
        this.contentLoading = false;
    }

    async onRejectDocument() {
        this.showReason = true;
    }

    keyupReason(event) {
        console.log('keyup', event, this.form.valid);
        if (this.form.valid) {
            this.contentLoading = true;
            const data = {
                document: this.file,
                reason: this.form.value.reason
            };
            console.log(this.file);
            this.employeeService.rejectDocument(this.employeeId, data).subscribe(
                (response) => {
                    console.log('response', response);
                    this.file.status = 'rejected';
                    this.documentUpdated.emit(this.file);
                    this.contentLoading = false;
                },
                (err) => {
                    console.log(err);
                    this.file.status = 'rejected';
                    this.documentUpdated.emit(this.file);
                    this.contentLoading = false;
                }
            );
        }
    }

    onDocumentLoaded(event) {
        //hide loadeer
        this.loading = false;
    }
}
