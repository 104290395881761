import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilitiesService } from './utilities.service';

@Injectable({
    providedIn: 'root'
})
export class PreferencesService {
    apiURL: string = environment.apiUrl;

    constructor(private http: HttpClient, private utilities: UtilitiesService) {}

    // getPreferences() {
    //     return this.http
    //         .get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/settings/preferences`)
    //         .pipe(catchError((error: any) => throwError(error)));
    // }

    // savePreferences(data: any) {
    //     return this.http
    //         .put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/settings/preferences`, data)
    //         .pipe(catchError((error: any) => throwError(error)));
    // }

    // createPreferenceOption(data: any) {
    //     return this.http
    //         .post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/settings/preferences`, data)
    //         .pipe(catchError((error: any) => throwError(error)));
    // }

    uploadImage(data: any) {
        return this.http
            .post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/settings/preferences/image`, data)
            .pipe(catchError((error: any) => throwError(error)));
    }

    uploadImageForPortal(data: any) {
        return this.http
            .post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/settings/portal/image`, data)
            .pipe(catchError((error: any) => throwError(error)));
    }

    savePortalOption(data: any) {
        return this.http
            .put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/settings/portal`, data)
            .pipe(catchError((error: any) => throwError(error)));
    }

    // createNewLocation(data) {
    //     return this.http
    //         .post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/settings/preferences/location`, data)
    //         .pipe(catchError((error: any) => throwError(error)));
    // }

    // updateLocation(data) {
    //     return this.http
    //         .put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/settings/preferences/location`, data)
    //         .pipe(catchError((error: any) => throwError(error)));
    // }
}
