import { ITag } from './job';

export class Candidate {
    id?: string;
    first_name: string;
    last_name: string;
    stage: any;
    score?: any;
    skills?: any;
    matching?: any;
    gender?: string;
    ethnicity?: string;
    relocate?: boolean;
    management_story?: string;
    avg_months_per_employer?: any;
    resume_file?: string;
    resume_file_new?: string;
    resume_link?: string;
    resume_link_new?: string;
    questions?: any;
    source?: string;
    source_type?: string;
    feedback?: any;
    isDdEmployee?: boolean;
    languages?: any;
    summary?: string;
    employment_history?: any;
    last_position?: string;
    education_history?: any;
    employment_summary?: string;
    audit?: any;
    candidate_audit?: any;
    created_at?: number;
    stages_data?: any;
    markedAsUnsuccessful?: any;
    profile_image?: any;
    profile_image_link?: any;
    created_at_rel?: any;
    updated_at_rel?: any;
    job_specific?: any;
    read?: string[];
    application_completed?: any;
    tags?: ITag[];
    hasUser: boolean;
    hasUserReviewed: boolean;
    assignments?: any;
    blockData?: any;
    applicaitons?: any[];
    opportunities?: any[];
    userId?: string;
    uploaded?: any[];
    resume_file_type?: string;
    resume_file_new_type?: any[] | string;
    resumeId?: any;
    declinedJobs?: string[];
    job_id: string[];

    // contact data
    email: string;
    phone?: string;
    location?: string;
    facebook_url?: string;
    github_url?: string;
    instagram_url?: string;
    linkedin_url?: string;
    twitter_url?: string;

    owner?: string;
    fullName?: string;
    candidate_matches?: any;
    candidate_skills?: any[];
    loadedFull?: boolean;

    complianceRateClass?: string;
    stageClass?: string;
    order?: number;
    hasRead?: boolean;
    inProgress?: boolean;

    criminalCheckStatus?: string;
    idVerified?: boolean;
    criminalCheck?: any;
    personal?: any;
    time_off?: any[];
    start_date?: string;
    hiring_executive?: string;
    salary?: any[];
    job_details?: any;
    allowances?: any[];
    bonuses?: any[];
    id_number?: string;
    obfuscated_id_number?: string;
}
