import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

interface ChartData {
    labels: string[];
    data: string[];
    sum: number;
}

@Component({
    selector: 'app-pie-chart',
    templateUrl: './pie-chart.component.html',
    styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements AfterViewInit, OnInit {
    @Input() title;
    @Input() height;
    @Input() width;
    @Input() dataChart: ChartData;
    data: any;
    options: any;
    emptyChart = false;
    @ViewChild('chart') chart;
    @ViewChild('legend') legend: ElementRef;

    constructor() {}

    ngOnInit(): void {
        // console.log('dataChart', this.dataChart);
        this.emptyChart = this.dataChart.sum === 0 ? true : false;
        this.data = {
            labels: this.dataChart.labels,
            datasets: [
                {
                    data: this.dataChart.data,
                    backgroundColor: [
                        '#778ca3',
                        '#45aaf2',
                        '#a55eea',
                        '#20bf6b',
                        '#f7b731',
                        '#fc5c65',
                        '#DC143C',
                        '#FF8C00',
                        '#1a73e8',
                        '#D2AA1B'
                    ],
                    borderWidth: 0,
                    borderAlign: 'inner',
                    weight: 2
                }
            ]
        };
        this.options = {
            legend: {
                display: false,
                position: 'right'
            }
        };
    }

    ngAfterViewInit() {
        this.legendCallback(this.dataChart);
    }

    legendCallback(chart) {
        let text = '';
        text += '<ul>';
        /* tslint:disable */
        for (var i = 0; i < chart.data.length; i++) {
            if (chart.data[i] > 0) {
                const percent = chart.data[i] ? ((chart.data[i] / chart.sum) * 100).toFixed(1) : 0;
                text +=
                    '<li><span class="label">' +
                    this.dataChart.labels[i] +
                    '</span> <span class="count">' +
                    chart.data[i] +
                    '</span> <span class="line"><p style="background-color:' +
                    this.data.datasets[0].backgroundColor[i] +
                    ';width:' +
                    (chart.data[i] / chart.sum) * 100 +
                    '%"></p></span> <span class="percent">' +
                    percent +
                    '%</span>';
                text += '</li>';
            }
        }
        /* tslint:enable */
        text += '</ul>';
        this.legend.nativeElement.innerHTML = text;
    }
}
