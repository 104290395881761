import { Injectable } from '@angular/core';
import * as fromServices from '@app/core/services';
import * as stagesDataActions from '@app/products/hire/modules/jobs/store/actions/stagesData.action';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { WorkflowService } from '@app/core/services/workflow.service';

@Injectable()
export class StagesDataEffect {
    constructor(
        private actions$: Actions,
        private jobService: fromServices.JobService,
        private offerService: fromServices.OffersService,
        private questionnaireService: fromServices.QuestionnaireService,
        private integrationsService: fromServices.IntegrationsService,
        private workflowService: WorkflowService
    ) {}

    @Effect() loadStagesData$: Observable<Action> = this.actions$.pipe(
        ofType(stagesDataActions.LOAD_STAGES_DATA),
        switchMap(() => {
            return forkJoin([
                this.questionnaireService.getAll(),
                this.jobService.getDevskillerTest(),
                this.integrationsService.getInegrations(),
                this.offerService.getOfferTemplates(),
                this.workflowService.getManuallWorkflows(),
                this.jobService.getAlllocations()
            ]).pipe(
                map((resp: any) => new stagesDataActions.LoadStagesDataSuccess(resp)),
                catchError((error) => of(new stagesDataActions.LoadStagesDataFail(error)))
            );
        })
    );
}
