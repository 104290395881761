import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { from } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'app-read-more-height',
    templateUrl: './read-more-height.component.html',
    styleUrls: ['./read-more-height.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadMoreHeightComponent implements AfterViewInit {
    @Input() text: string;
    @Input() maxHeight: number = 100;
    public isCollapsed: boolean = false;
    public isCollapsible: boolean = false;
    public isLoading = true;
    constructor(private elementRef: ElementRef, private cdRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
        let currentHeight = this.elementRef.nativeElement.getElementsByTagName('p')[0].offsetHeight;
        if (currentHeight > this.maxHeight) {
            this.isCollapsed = true;
            this.isCollapsible = true;
        }
        from([1])
            .pipe(delay(0))
            .subscribe({
                next: () => null,
                complete: () => {
                    this.cdRef.markForCheck();
                    this.isLoading = false;
                }
            });
    }
}
