<div class="modal-default-style">
    <div class="content">
        <div class="header">
            <h3>Add {{ candidate.first_name }} {{ candidate.last_name }} to Another Job</h3>
            <button class="close-btn btn" (click)="onHideModal()">
                <img src="/assets/images/icons/icons-8-delete.svg" />
            </button>
        </div>
        <div class="form-content-section">
            <div class="content-view">
                <p>
                    Candidates can only be added to Published and Private jobs. Employees can be invited to Published,
                    Internal and Private jobs.
                </p>
                <div class="form-block">
                    <div class="input-block">
                        <app-auto-dropdown
                            [options]="jobTypeOptions"
                            [control]="jobControl"
                            placeholder="Role"
                            [ngClass]="{
                                invalid: !jobControl.valid && jobControl.touched
                            }"
                        >
                        </app-auto-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <button class="btn btn-primary mla" (click)="onAddCandidate()">Add Candidate</button>
        </div>
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>
<div class="overlay" (click)="onHideModal()"></div>
