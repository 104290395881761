import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numbertotext'
})
export class FromNumberToTextPipe implements PipeTransform {
    transform(value: any): string {
        if (value === 1) {
            return 'one';
        } else if (value === 2) {
            return 'two';
        } else if (value === 3) {
            return 'three';
        } else if (value === 4) {
            return 'four';
        } else if (value === 5) {
            return 'five';
        } else if (value === 6) {
            return 'six';
        } else {
            return value;
        }
    }
}
