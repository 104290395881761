import { HttpResponse } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { UIChart } from 'primeng/chart';
import { Subscription } from 'rxjs';
import { Candidate, Stage, User } from '../../../../../core/models';
import { SdkJob } from './../../../../../core/models/job';
import {
    CandidateService,
    JobService,
    QuestionnaireService,
    UserService,
    UtilitiesService
} from './../../../../../core/services';
import * as fromStore from './../../../../../store';

interface ISelect {
    label?: string;
    value: number | string;
}

@Component({
    selector: 'app-candidate-item-no-job',
    templateUrl: './candidate-item-no-job.component.html',
    styleUrls: ['./candidate-item-no-job.component.scss']
})
export class CandidateItemNoJobComponent implements OnInit, OnChanges, OnDestroy {
    @Input() candidateId: string;
    @Output() closeModal: EventEmitter<any> = new EventEmitter();
    math = Math;
    sections: string[] = ['overview', 'details', 'resume'];
    activeSection = 'overview';
    activeInteractivity = 'chat';
    job: SdkJob;
    stages: Stage[] = [];
    user: User;
    candidate: Candidate;
    radar_chart_data: any;
    radar_chart_options: any;

    contentLoading = true;
    uploadQueue: any[] = [];
    uploadError: string;
    supportedFileTypes: string[];
    showFeedback = false;
    jobOwner = false;
    questions: any[];
    questionsAnswers: any = {};
    questionnaire: any = null;
    questionsList: any[];

    personality_assessment: any = null;
    available_assessment = {};
    assignments = [];
    @ViewChild('chart') chart: UIChart;
    @ViewChild('appliedContent') appliedContent;
    baseUrl: string;

    declineModalVisible: boolean = false;
    declineModalForm: FormGroup;
    declineForm: FormGroup;
    modalSubmission: object = {};
    emailModalVisible: boolean = false;

    candidateSubscription: Subscription;
    userSubscription: Subscription;
    jobSubscription: Subscription;
    resumeThreshold: number;
    offerAssessment = null;
    offerAudit = null;
    questionnaires: any[] = [];
    stagesData: any[] = [];
    showRelocate: boolean = false;
    appliedJobs = [];
    appliedJobsId;
    appliedProgress;
    showRatingDropdown = false;

    potentialOwners;
    currentOwner;
    changedJobId = null;
    currentRating = null;
    candidateInterviews;
    documents: any[] = [];
    dataChanged = false;

    constructor(
        private jobService: JobService,
        private fb: FormBuilder,
        private candidateService: CandidateService,
        private utilities: UtilitiesService,
        private userService: UserService,
        private questionnaireService: QuestionnaireService,
        private store: Store<fromStore.State>
    ) {
        this.baseUrl = this.utilities.getHireBaseUrl();
        this.supportedFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.oasis.opendocument.text',
            'text/rtf'
        ];
        this.radar_chart_data = this.utilities.getRadarChartData();
        this.radar_chart_options = this.utilities.getRadarChartOptions();
    }

    ngOnInit() {
        this.contentLoading = true;
        this.changedJobId = null;
        this.loadCandidate(this.candidateId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
        // if (changes.candidateId) {
        //     this.contentLoading = true;
        //     this.changedJobId = null;
        //     this.loadCandidate(this.candidateId);
        // }
    }

    loadCandidate(candidateId) {
        this.unsubscribeSubscriptions();
        this.candidateService.getCandidateNoJob(candidateId).subscribe((candidate: Candidate) => {
            console.log(candidate);
            this.getCandidateInfo(candidate);
        });

        this.userSubscription = this.store.pipe(select(fromStore.getUserEntity)).subscribe((user: User) => {
            this.user = user;
        });
    }

    onRatingClick(e) {
        e.stopPropagation();
        this.showRatingDropdown = !this.showRatingDropdown;
    }

    get candidateNameOrEmail() {
        if (!this.candidate) return '';
        const { first_name, last_name, email } = this.candidate;
        return { first_name, last_name, email };
    }

    setCandidateFields() {
        if (!this.candidate) {
            return;
        }
        if (this.candidate.profile_image && !this.candidate.profile_image_link) {
            if (this.candidate.profile_image.includes('https://')) {
                this.candidate.profile_image_link = this.candidate.profile_image;
            } else {
                this.candidateService.getProfileImageLink(`${this.candidate.profile_image}&collection=Users`).subscribe(
                    (response: string) => (this.candidate.profile_image_link = response),
                    (errorResponse) => console.error(errorResponse)
                );
            }
        }
    }

    completeCandidateWithData(data: any) {
        // console.log('GOT candidate data:', data);
        if (data.education && data.education.languages) {
            this.candidate.languages = data.education.languages;
        }
        if (data.education && data.education.education_history) {
            this.candidate.education_history = data.education.education_history;
        }
        if (data.skills) {
            this.candidate.skills = data.skills;
        }
        if (data.summary) {
            this.candidate.summary = data.summary;
        }
        if (data['candidate-resume'] && data['candidate-resume'].employment_summary) {
            this.candidate.employment_summary = data['candidate-resume'].employment_summary;
        }
        if (data['candidate-resume'] && data['candidate-resume'].employment_history) {
            this.candidate.employment_history = data['candidate-resume'].employment_history;
        }
        if (data.sex) {
            this.candidate.gender = data.sex;
        }
        if (data.ethnicity) {
            this.candidate.ethnicity = data.ethnicity;
        }
        if (data.management_story) {
            this.candidate.management_story = data.management_story;
        }
        if (data.avg_months_per_employer) {
            this.candidate.avg_months_per_employer = this.utilities.convertMonthsToYears(
                parseInt(data.avg_months_per_employer, 10)
            );
        }
        this.checkShowSections();
    }

    checkShowSections() {
        if (!this.candidate.resume_file && !this.candidate.resume_file_new) {
            this.sections = this.sections.filter((section) => section !== 'details');
        }
    }

    getExtension(filename) {
        const allowedExtensions = ['pdf'];
        const newFilename = filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
        if (allowedExtensions.indexOf(newFilename) !== -1) {
            return newFilename;
        } else {
            return 'docx';
        }
    }

    checkResumeFile() {
        // Attachments
        if (!this.candidate.resume_file && !this.candidate.resume_file_new && this.candidate.source !== 'application') {
            this.activeSection = 'attachments';
        }
        if (this.candidate.resume_file && this.candidate.resume_file.length) {
            this.candidateService.getResumeLink(this.candidate.resume_file).subscribe(
                (response: string) => (this.candidate.resume_link = response),
                (errorResponse) => console.error(errorResponse)
            );

            this.candidate.resume_file_type = this.getExtension(this.candidate.resume_file);
        }
        if (this.candidate.resume_file_new && this.candidate.resume_file_new.length) {
            this.candidateService.getResumeLink(this.candidate.resume_file_new).subscribe(
                (response: string) => (this.candidate.resume_link_new = response),
                (errorResponse) => console.error(errorResponse)
            );

            this.candidate.resume_file_new_type = this.getExtension(this.candidate.resume_file_new);
        }
    }

    checkOtherFiles() {
        if (this.candidate.uploaded && this.candidate.uploaded.length) {
            for (let i = 0; i <= this.candidate.uploaded.length - 1; i++) {
                this.candidateService.getResumeLink(this.candidate.uploaded[i].name).subscribe(
                    (response: string) => {
                        if (this.candidate && this.candidate.uploaded && this.candidate.uploaded[i]) {
                            this.candidate.uploaded[i]['link'] = response;
                            this.candidate.uploaded[i]['type'] = this.getExtension(this.candidate.uploaded[i].name);
                        }
                    },
                    (errorResponse) => console.error(errorResponse)
                );
            }
        }
    }

    dateFormat(date) {
        return moment.unix(date).format('DD MMMM YYYY');
    }

    onChangeSection(section: string) {
        this.activeSection = section;
        if (this.chart) {
            this.chart.refresh();
        }
    }

    onChangeInteractivity(section: string) {
        this.activeInteractivity = section;
    }

    onBackClick() {
        this.closeModal.emit();
    }

    uploadFile(item) {
        this.utilities
            .readFile(item.file)
            .then((fileValue) => {
                item.uploadStarted = true;
                const uploadProgressInterval = setInterval(() => {
                    item.progress = item.progress + 1 < 100 ? item.progress + 1 : item.progress;
                }, 400);

                this.jobService
                    .addDocumentsToCandidate(this.job.id, this.candidateId, {
                        document: fileValue,
                        documentType: item.type,
                        name: item.text
                    })
                    .subscribe(
                        (response: HttpResponse<any>) => {
                            item.progress = 100;
                            item.uploadFinished = true;
                            item.success = true;
                            item.fadeout = true;
                            clearInterval(uploadProgressInterval);

                            const resp: any = response;
                            this.candidate.uploaded = [...resp.candidate.uploaded];
                            console.log(this.candidate.uploaded);
                            for (const file of this.candidate.uploaded) {
                                if (!this.documents.find((d) => d.name === file.name)) {
                                    this.documents.push({
                                        id: file.id,
                                        name: file.name,
                                        originalName: file.original_name || file.name,
                                        link: file.link,
                                        documentType: this.getDocumentType(file.type || 'other'),
                                        extension: this.getExtension(file.name)
                                    });
                                }
                            }

                            this.checkOtherFiles();
                        },
                        (error) => {
                            console.error(error);
                            item.text =
                                error && error.error && error.error.message ? error.error.error.message : 'Error';
                            item.progress = 100;
                            item.uploadFinished = true;
                            clearInterval(uploadProgressInterval);
                        }
                    );
            })
            .catch((error) => {
                console.error(error);
                console.error('Error reading uploaded file');
            });
    }

    onShowModal(visible = true) {
        if (visible) {
            this.declineModalForm.reset();
            delete this.modalSubmission['declineModalForm'];
        }
        this.declineModalVisible = visible;
        if (visible) {
            const stage =
                this.candidate.stage && this.candidate.stage[this.job.id] ? this.candidate.stage[this.job.id] : '';
            if (!stage || stage === 'applied') {
                this.declineForm.get('send_email').patchValue(true);
            } else {
                this.declineForm.get('send_email').patchValue(false);
            }
        }
    }

    onShowEmailModal(visible = true) {
        this.emailModalVisible = visible;
    }

    getCandidateInfo(candidate) {
        // console.log('!!!GET CANDIDATE INFO');
        if (!candidate && this.candidate) {
            console.log('Probably candidate was deleted and we need to close the modal');
        }
        if (!candidate) {
            // return this.router.navigateByUrl('/not-found');
        }
        this.candidate = candidate;
        this.showRelocate = false;
        this.setCandidateFields();
        this.candidateService.getCandidateDetails(this.candidateId).subscribe((response: any) => {
            const candidateData = response;
            if (candidateData) {
                // console.log('!!!Candidate:', candidate);
                this.completeCandidateWithData(candidateData);
            }

            this.checkResumeFile();
            this.checkOtherFiles();
            this.stagesData = [];
            this.contentLoading = false;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
        console.log('inner destroyed');
    }

    unsubscribeSubscriptions() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.candidateSubscription) {
            this.candidateSubscription.unsubscribe();
        }
        if (this.jobSubscription) {
            this.jobSubscription.unsubscribe();
        }
    }

    getDocumentType(type: string) {
        switch (type) {
            case 'resume':
                return 'Resume';
            case 'letter':
                return 'Cover Letter';
            case 'portfolio':
                return 'Portfolio';
            case 'id':
                return 'ID / Passport';
            case 'other':
                return 'Other';
            default:
                return 'Other';
        }
    }

    tagUpdated(event) {
        this.dataChanged = true;
        setTimeout(() => {
            this.dataChanged = false;
        }, 200);
    }
}
