import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from '@app/core/models';
import { CandidateService, JobService, QuestionnaireService, UtilitiesService } from '@app/core/services';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { SdkJob } from './../../../../../../core/models/job';

@Component({
    selector: 'app-candidate-questions',
    templateUrl: './candidate-questions.component.html',
    styleUrls: ['./candidate-questions.component.scss']
})
export class CandidateQuestionsComponent implements OnInit, OnChanges {
    @Input() questionnaires;
    @Input() stageId;
    @Input() candidate;

    @Input() personality_assessment;
    @Input() logicTest;
    @Input() criminalCheckAss;
    @Input() stagesData;
    @Input() job: SdkJob;
    @Input() user: User;
    @Input() videos;
    @Input() personalityProfileScores;
    @Output() extendDeadline: EventEmitter<any> = new EventEmitter();
    radar_chart_options;
    @Input() radar_chart_data;
    @Input() hideButtons;
    math = Math;
    candidateId;
    showVideoScore = false;
    stars: any[] = [{ index: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
    jobOwner = false;

    index: number = -1;
    jobId: string;
    resetPrompt = {
        show: false,
        text: 'Reset Questionnaire'
    };
    dataUpdated = false;
    constructor(
        private jobService: JobService,
        private utilities: UtilitiesService,
        private questionnaireService: QuestionnaireService,
        private candidateService: CandidateService,
        private toastr: ToastrService
    ) {
        this.radar_chart_data = this.utilities.getRadarChartData();
        this.radar_chart_options = this.utilities.getRadarChartOptions();
    }

    ngOnInit(): void {
        // console.log('questionnaires', this.questionnaires);
        // console.log('stageId', this.stageId);
        // console.log('candidate', this.candidate);

        // console.log('personality_assessment', this.personality_assessment);
        // console.log('logicTest', this.logicTest);
        // console.log('stagesData', this.stagesData);
        // console.log('videos', this.videos);
        // console.log('personalityProfileScores', this.personalityProfileScores);
        // console.log('radar_chart_data', this.radar_chart_data);
        console.log('QUESTIONNAIRES and CANDIDATE', this.questionnaires, this.candidate);
        this.candidateId = this.candidate.id;
        this.jobId = this.job.id;
        this.jobOwner = this.job.owner === this.user.id;
        this.criminalCheckAss = this.candidate.assignments.find((ass) => ass.type === 'criminal-check');

        console.log('criminalCheckAss', this.criminalCheckAss);
        this.calculateRating(this.questionnaires);
    }

    ngOnChanges(changes: SimpleChanges): void {}

    dateFormat(date) {
        return moment.unix(date).format('DD MMMM YYYY');
    }

    onExtendDeadline(data) {
        this.extendDeadline.emit(data);
    }

    onViewAndRate(id) {
        let index = this.videos.findIndex((v) => v.id === id);
        this.showVideoScore = true;
        this.videos[index].current = true;

        this.stars.forEach((s) => (s.active = false));
        if (this.videos[index].rating) {
            for (let i = 0; i <= this.videos[index].rating - 1; i++) {
                this.stars[i].active = true;
            }
        }
    }

    onCloseModal() {
        this.videos.forEach((s) => (s.current = false));
        this.stars.forEach((s) => (s.active = false));
        this.showVideoScore = false;
    }

    onResetQuestionnaire(event, item) {
        event.stopPropagation();
        /* tslint:disable */
        // for (let assignments in this.candidate.assignments) {
        //     if (assignments !== this.jobId) {
        //         const ass = this.candidate.assignments[assignments].find(
        //             (b) => item.option === b.option && item.completed
        //         );
        //         if (ass) {
        //             this.toastr.error('You cannot Reset Questionnaire, which is already completed in another job.');
        //             return false;
        //         }
        //     }
        // }
        /* tslint:enable */
        console.log('onResetQuestionnaire', item.id);
        if (this.resetPrompt.show) {
            item.completed = false;
            item.expired = false;
            item.fromCompleted = false;
            item.videos = null;
            item.invitationSent = moment().format('DD MMMM YYYY');
            this.index = -1;
            delete item.started_at;
            this.candidateService
                .resetQuestionnaire(this.job.id, this.candidate.id, item.id)
                .subscribe((response: any) => console.log(response));
        } else {
            this.resetPrompt = {
                show: true,
                text: 'Are you sure you want to reset ?'
            };
        }
    }

    onBlurResetQuestionnaireBtn() {
        this.resetPrompt = {
            show: false,
            text: 'Reset Questionnaire'
        };
    }

    onRatesUpdated() {
        this.calculateRating(this.questionnaires);
        this.dataUpdated = true;
        setTimeout(() => {
            this.dataUpdated = false;
        }, 100);
    }

    calculateRating(questionnaires) {
        questionnaires.forEach((v) => {
            v.questions.forEach((q) => {
                let sum = 0;
                let length = 0;
                if (q.type === 'video-interview' && q.videos && q.videos.length) {
                    q.videos.forEach((v) => {
                        if (v.rates) {
                            const rate = v.rates.find((r) => {
                                return r.id === this.user.id && r.rating > 0;
                            });
                            if (rate) {
                                sum += rate.rating;
                                length += 1;
                            }
                            v.evaluated = rate;
                        }
                    });
                    q.averageRating = (sum / length).toFixed(1);
                    if (q.averageRating === 5) {
                        q.ratingStatus = 'Excellent';
                    } else if (q.averageRating >= 4) {
                        q.ratingStatus = 'Good';
                    } else if (q.averageRating >= 3) {
                        q.ratingStatus = 'Average';
                    } else if (q.averageRating >= 2) {
                        q.ratingStatus = 'Poor';
                    } else {
                        q.ratingStatus = 'Very Poor';
                    }
                    q.allowShowScore = q.videos.every((v) => v.evaluated);
                }
            });
        });
    }

    isResetAvailable(item) {
        let allowedByRole = false;

        if (this.jobOwner || this.user.role === 'admin' || this.user.role === 'account_owner') {
            allowedByRole = true;
        }
        // check if hiring manager
        if (
            this.job.hiring_managers &&
            this.job.hiring_managers.length &&
            this.job.hiring_managers.find((hm) => hm === this.user.id)
        ) {
            allowedByRole = true;
        }
        // check if recruiter
        if (this.job.recruiters && this.job.recruiters.length && this.job.recruiters.find((r) => r === this.user.id)) {
            allowedByRole = true;
        }
        if (this.user.role === 'recruitment_agency' || this.user.role === 'agency_user') {
            allowedByRole = false;
        }
        const isResetAvailable = allowedByRole && (item.started_at || item.completed) ? true : false;
        return isResetAvailable;
    }

    get isAllowRateVideo() {
        return (
            this.user &&
            this.user.role === 'employee' &&
            ((this.job.hiring_managers && this.job.hiring_managers.indexOf(this.user.id) !== -1) ||
                (this.job.hiring_panels && this.job.hiring_panels.indexOf(this.user.id) !== -1))
        );
    }

    getAssignmentStatus(type, ass) {
        let status = '';
        if (type === 'criminal-check') {
            if (ass.completed) {
                if (ass.reportFile) {
                    status = 'Complete';
                } else if (ass.orderResponse) {
                    status = `Fingerprints Captured`;
                } else {
                    status = 'Pending';
                }
            } else if (ass.expired) {
                status = `Expired: ${ass.expired_at_formatted || ass.expired_at}`;
            } else {
                status = `Sent: ${ass.added_at_formatted || ass.added_at}`;
            }
        }

        return status;
    }

    convertSize(size: number) {
        const kb = size / 1000;
        const mb = size / 1000 / 1000;

        return mb > 1 ? `${mb.toFixed(2)} MB` : `${Math.round(kb)} KB`;
    }
}
