import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';

@Component({
    selector: 'app-input-phone',
    templateUrl: './input-phone.component.html',
    styleUrls: ['./input-phone.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputPhoneComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputPhoneComponent),
            multi: true
        }
    ]
})
export class InputPhoneComponent implements ControlValueAccessor, Validator, OnInit {
    pluginInstance: any;
    phoneValue: string;
    isValid: boolean;
    blur = false;
    @ViewChild('phoneInput') phoneInput: ElementRef;
    @Output() blurEmitter = new EventEmitter<boolean>();

    constructor(private elRef: ElementRef, private fb: FormBuilder) {}

    ngOnInit() {}
    @Input()
    @Input()
    set country(country: any) {
        if (country && country.length) {
            // console.log(country);
            setTimeout(() => {
                this.pluginInstance.intlTelInput && this.pluginInstance.intlTelInput('setCountry', country);
            }, 200);
        }
    }

    writeValue(value: any) {
        // if (value !== undefined) {
        //     // console.log('☎️ VALUE PASSED TO COMPONENT:', value);
        //     this.phoneInput.nativeElement.value = value;
        //     if (this.pluginInstance) {
        //         this.pluginInstance.intlTelInput('setNumber', value);
        //     }
        // }

        if (value !== undefined) {
            // console.log('☎️ VALUE PASSED TO COMPONENT:', value);
            // if (this.phoneInput)
            //     this.phoneInput.nativeElement.value = value;
            // if (this.pluginInstance) {
            //     this.pluginInstance.setNumber(value);
            // }

            setTimeout(
                () => {
                    if (this.phoneInput) this.phoneInput.nativeElement.value = value;
                    if (this.pluginInstance) {
                        this.pluginInstance.setNumber(value);
                    }
                },
                this.phoneInput ? 0 : 50
            );
        }
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }
    onFocus() {
        this.elRef.nativeElement.classList.add('focus');
        this.blur = false;
    }
    onBlur() {
        this.elRef.nativeElement.classList.remove('focus');
        this.blur = true;
        this.blurEmitter.emit(true);
        this.onTouched();
    }

    onTouched = () => {};

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    telInstance(inst) {
        this.pluginInstance = inst;
    }

    onCountryChange(e) {
        if (e) {
            this.propagateChange(this.phoneInput.nativeElement.value);
        }
    }

    // onChange(event) {
    //     this.onTouched();
    //     const w: any = window;
    //     if (typeof w.intlTelInputUtils !== 'undefined') {
    //         const currentText = this.pluginInstance.intlTelInput('getNumber', w.intlTelInputUtils.numberFormat.E164);
    //         if (typeof currentText === 'string') {
    //             this.pluginInstance.intlTelInput('setNumber', currentText);
    //             this.propagateChange(this.phoneInput.nativeElement.value);
    //         }
    //     }
    // }

    onChange(event) {
        this.onTouched();
        const w: any = window;
        if (typeof w.intlTelInputUtils !== 'undefined') {
            const currentText = this.pluginInstance.getNumber(w.intlTelInputUtils.numberFormat.E164);
            if (typeof currentText === 'string') {
                this.pluginInstance.setNumber(currentText);
                this.propagateChange(this.phoneInput.nativeElement.value);
            }
        }
    }

    // public validate() {
    //     const w: any = window;
    //     if (typeof this.pluginInstance !== 'undefined' && typeof w.intlTelInputUtils !== 'undefined') {
    //         const valid = w.intlTelInputUtils.isValidNumber(this.pluginInstance.intlTelInput('getNumber'));
    //         const response = valid
    //             ? null
    //             : {
    //                   incorrect_number: true
    //               };
    //         return response;
    //     } else {
    //         return null;
    //     }
    // }

    public validate() {
        const w: any = window;
        if (typeof this.pluginInstance !== 'undefined' && typeof w.intlTelInputUtils !== 'undefined') {
            const valid = w.intlTelInputUtils.isValidNumber(this.phoneInput.nativeElement.value);
            const response =
                valid || !this.phoneInput.nativeElement.value
                    ? null
                    : {
                          incorrect_number: true
                      };
            if (!valid) {
                this.onTouched();
            }
            return response;
        } else {
            return null;
        }
    }
}
