import * as fromJobsActions from '../actions/jobs.action';
import { SdkJob } from './../../../../../../core/models/job';

export interface JobsListState {
    entities: { [id: string]: SdkJob };
    loaded: boolean;
    loading: boolean;
    updated: boolean;
}

export const initialState: JobsListState = {
    entities: {},
    loaded: false,
    loading: false,
    updated: false
};

export function reducer(state = initialState, action: fromJobsActions.JobsAction): JobsListState {
    switch (action.type) {
        case fromJobsActions.LOAD_JOBS: {
            return { ...state, loading: true };
        }

        case fromJobsActions.LOAD_JOBS_FAIL: {
            return { ...state, loading: false, loaded: false };
        }

        case fromJobsActions.LOAD_JOBS_SUCCESS: {
            const jobs = action.payload;
            const entities = jobs.reduce((_entities: { [id: string]: SdkJob }, job) => {
                return {
                    ..._entities,
                    [job.id]: job
                };
            }, {});
            return { ...state, loading: false, loaded: true, entities };
        }

        case fromJobsActions.LOAD_JOB_SUCCESS: {
            const job = action.payload;
            const entities = { ...state.entities };
            entities[job.id] = job;
            return { ...state, entities };
        }

        case fromJobsActions.CREATE_JOB_SUCCESS: {
            const job = action.payload;
            const entities = { ...state.entities, [job.id]: job };
            return { ...state, entities };
        }

        case fromJobsActions.UPDATE_JOB: {
            const { id, data } = action.payload;
            const entities = { ...state.entities, [id]: data };
            return { ...state, entities };
        }

        case fromJobsActions.UPDATE_JOB_SUCCESS: {
            const job = action.payload;
            const entities = { ...state.entities, [job.id]: job };
            return { ...state, entities };
        }

        case fromJobsActions.UPDATE_JOBS_SUCCESS: {
            const jobs = action.payload;
            const entities = jobs.reduce((_entities: { [id: string]: SdkJob }, job) => {
                return {
                    ..._entities,
                    [job.id]: job
                };
            }, {});
            return { ...state, loading: false, loaded: true, entities, updated: true };
        }

        case fromJobsActions.BULK_DELETE_JOBS_SUCCESS: {
            const ids = action.payload;
            const entities = { ...state.entities };
            ids.forEach((id) => {
                delete entities[id];
            });
            return { ...state, entities };
        }

        case fromJobsActions.UPDATE_JOB_FIELDS_IN_LIST: {
            const { id, data } = action.payload;
            const entities = { ...state.entities };
            const job = entities[id];
            entities[id] = { ...job, ...data };
            return { ...state, entities };
        }

        case fromJobsActions.DELETE_JOB: {
            const id = action.payload;
            const entities = { ...state.entities };
            const job = entities[id];
            if (job) {
                delete entities[id];
            }
            return { ...state, entities };
        }
    }

    return state;
}

export const getJobsUpdated = (state: JobsListState) => state.updated;
export const getJobsLoading = (state: JobsListState) => state.loading;
export const getJobsLoaded = (state: JobsListState) => state.loaded;
export const getJobEntities = (state: JobsListState) => state.entities;
