import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelperService } from '@app/core/services/form-helper.service';

@Component({
    selector: 'app-candidate-education',
    templateUrl: './candidate-education.component.html',
    styleUrls: ['./candidate-education.component.scss']
})
export class CandidateEducationComponent implements OnInit {
    @Input() form: FormGroup;

    @Input() set formData(data: any) {
        if (data && this.form) {
            this.populateForm(data);
        }
    }
    educationInvalid = [];

    degreesOptions = [
        { value: 'diploma', label: 'Diploma' },
        { value: 'graduate', label: 'Graduate' },
        { value: 'high school certificate', label: 'High School Certificate' },
        { value: 'postgraduate', label: 'Postgraduate' },
        { value: 'postgraduate diploma', label: 'Postgraduate Diploma' },
        { value: 'relevant qualification', label: 'Relevant Qualification' },
        { value: 'undergraduate', label: 'Undergraduate' }
    ];

    constructor(private fb: FormBuilder, public formHelper: FormHelperService) {}

    ngOnInit(): void {}

    get educationHistory() {
        return this.form.get('education') as FormArray;
    }

    populateForm(data) {
        data.forEach((item) => {
            const formData = {
                school: [item.school || '', Validators.required],
                degree: [item.degree || '', Validators.required],
                start_date: [item.start_date, Validators.required],
                end_date: [item.end_date, Validators.required],
                area_of_study: [item.area_of_study || '', Validators.required],
                score: [item.score || '']
            };
            this.educationHistory.push(this.fb.group(formData));
            !item.school || !item.degree || !item.end_date || !item.start_date || !item.area_of_study
                ? this.educationInvalid.push(true)
                : this.educationInvalid.push(false);
        });
        this.formHelper.markFormGroupTouched(this.form);
    }

    onSectionRemove(i: number) {
        this.educationHistory.removeAt(i);
        this.educationInvalid.splice(i, 1);
    }

    onSectionAdd() {
        this.educationHistory.push(
            this.fb.group({
                school: ['', Validators.required],
                degree: ['', Validators.required],
                start_date: ['', Validators.required],
                end_date: ['', Validators.required],
                area_of_study: ['', Validators.required],
                score: ['']
            })
        );
        this.educationInvalid.push(true);
    }
}
