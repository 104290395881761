import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '@app/core/services/user.service';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { UtilitiesService } from './utilities.service';

@Injectable({
    providedIn: 'root'
})
export class SurveyService {
    apiURL: string = environment.apiUrl;
    tenantId: string;
    private subject: Subject<any> = new Subject<any>();

    constructor(
        private http: HttpClient,
        private firestore: AngularFirestore,
        private utilities: UtilitiesService,
        private userService: UserService
    ) {
        this.tenantId = this.utilities.getTenant();
    }

    getSurveys() {
        return this.firestore
            .collection(`tenants/${this.tenantId}/surveys`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getSurveyById(id) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/surveys`)
            .doc(id)
            .valueChanges()
            .pipe(take(1));
    }

    saveSurvey(surveyId, data: any, id = null) {
        if (surveyId === 'new') {
            return this.firestore
                .collection(`tenants/${this.tenantId}/surveys`)
                .doc(id)
                .set(data);
        } else {
            return this.firestore
                .collection(`tenants/${this.tenantId}/surveys`)
                .doc(surveyId)
                .update(data);
        }
    }
}
