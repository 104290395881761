import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MsService {
    _isAuthenticated: BehaviorSubject<boolean>;
    GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0';

    scopes = [
        'user.read',
        'openid',
        'profile',
        'Calendars.ReadWrite',
        'Contacts.ReadWrite',
        'Group.ReadWrite.All',
        'OnlineMeetings.ReadWrite'
    ];
    config = {
        scopes: this.scopes
    };
    extraScopes = ['Calendars.ReadWrite', 'Contacts.ReadWrite'];
    extraConfig = {
        extraScopesToConsent: this.extraScopes
    };

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Prefer: 'outlook.timezone="South Africa Standard Time"'
        })
    };

    isAuthenticated = false;
    newToastrAllowed = true;

    constructor(
        private msal: MsalService,
        private http: HttpClient,
        private broadcastService: BroadcastService,
        private cookie: CookieService,
        private toastr: ToastrService
    ) {
        this._isAuthenticated = new BehaviorSubject(false);
        if (this.msal.getAccount()) {
            // console.log('MSAL account:', this.msal.getAccount());
            this.setAuthenticated(true);
        }

        this.subscriptions();
    }

    handleError(error) {
        if (
            this.isSafari() &&
            error &&
            error.errorMessage &&
            error.errorMessage.indexOf('Error opening popup window') !== -1
        ) {
            if (this.newToastrAllowed) {
                const errorMessage = `Please allow popups for this site and turn off 'Prevent cross-site tracking' option in Preferences -> Privacy settings of your browser and reload the page to work with Office365 Calendar.`;
                this.toastr.error(errorMessage);

                this.cookie.set('msal_access_token', '', 12, '/');
                this.setAuthenticated(false);

                this.newToastrAllowed = false;
                setTimeout(() => {
                    this.newToastrAllowed = true;
                }, 2000);
            } else {
                console.log('New toastr is not allowed yet');
            }
        }
    }

    subscriptions() {
        this.broadcastService.subscribe('msal:loginFailure', (error) => {
            console.log('[MSAL] loginFailure', error);
            this.handleError(error);
            this.setAuthenticated(false);
            this.msal.acquireTokenSilent(this.config).then((response) => {
                if (response) {
                    console.log('[MSAL] Token:', response);
                }
            });
        });

        this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
            console.log('[MSAL] loginSuccess', payload);
            // this.setMsalTokenInCookies(payload);
            this.setAuthenticated(true);
            this.msal.acquireTokenSilent(this.config).then((response) => {
                if (response) {
                    console.log('[MSAL] Token:', response);
                }
            });
        });

        this.broadcastService.subscribe('msal:gotTokenSilent', (payload) => {
            console.log('[MSAL] gotTokenSilent', payload);
            this.setMsalTokenInCookies(payload);
            this.setAuthenticated(true);
        });

        this.broadcastService.subscribe('gotTokenPopup', (payload) => {
            console.log('[MSAL] gotTokenPopup', payload);
            this.setMsalTokenInCookies(payload);
            this.setAuthenticated(true);
        });

        this.broadcastService.subscribe('msal:notAuthorized', (error) => {
            console.log('[MSAL] notAuthorized', error);
            // if (this.isSafari() && error && error.errorMessage && error.errorMessage.indexOf('AADSTS50058') !== -1) {
            //     const errorMessage = `Please turn off 'Prevent cross-site tracking' option in Preferences -> Privacy settings of your browser and reload the page to work with Office365 Calendar.`;
            //     this.toastr.error(errorMessage);
            // }
            this.setAuthenticated(false);
        });
    }

    getAuthenticated() {
        return this.isAuthenticated;
    }

    setAuthenticated(value) {
        this.isAuthenticated = value;
        this._isAuthenticated.next(value);
    }

    public get $isAuthenticated(): Observable<boolean> {
        return this._isAuthenticated.asObservable();
    }

    authenticate() {
        console.log('[MsService] authenticate =>');
        if (this.msal.getAccount()) {
            console.log('[MsService] authenticate => getting token silently', this.msal.getAccount());
            this.getTokenPopup();
        } else {
            console.log('[MsService] authenticate => show login popup');
            this.login();
        }
    }

    login() {
        console.log('[MsService] login');
        const isIE =
            window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

        if (isIE) {
            this.msal.loginRedirect(this.extraConfig);
        } else {
            this.msal.loginPopup(this.extraConfig);
        }
    }

    getTokenPopup() {
        return this.msal
            .acquireTokenSilent(this.config)
            .catch((error) => {
                console.error('[MsService] Silent token acquisition failed:', error);
                this.broadcastService.broadcast('msal:notAuthorized', error);
                return this.msal
                    .acquireTokenPopup(this.config)
                    .then((tokenResponse) => {
                        console.log('[MsService] Response from getTokenPopup:', tokenResponse);
                        if (tokenResponse) {
                            this.broadcastService.broadcast('msal:gotTokenPopup', tokenResponse);
                        }
                        return tokenResponse;
                    })
                    .catch((error) => {
                        console.error('[MsService] Getting token from popup failed:', error);
                        this.broadcastService.broadcast('msal:notAuthorized', error);
                        this.login();
                    });
            })
            .then((response) => {
                console.log('[MsService] [getTokenPopup]', response);
                if (!response) {
                    this.broadcastService.broadcast('msal:notAuthorized', response);
                    return this.msal
                        .acquireTokenPopup(this.config)
                        .then((tokenResponse) => {
                            console.log('[MsService] Response from getTokenPopup:', tokenResponse);
                            if (tokenResponse) {
                                this.broadcastService.broadcast('msal:gotTokenPopup', tokenResponse);
                            }
                            return tokenResponse;
                        })
                        .catch((error) => {
                            console.error('[MsService] Getting token from popup failed:', error);
                            this.broadcastService.broadcast('msal:notAuthorized', error);
                            this.login();
                        });
                } else {
                    this.broadcastService.broadcast('msal:gotTokenSilent', response);
                }
            });
    }

    getProfile() {
        return this.http.get(this.GRAPH_ENDPOINT + '/me', this.httpOptions).pipe(retry(1));
    }

    getCalendars() {
        return this.http.get(this.GRAPH_ENDPOINT + '/me/calendars', this.httpOptions);
    }

    getCalendar() {
        return this.http.get(this.GRAPH_ENDPOINT + '/me/calendar', this.httpOptions);
    }

    getSchedule(data: { schedules: string[]; startTime: any; endTime: any; availabilityViewInterval: number }) {
        return this.http.post(this.GRAPH_ENDPOINT + '/me/calendar/getSchedule', data, this.httpOptions);
    }

    getEvents(id: string) {
        return this.http.get(this.GRAPH_ENDPOINT + `/groups/${id}/events`, this.httpOptions);
    }

    createEvent(data: any) {
        return this.http.post(this.GRAPH_ENDPOINT + '/me/events', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Prefer: 'IdType="ImmutableId"'
            })
        });
    }

    createOnlineMeeting(data: any) {
        return this.http.post(this.GRAPH_ENDPOINT + '/me/onlineMeetings', data);
    }

    getEvent(calendarId: string, eventId: string) {
        return this.http.get(this.GRAPH_ENDPOINT + `/groups/${calendarId}/events/${eventId}`, this.httpOptions);
    }

    updateEvent(eventId: string, data) {
        return this.http.patch(this.GRAPH_ENDPOINT + `/me/events/${eventId}`, data).toPromise();
    }

    deleteEvent(eventId: string) {
        return this.http.delete(this.GRAPH_ENDPOINT + `/me/events/${eventId}`).toPromise();
    }

    findMeetingTimes(data: {
        attendees?: any[];
        timeConstraint?: any;
        locationConstraint?: any;
        isOrganizerOptional?: any;
        meetingDuration?: string;
        returnSuggestionReasons?: any;
        minimumAttendeePercentage?: any;
    }) {
        return this.http.post(this.GRAPH_ENDPOINT + '/me/findMeetingTimes', data, this.httpOptions);
    }

    logout() {
        return this.msal.logout();
    }

    setMsalTokenInCookies(payload: any) {
        // console.log('setMsalTokenInCookies', payload);
        this.cookie.set('msal_access_token', payload.accessToken || '', 12, '/');
    }

    isSafari() {
        return (
            navigator &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('Safari') != -1 &&
            navigator.userAgent.indexOf('Chrome') == -1
        );
    }
}
