import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isEqual } from 'lodash';
import { SelectItem } from 'primeng/api';
// import { UtilitiesService } from '@app/core/services';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
    @Input() options: SelectItem[];
    @Input() control: FormControl;
    @Input() placeholder: string;
    @Input() value: SelectItem;
    @Input() disabled: boolean;
    @Input() newStyle: boolean;
    @Output() onChange = new EventEmitter<any>();
    @ViewChild('matInput') matInput: any;
    @Input() set dropdownValueUpdated(v) {
        if (v) {
            this.ngOnInit();
        }
    }
    @ViewChild('autocomplete') autocomplete: any;
    @Input() readonly: boolean;

    filteredList: SelectItem[];
    filterControl: FormControl;
    filteredOptions: Observable<any[]>;

    selectedValue: any;
    oldValue: any;

    constructor() {}

    ngOnChanges(changes) {
        if (
            (changes && changes.options?.currentValue) ||
            (changes && changes.disabled) ||
            (changes && changes.dropdownValueUpdated)
        ) {
            this.initDropdown();
        }
    }

    ngOnInit(): void {}

    private initDropdown(): void {
        console.log('🛠 DROPDOWN initDropdown', this.control);
        if (this.control && this.options?.length) {
            this.initValue(this.control.value);

            this.filteredList = this.options;
            this.selectedValue = this.value;

            this.initFilterControl(this.value ? this.value : '', this.disabled);

            this.filteredOptions = this.filterControl.valueChanges.pipe(
                startWith(''),
                map((value) => (typeof value === 'string' ? value : value ? value.label : '')),
                map((name) => (name ? this._filter(name) : this.filteredList.slice()))
            );

            this.control.statusChanges.subscribe((data) => {
                this.filterControl.setValidators(this.control.validator);
                this.filterControl.updateValueAndValidity();
            });
        } else {
            this.initFilterControl(this.value ? this.value : '', this.disabled);
        }
    }

    private initFilterControl(value, disable): void {
        this.filterControl = new FormControl({
            value: value ? value.value : '',
            label: value ? value.label : ''
        });
        if (disable !== undefined) {
            disable ? this.filterControl.disable() : this.filterControl.enable();
        }
    }

    initValue(value: SelectItem): void {
        this.value = this.options?.find((item) => item.value === value || isEqual(item.value, value));
        console.log('🛠 DROPDOWN initValue:', this.value);
    }

    private _filter(label: string): any[] {
        const filterValue = label.toLowerCase().trim();
        const queryWords = filterValue.split(' ').filter((word) => word);
        return this.options.filter((option) => {
            const matched = queryWords.every((word) => option.label.toLowerCase().indexOf(word) !== -1);
            return matched;
        });
    }

    onfocus() {
        console.log('🛠 DROPDOWN onfocus');
        this.oldValue = this.selectedValue || this.value;
        this.selectedValue = null;
        // this.control?.setValue(null);
        this.filterControl?.setValue({ value: null, label: '' });
    }

    optionSelected(event): void {
        console.log('🛠 DROPDOWN optionSelected:', event.value);
        this.selectedValue = event.value;
        this.control?.setValue(this.selectedValue.value);
        this.filterControl?.setValue(event.value);
        this.onChange.emit(event.value);
        this.matInput.nativeElement.blur();
        this.control.markAsDirty();
    }

    onblur() {
        /*
            In order to blur work well should call handler with setTimeout, 
            because onBlur calls earlier than optionSelected and set old value.
        */
        setTimeout(() => {
            console.log('🛠 DROPDOWN onblur');
            if (!this.selectedValue || (this.selectedValue.value !== this.control.value && this.oldValue)) {
                this.selectedValue = this.oldValue;
                this.control.setValue((this.selectedValue && this.selectedValue.value) || null);
                this.filterControl.setValue(this.selectedValue || null);
            }
        }, 150);
    }

    displayFn(option: any): string {
        console.log('🛠 DROPDOWN displayFn');
        return option && option.label ? option.label : '';
    }
}
