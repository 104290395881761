import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { FindVariables } from 'app/libs/util';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Candidate, ITag, User } from './../../../../../core/models';
import { SdkJob } from './../../../../../core/models/job';
import { CandidateService, JobService, UserService, UtilitiesService } from './../../../../../core/services';
import * as fromStore from './../../../../../store';
import * as fromSelectors from './../../../../../store/selectors';

declare var Quill: any;

@Component({
    selector: 'app-candidate-item-timeline',
    templateUrl: './candidate-item-timeline.component.html',
    styleUrls: ['./candidate-item-timeline.component.scss']
})
export class CandidateItemTimelineComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() job: any;
    @Input() candidate: any;
    @Input() set offerAudit(value) {
        if (value) {
            this.audit = this.transformAudit(value);
        }
    }
    @Input() set dataChanged(value) {
        if (value) {
            setTimeout(() => {
                this.loadAudit();
            }, 100);
        }
    }
    @Input() hideButtons;
    user: User;
    users: User[];
    usersSubscription: Subscription;

    commentForm: FormGroup;
    contentLoading = false;
    auditData: any[] = [];
    audit = [];

    @ViewChild('pEditor') pEditor: any;
    quill: any;
    cursorPosition: any;
    globalTags: ITag[] = [];
    currentHash: string = '';
    createMode: boolean = false;
    hashColors: string[] = [];
    newHashes: ITag[] = [];
    lastHash: ITag = null;
    commentFormShow = false;

    constructor(
        private fb: FormBuilder,
        private candidateService: CandidateService,
        private jobService: JobService,
        private userService: UserService,
        private toastr: ToastrService,
        private store: Store<fromStore.State>,
        private utilities: UtilitiesService
    ) {
        this.globalTags = [
            {
                hash: '#internal',
                color: '#3bb273'
            },
            {
                hash: '#ex-internal',
                color: '#7a6ff0'
            },
            {
                hash: '#do-not-hire',
                color: '#ff3b30'
            },
            {
                hash: '#agency',
                color: '#2b6fe3'
            }
        ];
        this.hashColors = [
            '#f6f8f9',
            '#fe602c',
            '#fc9a04',
            '#eec302',
            '#a4cf2f',
            '#37c3aa',
            '#20aaea',
            '#7a6ff0',
            '#e362e3',
            '#ea4d9d',
            '#fc91ad',
            '#525f7f'
        ];
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.currentHash = '';
    }

    ngOnInit() {
        this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
            this.user = user;
            // this.loadAudit();
        });
        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.users = [...users];
            if (this.users && this.users.length) {
                this.loadAudit();
            }
        });
        this.commentForm = this.fb.group({
            description: ['', Validators.required]
        });
    }

    ngAfterViewInit() {
        this.quill = this.pEditor.quill;

        this.textChange(this.quill);
    }

    get tags(): ITag[] {
        return [...this.globalTags, ...(this.job.tags || []), ...this.newHashes];
    }

    get tagWithTitles(): ITag[] {
        return this.tags
            .filter(({ hash }) => hash.indexOf(this.currentHash) !== -1)
            .filter((obj) => {
                if (this.candidate && this.candidate.tags) {
                    return !this.candidate.tags.find((it) => it.hash === obj.hash);
                }
            })
            .map(({ hash, color }) => ({ hash, color, title: this.titleCase(hash) }));
    }

    get tagTitles(): string[] {
        const titles = [...this.tags.map(({ hash }) => this.titleCase(hash)), ...this.tags.map(({ hash }) => hash)];
        return this.currentHash ? [...titles, this.currentHash] : titles;
    }

    get suggestionPosition() {
        const span = document.querySelector('.ql-editor p:last-child span:last-child');
        if (span) {
            return {
                top:
                    Number(document.querySelector('.ql-editor p:last-child')['offsetTop']) +
                    (this.lastHash ? 29 : 22) -
                    Number(document.querySelector('.ql-editor').scrollTop),
                left: span
                    ? Math.min(span['offsetLeft'], span['offsetParent']['offsetWidth'] - (this.lastHash ? 130 : 269)) -
                      (this.lastHash ? 63 + this.lastHash.hash.length * 3 : 0)
                    : 11
            };
        }
    }

    getTagStyle(color: string) {
        function getTextColor(color: string): string {
            return color === '#f6f8f9' ? 'black' : 'white';
            // const r = parseInt(color.substr(1, 2), 16);
            // const g = parseInt(color.substr(3, 2), 16);
            // const b = parseInt(color.substr(5, 2), 16);
            // return (r * 299 + g * 587 + b * 114) / 1000 > 138 ? 'black' : 'white';
        }
        return {
            background: color,
            color: getTextColor(color)
        };
    }

    getTag(hash: string): ITag {
        return this.tags.find((tag) => tag.hash === hash || this.titleCase(tag.hash) === hash);
    }

    textChange(quill: any) {
        quill.root.setAttribute('spellcheck', false);
        quill.root.setAttribute('autocorrect', false);

        quill.on('text-change', (delta, oldContents, source) => {
            this.formatQuill(quill);
        });
        this.formatQuill(quill);

        quill.on('selection-change', (range, oldRange, source) => {
            this.cursorPosition = oldRange;
        });

        quill.keyboard.bindings[13].splice(0, 0, {
            key: 13,
            collapsed: true,
            handler: this.onSaveComment.bind(this)
        });
    }

    titleCase(str: string) {
        return str
            .replace(/#/g, '')
            .split(/[-|_]/g)
            .join(' ')
            .replace(/\w*\S/g, (t) => t[0].toUpperCase() + t.substr(1));
    }

    placeHash(tag: ITag) {
        const { ops: origin } = this.quill.getContents();
        const lastIndex = origin[origin.length - 1].insert === '\n' ? origin.length - 2 : origin.length - 1;
        const last = origin[lastIndex].insert.split(' ');
        let hashIndex = -1;
        let hashOffset = 0;
        if (last.length) {
            hashIndex = last[last.length - 1].lastIndexOf('#');
            for (let i = 0; i < last.length - 1; i++) hashOffset += last[i].length + 1;
        }

        if (hashIndex === -1) return;
        hashOffset += hashIndex;
        hashIndex = origin[lastIndex].insert.length - hashOffset;

        let i = 0;
        let offset = 0;
        while (i < lastIndex) {
            offset += (origin[i].insert || '').length;
            i++;
        }

        const delta = { ops: [] };
        delta.ops.push({ retain: offset + hashOffset }, { delete: hashIndex }, { insert: tag.hash });
        if (offset === 0) delta.ops.shift();
        if (hashIndex === 0) delta.ops.shift();
        this.quill.updateContents(delta);
    }

    onCreateNew() {
        this.createMode = true;
        this.lastHash = {
            hash: this.currentHash,
            color: this.hashColors[0]
        };
        this.newHashes.push(this.lastHash);
        this.formatQuill(this.quill);
    }

    async onDeleteTag(deleteHash) {
        this.contentLoading = true;
        const removeTag = this.candidate.tags.find(({ hash }) => hash === deleteHash);
        this.candidate.tags = this.candidate.tags.filter(({ hash }) => hash !== deleteHash);
        await new Promise((resolve, reject) => {
            this.jobService
                .updateCandidateTags(this.job.id, this.candidate.id, { tags: this.candidate.tags })
                .subscribe(resolve, reject);
        });
        const comment = {
            id: this.utilities.generateUID(10).toLowerCase(),
            text: JSON.stringify([removeTag]),
            param: 'removed',
            created_at: new Date().getTime(),
            user_id: this.user.id,
            type: 'tags'
        };
        if (removeTag.added_at && (removeTag.hash === '#internal' || removeTag.hash === '#ex-internal')) {
            this.candidateService
                .addToAudit(this.job.id, this.candidate.id, removeTag)
                .then((res) => {
                    this.auditData.push(removeTag);
                    this.candidateService
                        .addToAudit(this.job.id, this.candidate.id, comment)
                        .then((response) => {
                            this.commentForm.reset();
                            this.contentLoading = false;
                            this.auditData.push(comment);
                            this.audit = this.transformAudit(this.auditData);
                        })
                        .catch((errorResponse) => {
                            console.error(errorResponse);
                            this.contentLoading = false;
                        });
                })
                .catch((errorResponse) => {
                    console.error(errorResponse);
                });
        } else {
            this.candidateService
                .addToAudit(this.job.id, this.candidate.id, comment)
                .then((response) => {
                    this.commentForm.reset();
                    this.contentLoading = false;
                    this.auditData.push(comment);
                    this.audit = this.transformAudit(this.auditData);
                })
                .catch((errorResponse) => {
                    console.error(errorResponse);
                    this.contentLoading = false;
                });
        }
    }

    onChangeHashColor(color: string) {
        this.lastHash.color = color;
        this.formatQuill(this.quill);
        this.createMode = false;
    }

    formatQuill(quill: any) {
        const { ops: origin } = quill.getContents();

        const lastIndex = origin[origin.length - 1].insert === '\n' ? origin.length - 2 : origin.length - 1;
        if (lastIndex < 0) return;
        const last = origin[lastIndex].insert.split(' ');
        let hashIndex = -1;
        if (last.length && (hashIndex = last[last.length - 1].lastIndexOf('#')) !== -1) {
            const newHash = last[last.length - 1].substr(hashIndex).replace(/\n/g, '');
            if (this.currentHash !== newHash && (!this.lastHash || (this.lastHash && this.lastHash.hash !== newHash))) {
                this.currentHash = newHash;
                this.createMode = false;
                this.lastHash = null;
            }
        } else {
            this.currentHash = '';
            if (
                !this.lastHash ||
                (origin[lastIndex].insert !== ' ' &&
                    origin[lastIndex - 1] &&
                    origin[lastIndex - 1].insert === this.titleCase(this.lastHash.hash))
            )
                this.createMode = false;
        }

        let i = 0;
        let offset = 0;

        while (origin[i]) {
            const d = origin[i];
            if (d.attributes) {
                if (d.attributes.color === 'white' || d.attributes.colr === 'black') {
                    const placeholder = this.tags.find(({ hash }) => hash === d.insert);
                    if (!placeholder) {
                        quill.removeFormat(offset, d.insert.length);
                        break;
                    }
                } else {
                    quill.removeFormat(offset, d.insert.length);
                    break;
                }
            } else {
                offset += (d.insert || '').length;
            }
            i++;
        }
        if (origin[i]) return;

        const inset = quill.getText();
        let variables = FindVariables(inset, this.tagTitles);
        offset = 0;
        for (let variable of variables) {
            if (variable.name[0] === '#') {
                const placeholder = this.tags.find(({ hash }) => hash === variable.name);
                if (placeholder) {
                    const delta = { ops: [] };
                    const title = this.titleCase(placeholder.hash);
                    const off = title.length - variable.name.length;
                    delta.ops.push(
                        { retain: variable.index - offset },
                        { delete: variable.name.length },
                        { insert: title + ' ' }
                    );
                    if (variable.index - offset === 0) delta.ops.shift();
                    quill.updateContents(delta);
                    offset += off;
                }
                break;
            }
        }
        if (offset < 0) return;

        for (let variable of variables) {
            const tag = this.getTag(variable.name);
            quill.formatText(
                variable.index,
                variable.index + variable.name.length,
                tag
                    ? this.getTagStyle(tag.color)
                    : {
                          color: '#000',
                          background: 'transparent'
                      },
                'silent'
            );

            quill.formatText(
                variable.index + variable.name.length,
                variable.index + variable.name.length + 1,
                {
                    color: '#000',
                    background: 'transparent'
                },
                'silent'
            );
        }
    }

    loadAudit() {
        const currentAudit = this.candidate.candidateAudits.audits.find(
            (audit) => audit.jobId.toString() === this.job._id.toString()
        );
        console.log(currentAudit);
        if (currentAudit) {
            this.audit = this.transformAudit(currentAudit.audits);
        }
        return;
        if (this.candidate.audit && this.candidate.audit[this.job.id]) {
            this.auditData = this.candidate.audit[this.job.id];
            console.log('AUDIT DATA', this.auditData.slice());
            this.audit = this.transformAudit(this.auditData);
        } else {
            this.audit = this.transformAudit([]);
        }
    }

    transformAudit(auditData: any[]) {
        console.log('transformAudit', auditData);
        const creationEntry = auditData.find((e) => e.type === 'created');
        if (!creationEntry) {
            // let created_app = this.candidate.applicaitons[this.job.id].created_at;
            // let created_app = this.candidate.assignments.find((a) => a.type === 'questions');
            auditData.push({
                type: 'created',
                created_at: this.job.created_at * 1000,
                source: this.candidate.source
            });
        }

        const result = [];
        if (this.candidate && this.candidate.tags && this.candidate.tags.length > 0) {
            this.candidate.tags.forEach((t: any) => {
                if (['#internal', '#ex-internal', '#agency'].includes(t.hash) && t.added_at) {
                    t.type = 'internal';
                    t.text = [{ color: t.color, hash: t.hash }];
                    t.internal = true;
                    t.created_at_rel = this.utilities.fromNow(t.added_at * 1000);
                    t.created_at = t.added_at * 1000;
                    result.push(t);
                }
            });
        }
        const createProfileAudit = auditData.find((a) => a.email_name === 'create_profile_email');
        if (createProfileAudit) {
            auditData = auditData.filter(
                (a) => a.email_name !== 'application_successful' && a.email_name !== 'additional_questions'
            );
        }
        auditData.forEach((e) => {
            // temprorary code
            if (e.created_at.toString(10).split('').length === 10) {
                e.created_at = e.created_at * 1000;
            }
            const today = moment()
                .utc()
                .startOf('day')
                .valueOf();
            const timeinmilisec = today - e.created_at;
            const days = Math.floor(timeinmilisec / (1000 * 60 * 60 * 24));
            days > 14
                ? (e.created_at_rel = moment(e.created_at).format('DD MMMM YYYY [at] HH:mm'))
                : (e.created_at_rel = this.utilities.fromNow(e.created_at));
            e.job_title = this.job.title;
            e.candidate_name = this.candidate.first_name + ' ' + this.candidate.last_name;
            if (e.type === 'created') {
                const { source, sourceText } = this.getSourceValues(this.candidate);
                e.source = source;
                e.sourceText = sourceText;
            }

            if (
                e.type === 'created' ||
                e.type === 'rating' ||
                e.type === 'assigned' ||
                e.type === 'delete' ||
                e.type === 'offer-letter' ||
                e.type === 'contact-details' ||
                e.type === 'offer-letter-workflow'
            ) {
                const author = this.users.find((u) => u.id === e.user_id);
                if (author) {
                    e.user = author;
                }
            }
            if (e.type === 'comment' || e.type === 'tags' || e.type === 'offer') {
                const author = this.users.find((u) => u.id === e.user_id);
                if (author) {
                    e.user = author;
                    e.image_url = author.icon_url || null;

                    result.push(e);
                }
            } else if (e.type === 'stages_progress') {
                const author = this.users.find((u) => u.id === e.user_id);
                if (author) {
                    e.user = author;
                    e.image_url = author.icon_url || null;
                }
                const stages = this.job.stages;
                const stage_from = stages.find((s) => s.id === e.stage_from_id);
                const stage_to = stages.find((s) => s.id === e.stage_to_id);
                e.stage_from_title = (stage_from && stage_from.title) || 'Applied';
                e.stage_to_title = (stage_to && stage_to.title) || 'Applied';

                if (e.user && e.stage_from_title && e.stage_to_title) {
                    result.push(e);
                }
            } else if (e.type === 'rating') {
                e.rating = [];
                for (let i = 1; i <= e.value; i++) {
                    e.rating.push(i);
                }
                result.push(e);
            } else if (e.email_name && e.type === 'created') {
                if (this.candidate.assignments && this.candidate.assignments) {
                    const assignments = this.candidate.assignments.filter((a) => a.stageId === e.stage_id);
                    e.completed = assignments.every((a) => a.completed);
                }
                this.getEmailLastActivity(e);
                result.push(e);
            } else if (e.type === 'assigned') {
                const stages = this.job.stages;
                const stage = stages.find((s) => s.id === e.stage_id);
                e.stage_title = (stage && stage.title) || 'Applied';
                if (this.candidate.assignments && this.candidate.assignments) {
                    const assignments = this.candidate.assignments.filter((a) => a.stageId === e.stage_id);
                    e.completed = assignments.every((a) => a.completed);
                }
                this.getEmailLastActivity(e);
                result.push(e);
            } else if (e.type === 'offer-letter') {
                this.getEmailLastActivity(e);
                result.push(e);
            } else if (e.type === 'contact-details') {
                this.updateNameOfField(e);
                result.push(e);
            } else {
                result.push(e);
            }
        });
        const sorted = result.sort((a: any, b: any) => b.created_at - a.created_at);
        return sorted;
    }

    updateNameOfField(e) {
        e.updated_fields = [];
        e.updated.forEach((u) => {
            switch (u) {
                case 'fullName':
                    e.updated_fields.push('Full Name edited');
                    break;
                case 'email':
                    e.updated_fields.push('Email Address edited');
                    break;
                case 'location':
                    e.updated_fields.push('Location edited');
                    break;
                case 'phone':
                    e.updated_fields.push('Phone Number edited');
                    break;
                case 'linkedin_url':
                    e.updated_fields.push('Linkedin Address edited');
                    break;
                case 'facebook_url':
                    e.updated_fields.push('Facebook Address edited');
                    break;
                case 'instagram_url':
                    e.updated_fields.push('Instagram Address edited');
                    break;
                case 'github_url':
                    e.updated_fields.push('Github Address edited');
                    break;
                case 'twitter_url':
                    e.updated_fields.push('Twitter Address edited');
                    break;
            }
        });
        e.created.forEach((u) => {
            switch (u) {
                case 'fullName':
                    e.updated_fields.push('Full Name added');
                    break;
                case 'email':
                    e.updated_fields.push('Email Address added');
                    break;
                case 'phone':
                    e.updated_fields.push('Phone Number added');
                    break;
                case 'linkedin_url':
                    e.updated_fields.push('Linkedin Address added');
                    break;
                case 'facebook_url':
                    e.updated_fields.push('Facebook Address added');
                    break;
                case 'instagram_url':
                    e.updated_fields.push('Instagram Address added');
                    break;
                case 'github_url':
                    e.updated_fields.push('Github Address added');
                    break;
                case 'twitter_url':
                    e.updated_fields.push('Twitter Address added');
                    break;
            }
        });
    }

    getEmailLastActivity(e) {
        if (e.bounce_at) {
            e.email_activity = `Bounce (${moment(e.bounce_at * 1000).format('YYYY/MM/DD HH:mm a UTC Z')})`;
            e.class = 'red';
        } else if (e.dropped_at) {
            e.email_activity = `Dropped (${moment(e.dropped_at * 1000).format('YYYY/MM/DD HH:mm a UTC Z')})`;
            e.class = 'red';
        } else if (e.open_at) {
            e.email_activity = `Opened (${moment(e.open_at * 1000).format('YYYY/MM/DD HH:ss a UTC Z')})`;
            e.class = 'green';
        } else if (e.processed_at) {
            e.email_activity = `Delivered (${moment(e.processed_at * 1000).format('YYYY/MM/DD HH:mm a UTC Z')})`;
            e.class = 'green';
        } else {
            e.email_activity = `Sent (${moment(e.created_at * 1000).format('YYYY/MM/DD HH:mm a UTC Z')})`;
            e.class = 'yellow';
        }
    }

    getTags(text) {
        if (typeof text === 'string') {
            return JSON.parse(text);
        }
        return text;
    }

    async onSaveComment() {
        console.log('save');
        if (this.commentForm.valid) {
            const visits = {};
            (this.candidate.tags || []).forEach(({ hash }) => (visits[hash] = true));
            const { ops: origin } = this.quill.getContents();
            const description = this.commentForm.value.description.replace(/<p>/i, '').replace(/<\/p>/i, '');
            const newTags = [];
            origin.forEach(({ insert: word }) => {
                const hashIndex = this.tags.findIndex(({ hash }) => this.titleCase(hash) === word || hash === word);
                if (hashIndex !== -1 && !visits[this.tags[hashIndex].hash]) {
                    visits[this.tags[hashIndex].hash] = true;
                    newTags.push(this.tags[hashIndex]);
                }
            });
            const tags = this.newHashes;
            if (tags.length > 0) {
                await new Promise((resolve, reject) => {
                    this.jobService
                        .updateJobTags(this.job.id, [...(this.job.tags || []), ...tags])
                        .subscribe(resolve, (err) => {
                            this.toastr.error(err.message);
                            reject(err);
                        });
                });
                this.job.tags ? this.job.tags.push(...tags) : (this.job.tags = tags);
            }
            if (newTags.length > 0) {
                await new Promise((resolve, reject) => {
                    this.jobService
                        .updateCandidateTags(this.job.id, this.candidate.id, {
                            tags: [...(this.candidate.tags || []), ...newTags]
                        })
                        .subscribe(resolve, (err) => {
                            this.toastr.error(err.message);
                            reject(err);
                        });
                });
                const comment = {
                    id: this.utilities.generateUID(10).toLowerCase(),
                    text: JSON.stringify(newTags),
                    param: 'added',
                    created_at: new Date().getTime(),
                    user_id: this.user.id,
                    type: 'tags'
                };
                this.newHashes = [];
                this.lastHash = null;
                this.currentHash = '';
                this.createMode = false;
                this.candidate.tags ? this.candidate.tags.push(...newTags) : (this.candidate.tags = newTags);
                this.candidateService
                    .addToAudit(this.job.id, this.candidate.id, comment)
                    .then((response) => {
                        this.commentForm.reset();
                        this.auditData.push(comment);
                        this.audit = this.transformAudit(this.auditData);
                        this.commentFormShow = false;
                    })
                    .catch((errorResponse) => {
                        console.error(errorResponse);
                    });
            } else if (newTags.length === 0) {
                const comment = {
                    id: this.utilities.generateUID(10).toLowerCase(),
                    text: description,
                    created_at: new Date().getTime(),
                    user_id: this.user.id,
                    type: 'comment'
                };
                this.candidateService
                    .addToAudit(this.job.id, this.candidate.id, comment)
                    .then(
                        (response) => {
                            this.commentForm.reset();
                            this.auditData.push(comment);
                            this.audit = this.transformAudit(this.auditData);
                            this.commentFormShow = false;
                        },
                        (errorResponse) => {
                            console.error(errorResponse);
                        }
                    )
                    .catch((errorResponse) => {
                        console.error(errorResponse);
                    });
            }
        }
    }

    onAddNote() {
        this.commentFormShow = !this.commentFormShow;
    }

    ngOnDestroy() {
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
    }

    copyURL(url) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.toastr.success('The link has been copied to the dashboard.');
    }

    onResendEmail(item) {
        console.log('onResendEmail');
        this.contentLoading = true;
        const data = {
            ms_id: item.ms_id,
            stage_id: item.stage_id,
            type: item.email_name === 'create_profile_email' && !this.candidate.hasUser ? 'created' : 'assigned'
        };
        this.candidateService.resendEmail(this.job.id, this.candidate.id, data).subscribe(
            (response: any[]) => {
                console.log('response', response);
                this.contentLoading = false;
                this.auditData = response;
                this.audit = this.transformAudit(this.auditData);
            },
            (err) => {
                console.error(err);
                this.contentLoading = false;
            }
        );
    }

    getSourceValues(candidate: Candidate): { source: any; sourceText: any } {
        let source = null;
        let sourceText = null;
        const texts = {
            googlejobs: 'Google Jobs',
            boost: 'Boost Promotion',
            paid: 'Paid Promotion',
            email: 'Email',
            cv: 'CV',
            direct: 'Career Portal',
            socialshare: 'Social Network',
            share: 'Share'
        };
        if (candidate.source === 'application') {
            source = 'direct';
            sourceText = texts['direct'];
        } else if (candidate.source === 'email') {
            source = 'direct';
            sourceText = texts['email'];
        } else if (candidate.source === 'cv') {
            source = 'direct';
            sourceText = texts['cv'];
        } else if (candidate.source === 'direct') {
            if (candidate.source_type === 'cv') {
                source = 'direct';
                sourceText = texts['cv'];
            } else if (candidate.source_type === 'email') {
                source = 'direct';
                sourceText = texts['email'];
            } else {
                source = 'direct';
                sourceText = texts['direct'];
            }
        } else if (candidate.source === 'googlejobs' || candidate.source === 'googlejobs,google_jobs_apply') {
            source = 'googlejobs';
            sourceText = texts['googlejobs'];
        } else if (candidate.source === 'boost') {
            source = 'boost';
            sourceText = texts['boost'];
        } else if (candidate.source === 'paid') {
            source = 'paid';
            sourceText = texts['paid'];
        } else if (candidate.source === 'socialshare') {
            source = 'socialshare';
            sourceText = texts['socialshare'];
        } else if (candidate.source === 'share') {
            source = 'share';
            sourceText = texts['share'];
        }

        return {
            source,
            sourceText
        };
    }

    onSendReminderForOfferLetter(item) {
        console.log('onSendReminderForOfferLetter', item);
        this.candidateService.resendOfferNotification(item.job_id, item.candidate_id).subscribe((response) => {
            console.log('Success', response);
        });
    }
}
