export class Employee {
    id?: string;
    first_name: string;
    last_name: string;
    email: string;
    anniversary_date?: string;
    cost?: number;
    created_at?: number;
    employee_id?: string;
    employment_history: any[];
    invited_by?: string;
    job_details?: any;
    location?: string;
    location_short?: string;
    personal_email?: string;
    phone?: string;
    private_email?: string;
    start_date?: string;
    status?: string;
    summary?: string;
    type?: string;
    unverified?: boolean;
    workflows?: string[];
    tags?: any[];
    audit?: any[];
    answers?: any[];
}
