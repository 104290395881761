import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelperService } from '@app/core/services/form-helper.service';

@Component({
    selector: 'app-work-experience',
    templateUrl: './work-experience.component.html',
    styleUrls: ['./work-experience.component.scss']
})
export class WorkExperienceComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() set formData(data: any) {
        if (data && this.form) {
            this.populateForm(data);
        }
    }
    experienceInvalid = [];

    constructor(private fb: FormBuilder, public formHelper: FormHelperService) {}

    ngOnInit(): void {}

    get employmentHistory() {
        return this.form.get('employment_history') as FormArray;
    }

    onSectionRemove(i: number) {
        this.employmentHistory.removeAt(i);
        this.experienceInvalid.splice(i, 1);
    }

    onSectionAdd() {
        this.employmentHistory.push(
            this.fb.group({
                title: ['', Validators.required],
                company: ['', Validators.required],
                start_date: ['', Validators.required],
                end_date: ['', Validators.required],
                description: ['', Validators.required]
            })
        );
        this.experienceInvalid.push(true);
    }

    populateForm(data) {
        data.forEach((item) => {
            const formData = {
                company: [item.company || '', Validators.required],
                description: [item.description || '', Validators.required],
                end_date: [item.end_date, Validators.required],
                start_date: [item.start_date, Validators.required],
                title: [item.title || '', Validators.required]
            };
            this.employmentHistory.push(this.fb.group(formData));
            !item.company || !item.description || !item.end_date || !item.start_date || !item.title
                ? this.experienceInvalid.push(true)
                : this.experienceInvalid.push(false);
        });
        this.formHelper.markFormGroupTouched(this.form);
    }
}
