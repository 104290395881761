<div class="content-section">
    <form [formGroup]="themeForm">
        <div class="form-content-section">
            <div class="description">
                <strong class="title">Branding</strong>
                <p class="p-height">
                    Candidate portals are any candidate facing websites like the Application Portal or your Careers
                    Portal.
                </p>
                <p>
                    You can upload a header image to further customise your career portal. This image should be a
                    minimum of 1900 x 600px.
                </p>
            </div>
            <div class="form-content-holder">
                <div class="form-content-box">
                    <div class="form-block">
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <label for="color" class="form-block-label">Primary Color</label>
                                    <div class="input-holder colorpicker">
                                        <div class="color-indicator" [style.background]="color"></div>
                                        <input
                                            id="color"
                                            #colorInput
                                            class="form-input"
                                            formControlName="color"
                                            [(colorPicker)]="color"
                                            (keydown)="inputColor($event.target.value)"
                                            [attr.cpWidth]="cpWidth"
                                            cpPosition="top"
                                            cpOutputFormat="hex"
                                            cpAlphaChannel="disabled"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <label for="button_color" class="form-block-label">Button / Link Colour</label>
                                    <div class="input-holder colorpicker">
                                        <div class="color-indicator" [style.background]="buttonColor"></div>
                                        <input
                                            id="button_color"
                                            #colorButtonInput
                                            class="form-input"
                                            formControlName="button_color"
                                            [(colorPicker)]="buttonColor"
                                            (keydown)="inputButtonColor($event.target.value)"
                                            [attr.cpWidth]="cpWidth"
                                            cpPosition="top"
                                            cpOutputFormat="hex"
                                            cpAlphaChannel="disabled"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-block">
                        <label for="uploader" class="form-block-label">Company Logo</label>
                        <div class="logo-upload-block fdr">
                            <div class="image-block">
                                <input type="hidden" formControlName="logo_url" />
                                <div
                                    class="upload-block fdr aic jcc"
                                    appDragEnter
                                    (dropFile)="onDropFile($event, 'logo')"
                                >
                                    <div class="upload-block-holder" *ngIf="!logoUrl">
                                        <img src="/assets/images/image-upload.svg" width="66" height="50" alt="" />
                                        <p>Drop logo or <label for="uploader">click to upload</label></p>
                                    </div>
                                    <input
                                        class="file"
                                        type="file"
                                        name="logo"
                                        id="uploader"
                                        (change)="onDropFile($event, 'logo')"
                                    />
                                    <div class="upload-overlay fdr aic jcc" *ngIf="uploadLogo">
                                        <app-progress-circle
                                            [progress]="uploadLogo.progress"
                                            *ngIf="uploadLogo.uploadStarted && !uploadLogo.uploadFinished"
                                        ></app-progress-circle>
                                        <div
                                            class="status-circle success"
                                            *ngIf="uploadLogo.uploadFinished && uploadLogo.success"
                                        >
                                            <img
                                                class="success-img"
                                                src="/assets/images/check.svg"
                                                alt=""
                                                width="18"
                                                height="14"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="image-holder-logo fdr aic jcc"
                                        *ngIf="logoUrl && !uploadLogo"
                                        [ngStyle]="{ 'background-image': 'url(' + logoUrl + ')' }"
                                    ></div>
                                </div>
                            </div>
                            <div class="text">
                                <p>
                                    For the best results you should upoad a <strong>transparrent png</strong> with an
                                    ideal <strong>aspect ratio of 5:1</strong>
                                </p>
                            </div>
                        </div>
                        <div class="error-message" *ngIf="uploadError && uploadError.type === 'logo'">
                            {{ uploadError.error }}
                        </div>
                    </div>
                    <div class="form-block">
                        <label for="uploaderFavicon" class="form-block-label">Favicon</label>
                        <div class="logo-upload-block favicon-block fdr">
                            <div class="image-block">
                                <input type="hidden" formControlName="favicon_url" />
                                <div
                                    class="upload-block fdr aic jcc"
                                    appDragEnter
                                    (dropFile)="onDropFile($event, 'favicon')"
                                >
                                    <div class="upload-block-holder" *ngIf="!faviconUrl">
                                        <img src="/assets/images/image-upload.svg" width="66" height="50" alt="" />
                                        <p>Drop favicon or <label for="uploaderFavicon">click to upload</label></p>
                                    </div>
                                    <input
                                        class="file"
                                        type="file"
                                        name="favicon"
                                        id="uploaderFavicon"
                                        (change)="onDropFile($event, 'favicon')"
                                    />
                                    <div class="upload-overlay fdr aic jcc" *ngIf="uploadFavicon">
                                        <app-progress-circle
                                            [progress]="uploadFavicon.progress"
                                            *ngIf="uploadFavicon.uploadStarted && !uploadFavicon.uploadFinished"
                                        ></app-progress-circle>
                                        <div
                                            class="status-circle success"
                                            *ngIf="uploadFavicon.uploadFinished && uploadFavicon.success"
                                        >
                                            <img
                                                class="success-img"
                                                src="/assets/images/check.svg"
                                                alt=""
                                                width="18"
                                                height="14"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="image-holder-logo fdr aic jcc"
                                        *ngIf="faviconUrl && !uploadFavicon"
                                        [ngStyle]="{ 'background-image': 'url(' + faviconUrl + ')' }"
                                    ></div>
                                </div>
                            </div>
                            <div class="text">
                                <p>
                                    <!-- Favicons are generally 16 x 16 pixels or 32 x 32 pixels in size. -->
                                    The most widely-supported file format to use for a favicon is <strong>.ico</strong>.
                                </p>
                            </div>
                        </div>
                        <div class="error-message" *ngIf="uploadError && uploadError.type === 'favicon'">
                            {{ uploadError.error }}
                        </div>
                    </div>

                    <div class="form-block">
                        <label for="uploaderHeader" class="form-block-label">Header Image (Recommended)</label>
                        <div class="logo-upload-block fdr">
                            <div class="image-header-block">
                                <input type="hidden" formControlName="header_url" />
                                <div
                                    class="upload-block fdr aic jcc"
                                    appDragEnter
                                    (dropFile)="onDropFile($event, 'header')"
                                >
                                    <div class="upload-block-holder" *ngIf="!headerUrl">
                                        <img src="/assets/images/image-upload.svg" width="66" height="50" alt="" />
                                        <p>Drop logo or <label for="uploaderHeader">click to upload</label></p>
                                    </div>
                                    <input
                                        class="file"
                                        type="file"
                                        name="header"
                                        id="uploaderHeader"
                                        (change)="onDropFile($event, 'header')"
                                    />
                                    <div class="upload-overlay fdr aic jcc" *ngIf="uploadHeader">
                                        <app-progress-circle
                                            [progress]="uploadHeader.progress"
                                            *ngIf="uploadHeader.uploadStarted && !uploadHeader.uploadFinished"
                                        ></app-progress-circle>
                                        <div
                                            class="status-circle success"
                                            *ngIf="uploadHeader.uploadFinished && uploadHeader.success"
                                        >
                                            <img
                                                class="success-img"
                                                src="/assets/images/check.svg"
                                                alt=""
                                                width="18"
                                                height="14"
                                            />
                                        </div>
                                    </div>
                                    <div class="image-holder fdr aic jcc" *ngIf="headerUrl && !uploadHeader">
                                        <img class="image-header" [src]="headerUrl" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="error-message" *ngIf="uploadError && uploadError.type === 'header'">
                            {{ uploadError.error }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
