import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import algoliasearch from 'algoliasearch/lite';
import { isEqual, pick, sortBy } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { UpdateJob } from './../../products/hire/modules/jobs/store/actions/jobs.action';
import { JobsState } from './../../products/hire/modules/jobs/store/reducers/index';
import { getUserEntity } from './../../store/selectors/user.selector';
import { Candidate } from './../models/candidate';
import { SdkJob } from './../models/job';
import { User } from './../models/user';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';
import { UtilitiesService } from './utilities.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as Realm from 'realm-web';
export const APP_ID = 'testingd9-swxkf';
// const app = new Realm.App(APP_ID);
import { getValidAccessToken } from '../../realm';
import ObjectID from 'bson-objectid';

const ALGOLIA_INDEX_NAME = 'candidates';
const searchClient = algoliasearch(environment.ALGOLIA_APP_ID, environment.ALGOLIA_API_KEY);
const JobUpdateFields = [
    'title',
    'ref',
    'company',
    'category',
    'contract_duration',
    'location',
    'is_remote',
    'type',
    'number_of_hires',
    'education',
    'experience',
    'salary',
    'role',
    'description',
    'requirements',
    'start_date',
    'status',
    'owner',
    'default_email_name',
    'resume_matching_threshold',
    'do_not_match',
    'pipeline',
    'industry',
    'years_experience',
    'seniority',
    'vonq_category',
    'vonq_campaigns',
    'upload_resume_required',
    'tags',
    'updated_at',
    'stages_stats',
    'rating',
    'closed_at',
    'hold_at',
    'published_at',
    'private_at'
];

@Injectable({
    providedIn: 'root'
})
export class JobService {
    apiURL: string = environment.apiUrl;
    baseURL = '';
    private searchInputSubject = new BehaviorSubject<any>(null);
    private searchInputSubjectP = new BehaviorSubject<any>(null);
    private updateAfterOfferSubject = new Subject<any>();
    private status: Subject<any> = new Subject<any>();
    private stageUpdatedSubject = new Subject<any>();
    constructor(
        private apollo: Apollo,
        private http: HttpClient,
        private utilities: UtilitiesService,
        private db: AngularFirestore,
        private tenantService: TenantService,
        private userService: UserService,
        private jobsStore: Store<JobsState>
    ) {
        this.tenantService.init();
    }

    getAll() {
        // return this.db.collection(`tenants/${this.utilities.getTenant()}/jobs-items`).valueChanges({ idField: 'id' });
        return new Observable((subscriber) => {
            this.getAllPromise()
                .then((jobs: SdkJob[]) => {
                    subscriber.next(jobs);
                    subscriber.complete();
                })
                .catch((error) => {
                    console.error(error);
                    subscriber.next([]);
                    subscriber.complete();
                });
        });
    }

    getAllPromise() {
        return new Promise(async (resolve, reject) => {
            try {
                const user: User = await this.jobsStore
                    .pipe(select(getUserEntity))
                    .pipe(
                        filter((user) => !!user),
                        take(1)
                    )
                    .toPromise();
                let data = [];
                let collection = '';
                if (user.role === 'hiring_manager') {
                    collection = 'hiring_managers';
                } else if (user.role === 'recruiter') {
                    collection = 'recruiters';
                } else if (user.role === 'recruitment_agency') {
                    collection = 'recruitment_agencies';
                } else if (user.role === 'hr_business_partner') {
                    collection = 'hr_business_partners';
                } else if (user.role === 'agency_user') {
                    collection = 'recruitment_agencies';
                }
                if (user.role === 'account_owner' || user.role === 'admin') {
                    data =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];
                } else if (
                    user.role === 'hiring_manager' ||
                    user.role === 'recruiter' ||
                    user.role === 'hr_business_partner'
                ) {
                    data =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) =>
                                ref.where('owner', '==', user.id)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];

                    const managed =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) =>
                                ref.where(collection, 'array-contains', user.id)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];

                    const job_hiring_roles =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) =>
                                ref.where('hiring_panels', 'array-contains', user.id)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];
                    if (managed && managed.length) {
                        data = [...data, ...managed];
                    }
                    if (job_hiring_roles && job_hiring_roles.length) {
                        data = [...data, ...job_hiring_roles];
                    }
                } else if (user.role === 'employee') {
                    data =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) =>
                                ref.where('owner', '==', user.id)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];

                    const hiring_managers =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) =>
                                ref.where('hiring_managers', 'array-contains', user.id)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];
                    const hiring_panels =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) =>
                                ref.where('hiring_panels', 'array-contains', user.id)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];
                    const hr_business_partners =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) =>
                                ref.where('hr_business_partners', 'array-contains', user.id)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];
                    if (hiring_managers && hiring_managers.length) {
                        data = [...data, ...hiring_managers];
                    }
                    if (hiring_panels && hiring_panels.length) {
                        data = [...data, ...hiring_panels];
                    }
                    if (hr_business_partners && hr_business_partners.length) {
                        data = [...data, ...hr_business_partners];
                    }
                } else if (user.role === 'recruitment_agency' || user.role === 'agency_user') {
                    data =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) =>
                                ref.where('owner', '==', user.id)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];
                    const managed =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) =>
                                ref.where(collection, 'array-contains', user.id)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];
                    let agenciesAdded =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) =>
                                ref.where('all_agencies_added', '==', true)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];
                    if (managed && managed.length) {
                        data = [...data, ...managed];
                    }
                    if (agenciesAdded && agenciesAdded.length) {
                        data = [...data, ...agenciesAdded];
                    }
                } else {
                    data =
                        (await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise()) || [];
                }
                data.sort((a, b) => {
                    return b.created_at - a.created_at;
                });
                return resolve(data);
            } catch (error) {
                return reject(error);
            }
        });
    }

    getJobsByOwnerId(ownerId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`, (ref) => ref.where('owner', '==', ownerId))
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getJob(id) {
        if (id === 'new') {
            const newJob: SdkJob = {
                title: '',
                ref: '',
                company: '',
                category: [],
                contract_duration: '',
                location: [],
                is_remote: false,
                type: '',
                role: '',
                number_of_hires: 1,
                education: '',
                experience: '',
                salary: {
                    from: 0,
                    to: 0,
                    period: '',
                    currency: '',
                    single: false,
                    hide: false
                },
                description: '',
                requirements: '',
                hiring_managers: [],
                recruiters: [],
                recruitment_agencies: [],
                default_email_name: '',
                start_date: '',
                status: 'BUILD',
                owner: '',
                applications: 0,
                resume_matching_threshold: 60,
                do_not_match: false,
                pipeline: null,
                industry: '',
                vonq_category: '',
                years_experience: 0,
                seniority: '',
                stages_stats: []
            };
            return of(newJob);
        } else {
            // return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${id}`);
            return this.getJobById$(id);
        }
    }
    getMongoJob(_id) {
        if (_id === 'new') {
        } else {
            // const id = getObjectId(_id);
            // console.log(id, _id);

            this.apollo
                .query<any>({
                    query: gql`
                        {
                            userjob(query: { _id: ${_id} }) {
                                _id
                                created_at
                                jobId
                                number_of_hires
                                owner
                                ref
                                status
                                title
                                updated_at
                                stages {
                                    id
                                    icon
                                    created_at
                                    title
                                    order
                                    hasCompletedCandidates
                                    candidates
                                    completedCandidates
                                    candidatesArr {
                                        complianceRateClass
                                        email
                                        first_name
                                        lastPosition
                                        last_name
                                        matching_scores
                                        oldId
                                        profile_image_link
                                        stage
                                        tags {
                                            added_at
                                            auto
                                            color
                                            created_at
                                            hash
                                            internal
                                        }
                                    }
                                }
                                location
                                vonq_campaigns {
                                    campaignId
                                    status
                                    orderedProducts {
                                        jobBoardLink
                                        productId
                                    }
                                }
                            }
                        }
                    `
                })
                .subscribe(
                    ({ data, loading }) => {
                        console.log(data);
                    },
                    (error: any) => {
                        console.log('Here is the error', error);
                    }
                );
        }
    }

    getJobById$(jobId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
            .doc(jobId)
            .valueChanges()
            .pipe(
                take(1),
                map((job: SdkJob) => {
                    if (job) {
                        job.id = jobId;
                        return job;
                    } else {
                        return null;
                    }
                })
            );
    }

    getJobById(jobId): Promise<SdkJob> {
        return new Promise(async (resolve, reject) => {
            this.getJobById$(jobId).subscribe(
                (job: SdkJob) => {
                    job.id = jobId;
                    return resolve(job);
                },
                (errorResponse) => reject(errorResponse)
            );
        });
    }

    getFullJobById(jobId: string): Promise<SdkJob> {
        return new Promise(async (resolve, reject) => {
            const job = await this.getJobById(jobId);
            if (!job) {
                return resolve(null);
            }
            job.stages =
                (await this.getStages(jobId)
                    .pipe(take(1))
                    .toPromise()) || [];
            job.questions_list = (await this.getJobQuestionsList(job).toPromise()) || [];
            return resolve(job);
        });
    }

    getFullJobById$(jobId: string) {
        return of(this.getFullJobById(jobId));
    }

    getJobQuestionsList(job: SdkJob) {
        let needToGetQuestions = false;
        if (job.stages && job.stages.length) {
            job.stages
                .filter((s) => s.assessment && s.assessment.length)
                .forEach((s) => {
                    if (s.assessment.find((a) => a.type === 'questions' || a.type === 'video-interview')) {
                        needToGetQuestions = true;
                    }
                });
        }
        if (needToGetQuestions) {
            // console.log('[+] - NEED to get questions');
            // return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${job.id}/questions-list`);
            return from(this.getDbQuestiosnList(job));
        } else {
            // console.log('[x] - NO need to get questions');
            return of([]);
        }
    }

    getDbQuestiosnList(job) {
        return new Promise(async (resolve, reject) => {
            try {
                const questionsList = [];
                if (job.stages) {
                    const questionIds = [];
                    for (const stage of job.stages) {
                        if (stage.assessment) {
                            if (stage.assessment && stage.assessment.length > 0) {
                                stage.assessment.forEach((a) => {
                                    if (a.type === 'questions' || a.type === 'video-interview')
                                        questionIds.push(a.option);
                                });
                            }
                        }
                    }
                    if (job.questionnaire) {
                        questionIds.push(job.questionnaire);
                    }
                    for (const qId of questionIds) {
                        try {
                            const questions = await this.getDbQuestionnaire(qId);
                            questionsList.push(questions);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                    return resolve(questionsList);
                } else {
                    return resolve([]);
                }
            } catch (error) {
                console.error(error);
                return resolve([]);
            }
        });
    }

    getDbQuestionnaire(questionnaireId: string) {
        return new Promise(async (resolve, reject) => {
            const questionnaire: any = await this.db
                .collection(`tenants/${this.utilities.getTenant()}/questionnaires`)
                .doc(questionnaireId)
                .valueChanges()
                .pipe(take(1))
                .toPromise();

            if (!questionnaire) {
                return reject('Questionnaire not found.');
            }
            const questions: any[] = await this.db
                .collection(`tenants/${this.utilities.getTenant()}/questionnaires/${questionnaireId}/questions`)
                .valueChanges({ idField: 'id' })
                .pipe(take(1))
                .toPromise();

            questions.sort((a, b) => a.order - b.order);
            questionnaire.questions_col = questions;
            questionnaire.id = questionnaireId;
            return resolve(questionnaire);
        });
    }

    loadJobsFiltered(filters): Promise<SdkJob[]> {
        console.log('loadJobsFiltered', filters);
        return new Promise(async (resolve, reject) => {
            try {
                const jobs$ = this.db
                    .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
                    .valueChanges({ idField: 'id' })
                    .pipe(take(1));
                const jobs: any[] = await jobs$.toPromise();
                let filteredJobs = jobs.slice();

                // 1. Apply range filter
                const range = filters.range ? filters.range.selectedValue : null;
                if (range) {
                    const from = range.from;
                    const to = range.to;
                    filteredJobs = filteredJobs.filter((job) => {
                        // if (
                        //     (job.created_at >= from && job.created_at <= to) ||
                        //     (job.closed_at >= from && job.closed_at <= to)
                        // ) {
                        //     return true;
                        // }

                        if (job.created_at <= to && !job.closed_at) {
                            return true;
                        }
                        if (job.created_at >= from && job.closed_at <= to) {
                            return true;
                        }
                        if (job.closed_at >= from && job.closed_at <= to) {
                            return true;
                        }
                        return false;
                    });
                }
                // 2. Apply type filter
                if (filters.jobStatuses.selectedValues) {
                    filteredJobs = filteredJobs.filter((job) =>
                        filters.jobStatuses.selectedValues.includes(job.status)
                    );
                }
                // 3. Apply user filter
                if (filters.users.selectedValue !== 'all') {
                    filteredJobs = filteredJobs.filter((job) => {
                        return (
                            job.owner === filters.users.selectedValue ||
                            (job.recruiters && job.recruiters.includes(filters.users.selectedValue)) ||
                            (job.recruitment_agencies &&
                                job.recruitment_agencies.includes(filters.users.selectedValue)) ||
                            (job.hiring_managers && job.hiring_managers.includes(filters.users.selectedValue))
                        );
                    });
                }
                // 4. Apply job types filter
                if (filters.jobTypes.selectedValues) {
                    filteredJobs = filteredJobs.filter((job) => {
                        return filters.jobTypes.selectedValues.includes(job.type);
                    });
                }

                return resolve(filteredJobs);
            } catch (err) {
                return reject(err);
            }
        });
    }

    loadReportJobsFiltered(filters): Promise<any[]> {
        console.log('loadJobsFiltered', filters);
        return new Promise(async (resolve, reject) => {
            try {
                const jobs$ = this.db
                    .collection(`tenants/${this.utilities.getTenant()}/report_data`)
                    .valueChanges({ idField: 'id' })
                    .pipe(take(1));
                const jobs: any[] = await jobs$.toPromise();
                let filteredJobs = jobs.slice();
                let filteredJobsForTrendChart = jobs.slice();

                // 1. Apply range filter
                const range = filters.range ? filters.range.selectedValue : null;
                if (range) {
                    const from = range.from;
                    const to = range.to;
                    filteredJobs = filteredJobs.filter((job) => {
                        // if (
                        //     (job.created_at >= from && job.created_at <= to) ||
                        //     (job.closed_at >= from && job.closed_at <= to)
                        // ) {
                        //     return true;
                        // }

                        if (job.created_at <= to && !job.closed_at) {
                            return true;
                        }
                        if (job.created_at >= from && job.closed_at <= to) {
                            return true;
                        }
                        if (job.closed_at >= from && job.closed_at <= to) {
                            return true;
                        }
                        return false;
                    });
                    filteredJobsForTrendChart = jobs.filter((job) => {
                        let diff = range.to - range.from;
                        let from = range.from - diff;
                        let to = range.to;
                        if (job.created_at <= to && !job.closed_at) {
                            return true;
                        }
                        if (job.created_at >= from && job.closed_at <= to) {
                            return true;
                        }
                        if (job.closed_at >= from && job.closed_at <= to) {
                            return true;
                        }
                        return false;
                    });
                }
                // 2. Apply job status filter
                if (filters.jobStatuses.selectedValues) {
                    filteredJobs = filteredJobs.filter((job) =>
                        filters.jobStatuses.selectedValues.includes(job.status)
                    );
                    filteredJobsForTrendChart = filteredJobsForTrendChart.filter((job) =>
                        filters.jobStatuses.selectedValues.includes(job.status)
                    );
                }
                // 3. Apply user filter
                if (filters.users.selectedValue !== 'all') {
                    filteredJobs = filteredJobs.filter((job) => {
                        return (
                            job.owner === filters.users.selectedValue ||
                            (job.recruiters && job.recruiters.includes(filters.users.selectedValue)) ||
                            (job.recruitment_agencies &&
                                job.recruitment_agencies.includes(filters.users.selectedValue)) ||
                            (job.hiring_managers && job.hiring_managers.includes(filters.users.selectedValue))
                        );
                    });
                    filteredJobsForTrendChart = filteredJobsForTrendChart.filter((job) => {
                        return (
                            job.owner === filters.users.selectedValue ||
                            (job.recruiters && job.recruiters.includes(filters.users.selectedValue)) ||
                            (job.recruitment_agencies &&
                                job.recruitment_agencies.includes(filters.users.selectedValue)) ||
                            (job.hiring_managers && job.hiring_managers.includes(filters.users.selectedValue))
                        );
                    });
                }
                // 4. Apply job types filter
                if (filters.jobTypes.selectedValues) {
                    filteredJobs = filteredJobs.filter((job) => {
                        return filters.jobTypes.selectedValues.includes(job.type);
                    });
                    filteredJobsForTrendChart = filteredJobsForTrendChart.filter((job) => {
                        return filters.jobTypes.selectedValues.includes(job.type);
                    });
                }
                return resolve([filteredJobs, filteredJobsForTrendChart]);
            } catch (err) {
                return reject(err);
            }
        });
    }

    saveJob(job: SdkJob, user: User): Promise<SdkJob> {
        return new Promise(async (resolve, reject) => {
            try {
                if (job.id) {
                    // Update
                    console.log('saveJob => update', job);

                    // 1. get existing job
                    const existingJob: SdkJob = await this.getJobById(job.id);
                    console.log('1. Got existing job');

                    // 2. check if fields required for reparse were changed
                    let reparseIsNeeded = this.checkIfReparseIsNeeded(existingJob, job);
                    console.log('2. reparseIsNeeded', reparseIsNeeded);

                    // 3. check if job status changed
                    if (existingJob.status !== job.status) {
                        console.log('3. status changed', existingJob.status, '=>', job.status);
                        switch (job.status) {
                            case 'closed':
                                job.closed_at = Math.floor(Date.now() / 1000);
                                break;
                            case 'hold':
                                job.hold_at = Math.floor(Date.now() / 1000);
                                break;
                            case 'published':
                                job.published_at = Math.floor(Date.now() / 1000);
                                break;
                            case 'private':
                                job.private_at = Math.floor(Date.now() / 1000);
                                break;
                        }

                        if (job.status === 'closed' && !existingJob.rating) {
                            job.rating = [
                                {
                                    id: user.id,
                                    created_at: Math.floor(Date.now() / 1000)
                                }
                            ];
                            const ids = [];
                            if (existingJob.hiring_managers) {
                                ids.push(...existingJob.hiring_managers);
                            }
                            if (existingJob.hr_business_partners) {
                                ids.push(...existingJob.hr_business_partners);
                            }
                            this.http
                                .post(
                                    `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${job.id}/send-rate`,
                                    ids
                                )
                                .subscribe(
                                    () => console.log('Rates requests sent.'),
                                    (errorResponse) => console.error(errorResponse.error)
                                );
                        }

                        if (job.status === 'published' && existingJob.status !== 'published') {
                            // VONQ - Google, Boost
                            this.postToVonq(job)
                                .then(() => console.log('💼 JOB IS PUBLISHED => VONQ campaigns are created!'))
                                .catch((error) => console.error(error));
                        }
                        let type = 'URL_DELETED';
                        if (job.status === 'published') {
                            type = 'URL_UPDATED';
                        }
                        this.indexingJob(job.id, type).subscribe((res) => {
                            console.log('indexingJob', type, res);
                        });
                    }

                    // 4. set updated_at
                    job.updated_at = Math.floor(Date.now() / 1000);

                    // 5. check if pipeline changed
                    if (
                        (existingJob.pipeline !== job.pipeline &&
                            (!existingJob.applications || existingJob.applications === 0)) ||
                        !job.stages_stats
                    ) {
                        console.log('setting jobs stages/stats');
                        const stagesData = await this.setStagesForJob(job);
                        job.stages_stats = stagesData.stages_stats;
                        job.stages = stagesData.stages;
                    }

                    console.log('stages_stats', job.stages_stats);
                    // 6. save to the database

                    const updData = pick(job, JobUpdateFields);
                    await this.db
                        .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
                        .doc(job.id)
                        .update(updData);

                    // 7. send reparse request if needed
                    if (existingJob.status === 'BUILD' && job.status !== 'BUILD') {
                        this.sendReparseRequest(job.id)
                            .then(() => console.log('!'))
                            .catch((error) => console.error(error));
                    }

                    if (user) {
                        // 8. add audit record
                        const auditRecord = {
                            type: 'update',
                            user_id: user.id,
                            created_at: Math.floor(Date.now() / 1000),
                            job
                        };
                        this.addToAudit(job.id, auditRecord)
                            .then(() => console.log('Added to audit'))
                            .catch((error) => console.error(error));
                    }

                    delete job.created;
                    return resolve(job);
                } else {
                    // Create
                    // 1. set owner and created time
                    job.owner = user.role === 'employee' ? null : user.id;
                    job.created_at = Math.floor(Date.now() / 1000);
                    if (user.role === 'employee') {
                        job.created_by = user.id;
                    }

                    // 2. set default email name
                    if (!job.default_email_name || !job.default_email_name.length) {
                        job.default_email_name = `${user.first_name} ${user.last_name}`;
                    }

                    // 3. Add to database
                    console.log('saveJob => create', job);
                    const response = await this.db
                        .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
                        .add(job);

                    const jobId = response.id;
                    const dbJob = await this.getJobById(jobId);
                    dbJob.created = true;
                    return resolve(dbJob);
                }
            } catch (error) {
                return reject(error);
            }
        });
    }

    saveJobDescriptionDetails(job: SdkJob, description: any, jdAiCompleted: boolean = false): Promise<SdkJob> {
        return new Promise(async (resolve, reject) => {
            try {
                if (job.id) {
                    await this.db
                        .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
                        .doc(job.id)
                        .update({ jdAiCompleted });
                    await this.db
                        .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${job.id}/details`)
                        .doc('description')
                        .set(description);
                }
                return resolve(job);
            } catch (error) {
                return reject(error);
            }
        });
    }

    getJobDescriptionDetails(jobId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/details`)
            .doc('description')
            .get();
    }

    indexingJob(jobId, type) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/indexing`, { type });
    }

    updateJobs(data: Array<{ id: string; data: any }>) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/update-jobs`, { data });
    }

    updateJob(id, data) {
        console.log('updateJob', id, data);
        // return this.http.put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${id}`, {
        //     section: '',
        //     data,
        //     next: false
        // });

        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
            .doc(id)
            .update(data);
    }

    deleteJob(id) {
        // return this.http.delete(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${id}`);
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
            .doc(id)
            .delete();
    }

    updateJobListing(id) {
        return this.http.put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${id}/hidden`, {});
    }

    declineJobCandidates(id) {
        return this.http.delete(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${id}/decline-candidates`);
    }

    bulkDeleteJobs(ids) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/bulk-delete`, { items: ids });
    }

    getUsers() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/users`);
    }

    getAllCandidates() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates`);
    }

    getAllCandidatesLastDays() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates-statistics`);
    }

    getReports() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/reports`);
    }

    updateUserStateStatistics(data) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates-statistics`, data);
    }

    getCandidatesChunk(startAt, limit, sortBy = 'first_name') {
        return this.http.get(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/candidates-chunk?sortBy=${sortBy}&startAt=${startAt}&limit=${limit}`
        );
    }

    getAllCandidatesData() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates-data`);
    }

    getCandidatesChunkData(startAt, limit, sortBy = 'first_name') {
        return this.http.get(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/candidates-data-chunk?sortBy=${sortBy}&startAt=${startAt}&limit=${limit}`
        );
    }

    getDbCandidatesChunk(startAt, limit, sortBy = 'first_name') {
        return new Promise(async (resolve, reject) => {
            if (startAt !== 'first') {
                this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates_data`, (ref) =>
                        ref
                            .orderBy(sortBy)
                            .startAfter(startAt)
                            .limit(Number(limit))
                    )
                    .get()
                    .subscribe(
                        (snapshot) => {
                            let data = [];
                            snapshot.forEach((doc: any) => {
                                let item = doc.data();
                                item.id = doc.id;
                                data.push(item);
                            });

                            return resolve(
                                data.filter((c) => c.email && c.email.length && c.first_name && c.first_name.length)
                            );
                        },
                        (error) => reject(error)
                    );
            } else {
                this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates_data`, (ref) =>
                        ref.orderBy(sortBy).limit(Number(limit))
                    )
                    .get()
                    .subscribe(
                        (snapshot) => {
                            let data = [];
                            snapshot.forEach((doc: any) => {
                                let item = doc.data();
                                item.id = doc.id;
                                data.push(item);
                            });
                            return resolve(
                                data.filter((c) => c.email && c.email.length && c.first_name && c.first_name.length)
                            );
                        },
                        (error) => reject(error)
                    );
            }
        });
    }

    searchAlgloliaCandidates(tenantId, query, algoliaIndexName) {
        return new Promise(async (resolve, reject) => {
            const index = searchClient.initIndex(`${tenantId}_${algoliaIndexName}`);
            index
                .search(query)
                .then((responses) => {
                    return resolve(responses.hits);
                })
                .catch((error) => reject(error));
        });
    }

    getAgencyUsers(id: string): Promise<any[]> {
        return this.db
            .collection<User[]>(`users`, (ref) => ref.where('agency', '==', id))
            .valueChanges({ idField: 'id' })
            .pipe(take(1))
            .toPromise();
    }

    getAllCandidatesByOwner(ids: string[]) {
        console.log('getAllCandidatesByOwner', ids);
        // return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates-by-owner`);
        return new Promise(async (resolve, reject) => {
            if (!ids || !ids.length) {
                return resolve([]);
            }
            this.db
                .collection(`tenants/${this.utilities.getTenant()}/candidates_data`, (ref) =>
                    ref.where('owner', 'in', ids).orderBy('first_name')
                )
                .get()
                .subscribe(
                    (snapshot) => {
                        let data = [];
                        snapshot.forEach((doc: any) => {
                            let item = doc.data();
                            item.id = doc.id;
                            data.push(item);
                        });

                        return resolve(
                            data.filter((c) => c.email && c.email.length && c.first_name && c.first_name.length)
                        );
                    },
                    (error) => reject(error)
                );
        });
    }

    resetCandidateDefaultOwner(owners: string[]) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/reset-candidates-owner`, {
            owners
        });
    }

    getCandidatesAmount() {
        return new Promise(async (resolve, reject) => {
            this.db
                .collection(`tenants`)
                .doc(this.utilities.getTenant())
                .valueChanges()
                .pipe(
                    take(1),
                    map((tenant: any) => {
                        return tenant.counters;
                    })
                )
                .subscribe((counters: any) => {
                    return resolve(counters && counters.candidates ? counters.candidates : 0);
                });
        });
        // return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates-amount`);
    }

    getDataCompany(url) {
        return this.http.post(`${this.apiURL}/company`, { url });
    }

    getStages(jobId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/stages`)
            .valueChanges({ idField: 'id' });
    }

    getStage(jobId: string, stageId: string) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/stages/${stageId}`);
    }

    createStage(jobId: string, data: { title: string }) {
        // this.baseURL = `${this.apiURL}/tenants/${this.utilities.getTenant()}`;
        // return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/stages`, { data });

        return this.db.collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/stages`).add(data);
    }

    updateStage(jobId: string, stageId: string, data: object) {
        return from(
            this.db
                .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/stages`)
                .doc(stageId)
                .update(data)
        );
    }

    updateStagePromise(jobId: string, stageId: string, data: object) {
        return from(
            this.db
                .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/stages`)
                .doc(stageId)
                .update(data)
        );
    }

    removeStage(jobId: string, stageId: string) {
        return from(
            this.db
                .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/stages`)
                .doc(stageId)
                .delete()
        );
    }

    getCandidates(jobId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/candidates`, (ref) =>
                ref.orderBy('score')
            )
            .valueChanges({ idField: 'id' });
    }

    getFullCandidates(jobId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates`, (ref) =>
                ref.where('job_id', 'array-contains', jobId)
            )
            .valueChanges({ idField: 'id' });
    }

    getCandidatesOne(jobId: string, candidateId: string) {
        return this.http.get(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/${candidateId}/candidates`
        );
    }

    getCandidate(jobId: string, candidateId) {
        if (jobId === 'new') {
            return of({ title: '' });
        } else {
            return this.http.get(
                `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}`
            );
        }
    }

    getCandidateFull(jobId: string, candidateId) {
        return this.http.get(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}?full=true`
        );
    }

    createCandidate(jobId: string, formData: object) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates`,
            formData
        );
    }

    getCandidateDetails(candidate: Candidate) {
        return new Promise(async (resolve, reject) => {
            try {
                if (!candidate) {
                    return resolve(null);
                }

                const user: any = await this.userService.getDbUser(candidate.email);
                if (user) {
                    let userId = user.id;
                    const resume = await this.userService.getUserResume(userId);
                    if (!resume) {
                        return resolve(null);
                    } else {
                        // console.log('RESUME: ', resume);
                    }

                    const response = pick(resume, [
                        'education',
                        'skills',
                        'summary',
                        'work-experience',
                        'avg_months_per_employer',
                        'management_story'
                    ]);
                    const personalDetails = resume['personal-details'];
                    response.ethnicity = personalDetails.employment_equity_status || null;
                    response.sex = personalDetails.sex || null;

                    response.avg_months_per_employer =
                        response.avg_months_per_employer && response.avg_months_per_employer.length
                            ? parseInt(response.avg_months_per_employer, 10)
                            : 0;
                    response.management_story = response.management_story || null;
                    // response.audit = (await this.getCandidateAudit(candidate.id)) || {};
                    return resolve(response);
                } else {
                    // candidate.audit = (await this.getCandidateAudit(candidate.id)) || {};
                    return resolve(candidate);
                }
            } catch (error) {
                console.error(error);
                return reject(error);
            }
        });
    }

    getCandidateAudit(candidateId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates_audit`)
            .doc(candidateId)
            .valueChanges()
            .pipe(take(1))
            .toPromise();
    }

    getAssignments(candidateId: string, jobId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates/${candidateId}/assignments`, (ref) =>
                ref.where('jobId', '==', jobId)
            )
            .valueChanges({ idField: 'id' })
            .pipe(take(1))
            .toPromise();
    }

    getOpportunities(candidateId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates_opportunities`)
            .doc(candidateId)
            .valueChanges()
            .pipe(
                take(1),
                map((opp: any) => (opp ? opp.data : []))
            )
            .toPromise();
    }

    getAllAssignments(candidateId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates/${candidateId}/assignments`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1))
            .toPromise();
    }

    getCandidatesMatches(jobId: string, candidateId: string) {
        return new Promise(async (resolve, reject) => {
            this.db
                .collection(`tenants/${this.utilities.getTenant()}/candidates/${candidateId}/matches`)
                .doc(jobId)
                .valueChanges()
                .subscribe(
                    (item: any) => {
                        return resolve(item);
                    },
                    (errorResponse) => reject(errorResponse)
                );
        });
    }

    getDbCandidate(jobId: string, candidateId: string): Promise<Candidate> {
        return new Promise(async (resolve, reject) => {
            try {
                this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates`)
                    .doc(candidateId)
                    .valueChanges()
                    .pipe(take(1))
                    .subscribe(
                        (candidate: Candidate) => resolve(candidate),
                        (errorResponse) => reject(errorResponse)
                    );
            } catch (error) {
                return reject(error);
            }
        });
    }

    getCandidateFromEmail(jobId: string, email: string, user: User) {
        return new Promise(async (resolve, reject) => {
            try {
                // prepare email
                email = email.toLowerCase().trim();
                // find candidate via email
                const candidates$ = this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates`, (ref) =>
                        ref.where('email', '==', email)
                    )
                    .valueChanges({ idField: 'id' })
                    .pipe(take(1));

                const candidates: any[] = await candidates$.toPromise();
                const candidate = candidates && candidates.length ? candidates[0] : null;
                if (candidate) {
                    //candidate exists
                    const isCandidateOnThisJob = candidate.job_id && candidate.job_id.indexOf(jobId) !== -1;
                    let hasAnotherOwner = false;
                    if (user.role === 'recruitment_agency' || user.role === 'agency_user') {
                        if (candidate.owner && (candidate.owner === user.id || candidate.owner === user.agency)) {
                            hasAnotherOwner = false;
                        } else if (candidate.owner) {
                            hasAnotherOwner = true;
                        }
                        const candidsateUsers$ = this.db
                            .collection(`users`, (ref) => ref.where('email', '==', email))
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1));
                        const candidateUsers = await candidsateUsers$.toPromise();
                        if (candidateUsers.length) {
                            return resolve({
                                candidate_exists: true,
                                isCandidateOnThisJob: isCandidateOnThisJob ? true : false,
                                hasAnotherOwner
                            });
                        }
                    }

                    return resolve({
                        candidate_exists: true,
                        isCandidateOnThisJob: isCandidateOnThisJob ? true : false,
                        hasAnotherOwner
                    });
                } else {
                    return resolve({ candidate_exists: false });
                }
            } catch (error) {
                return reject(error);
            }
        });
    }

    createCandidateFromEmail(jobId: string, email: string) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/email`, {
            email
        });
    }

    getUserByEmail(jobId: string, email: string) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/users/email`, {
            email
        });
    }

    createCandidateFromCv(jobId: string, formData: object) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/cv`,
            formData
        );
    }

    createCandidateFromUserCv(formData: object) {
        return this.http.post(`${this.apiURL}/applications/tenants/${this.utilities.getTenant()}/user-cv`, formData);
    }

    addDocumentsToCandidate(jobId: string, candidateId: string, formData: object) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/documents`,
            formData
        );
    }

    createCandidateApplication(data: any) {
        return this.http.post(`${this.apiURL}/applications/tenants/${this.utilities.getTenant()}/user-signup`, data);
    }

    deleteCandidate(jobId: string, candidateId: string, data = {}) {
        // return this.http.delete(
        //     `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}`
        // );
        return this.http.request(
            'delete',
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}`,
            { body: data }
        );
    }

    undeclineCandidate(jobId: string, candidateId: string) {
        return this.http.request(
            'delete',
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/undecline-candidate`
        );
    }

    bulkDeleteCandidate(jobId: string, data = {}) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/bulk-delete-candidates`,
            { data }
        );
    }

    sendAssignmentsEmails(jobId: string, stageId: string, assignments: any[]) {
        return this.http.post(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/stages/${stageId}/send-assignments-emails`,
            { assignments }
        );
    }

    setJobCandidatesAsDeleted(jobId: string, candidateIds: string[]) {
        console.log('setJobCandidatesAsDeleted', jobId, candidateIds);
        return new Promise(async (resolve, reject) => {
            try {
                for (const candidateId of candidateIds) {
                    await this.db
                        .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/candidates`)
                        .doc(candidateId)
                        .update({
                            stageClass: 'deleted',
                            complianceRateClass: 'red',
                            order: 6
                        });
                }
                return resolve(null);
            } catch (error) {
                return reject(error);
            }
        });
    }

    setCandidatesEmailNotifications(jobId: string, emails: string[]) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/set-email-notifications`,
            {
                emails
            }
        );
    }

    sendJobNotifications(jobId: string, emails: string[]) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/send-job-notifications`,
            {
                emails
            }
        );
    }

    addJob(jobId: string, emails: any) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/add-job`, {
            ...emails
        });
    }

    createJobFromCv(formData: object) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/spec`, formData);
    }

    updateCandidateStage(jobId: string, candidateId: string, data: any) {
        return this.http.put(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/stage`,
            { data }
        );
    }

    updateCandidateTags(jobId: string, candidateId: string, data: any) {
        return this.http.put(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}`,
            { data }
        );
    }

    updateJobTags(jobId: string, data: any) {
        return this.http.put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/tags`, { data });
    }

    updateJobOfferVariables(jobId: string, data: any) {
        return this.http.put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/offer_fields`, {
            data
        });
    }

    readCandidate(jobId: string, candidateId: string, read: string[]) {
        return this.http.put(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}`,
            { data: { read } }
        );
    }

    evaluateCandidateVideoAnswer(jobId: string, candidateId: string, stageId: string, data: any) {
        return this.http.put(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/stages/${stageId}/evaluate-video`,
            data
        );
    }

    sendEmailToCandidate(jobId: string, candidateId: string, data: any) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/email`,
            data
        );
    }

    getDevskillerTest() {
        return this.http.get(`${this.apiURL}/devskiller-tests`);
    }

    setSearchValueForJobs(val) {
        this.searchInputSubject.next(val);
    }

    getSearchValueForJobs(): Observable<any> {
        return this.searchInputSubject.asObservable();
    }

    setSearchValueForPeople(val) {
        this.searchInputSubjectP.next(val);
    }

    getSearchValueForPeople(): Observable<any> {
        return this.searchInputSubjectP.asObservable();
    }

    setCandidateAfterOffer(val) {
        this.updateAfterOfferSubject.next(val);
    }

    getCandidateAfterOffer(): Observable<any> {
        return this.updateAfterOfferSubject.asObservable();
    }

    stageUpdated(val) {
        this.stageUpdatedSubject.next(val);
    }

    stageUpdated$(): Observable<any> {
        return this.stageUpdatedSubject.asObservable();
    }

    generateSignatureLink(jobId, signature_id) {
        return this.http.get(
            `${
                this.apiURL
            }/applications/tenants/${this.utilities.getTenant()}/jobs/${jobId}/generate-link?signatureId=${signature_id}`
        );
    }

    signedOffer(userId, jobId, candidateId) {
        return this.http.get(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/${candidateId}/${userId}/check-offer-signer`
        );
    }

    getVonqList(jobId) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/marketplace`);
    }

    getLocalVonqList() {
        return this.http.get(`${this.apiURL}/vonq_products`);
    }

    addVonqProduct(id) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/vonq_product/${id}`, { data: '' });
    }

    removeCampaigns(jobId, data) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/marketplace/pause_campaigns`,
            data
        );
    }

    convertJobForVonq(job, products, utm, totalPrice = 0) {
        return new Observable((subscriber) => {
            this.getTaxonomyEndpoints(null)
                .pipe(take(1))
                .subscribe(
                    (data: any) => {
                        const educationOptions = [
                            { label: 'Master / Post-Graduate / PhD', value: 'masters', id: 1 },
                            { label: 'Bachelor / Graduate', value: 'bachelors', id: 2 },
                            { label: 'Vocational / Diploma / Associates degree', value: 'vocational', id: 3 },
                            { label: 'GCSE / A-Level / Highschool / GED', value: 'school', id: 4 }
                        ];
                        const [industryOptions, jobcategoryOptions, seniorityOptions] = data;
                        const education = educationOptions.find((option) => job.education === option.value);
                        const seniority = seniorityOptions.find((option) => job.seniority === option.value);
                        if (seniority) {
                            seniority['id'] = seniority['value'];
                        }
                        const jobcategory = jobcategoryOptions.find((option) => job.vonq_category === option.value);
                        const industry = industryOptions.find((option) => job.industry === option.value);
                        const selectedProducts = products;
                        const selectedProductsUTM = utm;
                        const tenant = this.utilities.getTenant();

                        const url =
                            tenant === 'dimensiondata' || tenant === 'D9BO1JR3D'
                                ? environment.jobsPortalUrl
                                : `${environment.genericPortalUrl}/${tenant}`;
                        const val = `${url}/positions/${job.id}?fromApp=true`;
                        console.log(environment.jobsPortalUrl, environment.genericPortalUrl);
                        const applicationUrl = `https://www.dimensiondatajobs.com/personal-details?jobId=${job.id}&action=createProfile&feature=apply`;
                        const workingHours = job.type === 'permanent' ? 40 : 30;

                        const salaryIndication = {
                            salary_period: job.salary_period,
                            salary_from: job.salary_from,
                            salary_to: job.salary_to
                        };

                        const jobDescription = job.requirements
                            ? `${job.description} <h3>Requirements</h3> ${job.requirements}`
                            : job.description;
                        const dataUpd: any = {
                            jobId: job.id,
                            jobOwner: job.owner,
                            jobTitle: job.title,
                            jobDescription,
                            currency: 'USD',
                            orderedProducts: selectedProducts,
                            jobUrl: val,
                            applicationJobUrl: applicationUrl,
                            jobType: job.type,
                            location: job.location,
                            isRemote: job.is_remote,
                            yearsOfExperience: job.years_experience,
                            workingHours,
                            seniority,
                            education,
                            jobcategory,
                            industry,
                            orderedProductsSpecs: selectedProductsUTM,
                            totalPrice
                        };
                        if (!job.salary.hide) {
                            dataUpd.salaryIndication = salaryIndication;
                        }
                        subscriber.next(dataUpd);
                        subscriber.complete();
                    },
                    (err) => {
                        subscriber.error(err);
                    }
                );
        });
    }

    getTaxonomyEndpoints(jobId) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/endpoints`);
    }

    createVonqCampaign(jobId, data) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/marketplace`, data);
    }

    getVonqCampaigns(jobId: string) {
        return this.http.get(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/marketplace/campaigns`
        );
    }

    rateJob(jobId, userId, data) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/${userId}/rate`,
            data
        );
    }

    list(jobs) {
        return jobs.map(({ ...item }) => {
            if (item.job_listing) {
                let listing = item.job_listing;
                item.job_listing_transformed = item.job_listing;
                if (listing === 'auto-matching-off') item.job_listing_transformed = 'Auto-match Off';
                if (listing === 'default') item.job_listing_transformed = 'Visible';
            }
            item.created = item.created_at ? this.utilities.fromNowExt(item.created_at) : '';
            item.updated = item.updated_at ? this.utilities.fromNowExt(item.updated_at) : '';
            item.campaigns = 0;

            if (item.vonq_campaigns && item.vonq_campaigns.length) {
                let total = 0;
                const campaigns = item.vonq_campaigns.filter((c) => c.status === 'online');
                campaigns.forEach((c) => {
                    if (c.orderedProducts) {
                        c.orderedProducts = c.orderedProducts.filter(
                            (p) => p !== 'cc38182c-c6ef-510c-a7c3-8f740f600e7c' && p !== '4063'
                        );
                        total += c.orderedProducts.length;
                    } else {
                        total += 1;
                    }
                });
                item.campaigns = total;
            }
            return { ...item };
        });
    }

    getPdf(jobId) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/get-pdf`);
    }

    takeOwnership(job, jobId) {
        return this.http.put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/take-ownership`, {
            ...job
        });
    }

    getJobsByOwner(jobOwner) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/get-jobs-by-owner`, {
            jobOwner
        });
    }

    changeJobsOwner(jobsId: string[], newJobOwner: string) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/change-jobonwers`, {
            jobsId,
            newJobOwner
        });
    }

    sendReparseRequest(jobId: string) {
        return this.http
            .post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/reparse`, {})
            .toPromise();
    }

    checkIfReparseIsNeeded(existingJob, job) {
        const fieldsToCheck = ['title', 'type', 'company', 'location', 'experience', 'description', 'requirements'];
        const job1 = pick(existingJob, fieldsToCheck);
        const job2 = pick(job, fieldsToCheck);
        return isEqual(job1, job2);
    }

    setStagesForJob(job): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                if (job.pipeline && job.pipeline.length) {
                    const stages_stats = [];
                    const stages = [];
                    // 1. Get pipelines list
                    const pipelines = await this.tenantService.pipelinesAsPromise();
                    // 2. clear job stages collection
                    await this.clearJobStages(job.id);
                    // 3. add items to job stages collection
                    const pipeline = pipelines.find((p) => p.id === job.pipeline);

                    console.log('Pipeline:', pipeline);
                    if (pipeline) {
                        const pipelineStages = pipeline.stages || [];
                        let i = 0;
                        for (const s of pipelineStages) {
                            // const id = s.stage_name
                            //     .split(' ')[0]
                            //     .split('/')[0]
                            //     .toLowerCase();
                            const stage: any = {
                                title: s.stage_name,
                                candidates: 0,
                                created_at: Math.floor(Date.now() / 1000)
                            };
                            if (i === pipeline.stages.length - 1) {
                                stage.id = 'hired';
                                stage.icon = s.icon || '';
                                stage.order = i;
                            } else if (i !== 0) {
                                const id =
                                    s.stage_name
                                        .split(' ')[0]
                                        .split('/')[0]
                                        .split('.')[0]
                                        .split('-')[0]
                                        .split('~')[0] +
                                    '_' +
                                    this.utilities.generateUID(4).toLowerCase();
                                stage.id = id;
                                stage.order = i;
                                stage.icon = s.icon;
                            } else if (i === 0 && s.stage_name === 'Applied') {
                                stage.icon = s.icon || '';
                                stage.id = 'applied';
                                stage.title = 'Applied';
                                stage.order = 0;
                            }

                            await this.saveJobStage(job.id, stage);
                            stages.push(stage);
                            stages_stats.push({
                                id: stage.id,
                                order: stage.order,
                                title: stage.title,
                                candidates: 0
                            });
                            i += 1;
                        }
                    }
                    // 4. return stages_stats
                    return resolve({ stages_stats: sortBy(stages_stats, ['order']), stages });
                } else {
                    if (job.stages_stats) {
                        return resolve({ stages_stats: sortBy(job.stages_stats, ['order']), stages: job.stages || [] });
                    } else {
                        const stages_stats = [];
                        const stages = [];
                        // 1. clear job stages collection
                        await this.clearJobStages(job.id);

                        // 2. create applied stage
                        const stage = {
                            id: 'applied',
                            order: 0,
                            title: 'Applied'
                        };
                        await this.saveJobStage(job.id, stage);
                        stages.push(stage);
                        stages_stats.push({
                            id: stage.id,
                            title: stage.title,
                            order: stage.order,
                            candidates: 0
                        });
                        return resolve({ stages_stats: sortBy(stages_stats, ['order']), stages });
                    }
                }
            } catch (error) {
                return reject(error);
            }
        });
    }

    clearJobStages(jobId) {
        return new Promise(async (resolve, reject) => {
            const snapshot = await this.db.firestore
                .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/stages`)
                .get();
            const batchSize = snapshot.size;
            if (batchSize === 0) {
                // When there are no documents left, we are done
                return resolve(null);
            }
            const batch = this.db.firestore.batch();
            snapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });
            await batch.commit();
            // process.nextTick(() => {
            return resolve(null);
            // });
        });
    }

    saveJobStage(jobId, stage) {
        // console.log('saveJobStage', jobId, stage);
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/stages`)
            .doc(stage.id)
            .set(stage);
    }

    addToAudit(jobId, auditRecord) {
        return this.db.collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/audit`).add(auditRecord);
    }

    // Stage classes
    getCandidateStageClass(job, candidateId, newStage = null) {
        // console.log('getCandidateStageClass', job.id, candidateId, newStage);
        return new Promise(async (resolve, reject) => {
            try {
                // job.questions = (await this.getJobQuestionsList(job).toPromise()) || [];
                const candidate$ = this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates`)
                    .doc(candidateId)
                    .valueChanges()
                    .pipe(take(1));
                const candidate: any = await candidate$.toPromise();
                if (!candidate) {
                    return reject('Candidate not found');
                }
                candidate.score = (candidate.matching && candidate.matching[job.id]) || 0;
                candidate.assignments = (await this.getAssignments(candidateId, job.id)) || [];
                candidate.opportunities = (await this.getOpportunities(candidateId)) || [];
                if (!candidate.stage) {
                    candidate.stage = {};
                }

                let stageId = candidate.stage && candidate.stage[job.id] ? candidate.stage[job.id] : 'applied';
                if (newStage) {
                    stageId = newStage;
                    candidate.stage[job.id] = newStage;
                }
                const stage = job.stages.find((s) => s.id === stageId);
                // console.log(stage);
                const assignments = candidate.assignments
                    ? candidate.assignments.filter((a) => a.stageId === stageId)
                    : [];
                assignments.forEach((assignment) => {
                    // console.log(assignment);
                    if (assignment.type === 'questions') {
                        const stageQuestion = (job.questions_list || []).filter((q) => {
                            return q.id === assignment.option || q.id === assignment.id;
                        });
                        const candidateQ = {
                            hasAnswers: false,
                            isKnockout: false,
                            isExpired: false
                        };
                        const stageAss =
                            stage && stage.assessment
                                ? stage.assessment.find((a) => {
                                      return a.type === assignment.type;
                                  })
                                : [];
                        let candidateQuestions = {};
                        if (assignment) {
                            candidateQ.hasAnswers = assignment.completed;
                            if (!assignment.completed) {
                                candidateQ.isExpired =
                                    moment
                                        .unix(assignment.expired_at || assignment.added_at)
                                        .add((stageAss && stageAss.deadline) || 7, 'days')
                                        .unix() < moment().unix()
                                        ? true
                                        : false;
                            }
                            candidateQuestions = assignment.data;
                        }
                        if (candidateQ.hasAnswers && !candidateQ.isKnockout && stageQuestion[0]) {
                            stageQuestion[0].questions_col.forEach((q) => {
                                let questionKnockout = '';
                                function applyKnockout(answer) {
                                    if (answer.is_knockout !== undefined && questionKnockout !== 'knockout wrong') {
                                        questionKnockout = !answer.is_knockout ? 'knockout' : 'knockout wrong';
                                        if (!candidateQ.isKnockout && answer.is_knockout) candidateQ.isKnockout = true;
                                    }
                                }
                                if (q.answers) {
                                    if (
                                        candidateQuestions[q.id] &&
                                        candidateQuestions[q.id].selectedItems &&
                                        Array.isArray(candidateQuestions[q.id].selectedItems)
                                    ) {
                                        candidateQuestions[q.id].selectedItems.forEach((qa) => {
                                            const answer = q.answers.find((a) => a.id === qa);
                                            if (answer) {
                                                applyKnockout(answer);
                                            }
                                        });
                                    } else {
                                        const qa = candidateQuestions[q.id];
                                        const answer = q.answers.find((a) => a.id === qa);
                                        if (answer) {
                                            applyKnockout(answer);
                                        }
                                    }
                                }
                            });
                        }
                        assignment.isExpired = candidateQ.isExpired;
                        assignment.isKnockout = candidateQ.isKnockout;
                        assignment.hasAnswers = candidateQ.hasAnswers;
                    } else if (
                        !assignment.completed &&
                        (assignment.type === 'personality' || assignment.type === 'logic-test')
                    ) {
                        const stageAss =
                            stage && stage.assessment
                                ? stage.assessment.find((a) => {
                                      return a.type === assignment.type;
                                  })
                                : null;

                        assignment.isExpired =
                            moment
                                .unix(assignment.expired_at || assignment.added_at)
                                .add((stageAss && stageAss.deadline) || 5, 'days')
                                .unix() < moment().unix();
                    } else if (
                        !assignment.completed &&
                        (assignment.type === 'video-interview' || assignment.type === 'devskiller')
                    ) {
                        const stageAss =
                            stage && stage.assessment
                                ? stage.assessment.find((a) => {
                                      return a.option === assignment.option;
                                  })
                                : null;

                        assignment.isExpired =
                            moment
                                .unix(assignment.expired_at || assignment.added_at)
                                .add((stageAss && stageAss.deadline) || 5, 'days')
                                .unix() < moment().unix();
                    }
                    if (assignment.type === 'devskiller') {
                        const devAss = assignment;
                        if (assignment.completed) {
                            const score = (devAss.results.scoredPoints / devAss.results.maxPoints) * 100;
                            if (score < 40) assignment.isKnockout;
                            else score >= 60 ? '' : assignment.isAverege;
                        }
                    }
                    if (assignment.type === 'logic-test') {
                        if (assignment.completed) {
                            let score = assignment.data.score;
                            if (score <= 5) {
                                assignment.isKnockout = true;
                            } else if (score > 5 && score < 8) {
                                assignment.isAverege = true;
                            } else {
                                assignment.isPass = true;
                            }
                        }
                    }
                });
                const isAverege = assignments.some((ass) => ass.isAverege);
                const isExpired = assignments.some((ass) => ass.isExpired && candidate.hasUser);
                const isKnockout = assignments.some((ass) => ass.isKnockout);
                const isStarted = assignments.some((ass) => ass.started_at || ass.completed);
                const isExtended = assignments.some((ass) => ass.expired_at && !ass.completed);
                let isCompleted = assignments.every((ass) => ass.completed);
                let isSelfDeleted = false;
                let isDeleted = false;
                let underReviewOnAnotherJob = false;
                let acceptedAnotherOffer = false;
                let offerRetracted = false;
                if (candidate.stage[job.id] === 'hired') {
                    const assignment = candidate.assignments.find((ass) => ass.stageId === 'hired');
                    if (assignment && !assignment.offer_accepted) {
                        [
                            isDeleted,
                            isSelfDeleted,
                            underReviewOnAnotherJob,
                            acceptedAnotherOffer
                        ] = this.getOffersStatuses(candidate, job);
                    }
                    if (assignment && assignment.retracted) {
                        offerRetracted = true;
                    }
                    if (assignment && assignment.completed) {
                        isCompleted = true;
                    }
                } else {
                    [isDeleted, isSelfDeleted, underReviewOnAnotherJob, acceptedAnotherOffer] = this.getOffersStatuses(
                        candidate,
                        job
                    );
                }

                let complianceRateClass = this.getCandidateComplienceClass(candidate, job);

                let stageClass = '';
                let stageOrder = 0;

                if (stageId === 'applied') {
                    if (isSelfDeleted) {
                        stageClass = 'self-deleted';
                        stageOrder = 10;
                    } else if (isDeleted) {
                        stageClass = 'deleted';
                        stageOrder = 9;
                    } else if (acceptedAnotherOffer) {
                        stageClass = 'accepted-another-offer';
                        stageOrder = 8;
                    } else if (underReviewOnAnotherJob) {
                        if (complianceRateClass !== 'pending') {
                            stageClass = 'under-review';
                            stageOrder = 6;
                        } else {
                            stageClass = 'pending';
                            stageOrder = 1;
                        }
                    } else if (
                        (!candidate.employment_history || !candidate.employment_history.length) &&
                        candidate.hasUser &&
                        !candidate.resume_file
                    ) {
                        stageClass = 'resume-required';
                        stageOrder = 5;
                    } else if (isExpired) {
                        stageClass = 'expired';
                        stageOrder = 4;
                    } else if (isCompleted && assignments && assignments.length > 0) {
                        if (isKnockout) {
                            stageClass = 'red';
                            stageOrder = 7;
                        } else if (
                            job &&
                            job.do_not_match &&
                            complianceRateClass !== 'pending' &&
                            complianceRateClass !== 'yellow'
                        ) {
                            stageClass = 'green';
                            stageOrder = 2;
                        } else if (this.getCandidateComplienceClass(candidate, job) === 'orange') {
                            stageClass = 'orange';
                            stageOrder = 3;
                        } else if (this.getCandidateComplienceClass(candidate, job) === 'green') {
                            stageClass = 'green';
                            stageOrder = 2;
                        } else {
                            stageClass = 'red';
                            stageOrder = 7;
                        }
                    } else if (!isStarted && assignments && assignments.length > 0) {
                        if (isExtended) {
                            stageClass = 'notification';
                        } else if (complianceRateClass === 'yellow' || complianceRateClass === 'pending') {
                            stageClass = complianceRateClass;
                        } else {
                            stageClass = 'notification';
                        }
                        stageOrder = this.setOrderForCandidate(candidate, job);
                    } else if (!isCompleted) {
                        if (job && job.do_not_match) {
                            stageClass = 'grey';
                        } else if (complianceRateClass === 'green') {
                            stageClass = 'grey';
                        } else if (complianceRateClass === 'orange') {
                            stageClass = 'grey-yellow';
                        } else {
                            stageClass = 'grey-red';
                        }
                        stageOrder = 3;
                    } else {
                        if (
                            job &&
                            job.do_not_match &&
                            complianceRateClass !== 'pending' &&
                            complianceRateClass !== 'yellow'
                        ) {
                            stageClass = 'green';
                            stageOrder = 2;
                        } else {
                            stageClass = complianceRateClass;
                            stageOrder = this.setOrderForCandidate(candidate, job);
                        }
                    }
                } else {
                    if (offerRetracted) {
                        stageClass = 'offer-retracted';
                        stageOrder = 6;
                    } else if (isSelfDeleted) {
                        stageClass = 'self-deleted';
                        stageOrder = 6;
                    } else if (isDeleted) {
                        stageClass = 'deleted';
                        stageOrder = 6;
                    } else if (acceptedAnotherOffer) {
                        stageClass = 'accepted-another-offer';
                        stageOrder = 6;
                    } else if (underReviewOnAnotherJob) {
                        stageClass = 'under-review';
                        stageOrder = 6;
                    } else if (isExpired && !isCompleted) {
                        stageClass = 'yellow';
                        stageOrder = 4;
                    } else if (!isCompleted) {
                        stageOrder = 3;
                        if (isExpired || isKnockout) {
                            stageClass = 'grey-red';
                        } else if (isAverege) {
                            stageClass = 'grey-yellow';
                        } else if (isStarted) {
                            stageClass = 'grey';
                        } else {
                            stageClass = this.getCurrentStageClass(candidate, job);
                        }
                    } else {
                        // console.log('else');
                        if (isKnockout) {
                            stageClass = 'red';
                            stageOrder = 3;
                        } else {
                            // console.log('getCurrentStageClass');
                            stageClass = this.getCurrentStageClass(candidate, job);
                            stageOrder = 1;
                        }
                    }
                }

                // console.log('--------------');
                // console.log({
                //     stageClass,
                //     order: stageOrder,
                //     complianceRateClass
                // });

                return resolve({
                    stageClass,
                    order: stageOrder,
                    complianceRateClass
                });
            } catch (error) {
                return reject(error);
            }
        });
    }

    getCandidateComplienceClass(candidate, job): string {
        // console.log('---getCandidateComplienceClass', candidate.score, job.resume_matching_threshold);
        const resumeThreshold = job.resume_matching_threshold || 0;
        if (candidate.job_id) {
            const jobId = candidate.job_id.find((c) => c === job.id);
            if (jobId && candidate.opportunities) {
                let opportunities = candidate.opportunities.find((c) => c.jobId === jobId);
                if (opportunities && !opportunities.approved) {
                    return 'pending';
                }
            }
        }

        if (candidate.hasUser && (candidate.hasUserReviewed || candidate.matching)) {
            let averageMin = resumeThreshold - resumeThreshold / 3;
            let averageMax = resumeThreshold;
            if (candidate.score >= averageMax || resumeThreshold === 0) {
                return 'green';
            } else if (candidate.score < averageMax && candidate.score >= averageMin) {
                return 'orange';
            } else {
                return 'red';
            }
        } else {
            return 'yellow';
        }
    }

    getOffersStatuses(candidate, job) {
        let acceptedAnotherOffer = false;
        let underReviewOnAnotherJob = false;
        const isSelfDeleted =
            candidate.selfDeclinedJobs &&
            candidate.selfDeclinedJobs.length &&
            candidate.selfDeclinedJobs.indexOf(job.id) !== -1 &&
            candidate.job_id.indexOf(job.id) === -1;

        const isDeleted =
            candidate.declinedJobs &&
            candidate.declinedJobs.length &&
            candidate.declinedJobs.indexOf(job.id) !== -1 &&
            candidate.job_id &&
            candidate.job_id.indexOf(job.id) === -1;

        if (candidate.stage) {
            for (const key in candidate.stage) {
                if (candidate.stage.hasOwnProperty(key)) {
                    const st = candidate.stage[key];
                    if (st === 'hired' && key !== job.id) {
                        const candidateDeclined = candidate.declinedJobs ? [...candidate.declinedJobs] : [];
                        const isDeclinedOnHiredJob = candidateDeclined.find((id) => id === key);

                        if (isDeclinedOnHiredJob) {
                            continue;
                        }

                        const assignment = candidate.assignments.find((ass) => ass.stageId === 'hired');
                        if (assignment) {
                            if (typeof assignment.offer_accepted !== 'undefined') {
                                if (assignment.offer_accepted) {
                                    acceptedAnotherOffer = true;
                                } else {
                                    underReviewOnAnotherJob = true;
                                }
                            } else {
                                underReviewOnAnotherJob = true;
                            }
                        } else {
                            underReviewOnAnotherJob = true;
                        }
                    }
                }
            }
        }

        return [isDeleted, isSelfDeleted, underReviewOnAnotherJob, acceptedAnotherOffer];
    }

    getCurrentStageClass(candidate, job) {
        //define stage
        if (candidate.stage && candidate.stage[job.id] && candidate.stage[job.id] !== 'applied') {
            const stageId = candidate.stage[job.id];
            const getAssignmentClass = (ass, stageAss) => {
                if (!ass) return 0;
                if (ass.type === 'logic-test' && ass.data && ass.data.score) {
                    let score = ass.data.score;
                    if (score <= 5) {
                        return 1;
                    } else if (score > 5 && score < 8) {
                        return 2;
                    } else {
                        return 3;
                    }
                }
                if (ass.completed) return 3;
                return (ass.expired_at ||
                    moment
                        .unix(ass.added_at)
                        .add(stageAss.deadline || 5, 'days')
                        .unix()) < moment().unix()
                    ? -1
                    : !ass.started_at
                    ? -2
                    : 0;
            };

            // need to check stages data
            if (job && job.stages && job.stages.find((s) => s.id === stageId)) {
                const stage = job.stages.find((s) => s.id === stageId);
                if (stage && stage.assessment && stage.assessment.length) {
                    if (candidate.assignments.find((ass) => ass.stageId === stageId)) {
                        const completed = [];
                        const stageData = candidate.assignments.filter((ass) => ass.stageId === stageId);
                        stage.assessment.forEach((ass) => {
                            const candidateAss = stageData && stageData.find(({ option }) => option === ass.option);
                            if (ass.type === 'personality') {
                                completed.push(getAssignmentClass(candidateAss, ass));
                            }
                            if (ass.type === 'video-interview') {
                                completed.push(getAssignmentClass(candidateAss, ass));
                            }

                            if (ass.type === 'logic-test') {
                                const logicTest = candidateAss;
                                if (logicTest && logicTest.score >= 0) {
                                    if (logicTest.score < 6) completed.push(1);
                                    else completed.push(logicTest.score >= 8 ? 3 : 2);
                                } else {
                                    completed.push(getAssignmentClass(logicTest, ass));
                                }
                            }
                            if (ass.type === 'questions') {
                                completed.push(getAssignmentClass(candidateAss, ass));
                            }

                            if (ass.type === 'devskiller') {
                                const devAss = candidateAss;
                                if (devAss && devAss.completed) {
                                    const score = (devAss.results.scoredPoints / devAss.results.maxPoints) * 100;
                                    if (score < 40) completed.push(1);
                                    else completed.push(score >= 60 ? 3 : 2);
                                } else {
                                    completed.push(getAssignmentClass(devAss, ass));
                                }
                            }
                            if (ass.type === 'offer') {
                                if (candidateAss && !candidateAss.hasOwnProperty('offer_accepted')) {
                                    completed.push(-2);
                                } else if (candidateAss && candidateAss.offer_accepted) {
                                    completed.push(-20);
                                } else {
                                    const offerAss = stageData && stageData.find((o) => o.type === 'offer');
                                    if (offerAss && offerAss.offer_accepted) {
                                        completed.push(-20);
                                    } else {
                                        completed.push(-30);
                                    }
                                }
                            }
                        });

                        return this._getClassFromValue(Math.min(...completed));
                    } else {
                        return 'grey';
                    }
                } else if (stageId === 'hired' && (!stage.assessment || stage.assessment.length === 0)) {
                    const stageData = candidate.assignments.filter((ass) => ass.stageId === stageId);
                    const candidateAss = stageData && stageData.find(({ option }) => stage.id === 'hired');
                    if (candidateAss && candidateAss.hasOwnProperty('offer_accepted')) {
                        if (candidateAss && candidateAss.offer_accepted) {
                            return 'offer_accepted';
                        } else {
                            return 'offer_declined';
                        }
                    } else {
                        return 'green';
                    }
                } else {
                    return 'green';
                }
            } else {
                return 'green';
            }
        } else {
            // APPLIED STAGE
            const complienceRate = this._getClassValue(this.getCandidateComplienceClass(candidate, job));
            const values = [];
            values.push(complienceRate);
            // if (job.questionnaire) {
            // values.push(questionsStatus);
            // }
            const minValue = Math.min(...values);
            return this._getClassFromValue(minValue);
        }
    }

    _getClassValue(className) {
        switch (className) {
            case 'green':
                return 3;
            case 'orange':
                return 2;
            case 'red':
                return 1;
            default:
                return 0;
        }
    }

    _getClassFromValue(value) {
        switch (value) {
            case 3:
                return 'green';
            case 2:
                return 'orange';
            case 1:
                return 'red';
            case -1:
                return 'yellow';
            case -2:
                return 'notification';
            case -20:
                return 'offer_accepted';
            case -30:
                return 'offer_declined';
            default:
                return 'grey';
        }
    }

    setOrderForCandidate(candidate, job): number {
        const className = this.getCandidateComplienceClass(candidate, job);
        switch (className) {
            case 'green':
                return 2;
            case 'orange':
                return 3;
            case 'red':
                return 7;
            case 'pending':
                return 1;
            case 'yellow':
                return 4;
            case 'resume-required':
                return 5;
            case 'under-review':
                return 6;
            default:
                return 8;
        }
    }

    updateJobStagesStats(job) {
        console.log('📊 updateJobStagesStats', { ...job });
        return new Promise(async (resolve, reject) => {
            try {
                let stages_stats = job.stages_stats;
                if (!stages_stats) {
                    stages_stats = [];
                    for (const stage of job.stages) {
                        stages_stats.push({
                            id: stage.id,
                            order: stage.order,
                            title: stage.title,
                            candidates: 0
                        });
                    }
                    stages_stats = sortBy(stages_stats, ['order']);
                }

                const candidates$ = this.db
                    .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${job.id}/candidates`)
                    .valueChanges({ idField: 'id' })
                    .pipe(take(1));
                const candidates: any = await candidates$.toPromise();
                stages_stats.forEach((s) => {
                    const cand = candidates.filter((c) => c.stage === s.id);
                    s.candidates = cand.length;
                });
                await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
                    .doc(job.id)
                    .update({ stages_stats });
                console.log('stages_stats', stages_stats);
                job.stages_stats = stages_stats;
                if (!job.stages) {
                    const stages = await this.getStages(job.id)
                        .pipe(take(1))
                        .toPromise();
                    job.stages = stages;
                }
                this.jobsStore.dispatch(new UpdateJob(job));
                return resolve(stages_stats);
            } catch (error) {
                return reject(error);
            }
        });
    }

    getJobApplicants(jobId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs_visits/${jobId}/applicants`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getJobVisitsCollection(jobId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs_visits`)
            .doc(jobId)
            .valueChanges()
            .pipe(take(1));
    }

    sendRate(jobId: string, ids: string[]) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/send-rate`, ids);
    }

    private transformToSnakecase(string: string): string {
        return string
            .toLowerCase()
            .split(' ')
            .join('_');
    }

    postToVonq(job: SdkJob) {
        return new Promise(async (resolve, reject) => {
            try {
                const productUTM = {
                    productId: '4063',
                    utm: `utm_medium=social&utm_source=google&utm_campaign=${this.transformToSnakecase(job.title)}`
                };
                // const response1 = await this.convertJobForVonq(job, ['4063'], [productUTM])
                //     .pipe(switchMap((data) => this.createVonqCampaign(job.id, data)))
                //     .toPromise();
                // console.log('postToVonq:4063', response1);

                const productUTM2 = {
                    productId: 'cc38182c-c6ef-510c-a7c3-8f740f600e7c',
                    utm: `utm_medium=social&utm_source=boost&utm_campaign=${this.transformToSnakecase(job.title)}`
                };
                const response2 = await this.convertJobForVonq(
                    job,
                    ['cc38182c-c6ef-510c-a7c3-8f740f600e7c'],
                    [productUTM2]
                )
                    .pipe(switchMap((data) => this.createVonqCampaign(job.id, data)))
                    .toPromise();
                console.log('postToVonq:cc38182c-c6ef-510c-a7c3-8f740f600e7c', response2);

                return resolve(null);
            } catch (error) {
                return reject(error);
            }
        });
    }

    statusUpdated() {
        return this.status.asObservable();
    }

    statusJob(status: string, jobId: string) {
        return this.status.next({ status, jobId });
    }

    updateCandidatesStatuses(jobId) {
        return this.http.get(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/update-candidates-statuses`
        );
    }

    async getAtlasJob(jobId) {
        const app = await getValidAccessToken();
        const db = app.currentUser.mongoClient('mongodb-atlas').db('D9BO1JR3D');
        return db.collection('userjobs').findOne({ _id: new ObjectID(jobId) });
    }

    async getAtlasFullJob(jobId) {
        const app = await getValidAccessToken();
        const db = app.currentUser.mongoClient('mongodb-atlas').db('D9BO1JR3D');
        return db.collection('jobs').findOne({ _id: new ObjectID(jobId) });
    }

    async getAtlasUsers(ids) {
        const app = await getValidAccessToken();

        const db = app.currentUser.mongoClient('mongodb-atlas').db('D9BO1JR3D');
        return db.collection('users').find({
            _id: {
                $in: ids
            }
        });
    }
    // Don't delete
    getAlllocations(): Observable<any[]> {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/locations`, (ref) => ref.orderBy('name'))
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }
}
