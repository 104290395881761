<div class="content">
    <div class="header">
        <ng-container *ngIf="view === 'default' || view === 'workflow'">
            <h3>Offer Letter</h3>
        </ng-container>
        <ng-container *ngIf="view === 'compensation'">
            <h3>Compensation</h3>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <h3>Review {{ form.value?.full_name }}’s Offer Letter</h3>
        </ng-container>
        <button class="close-btn btn" *ngIf="view !== 'preview'" (click)="onHideOfferModal()">
            <img src="/assets/images/icons/icons-8-delete.svg" />
        </button>
    </div>
    <div class="form-content-section" [formGroup]="form">
        <ng-container *ngIf="view === 'default'">
            <div class="form-content-holder">
                <div class="form-block">
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Candidate Full Name</mat-label>
                                    <input type="text" autocomplete="off" formControlName="full_name" matInput />
                                    <mat-error
                                        *ngIf="!form.controls.full_name.valid && form.controls.full_name.touched"
                                    >
                                        Full Name is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Candidate Identity Number</mat-label>
                                    <input type="text" autocomplete="off" formControlName="identity_number" matInput />
                                    <mat-error
                                        *ngIf="
                                            !form.controls.identity_number.valid &&
                                            form.controls.identity_number.touched
                                        "
                                    >
                                        Identity Number is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline">
                                    <mat-label>Job Title</mat-label>
                                    <input type="text" autocomplete="off" formControlName="designation" matInput />
                                    <mat-error
                                        *ngIf="!form.controls.designation.valid && form.controls.designation.touched"
                                    >
                                        Job Title is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <mat-form-field appearance="outline" (click)="picker1.open()">
                                    <mat-label>Start Date</mat-label>
                                    <input
                                        formControlName="start_date"
                                        matInput
                                        [matDatepicker]="picker1"
                                        autocomplete="off"
                                    />
                                    <mat-datepicker #picker1></mat-datepicker>
                                    <mat-error
                                        *ngIf="!form.controls.start_date.valid && form.controls.start_date.touched"
                                    >
                                        Start Date is required</mat-error
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="jobLocationOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="Job Location"
                                    [control]="form.controls.location"
                                    [ngClass]="{
                                        invalid: !form.controls.location.valid && form.controls.location.touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="timeOffOptions"
                                    [newStyle]="true"
                                    placeholder="Time Off"
                                    [control]="form.controls.time_off"
                                    [ngClass]="{
                                        invalid: !form.controls.time_off.valid && form.controls.time_off.touched
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="!form.controls.time_off.valid && form.controls.time_off.touched"
                                >
                                    Time Off is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="separator-line"></div>
                    <h4>Reporting Structure</h4>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block" *ngIf="usersOptions?.length">
                                <app-auto-dropdown
                                    [options]="usersOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="Hiring Manager"
                                    [control]="form.controls.manager"
                                    [ngClass]="{
                                        invalid: !form.controls.manager.valid && form.controls.manager.touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="contactsOptions"
                                    [newStyle]="true"
                                    placeholder="Hiring Executive"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [control]="form.controls.hiring_executive"
                                    [ngClass]="{
                                        invalid:
                                            !form.controls.hiring_executive.valid &&
                                            form.controls.hiring_executive.touched
                                    }"
                                >
                                </app-auto-dropdown>
                                <div
                                    class="error-message"
                                    *ngIf="
                                        form.controls.hiring_executive.touched && !form.controls.hiring_executive.valid
                                    "
                                >
                                    Hiring Executive is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-block-columns">
                        <div class="form-block-col">
                            <div class="input-block">
                                <app-auto-dropdown
                                    [options]="usersOptions"
                                    [dropdownValueUpdated]="dropdownValueUpdated"
                                    [newStyle]="true"
                                    placeholder="HR Business Partner"
                                    [control]="form.controls.hr_business_partner"
                                    [ngClass]="{
                                        invalid:
                                            !form.controls.hr_business_partner.valid &&
                                            form.controls.hr_business_partner.touched
                                    }"
                                >
                                </app-auto-dropdown>
                            </div>
                        </div>
                        <div class="form-block-col-separator"></div>
                        <div class="form-block-col"></div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'compensation'">
            <div class="form-content-holder">
                <div class="form-block">
                    <ng-container formGroupName="salary">
                        <div class="form-block-columns">
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Annual Salary</mat-label>
                                        <input
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="salary"
                                            matInput
                                        />
                                        <mat-error
                                            *ngIf="
                                                !form.get('salary').controls.salary.valid &&
                                                form.get('salary').controls.salary.touched
                                            "
                                        >
                                            Annual Salary is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="currencyOptions"
                                        [newStyle]="true"
                                        [control]="form.get('salary').controls.currency"
                                        placeholder="Currency"
                                        [ngClass]="{
                                            invalid:
                                                form.get('salary').controls.currency.touched &&
                                                !form.get('salary').controls.currency.valid
                                        }"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="
                                            form.get('salary').controls.currency.touched &&
                                            !form.get('salary').controls.currency.valid
                                        "
                                    >
                                        Currency is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-block-columns mb20">
                            <p-checkbox
                                class="commission-checkbox"
                                binary="true"
                                label="Add Variable Pay / Commission"
                                (onChange)="onCommissionCheckboxChange($event)"
                                formControlName="variable_pay"
                            >
                            </p-checkbox>
                        </div>
                        <div
                            class="form-block-columns"
                            *ngIf="form.get('salary').controls.commission"
                            formGroupName="commission"
                        >
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Variable Pay Amount</mat-label>
                                        <input
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="form-block-columns">
                                    <div class="form-block-col">
                                        <app-auto-dropdown
                                            [options]="comissionTypeOptions"
                                            [newStyle]="true"
                                            [control]="form.get('salary').controls.commission.controls.type"
                                            placeholder="Type"
                                            [ngClass]="{
                                                invalid:
                                                    form.get('salary').controls.commission.controls.type.touched &&
                                                    !form.get('salary').controls.commission.controls.type.valid
                                            }"
                                        >
                                        </app-auto-dropdown>
                                    </div>
                                    <div class="form-block-col-separator"></div>
                                    <div class="form-block-col">
                                        <app-auto-dropdown
                                            [options]="payScheduleOptions"
                                            [newStyle]="true"
                                            [control]="form.get('salary').controls.commission.controls.payout_frequency"
                                            placeholder="Frequency"
                                        >
                                        </app-auto-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container formArrayName="bonuses" *ngIf="form.get('bonuses')['controls'].length">
                        <div class="separator-line"></div>
                        <h4>Bonuses</h4>
                        <div
                            class="form-block-columns"
                            *ngFor="let item of form.get('bonuses')['controls']; let i = index"
                            [formGroupName]="i"
                        >
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="bonusTypeOptions"
                                        [newStyle]="true"
                                        [control]="item.controls.type"
                                        placeholder="Bonus Type"
                                        [ngClass]="{
                                            invalid: item.controls.type.touched && !item.controls.type.valid
                                        }"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="item.controls.type.touched && !item.controls.type.valid"
                                    >
                                        Bonus Type is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Bonus Amount</mat-label>
                                        <input
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                        <mat-error *ngIf="!item.controls.amount.valid && item.controls.amount.touched">
                                            Amount is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-5 delete-ico-col">
                                <span (click)="onDeleteBonus(i)">
                                    <svg
                                        width="11px"
                                        viewBox="0 0 8 10"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                    >
                                        <g
                                            id="Recruit-Setup"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <g
                                                id="Job-View---Default"
                                                transform="translate(-498.000000, -237.000000)"
                                                fill="#212b35"
                                                fill-rule="nonzero"
                                            >
                                                <g id="icons8-delete" transform="translate(498.000000, 237.000000)">
                                                    <path
                                                        d="M3.26988655,0 C2.67329527,0 2.18181818,0.519831538 2.18181818,1.15084154 L2.18181818,1.53846154 L0,1.53846154 L0,2.30769231 L0.727272727,2.30769231 L0.727272727,8.84615385 C0.727272727,9.48016846 1.21874982,10 1.81818182,10 L6.18181818,10 C6.78125018,10 7.27272727,9.48016846 7.27272727,8.84615385 L7.27272727,2.30769231 L8,2.30769231 L8,1.53846154 L5.81818182,1.53846154 L5.81818182,1.15084154 C5.81818182,0.519831538 5.32670473,0 4.73011345,0 L3.26988655,0 Z M3.26988655,0.769230769 L4.73011345,0.769230769 C4.93465891,0.769230769 5.09090909,0.934495385 5.09090909,1.15084154 L5.09090909,2.30769231 L6.54545455,2.30769231 L6.54545455,8.84615385 C6.54545455,9.0625 6.38636364,9.23076923 6.18181818,9.23076923 L1.81818182,9.23076923 C1.61363636,9.23076923 1.45454545,9.0625 1.45454545,8.84615385 L1.45454545,2.30769231 L2.90909091,2.30769231 L2.90909091,1.15084154 C2.90909091,0.934495385 3.06534109,0.769230769 3.26988655,0.769230769 Z M2.18181818,3.07692308 L2.18181818,8.46153846 L2.90909091,8.46153846 L2.90909091,3.07692308 L2.18181818,3.07692308 Z M3.63636364,3.07692308 L3.63636364,8.46153846 L4.36363636,8.46153846 L4.36363636,3.07692308 L3.63636364,3.07692308 Z M5.09090909,3.07692308 L5.09090909,8.46153846 L5.81818182,8.46153846 L5.81818182,3.07692308 L5.09090909,3.07692308 Z"
                                                        id="Shape"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container formArrayName="allowances" *ngIf="form.get('allowances')['controls'].length">
                        <div class="separator-line"></div>
                        <h4>Allowances</h4>
                        <div
                            class="form-block-columns"
                            *ngFor="let item of form.get('allowances')['controls']; let i = index"
                            [formGroupName]="i"
                        >
                            <div class="form-block-col">
                                <div class="input-block">
                                    <app-auto-dropdown
                                        [options]="allowancesTypeOptions"
                                        [newStyle]="true"
                                        [control]="item.controls.type"
                                        placeholder="Allowance Type"
                                        [ngClass]="{
                                            invalid: item.controls.type.touched && !item.controls.type.valid
                                        }"
                                        (onChange)="onChangeAllowancesTypeOptions(i)"
                                    >
                                    </app-auto-dropdown>
                                    <div
                                        class="error-message"
                                        *ngIf="item.controls.type.touched && !item.controls.type.valid"
                                    >
                                        Allowance Type is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-block-col-separator"></div>
                            <div class="form-block-col">
                                <div class="input-block" *ngIf="item.get('type').value !== 'funeral cover'">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Allowance Amount</mat-label>
                                        <input
                                            type="text"
                                            currencyMask
                                            [options]="{
                                                prefix: '',
                                                thousands: ',',
                                                align: 'left'
                                            }"
                                            autocomplete="off"
                                            formControlName="amount"
                                            matInput
                                        />
                                        <mat-error *ngIf="!item.controls.amount.valid && item.controls.amount.touched">
                                            Amount is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-5 delete-ico-col">
                                <span (click)="onDeleteAllowance(i)">
                                    <svg
                                        width="11px"
                                        viewBox="0 0 8 10"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                    >
                                        <g
                                            id="Recruit-Setup"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <g
                                                id="Job-View---Default"
                                                transform="translate(-498.000000, -237.000000)"
                                                fill="#212b35"
                                                fill-rule="nonzero"
                                            >
                                                <g id="icons8-delete" transform="translate(498.000000, 237.000000)">
                                                    <path
                                                        d="M3.26988655,0 C2.67329527,0 2.18181818,0.519831538 2.18181818,1.15084154 L2.18181818,1.53846154 L0,1.53846154 L0,2.30769231 L0.727272727,2.30769231 L0.727272727,8.84615385 C0.727272727,9.48016846 1.21874982,10 1.81818182,10 L6.18181818,10 C6.78125018,10 7.27272727,9.48016846 7.27272727,8.84615385 L7.27272727,2.30769231 L8,2.30769231 L8,1.53846154 L5.81818182,1.53846154 L5.81818182,1.15084154 C5.81818182,0.519831538 5.32670473,0 4.73011345,0 L3.26988655,0 Z M3.26988655,0.769230769 L4.73011345,0.769230769 C4.93465891,0.769230769 5.09090909,0.934495385 5.09090909,1.15084154 L5.09090909,2.30769231 L6.54545455,2.30769231 L6.54545455,8.84615385 C6.54545455,9.0625 6.38636364,9.23076923 6.18181818,9.23076923 L1.81818182,9.23076923 C1.61363636,9.23076923 1.45454545,9.0625 1.45454545,8.84615385 L1.45454545,2.30769231 L2.90909091,2.30769231 L2.90909091,1.15084154 C2.90909091,0.934495385 3.06534109,0.769230769 3.26988655,0.769230769 Z M2.18181818,3.07692308 L2.18181818,8.46153846 L2.90909091,8.46153846 L2.90909091,3.07692308 L2.18181818,3.07692308 Z M3.63636364,3.07692308 L3.63636364,8.46153846 L4.36363636,8.46153846 L4.36363636,3.07692308 L3.63636364,3.07692308 Z M5.09090909,3.07692308 L5.09090909,8.46153846 L5.81818182,8.46153846 L5.81818182,3.07692308 L5.09090909,3.07692308 Z"
                                                        id="Shape"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </ng-container>
                    <div class="separator-line" *ngIf="form.get('bonuses')['controls'].length === 0"></div>
                    <button
                        class="add-section-button ng-star-inserted"
                        (click)="onAddBonusSection()"
                        *ngIf="
                            !form.get('bonuses')['controls'] ||
                            form.get('bonuses')['controls'].length < bonusTypeOptions?.length
                        "
                    >
                        <img src="/assets/images/plus-icon.svg" alt="" /><span>Add Bonus</span>
                    </button>
                    <button
                        class="add-section-button ng-star-inserted"
                        (click)="onAddAllowanceSection()"
                        *ngIf="
                            !form.get('allowances')['controls'] ||
                            form.get('allowances')['controls'].length < allowancesTypeOptions?.length
                        "
                    >
                        <img src="/assets/images/plus-icon.svg" alt="" /><span>Add Allowance</span>
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <div class="preview-holder">
                <div class="preview-block">
                    <div class="preview-title">
                        <strong>Personal Details</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item">
                            <div>Full Name</div>
                            <span>{{ form.value.full_name }}</span>
                        </div>
                        <div class="preview-item">
                            <div>ID Number</div>
                            <span>{{ form.value.identity_number }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Job Title</div>
                            <span>{{ form.value.designation }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Location</div>
                            <span>{{ previewData?.location_short || form.value.location }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Start Date</div>
                            <!-- <span>{{ form.value.start_date | amDateFormat: 'DD MMM YYYY' }}</span> -->
                            <span *ngIf="previewData.start_date">{{
                                previewData.start_date | amDateFormat: 'DD MMM YYYY'
                            }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Time Off</div>
                            <span>{{ form.value.time_off }}</span>
                        </div>
                    </div>
                </div>
                <div class="preview-block">
                    <div class="preview-title">
                        <strong>Reporting Structure</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item">
                            <div>Hiring Manager</div>
                            <span>{{ getUserName(form.value.manager) }}</span>
                        </div>
                        <div class="preview-item">
                            <div>Hiring Executive</div>
                            <span>{{ getUserName(form.value.hiring_executive) }}</span>
                        </div>
                        <div class="preview-item">
                            <div>HR Business Partner</div>
                            <span>{{ getUserName(form.value.hr_business_partner) }}</span>
                        </div>
                    </div>
                </div>
                <div
                    class="preview-block preview-compensation"
                    [ngClass]="{ last: form.value?.allowances.length === 0 }"
                >
                    <div class="preview-title">
                        <strong>Compensation</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper preview-allowances">
                        <div class="preview-item">
                            <div>Annual Salary</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ form.value.salary.salary | number }}
                            </span>
                        </div>
                        <div class="preview-item" *ngIf="form.value.salary?.commission?.amount">
                            <div>Variable Pay / Commission</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ form.value.salary.commission.amount | number }}
                            </span>
                        </div>
                        <div class="preview-item" *ngFor="let bonus of form.value.bonuses">
                            <div>Bonus</div>
                            <span>
                                {{ form.value.salary.currency }}
                                {{ bonus.amount | number }}
                                ({{ bonus.type | titlecase }})</span
                            >
                        </div>
                    </div>
                </div>
                <div class="preview-block preview-allowances" *ngIf="form.value?.allowances.length">
                    <div class="preview-title">
                        <strong>Allowances</strong>
                        <svg-icon
                            (click)="onHideContent($event)"
                            class="accordion-icon"
                            src="/assets/images/arrow-bottom.svg"
                            [svgStyle]="{ 'height.px': 12, fill: '#000000' }"
                        >
                        </svg-icon>
                    </div>
                    <div class="preview-wrapper">
                        <div class="preview-item" *ngFor="let item of form.value.allowances">
                            <ng-container *ngIf="item.type === 'funeral cover'">
                                <div>Funeral Cover</div>
                                <span>Yes</span>
                            </ng-container>
                            <ng-container *ngIf="item.type === 'mobile phone'">
                                <div>Mobile Phone</div>
                                <span>
                                    {{ form.value.salary.currency }}
                                    {{ item.amount | number }}</span
                                >
                            </ng-container>
                            <ng-container *ngIf="item.type === 'motor vehicle allowance'">
                                <div>Motor Vehicle Allowance</div>
                                <span>
                                    {{ form.value.salary.currency }}
                                    {{ item.amount | number }}</span
                                >
                            </ng-container>
                            <ng-container *ngIf="item.type === 'petrol'">
                                <div>Petrol</div>
                                <span>
                                    {{ form.value.salary.currency }}
                                    {{ item.amount | number }}</span
                                >
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="view === 'workflow'">
            <div class="workflow-view">
                <p>
                    Please select a workflow that you would like to send to this candidate. If you select “don’t
                    allocate a workflow”, the offer details will be saved to the candidate record but they will not
                    receive the workflow.
                </p>
                <div class="form-block">
                    <div class="input-block">
                        <app-auto-dropdown
                            [options]="manualWorkflowsOptions"
                            [control]="form.controls.workflow_id"
                            placeholder="Workflow"
                            [dropdownValueUpdated]="dropdownValueUpdated"
                        >
                        </app-auto-dropdown>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="footer">
        <ng-container *ngIf="view === 'default'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn-primary w140" (click)="onNext()">Compensation</button>
        </ng-container>
        <ng-container *ngIf="view === 'preview'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button *ngIf="form.value.workflow_id" class="btn btn btn-primary w80" (click)="onSendOffer('workflow')">
                Finish
            </button>
            <button
                *ngIf="!form.value.workflow_id"
                class="btn btn btn-primary w80"
                (click)="onMoveCandidateToOfferStage()"
            >
                Finish(NS)
            </button>
        </ng-container>
        <ng-container *ngIf="view === 'compensation'">
            <button class="btn btn-grey" (click)="onBack()">Back</button>
            <button class="btn btn-primary" (click)="onPreview()">
                Preview
            </button>
        </ng-container>
        <ng-container *ngIf="view === 'workflow'">
            <button class="btn btn-primary mla w72" (click)="onAddWorkflow()">Next</button>
        </ng-container>
    </div>
</div>
<app-loader *ngIf="contentLoading"></app-loader>
<div class="overlay" (click)="onHideOfferModal()"></div>
