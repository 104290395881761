import { Component, Input, OnInit } from '@angular/core';
import { SdkJob } from '@app/core/models';
import { CandidateService, JobService, OffersService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { WorkflowService } from '@app/core/services/workflow.service';
import { isEqual } from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-candidate-offer-block',
    templateUrl: './candidate-offer-block.component.html',
    styleUrls: ['./candidate-offer-block.component.scss']
})
export class CandidateOfferBlockComponent implements OnInit {
    @Input() job;
    @Input() candidate;
    contentLoading = false;
    offer;
    offerAssessment;
    acceptedOfferModal: {
        show: boolean;
        data: any;
        workflows: any[];
    } = {
        show: false,
        data: null,
        workflows: []
    };
    declineByCandidate = false;
    someoneSigned = false;
    candidateSigned = false;
    constructor(
        private candidateService: CandidateService,
        private offerService: OffersService,
        private workflowService: WorkflowService,
        private employeeService: EmployeesService,
        private toaster: ToastrService,
        private jobService: JobService
    ) {}

    async ngOnInit() {
        const job_details = await this.employeeService.getJobDetails(this.candidate.id);
        this.candidate.job_details = job_details;
        if (this.job['offer_signs'] && this.offer) {
            this.offer = this.job['offer_signs'][this.candidate.id];
            this.someoneSigned = this.offer.some((o) => o.signed);
        }
        this.offerAssessment = this.candidate.assignments.find((a) => {
            return a.stageId === 'hired';
        });

        // this.candidateSigned = assignment.offer_accepted ? assignment.offer_accepted : false;
        console.log(
            this.job,
            this.candidate,
            this.candidate.assignments,
            this.offer,
            this.offerAssessment,
            this.someoneSigned
        );
    }

    async onEditOffer() {
        const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
        if (
            job &&
            job.offer_signs &&
            job.offer_signs[this.candidate.id] &&
            job.offer_signs[this.candidate.id].some((o) => o.signed)
        ) {
            this.toaster.error('Contract has just signed');
            return;
        }
        const workflows = await this.workflowService.getManuallWorkflows().toPromise();
        this.acceptedOfferModal = {
            data: this.candidate,
            show: true,
            workflows
        };
    }

    async onRetractOffer() {
        const equal = await this.refreshOfferAssessment();
        if (!equal) {
            this.toaster.error('Could you please refresh page');
        } else {
            const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
            if (
                job &&
                job.offer_signs &&
                job.offer_signs[this.candidate.id] &&
                job.offer_signs[this.candidate.id].every((o) => o.signed)
            ) {
                this.toaster.error('Contract has just signed');
                return;
            }
            this.contentLoading = true;
            await this.offerService.retractOffer(this.job.id, this.candidate.id, this.offerAssessment);
            this.contentLoading = false;
            this.offerAssessment.retracted = true;
            const data = {
                stageClass: 'offer-retracted'
            };
            await this.candidateService.updateJobCandidate(this.candidate.id, this.job.id, data);
            this.candidateService.updateCandidate(this.candidate.id, data);

            const auditRecord = {
                type: 'offer-retracted',
                job_id: this.job.id,
                option: this.offerAssessment.option || '',
                created_at: new Date().getTime()
            };
            this.candidateService
                .addToAudit(this.job.id, this.candidate.id, auditRecord)
                .catch((error) => console.error(error));
        }
    }

    async onCandidateDeclineOffer() {
        const equal = await this.refreshOfferAssessment();
        if (!equal) {
            this.toaster.error('Could you please refresh page');
        } else {
            const job: SdkJob = await this.jobService.getJob(this.job.id).toPromise();
            if (
                job &&
                job.offer_signs &&
                job.offer_signs[this.candidate.id] &&
                job.offer_signs[this.candidate.id].some((o) => o.signed)
            ) {
                this.toaster.error('Contract has just signed');
                return;
            }
            this.contentLoading = true;
            await this.offerService.declineOffer(this.candidate.id, this.offerAssessment.id);
            this.contentLoading = false;
            this.offerAssessment.offer_accepted = false;
            this.offerAssessment.completed = true;
            const data = {
                stageClass: 'offer_declined'
            };
            await this.candidateService.updateJobCandidate(this.candidate.id, this.job.id, data);
            this.candidateService.updateCandidate(this.candidate.id, data);
        }
    }

    onSendOffer() {}

    onResendOffer() {
        this.contentLoading = true;
        this.candidateService.resendOfferNotification(this.job.id, this.candidate.id).subscribe((response) => {
            console.log('Success', response);
            this.contentLoading = false;
        });
    }

    async onSentOffer(event) {
        console.log('onSentOffer', event);
        this.acceptedOfferModal.show = false;
        delete this.offerAssessment.retracted;
        delete this.offerAssessment.offer_accepted;
        const data = {
            stageClass: 'grey',
            complianceRateClass: 'red',
            order: 3
        };
        await this.candidateService.updateJobCandidate(this.candidate.id, this.job.id, data);
        this.candidateService.updateCandidate(this.candidate.id, data);
    }

    refreshOfferAssessment() {
        return new Promise(async (resolve, reject) => {
            const assessment = await this.offerService.getOfferAssessment(this.candidate.id, this.offerAssessment.id);
            const equal = isEqual(assessment, this.offerAssessment);
            console.log(equal, assessment, this.offerAssessment);
            resolve(equal);
        });
    }
}
