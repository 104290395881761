import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@env/environment';
import { UtilitiesService } from './utilities.service';

@Injectable({
    providedIn: 'root'
})
export class IntegrationsService {
    apiURL: string = environment.apiUrl;

    constructor(private http: HttpClient, private utilities: UtilitiesService) {}

    getInegrations() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/integrations`);
    }

    updateInegrations(data) {
        return this.http.put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/integrations`, data);
    }
}
