import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'accountType'
})
export class AccountTypePipe implements PipeTransform {
    transform(value: any): any {
        if (value && value.role) {
            switch (value.role) {
                case 'superadmin':
                case 'account_owner':
                    return 'Account Owner';
                case 'recruiter':
                    return 'Recruiter';
                case 'hiring_manager':
                    return 'Hiring Manager';
                case 'admin':
                    return 'Administrator';
                case 'hr_business_partner':
                    return 'HR Business Partner';
                case 'recruitment_agency':
                    return 'Recruitment Agency';
                case 'agency_user':
                    return 'Agency User';
                case 'user':
                    return 'User';
                case 'employee':
                    return 'Employee';
                default:
                    return '';
            }
        } else {
            return '';
        }
    }
}
