import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobService, UtilitiesService } from '@app/core/services';
import { AuthService } from '@app/modules/auth/auth.service';
import { RequestPositionComponent } from '@app/modules/shared/request-position/request-position.component';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as fromStore from './../../../store';
import * as fromSelectors from './../../../store/selectors';
import { SdkJob } from './../../models/job';
import { User } from './../../models/user';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
    @ViewChild(RequestPositionComponent) requestPosition: RequestPositionComponent;
    user: User;
    contentLoading = false;
    newHire = false;
    isCreatedNewRequest = false;
    hiringType = 'new';
    jobId;

    subscribtions: Subscription = new Subscription();

    constructor(
        private jobService: JobService,
        private store: Store<fromStore.State>,
        private router: Router,
        private activateRoute: ActivatedRoute,
        private authService: AuthService,
        private utilities: UtilitiesService
    ) {
        this.utilities.setTitle('');
        this.jobId = activateRoute.snapshot.params['tenantId'];

        this.store
            .pipe(
                select(fromSelectors.getUserEntity),
                filter((user) => !!user)
            )
            .subscribe(
                (user: User) => {
                    this.user = user;
                    this.contentLoading = false;
                    //validate user role
                    if (user.role !== 'employee') {
                        this.router.navigateByUrl(`/tenant/${this.utilities.getTenant()}/hire/statistics`);
                    }
                },
                (errorResponse) => {
                    console.error(errorResponse);
                }
            );
    }

    ngOnInit(): void {}

    async createDraft(event) {
        event.preventDefault();
        try {
            const requestPositionData = this.requestPosition.onSaveDraft();
            if (requestPositionData) {
                this.contentLoading = true;
                const jobData = this.prepareJobData(requestPositionData.hireFormValue);
                const job: SdkJob = await this.jobService.saveJob(jobData, this.user);
                console.log('Job created:', job.id);
                this.isCreatedNewRequest = true;
                this.contentLoading = false;
            }
        } catch (error) {
            console.error(error);
            console.log(this.user);
            alert('Error! -');
        }
    }

    prepareJobData(formValue: any) {
        const jobData = { ...formValue };
        if (jobData.start_date && typeof jobData.start_date !== 'string') {
            try {
                jobData.start_date = jobData.start_date.format('YYYY-MM-DD HH:mm Z');
            } catch (error) {
                console.error(error);
            }
        }
        return jobData;
    }

    navigateTo(): void {
        let url = `${environment.jobsPortalUrl}/positions?access_token=${this.authService.getAuthorizationToken()}`;
        if (url.indexOf('https') === -1) {
            url = 'https://' + url;
        }
        console.log('url', url);
        window.open(url, '_blank');
    }

    routeNavigate(): void {
        this.router.navigateByUrl(`/tenant/${this.utilities.getTenant()}/hire/statistics`);
    }

    onNewHire(): void {
        this.newHire = true;
    }

    ngOnDestroy(): void {
        this.subscribtions.unsubscribe();
    }
}
