import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { environment } from '@env/environment';
import { take, catchError } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EsigningService {
    apiURL: string = environment.apiUrl;
    baseURL = '';
    tenantId: string;
    constructor(private utilities: UtilitiesService, private firestore: AngularFirestore, private http: HttpClient) {
        this.tenantId = this.utilities.getTenant();
    }

    getTemplates() {
        return this.firestore
            .collection(`tenants/${this.tenantId}/esigning-templates`)
            .valueChanges({ idField: 'id' })
            .pipe(take(1));
    }

    getTemplate(id) {
        return this.firestore
            .collection(`tenants/${this.tenantId}/esigning-templates`)
            .doc(id)
            .valueChanges()
            .pipe(take(1));
    }

    addTemplate(template) {
        return this.firestore.collection(`tenants/${this.tenantId}/esigning-templates`).add(template);
    }

    updateTemplate(id, data) {
        return this.firestore
            .collection(`tenants/${this.utilities.getTenant()}/esigning-templates`)
            .doc(id)
            .update(data);
    }

    deleteEsigningTemplate(id) {
        return this.http.delete(
            `${this.apiURL}/employees/tenants/${this.utilities.getTenant()}/esigning-template/${id}`
        );
    }

    parseTemplate(formData: object) {
        return this.http.post(
            `${this.apiURL}/employees/tenants/${this.utilities.getTenant()}/esigning-template/parse`,
            formData
        );
    }

    createSignature(data) {
        return this.http
            .post(`${this.apiURL}/employees/tenants/${this.tenantId}/get-test-signature`, data)
            .pipe(catchError((error: any) => throwError(error)));
    }

    getEditUrlTemplate(id) {
        return this.http.get(
            `${this.apiURL}/employees/tenants/${this.utilities.getTenant()}/esigning-template?template_id=${id}`
        );
    }
}
