import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { JobService } from '@app/core/services/job.service';
import { environment } from '@env/environment';
import { uniq } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Candidate } from './../models/candidate';
import { SdkJob } from './../models/job';
import { TenantService } from './tenant.service';
import { UtilitiesService } from './utilities.service';
import * as Realm from 'realm-web';
const app = new Realm.App('testingd9-swxkf');
import ObjectID from 'bson-objectid';
import { getValidAccessToken } from '@app/realm';

@Injectable({
    providedIn: 'root'
})
export class CandidateService {
    apiURL: string = environment.apiUrl;
    tenantId = 'undefined';
    baseURL = '';
    private searchInputSubject = new BehaviorSubject<any>(null);
    private subject: Subject<any> = new Subject<any>();
    constructor(
        private http: HttpClient,
        private utilities: UtilitiesService,
        private db: AngularFirestore,
        private tenantService: TenantService,
        private jobService: JobService
    ) {
        this.tenantService.init();
        this.baseURL = `${this.apiURL}/tenants/${this.utilities.getTenant()}`;
    }

    getJobCandidate(jobId: string, candidateId: string): Promise<Candidate> {
        return new Promise(async (resolve, reject) => {
            if (!jobId) {
                const candidate: Candidate = await this.getDbCandidate(candidateId);
                return resolve(candidate);
            }
            const jobCandidate = (await this.getJobItemCandidate(candidateId, jobId).toPromise()) || {};
            const dbCandidate = await this.getDbCandidate(candidateId);
            const candidate = { ...jobCandidate, ...dbCandidate };
            return resolve(candidate);
        });
    }

    getJobItemCandidate(candidateId: string, jobId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/candidates`)
            .doc(candidateId)
            .valueChanges()
            .pipe(
                take(1),
                map((candidate: Candidate) => {
                    if (candidate) {
                        candidate.id = candidateId;
                        return candidate;
                    } else {
                        return {};
                    }
                })
            );
    }

    getDbCandidateFromAPI(candidateId: string): Observable<Candidate> {
        return this.http.get<Candidate>(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/${candidateId}`
        );
    }

    getDbCandidate$(candidateId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates`)
            .doc(candidateId)
            .valueChanges()
            .pipe(take(1));
    }

    getDbCandidate(candidateId: string): Promise<Candidate> {
        return new Promise(async (resolve, reject) => {
            try {
                this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates`)
                    .doc(candidateId)
                    .valueChanges()
                    .pipe(take(1))
                    .subscribe(
                        (candidate: Candidate) => {
                            if (candidate) {
                                candidate.id = candidateId;
                                return resolve(candidate);
                            } else {
                                return resolve(null);
                            }
                        },
                        (errorResponse) => reject(errorResponse)
                    );
            } catch (error) {
                return reject(error);
            }
        });
    }

    updateDbCandidate(candidateId: string, data: any) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates`)
            .doc(candidateId)
            .update(data);
    }

    updateJobCandidate(candidateId: string, jobId: string, data: any) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/candidates`)
            .doc(candidateId)
            .update(data);
    }

    deleteCandidate(candidateId: string, jobId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/candidates`)
            .doc(candidateId)
            .delete();
    }

    getCandidateJobs(candidateId: string) {
        // return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/${candidateId}/job_id`);
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates`)
            .doc(candidateId)
            .valueChanges()
            .pipe(
                take(1),
                map((c: Candidate) => c.job_id || [])
            );
    }

    addToAudit(jobId: string, candidateId: string, data: any) {
        console.log('💽 addToAudit', jobId, candidateId, data);
        return new Promise(async (resolve, reject) => {
            try {
                // 1. get candidate audit
                try {
                    this.db
                        .collection(
                            `tenants/${this.utilities.getTenant()}/candidates/${candidateId}/jobs_audit/${jobId}/items`
                        )
                        .add(data);
                } catch (error) {
                    console.log(error);
                }
                const audit$ = this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates_audit`)
                    .doc(candidateId)
                    .valueChanges()
                    .pipe(take(1));
                const audit = (await audit$.toPromise()) || {};
                if (!audit[jobId]) {
                    audit[jobId] = [];
                }
                audit[jobId].push(data);
                await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates_audit`)
                    .doc(candidateId)
                    .set(audit);
                return resolve(null);
            } catch (error) {
                return reject(error);
            }
        });
    }

    getDbCandidateInterviews(candidateId: string, jobId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates_interviews`)
            .doc(candidateId)
            .valueChanges()
            .pipe(
                take(1),
                map((interviews: any) => {
                    interviews = interviews && interviews.items ? interviews.items : [];
                    return interviews.filter((i) => i.jobId === jobId);
                })
            );
    }

    updateHasRead(candidateId: string, jobId: string) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items/${jobId}/candidates`)
            .doc(candidateId)
            .update({ hasRead: true });
    }

    updateStage(candidate: any, job: SdkJob, stageId: string) {
        return new Promise(async (resolve, reject) => {
            // console.log('update stage', candidate);
            try {
                const stage = (job.stages || []).find((s) => s.id === stageId);
                if (!stage) {
                    return reject('Stage not found');
                }
                if (!stage['candidatesAdded']) {
                    stage['candidatesAdded'] = [];
                }
                if (stage['candidatesAdded'].indexOf(candidate.id) === -1) {
                    stage['candidatesAdded'].push(candidate.id);
                }
                this.jobService
                    .updateStage(job.id, stageId, { candidatesAdded: stage['candidatesAdded'] })
                    .toPromise()
                    .catch((error) => console.error());

                let stagesDataResponse = {};
                // 1. Update candidate record stage and read fields
                this.getDbCandidate(candidate.id).then((dbCandidate: Candidate) => {
                    if (!dbCandidate.read) {
                        dbCandidate.read = [];
                    }
                    if (!dbCandidate.stage) {
                        dbCandidate.stage = {};
                    }
                    dbCandidate.read = uniq([...dbCandidate.read, job.id]);
                    dbCandidate.stage[job.id] = stageId;
                    const data = {
                        read: dbCandidate.read,
                        stage: dbCandidate.stage
                    };
                    this.updateDbCandidate(candidate.id, data).catch((error) => console.error(error));
                });

                // 2. Check if job stage has assignments
                if (stage.assessment && stage.assessment.length && stage.id !== 'hired') {
                    console.log('update stage', candidate);
                    const response: any = await this.jobService
                        .updateCandidateStage(job.id, candidate.id, { stageId })
                        .toPromise();
                    const { stageClass, complianceRateClass, order } = response;

                    const data = {
                        stage: stageId,
                        stageClass,
                        complianceRateClass,
                        order
                    };
                    stagesDataResponse = data;
                    await this.updateJobCandidate(candidate.id, job.id, data);
                } else {
                    const response: any = await this.jobService.getCandidateStageClass(job, candidate.id, stageId);
                    const { stageClass, complianceRateClass, order } = response;
                    const data = {
                        stage: stageId,
                        stageClass,
                        complianceRateClass,
                        order
                    };
                    stagesDataResponse = data;
                    await this.updateJobCandidate(candidate.id, job.id, data);
                }

                this.jobService.updateJobStagesStats(job).catch((error) => console.error(error));
                // udpade job stages stats
                return resolve(stagesDataResponse);
            } catch (error) {
                return reject(error);
            }
        });
    }

    candidateUpdated() {
        return this.subject.asObservable();
    }

    updateCandidate(candidateId: string, data: any) {
        return this.subject.next({ candidateId, data });
    }

    updateCandidateData(candidateId: string, data: object) {
        return this.http.put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidate/${candidateId}/data`, {
            data
        });
    }

    updateFeedbackPositionRatingCategories(jobId: string, data: any[]) {
        return this.http.put(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/position-rating-categories`,
            {
                data
            }
        );
    }

    updateFeedback(jobId: string, candidateId: string, data: any) {
        return this.http.put(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/feedback`,
            { data }
        );
    }

    addCandidateToJob(jobId: string, candidateId: string) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/approve`,
            {}
        );
    }

    extendAssessmentDeadline(jobId: string, candidateId: string, data: any) {
        return this.http.put(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/assessment-deadline`,
            { data }
        );
    }

    extendTaskAssessmentDeadline(candidateId: string, data: any) {
        return this.http.put(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/${candidateId}/assessment-deadline`,
            { data }
        );
    }

    offerAccepted(jobId: string, candidateId: string, data: any) {
        return this.http.put(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/assessment-offer`,
            { data }
        );
    }

    saveFeed(jobId: string, candidateId: string, data: any) {
        return this.http.put(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/feed`,
            {
                data
            }
        );
    }

    isLocationMatch(jobId: string, candidateId: string) {
        return this.http.get(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/location-match`
        );
    }

    isUniqueEmail(email) {
        return this.http.get(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/check-email?email=${email}`
        );
    }

    checkEmailUnique(email: string) {
        return new Promise(async (resolve, reject) => {
            if (!email) {
                return reject('Email is not provided');
            }

            const candidates = await this.db
                .collection(`tenants/${this.utilities.getTenant()}/candidates`, (ref) =>
                    ref.where('email', '==', email.toLowerCase()).limit(1)
                )
                .valueChanges({ idField: 'id' })
                .pipe(take(1))
                .toPromise();
            const users = await this.db
                .collection(`users`, (ref) => ref.where('email', '==', email.toLowerCase()).limit(1))
                .valueChanges({ idField: 'id' })
                .pipe(take(1))
                .toPromise();

            if ((candidates && candidates.length) || (users && users.length)) {
                return resolve(false);
            } else {
                return resolve(true);
            }
        });
    }

    getResumeLink(resumeFile: string) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/resume-link?file=${resumeFile}`);
    }

    getCandidateNoJob(candidateId: any) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/${candidateId}`);
    }

    getCandidateDetails(candidateId: string) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/${candidateId}/data`);
    }

    getProfileImageLink(resumeFile: string) {
        return this.http.get(`${this.apiURL}/link?url=${resumeFile}`);
    }

    getAllAudit() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/allAudit`);
    }

    getDocuments(candidateId: string) {
        return this.http.get(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/${candidateId}/documents`
        );
    }

    bulkDelete(ids: string[]) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/bulk-delete`, {
            items: ids
        });
    }

    getAppliedJobs(id) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/${id}/appliedJobs`);
    }

    downloadDoc(link) {
        return this.http.get(link);
    }

    downloadFile(link) {
        return this.http.get(link);
    }

    getCandidateInterviews(candidateId, jobId) {
        return this.http.get(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/interviews`
        );
    }

    updateCandidateInterviewOverallRating(candidateId, jobId, interviewId, rating) {
        return this.http.put(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/interviews/${interviewId}/rating`,
            rating
        );
    }

    updateCandidateInterviewCriteriaVotes(candidateId, jobId, interviewId, criteria_votes) {
        return this.http.put(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/interviews/${interviewId}/criteria-votes`,
            criteria_votes
        );
    }

    getScorecard(scorecardId) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/scorecards/${scorecardId}`);
    }

    updateOwnerById(candidateId, jobId, newId) {
        return this.http.put(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/owner`,
            { data: newId }
        );
    }

    setSearchValueForCandidates(val) {
        this.searchInputSubject.next(val);
    }

    getSearchValueForCandidates(): Observable<any> {
        return this.searchInputSubject.asObservable();
    }

    resetQuestionnaire(jobId: string, candidateId: string, assessmentId: string) {
        return this.http.get(
            `${
                this.apiURL
            }/applications/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/assessments/${assessmentId}/reset`
        );
    }

    resendEmail(jobId: string, candidateId: string, data) {
        return this.http.put(
            `${
                this.apiURL
            }/applications/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/resend-email`,
            data
        );
    }

    resendOffer(jobId: string, candidateId: string) {
        return this.http.get(
            `${
                this.apiURL
            }/applications/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/resend-offer`
        );
    }

    updateJobCandidateStatus(candidateId: string, jobId: string) {
        console.log('💠  updateJobCandidateStatus', candidateId, jobId);
        return new Promise(async (resolve, reject) => {
            try {
                const job = await this.jobService.getFullJobById(jobId);
                const response: any = await this.jobService.getCandidateStageClass(job, candidateId);
                const { stageClass, complianceRateClass, order } = response;
                const data = {
                    stageClass,
                    complianceRateClass,
                    order
                };
                await this.updateJobCandidate(candidateId, job.id, data);
                this.updateCandidate(candidateId, data);
                return resolve(null);
            } catch (error) {
                return reject(error);
            }
        });
    }

    orderCriminalCheck(jobId: string, candidateId: string, postData: { data: any; totalPrice: number }) {
        return this.http.post(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/candidates/${candidateId}/criminal-check`,
            postData
        );
    }

    processCriminalCheckAss(candidateId, ass) {
        ass.added_at_formatted = ass.added_at ? moment.unix(ass.added_at).format('DD MMMM YYYY') : null;
        ass.completed_at_formatted = ass.completed_at ? moment.unix(ass.completed_at).format('DD MMMM YYYY') : null;
        const expired_at =
            ass.expired_at ||
            moment
                .unix(ass.added_at)
                .add(ass.deadline || 5, 'days')
                .unix();

        if (expired_at < moment().unix()) {
            if (!ass.completed && !ass.expired) {
                ass.expired = true;
                ass.expired_at = expired_at;
                this.updateCriminalCheckAss(candidateId, ass.id, {
                    expired: ass.expired,
                    expired_at: ass.expired_at
                })
                    .then(() => console.log('Criminal check assignment set as expired'))
                    .catch((error) => console.error(error));
            }
        }

        if (!ass.completed && ass.expired) {
            ass.expired_at_formatted = ass.expired_at
                ? moment.unix(ass.expired_at).format('DD MMMM YYYY')
                : moment
                      .unix(ass.added_at)
                      .add(ass.deadline, 'days')
                      .format('DD MMMM YYYY');
        }

        return ass;
    }

    getCriminalCheckAss(candidateId) {
        return new Promise(async (resolve, reject) => {
            const ass: any = await this.db
                .collection(`tenants/${this.utilities.getTenant()}/candidates/${candidateId}/assignments`, (ref) =>
                    ref.where('type', '==', 'criminal-check').limit(1)
                )
                .valueChanges({ idField: 'id' })
                .pipe(
                    take(1),
                    map((values) => (values && values.length ? values[0] : null))
                )
                .toPromise();

            if (ass) {
                ass.added_at_formatted = ass.added_at ? moment.unix(ass.added_at).format('DD MMMM YYYY') : null;
                ass.completed_at_formatted = ass.completed_at
                    ? moment.unix(ass.completed_at).format('DD MMMM YYYY')
                    : null;
                const expired_at =
                    ass.expired_at ||
                    moment
                        .unix(ass.added_at)
                        .add(ass.deadline || 5, 'days')
                        .unix();

                if (expired_at < moment().unix()) {
                    if (!ass.expired) {
                        ass.expired = true;
                        ass.expired_at = expired_at;
                        this.updateCriminalCheckAss(candidateId, ass.id, {
                            expired: ass.expired,
                            expired_at: ass.expired_at
                        })
                            .then(() => console.log('Criminal check assignment set as expired'))
                            .catch((error) => console.error(error));
                    }
                }

                if (ass.expired) {
                    ass.expired_at_formatted = ass.expired_at
                        ? moment.unix(ass.expired_at).format('DD MMMM YYYY')
                        : moment
                              .unix(ass.added_at)
                              .add(ass.deadline, 'days')
                              .format('DD MMMM YYYY');
                }
                return resolve(ass);
            } else {
                return resolve(null);
            }
        });
    }

    updateCriminalCheckAss(candidateId, assignmentId, data) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates/${candidateId}/assignments`)
            .doc(assignmentId)
            .update(data);
    }

    resendOfferNotification(jobId, candidateId) {
        return this.http.get(
            `${
                this.apiURL
            }/tenants/${this.utilities.getTenant()}/jobs/${jobId}/${candidateId}/resend-offer-notification`
        );
    }
    async getAtlasCandidate(candidateId) {
        const app = await getValidAccessToken();
        const db = app.currentUser.mongoClient('mongodb-atlas').db('D9BO1JR3D');
        return db.collection('users').findOne({ _id: new ObjectID(candidateId) });
    }
    // getAlgoliaCandidates(page, hitsPerPage) {
    //     return this.http.get(
    //         `${
    //             this.apiURL
    //         }/tenants/${this.utilities.getTenant()}/algolia-candidates?page=${page}&hitsPerPage=${hitsPerPage}`
    //     );
    // }

    getAlgoliaCandidates(page, hitsPerPage, collection = 'candidates', field = null, direction = null) {
        if (field && direction) {
            return this.http.get(
                `${
                    this.apiURL
                }/tenants/${this.utilities.getTenant()}/algolia-candidates?page=${page}&hitsPerPage=${hitsPerPage}&collection=${collection}&field=${field}&direction=${direction}`
            );
        } else {
            return this.http.get(
                `${
                    this.apiURL
                }/tenants/${this.utilities.getTenant()}/algolia-candidates?page=${page}&hitsPerPage=${hitsPerPage}&collection=${collection}`
            );
        }
    }

    getHellosignFile(candidateId) {
        return this.http.get(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/${candidateId}/get-hellosign-file`
        );
    }

    updateCandidatesDataCollection(candidateId) {
        return this.http.get(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/candidates/${candidateId}/update-candidate-data`
        );
    }
}
