import { Component, OnInit, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
    data: any;
    options: any;
    @Input() chartData;
    @Input() chartOptions;
    @Input() id;

    constructor() {}

    ngOnInit(): void {
        this.data = this.chartData;
        this.options = this.chartOptions;
        // console.log(this.data);
        // console.log(this.options);
        // if(this.id === 'funnel') {
        //     this.options.animation = {
        //         duration: 1,
        //         onComplete: (chartm) => {
        //             let ctx = document.getElementById(this.id).getElementsByTagName('canvas')[0].getContext("2d");
        //             console.log(document.getElementById(this.id))

        //             var controller = chartm.chart.controller;
        //             console.log(controller)
        //             var chart = controller.chart;
        //             var xAxis = controller.scales['x-axis-0'];

        //             var numTicks = xAxis.ticks.length;
        //             var xOffsetStart = xAxis.width / (numTicks - 1);
        //             var halfBarWidth = (xAxis.width / ((numTicks - 1) * 2));
        //             console.log('xAxis', xAxis);
        //             console.log('numTicks', numTicks);
        //             console.log('xOffsetStart', xOffsetStart, xAxis.width, halfBarWidth);
        //             xAxis.ticks.forEach((value, index) => {
        //                 var xOffset = (xOffsetStart * index) + halfBarWidth;
        //                 var yOffset = chart.height - 8;
        //                 console.log(value, xOffset, yOffset)
        //                 ctx.fillText(value, xOffset, yOffset);
        //             });
        //             console.log(xAxis.ticks);

        //         }
        //     }
        // }
    }
}
