import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { JobCategory } from '@app/core/models/cateogory';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-ac-chip-material',
    templateUrl: './ac-chip-material.component.html',
    styleUrls: ['./ac-chip-material.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AcChipMaterialComponent),
            multi: true
        }
    ]
})
export class AcChipMaterialComponent implements OnInit {
    _value: JobCategory[] = [];
    results: JobCategory[];
    @Input() suggestions: JobCategory[];
    @Input() title: string;
    @Input() key: string;
    @Input() set setSuggestions(v) {
        if (v) {
            this.input.nativeElement.value = '';
            this.input.nativeElement.blur();
            this.ctrl.setValue(null);
            this.onValueChange.next(this.suggestionsCopy);
        }
    }
    @Input() disabled;
    @Input() background;
    suggestionsCopy = [];
    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    ctrl = new FormControl();
    filteredProds: Observable<any[]>;
    styles = {};
    @Output() public onValueChange = new EventEmitter<any[]>();
    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    constructor() {}

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            const item = this.suggestions.find((s) => {
                return s.id === value;
            });
            this.suggestionsCopy = [item];
        }
        if (input) {
            input.value = '';
        }

        this.ctrl.setValue(null);
        this.onValueChange.next(this.suggestionsCopy);
    }

    remove(suggestion: any): void {
        this.suggestionsCopy = [];
        this.onValueChange.next(this.suggestionsCopy);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.suggestionsCopy = [event.option.value];
        this.input.nativeElement.value = '';
        this.input.nativeElement.blur();
        this.ctrl.setValue(null);
        this.onValueChange.next(this.suggestionsCopy);
    }

    private _filter(value): JobCategory[] {
        if (!value) {
            return this.suggestions;
        }
        const filterValue = value;
        return this.suggestions.filter((suggestion) => {
            // console.log(suggestion);
            return suggestion && suggestion.name.toLowerCase().indexOf(filterValue) === 0;
        });
    }

    ngOnInit(): void {
        if (this.background) {
            this.styles = {
                backgroundColor: this.background,
                color: '#fff'
            };
        }
        /* tslint:disable */
        this.filteredProds = this.ctrl.valueChanges.pipe(
            startWith(null),
            map((cat: JobCategory | null) => (cat ? this._filter(cat) : this.suggestions.slice()))
        );
        /* tslint:enable */
    }

    writeValue(value: any) {
        this.suggestionsCopy = [value];
        if (value !== undefined) {
            // console.log('VALUE PASSED TO COMPONENT:', value);
            const values = [];
            const item = this.suggestions.find((s) => {
                return s.id === value;
            });
            if (item) {
                values.push(item);
            }
            this.suggestionsCopy = values;
        }
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    triggerChange(event) {
        this.changeValue(this._value);
    }

    changeValue(val) {
        this.onValueChange.next(val);
        const modelValues = val.map((v) => v.id);
        this.propagateChange(modelValues);
    }
}
