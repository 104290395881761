import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { TenantService } from '@app/core/services/tenant.service';
import { currencyOptions } from '@app/core/constants/options.constants';
import { SelectItem } from 'primeng';
import { FormHelperService } from '@app/core/services/form-helper.service';
import * as moment from 'moment';
import { EmployeesService } from '@app/core/services/employees.service';
import { CandidateService } from '@app/core/services';
import { Candidate, Job, User } from '@app/core/models';
import { select, Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import * as fromSelectors from './../../../../../../store/selectors';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-offer-letter',
    templateUrl: './offer-letter.component.html',
    styleUrls: ['./offer-letter.component.scss']
})
export class OfferLetterComponent implements OnInit {
    @Input() candidate: Candidate;
    @Input() job: Job;
    @Input() workflows: any[];
    @Input() hired_workflow: string;
    @Output() close = new EventEmitter<boolean>();
    @Output() send = new EventEmitter<any>();
    @Output() move = new EventEmitter();
    contentLoading = false;
    form: FormGroup;
    jobLocationOptions: any[] = [];
    contactsOptions: SelectItem[] = [];
    usersOptions: SelectItem[] = [];
    timeOffOptions: any[] = [
        { value: 17, label: '17' },
        { value: 18, label: '18' },
        { value: 19, label: '19' },
        { value: 20, label: '20' },
        { value: 21, label: '21' },
        { value: 22, label: '22' },
        { value: 23, label: '23' },
        { value: 24, label: '24' },
        { value: 25, label: '25' },
        { value: 26, label: '26' },
        { value: 27, label: '27' },
        { value: 28, label: '28' },
        { value: 29, label: '29' },
        { value: 30, label: '30' }
    ];
    payScheduleOptions = [
        { value: 'hourly', label: 'Hourly' },
        { value: 'daily', label: 'Daily' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'yearly', label: 'Yearly' }
    ];
    comissionTypeOptions = [
        { value: 'fixed', label: 'Fixed' },
        { value: 'percentage', label: 'Percentage' }
    ];
    bonusTypeOptions = [
        { label: '13th Cheque', value: '13th cheque', selected: false },
        { label: 'Performance Bonus', value: 'performance bonus', selected: false }
    ];
    allowancesTypeOptions = [
        { label: 'Mobile Phone', value: 'mobile phone', selected: false },
        { label: 'Petrol', value: 'petrol', selected: false },
        { label: 'Motor Vehicle Allowance', value: 'motor vehicle allowance', selected: false },
        { label: 'Funeral Cover', value: 'funeral cover', selected: false }
    ];
    currencyOptions: SelectItem[] = currencyOptions;
    view = 'workflow';
    usersSubscription: Subscription;
    users: User[];
    dropdownValueUpdated = false;
    manualWorkflowsOptions = [];
    previewData = {
        start_date: '',
        location_short: ''
    };
    constructor(
        private employeeService: EmployeesService,
        private candidateService: CandidateService,
        private formHelper: FormHelperService,
        private fb: FormBuilder,
        private tenantService: TenantService,
        private cdr: ChangeDetectorRef,
        private store: Store<fromStore.State>,
        private toaster: ToastrService
    ) {}

    async ngOnInit() {
        this.workflows.forEach((w: any) => {
            this.manualWorkflowsOptions.push({
                label: w.title,
                value: w.id
            });
        });
        this.tenantService.locations().subscribe((response) => {
            if (response) {
                response = response.filter((l) => l.name !== 'Any of the Dimension Data Offices');
                response.forEach((l) => {
                    this.jobLocationOptions.push({
                        label: `${l.name} - ${l.location_short}`,
                        value: l.location,
                        short_label: l.location_short
                    });
                });
                if (this.job.location && this.job.location[0]) {
                    this.form.get('location').patchValue(this.job.location[0]);
                }
                this.dropdownValueUpdated = true;
                setTimeout(() => {
                    this.dropdownValueUpdated = false;
                }, 100);
                this.cdr.markForCheck();
            }
        });

        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.users = [...users];
            this.users.sort((a: any, b: any) => {
                if (a.first_name && b.first_name) {
                    const labelA = a.first_name.toUpperCase();
                    const labelB = b.first_name.toUpperCase();
                    return labelA.localeCompare(labelB);
                }
            });
            this.contactsOptions = this.users
                .filter((u) => u.role === 'employee')
                .map((u) => {
                    return {
                        label: `${u.first_name} ${u.last_name}`,
                        value: u.id
                    };
                });
            this.usersOptions = this.users.map((u) => {
                return {
                    label: `${u.first_name} ${u.last_name}`,
                    value: u.id
                };
            });
            setTimeout(() => {
                if (
                    this.candidate.job_details &&
                    this.candidate.job_details.reporting &&
                    this.candidate.job_details.reporting.find((u) => u.relationship === 'manager')
                ) {
                    const manager = this.candidate.job_details.reporting.find((u) => u.relationship === 'manager');
                    this.form.get('manager').patchValue(manager.contact);
                } else if (this.job.hiring_managers && this.job.hiring_managers[0]) {
                    let user;
                    for (let id of this.job.hiring_managers) {
                        user = this.usersOptions.find((us) => us.value === id);
                        if (user) {
                            this.form.get('manager').patchValue(user.value);
                            break;
                        }
                    }
                }
                if (
                    this.candidate.job_details &&
                    this.candidate.job_details.reporting &&
                    this.candidate.job_details.reporting.find((u) => u.relationship === 'hr_business_partner')
                ) {
                    const hr_b_partner = this.candidate.job_details.reporting.find(
                        (u) => u.relationship === 'hr_business_partner'
                    );
                    this.form.get('hr_business_partner').patchValue(hr_b_partner.contact);
                } else if (this.job.hr_business_partners && this.job.hr_business_partners[0]) {
                    let user;
                    for (let id of this.job.hr_business_partners) {
                        user = this.usersOptions.find((us) => us.value === id);
                        if (user) {
                            this.form.get('hr_business_partner').patchValue(user.value);
                            break;
                        }
                    }
                }
                if (this.candidate.hiring_executive) {
                    this.form.get('hiring_executive').patchValue(this.candidate.hiring_executive);
                }
                this.dropdownValueUpdated = true;
                setTimeout(() => {
                    this.dropdownValueUpdated = false;
                }, 100);
            }, 0);
        });

        this.form = this.fb.group({
            full_name: [
                `${this.candidate.first_name} ${this.candidate.last_name}`,
                [Validators.required, Validators.pattern('\\b\\w+\\b(?:.*?\\b\\w+\\b){1}')]
            ],
            identity_number: ['', Validators.required],
            designation: [this.job.title || '', Validators.required],
            start_date: ['', Validators.required],
            location: ['', Validators.required],
            time_off: ['', Validators.required],
            manager: ['', Validators.required],
            hiring_executive: ['', Validators.required],
            hr_business_partner: ['', Validators.required],
            payout_frequency: [],
            bonuses: this.fb.array([]),
            allowances: this.fb.array([]),
            salary: this.fb.group({
                end_date: [moment().format('DD-MM-YYYY')],
                currency: ['ZAR', Validators.required],
                salary: [null, Validators.required],
                variable_pay: [false]
            }),
            workflow_id: ['']
        });
        this.form.get('bonuses').valueChanges.subscribe((changes) => {
            this.bonusTypeOptions.forEach((o) => (o.selected = false));
            changes.forEach((c) => {
                const option = this.bonusTypeOptions.find((a) => a.value === c.type);
                if (option) {
                    option.selected = true;
                }
            });
        });
        this.form.get('allowances').valueChanges.subscribe((changes) => {
            this.allowancesTypeOptions.forEach((o) => (o.selected = false));
            changes.forEach((c) => {
                const option = this.allowancesTypeOptions.find((a) => a.value === c.type);
                if (option) {
                    option.selected = true;
                }
            });
        });
        if (this.candidate.personal && this.candidate.personal.identity_number) {
            this.form.get('identity_number').patchValue(this.candidate.personal.identity_number);
        } else {
            const criminalAss: any = await this.candidateService.getCriminalCheckAss(this.candidate.id);
            if (criminalAss && criminalAss.data && criminalAss.data.id) {
                this.form.get('identity_number').patchValue(criminalAss.data.id);
            }
        }
        if (this.candidate.time_off && this.candidate.time_off[0]) {
            this.form.get('time_off').patchValue(this.candidate.time_off[0].amount);
        }
        if (this.candidate.start_date) {
            this.form.get('start_date').patchValue(moment(this.candidate.start_date, 'DD-MM-YYYY').format());
        }
        if (this.candidate.salary && this.candidate.salary[0]) {
            const form = this.form.get('salary') as FormGroup;
            form.patchValue({
                end_date: this.candidate.salary[0].end_date,
                currency: this.candidate.salary[0].currency,
                salary: this.candidate.salary[0].salary,
                variable_pay: this.candidate.salary[0].variable_pay || false
            });

            if (this.candidate.salary[0].variable_pay && this.candidate.salary[0].commission) {
                const commision = this.candidate.salary[0].commission;
                let type;
                if (commision.amount) {
                    type = 'fixed';
                } else if (commision.payout_frequency) {
                    type = 'percentage';
                }
                form.addControl(
                    'commission',
                    this.fb.group({
                        type: [type, Validators.required],
                        amount: [commision.amount || null, Validators.required],
                        payout_frequency: [commision.payout_frequency || '']
                    })
                );
            }
        }
        if (this.candidate.allowances && this.candidate.allowances.length) {
            this.candidate.allowances.forEach((item) => {
                (this.form.get('allowances') as FormArray).push(
                    this.fb.group({
                        amount: [item.amount, item.type !== 'funeral cover' ? Validators.required : ''],
                        type: [item.type, Validators.required]
                    })
                );
            });
        }
        if (this.candidate.bonuses && this.candidate.bonuses.length) {
            this.candidate.bonuses.forEach((item) => {
                (this.form.get('bonuses') as FormArray).push(
                    this.fb.group({
                        amount: [item.amount, Validators.required],
                        type: [item.type, Validators.required]
                    })
                );
            });
        }
        if (this.hired_workflow) {
            this.form.get('workflow_id').patchValue(this.hired_workflow);
            this.dropdownValueUpdated = true;
            setTimeout(() => {
                this.dropdownValueUpdated = false;
            }, 100);
        }
    }

    onBack() {
        if (this.view === 'compensation') {
            this.view = 'default';
        } else if (this.view === 'preview') {
            this.view = 'compensation';
        } else if (this.view === 'default') {
            this.view = 'workflow';
        }
    }

    onPreview() {
        console.log('onPreview', this.form.value);
        if (!this.form.valid) {
            this.formHelper.markFormGroupTouched(this.form);
            this.cdr.detectChanges();
            return;
        }
        this.previewData.start_date = this.form.value.start_date;
        const location = this.jobLocationOptions.find((l) => l.value === this.form.value.location);
        if (location) {
            this.previewData.location_short = location.short_label;
        }
        this.view = 'preview';
    }

    onNext() {
        console.log('onNext', this.form.value);
        const fields = [
            'full_name',
            'identity_number',
            'designation',
            'start_date',
            'location',
            'time_off',
            'manager',
            'hiring_executive',
            'hr_business_partner'
        ];

        for (let field of fields) {
            if (!this.form.get(field).valid) {
                const group = this.form.get(field) as FormGroup;
                group.markAsTouched();
                return;
            }
        }
        this.view = 'compensation';
    }

    onSendOffer() {
        console.log('onSendOffer', this.form.valid, this.form, this.form.value);
        if (!this.form.valid) {
            this.formHelper.markFormGroupTouched(this.form);
            return;
        }
        this.contentLoading = true;
        this.form.value.job_id = this.job.id;
        this.form.value.start_date = moment(this.form.value.start_date).format('DD-MM-YYYY');
        this.employeeService.offerAccepted(this.candidate.id, this.form.value).subscribe(
            (response: any) => {
                console.log('response', response);
                this.send.emit({
                    workflow_title: response.workflow_title,
                    role: response.role,
                    name: response.name,
                    workflow_id: this.form.value.workflow_id
                });
                this.contentLoading = false;
            },
            (err) => {
                console.log(err);
                this.toaster.error(err.error.error);
                this.contentLoading = false;
                this.cdr.detectChanges();
            }
        );
    }

    onCommissionCheckboxChange(event) {
        const form = this.form.get('salary') as FormGroup;
        if (event.checked) {
            form.addControl(
                'commission',
                this.fb.group({
                    type: ['', Validators.required],
                    amount: [null, Validators.required],
                    payout_frequency: ['']
                })
            );
        } else {
            form.removeControl('commission');
        }
    }

    onAddBonusSection() {
        const form = this.form.get('bonuses');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                amount: [null, Validators.required],
                type: ['', Validators.required]
            })
        );
    }

    onDeleteBonus(i) {
        const form = this.form.get('bonuses');
        (form as FormArray).removeAt(i);
    }

    onAddAllowanceSection() {
        const form = this.form.get('allowances');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                amount: [null, Validators.required],
                type: ['', Validators.required]
            })
        );
    }

    onDeleteAllowance(i) {
        const form = this.form.get('allowances');
        (form as FormArray).removeAt(i);
    }

    onMoveCandidateToOfferStage() {
        console.log(this.form.valid, this.form);
        if (!this.form.valid) {
            this.formHelper.markFormGroupTouched(this.form);
            this.cdr.detectChanges();
            return;
        }
        this.form.value.start_date = moment(this.form.value.start_date).format('DD-MM-YYYY');
        this.move.emit(this.form.value);
    }

    onHideOfferModal() {
        this.close.emit();
    }

    onChangeAllowancesTypeOptions(i) {
        const form = this.form.get('allowances')['controls'][i];
        if (form.value.type === 'funeral cover') {
            form.get('amount').clearValidators();
            form.get('amount').updateValueAndValidity();
            form.get('amount').patchValue(null);
        } else {
            form.get('amount').setValidators([Validators.required]);
            form.get('amount').updateValueAndValidity();
        }
    }

    getUserName(id) {
        const user = this.users.find((u) => u.id === id);
        if (user) {
            return user.full_name;
        } else {
            return id;
        }
    }

    onHideContent(event) {
        (event.target as HTMLInputElement).closest('.preview-block').children[1].classList.toggle('hide');
    }

    onAddWorkflow() {
        this.view = 'default';
    }
}
