import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services';
import { select, Store } from '@ngrx/store';
import { Intercom } from 'ng-intercom';
import { filter } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { AuthService } from './../../../modules/auth/auth.service';
import * as fromStore from './../../../store';
import * as fromSelectors from './../../../store/selectors';
import { User } from './../../models/user';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    contentLoading = false;

    constructor(
        private authService: AuthService,
        private store: Store<fromStore.State>,
        private router: Router,
        public intercom: Intercom,
        private userService: UserService,
        public auth: AngularFireAuth
    ) {
        let url = window.location.href;
        if (url.includes('app.dev.clevateam.io')) {
            url = url.replace('app.dev.clevateam.io', 'app.dev.andteam.com');
            window.location.href = url;
        }
        if (url.includes('app.clevateam.io')) {
            url = url.replace('app.clevateam.io', 'app.andteam.com');
            window.location.href = url;
        }

        this.store
            .pipe(
                select(fromSelectors.getUserEntity),
                filter((user) => !!user)
            )
            .subscribe(
                (user: User) => {
                    this.contentLoading = false;
                    this.intercom.update();
                    // console.log('INITIALIZING INTERCOM FOR AUTHENTICATED USER');
                    this.intercom.boot({
                        app_id: environment.intercomAppId,
                        email: user.email,
                        user_hash: user.user_hash,
                        name: `${user.first_name} ${user.last_name}`,
                        user_type: user.role,
                        widget: {
                            activator: '#intercom'
                        }
                    });
                },
                (errorResponse) => {
                    console.error(errorResponse);
                    this.contentLoading = false;
                }
            );
        if (authService.isLoggedIn()) {
            this.contentLoading = true;
            this.store.dispatch(new fromStore.LoadUser());
            this.authService.authenticateFirebase();
        } else {
            // console.log('INITIALIZING INTERCOM FOR GUEST');
            this.intercom.boot({
                app_id: environment.intercomAppId,
                widget: {
                    activator: '#intercom'
                },
                user_type: 'user'
            });
        }

        this.authService.$unauthorized.subscribe((value) => {
            if (value) {
                console.log('UNAUTHORIZED');
                this.contentLoading = false;
            }
        });
    }
}
